import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@mui/material';

const columns: GridColDef[] = [
    { field: 'email', headerName: 'Email',flex: 1, minWidth: 150,},
    { field: 'fullName', headerName: 'Full Name',flex: 1, minWidth: 150,},
    { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 280},
    { field: 'accountId', headerName: 'Account Id',flex: 1, minWidth: 150,
      renderCell: (params : any) => {
        return <Button variant="contained" component={Link} to={"/account/"+params.row.accountId}>{params.row.accountId} </Button>
      },
    },
    { field: 'owner', headerName: 'Account Owner',flex: 1, minWidth: 150},
    { field: 'accountManager', headerName: 'Account Manager',flex: 1, minWidth: 150},
    { field: 'csm', headerName: 'CSM',flex: 1, minWidth: 150},
  ];

function createData(
    id: Number,
    accountName: string,
    accountId: Number,
    owner: string,
    accountManager: string,
    csm: string,
    email: string,
    fullName: string,
    
  ) {
    return {id, accountName, accountId, owner, accountManager, csm, email, fullName };
  }

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export const MyEmailsTable = (_props : any) =>{
    const rows : any = [];
    for (var i = 0; i < _props.emails.length; i++){
        rows.push(
            createData(
                i,
                _props.emails[i][0],
                _props.emails[i][1],
                _props.emails[i][2],
                _props.emails[i][3],
                _props.emails[i][4],
                _props.emails[i][5],
                _props.emails[i][6]
            )
        );
    }
    return (
      <>
        {_props.isLoading &&
          <CircularProgress />
        }
        {!_props.isLoading &&
        <div style={{ height: 600, width: '100%' }}>
            <DataGrid
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
        }
      </>
    );
}