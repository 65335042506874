import { Tile } from "@adjust/components";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { InfoTooltip } from "../../../../shared/components/infoTooltip/InfoTooltip";

const infoText = "Source: Salesforce | Refreshed: once a day";

export const AccountInvoices = (_props : any) => {
    let invoices = _props.invoices;
    let invoiceList = [];
    
    for (let i = 0; i < invoices.length; i++) {
        const invoice = invoices[i];
        invoiceList.push([invoice[0][0].amount,invoice[0][0].attribution,invoice[0][0].date_of_invoicing,invoice[0][0].date_of_payment,invoice[0][0].invoicing_month,invoice[0][0].status])
    }
    return(
        <Tile title="Invoices">
            <InfoTooltip message={infoText} />
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Amount</TableCell>
                        <TableCell>Attribution</TableCell>
                        <TableCell>Date of Invoicing</TableCell>
                        <TableCell>Date of Payment</TableCell>
                        <TableCell>Invoicing Month</TableCell>
                        <TableCell>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {invoiceList.map((invoice: any) => (
                    <TableRow
                    key={invoice[2]}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">{invoice[0]}</TableCell>
                        <TableCell>{invoice[1]}</TableCell>
                        <TableCell>{invoice[2]}</TableCell>
                        <TableCell>{invoice[3]}</TableCell>
                        <TableCell>{invoice[4]}</TableCell>
                        <TableCell>{invoice[5]}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </Tile>
    );
}