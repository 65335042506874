import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';



function createData(
    id: number,
    newVersionDate: string,
    newVersion: string,
    mobileAppId: string,
    platform: string,
    appToken: string,
    window: string,
    eventTypeId: string,
    eventName: string,
    eventFirstData: string,
    eventUpdatedAt: string,
    eventsBefore: string,
    eventAfter: string,
    eventRevenueBefore: string,
  ) {
    return { id,newVersionDate,newVersion,mobileAppId,platform,appToken,window,eventTypeId,eventName,eventFirstData,eventUpdatedAt,eventsBefore,eventAfter,eventRevenueBefore};
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export const EventAnomaliesTable = (_props : any) => {
    var events = _props.events;
    const rows : any = [];
    for (var i = 0; i < events.length; i++){
        rows.push(
            createData(
              i,
              events[i][0],
              events[i][1],
              events[i][2],
              events[i][3],
              events[i][4],
              events[i][5],
              events[i][6],
              events[i][7],
              events[i][8],
              events[i][9],
              events[i][10],
              events[i][11],
              events[i][12],
            )
        );
    }

    const columns: GridColDef[] = [
        { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 100,
        renderCell: (params : any) => {
            return <Button variant="contained" component={Link} to={"/account/app/"+params.row.appToken} >{params.row.appToken}</Button>;
            },
        },
        { field: 'newVersionDate', headerName: 'New Version Date',flex: 1, minWidth: 250},
        { field: 'newVersion', headerName: 'New Version',flex: 1, minWidth: 150},
        { field: 'mobileAppId', headerName: 'Mobile App Id',flex: 1, minWidth: 150},
        { field: 'platform', headerName: 'Platform',flex: 1, minWidth: 150},

        { field: 'window', headerName: 'Window',flex: 1, minWidth: 150},
        { field: 'eventTypeId', headerName: 'Event Type Id',flex: 1, minWidth: 150},
        { field: 'eventName', headerName: 'Event Name',flex: 1, minWidth: 150},
        { field: 'eventFirstData', headerName: 'Event First Data',flex: 1, minWidth: 150},
        { field: 'eventUpdatedAt', headerName: 'Event Updated At',flex: 1, minWidth: 150},
        { field: 'eventsBefore', headerName: 'Events Before',flex: 1, minWidth: 150},
        { field: 'eventAfter', headerName: 'Event After',flex: 1, minWidth: 150},
        { field: 'eventRevenueBefore', headerName: 'Event Revenue Before',flex: 1, minWidth: 150},
    ];

    return (
        <div style={{ height: 650, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}