import { Tile } from "@adjust/components"
import { Box, Button } from "@mui/material"
import { useEffect, useState } from "react";
import { formatDate } from "../../../shared/components/formatDate/FormatDate";

export const LatestReleasedAppVersion = (_props: any) => {
    const os = _props.os;
    const appToken = _props.appToken;
    const [versionAndroid,setVersionAndroid] = useState([{'date':'','description':'','version':''}]);
    const [versionIos,setVersionIos] = useState([{'date':'','description':'','version':''}]);
    const [versionAndroidAnalytics,setVersionAndroidAnalytics] = useState([{'date':'','description':'','version':''}]);
    const [versionIosAnalytics,setVersionIosAnalytics] = useState([{'date':'','description':'','version':''}]);

    useEffect(() => {
        if(versionAndroid[0].date === ''){
            setVersionAndroid(_props.newestAppVersionAndroid);
        }
        if(versionIos[0].date === ''){
            setVersionIos(_props.newestAppVersionIos);
        }
        if(versionAndroidAnalytics[0].date === ''){
            setVersionAndroidAnalytics(_props.newestAppVersionAndroidAnalytics);
        }
        if(versionIosAnalytics[0].date === ''){
            setVersionIosAnalytics(_props.newestAppVersionIosAnalytics);
        }
    }, [_props]);

    const updateVersion = () =>{
        if(appToken !== '' ){
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'appToken': appToken })
            };
            fetch('/api/updatenewestappversion', requestOptions)
                .then(response => response.json()).then(data => {
                    if(data["android"] !== ''){
                        setVersionAndroid(data.android);
                    }
                    if(data["ios"] !== ''){
                        setVersionIos(data.ios)
                    }
                });
        }
    }
    return (
        <Tile>
            <div style={{height:'200px'}}>
                <h4 style={{marginTop:'0%',marginBottom:'0%'}}>Latest Released App Version (Apptopia) </h4>
                {os === 'android' &&
                <>
                    <p style={{marginTop:'0%',marginBottom:'0%'}}>Version: {versionAndroid[0].version}</p>
                    <p style={{marginTop:'0%',marginBottom:'0%'}}>Release Date: {formatDate(versionAndroid[0].date)}</p>
                </>
                }
                {os === 'ios' &&
                <>
                    <p style={{marginTop:'0%',marginBottom:'0%'}}>Version: {versionIos[0].version}</p>
                    <p style={{marginTop:'0%',marginBottom:'0%'}}>Release Date: {formatDate(versionIos[0].date)}</p>
                </>
                }
                <Box display="flex" justifyContent="flex-start">
                    <Button variant="outlined" style={{alignItems:'right'}} onClick={updateVersion}>Refresh</Button>
                </Box>
                <h4 style={{marginBottom:'0%'}}>Latest Released App Version (Bagger Installs) </h4>
                {os === 'android' &&
                <>
                    <p style={{marginTop:'0%',marginBottom:'0%'}}>Version: {versionAndroidAnalytics[0].version}</p>
                    <p style={{marginTop:'0%',marginBottom:'0%'}}>Release Date: {formatDate(versionAndroidAnalytics[0].date)}</p>
                </>
                }
                {os === 'ios' &&
                <>
                    <p style={{marginTop:'0%',marginBottom:'0%'}}>Version: {versionIosAnalytics[0].version}</p>
                    <p style={{marginTop:'0%',marginBottom:'0%'}}>Release Date: {formatDate(versionIosAnalytics[0].date)}</p>
                </>
                }

            </div>
        </Tile>
    )
}