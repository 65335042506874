import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import {NumericFormat} from 'react-number-format';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../../shared/components/formatDate/FormatDate';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

function createData(
    id: number,
    accountId: string,
    accountName: string,
    acv: string,
    contractEndDate: string,
    contractStartDate: string,
    adRevenue: string,
    audienceBuilder: string,
    controlCenter: string,
    fps: string,
    multitouch: string,
    pulse: string,
    purchaseVerification: string,
    subscription: string,
    uninstallReinstall: string,
    webAttribution: string,
    potentialUpsell: string,
    dataResidency: string,
    smartBanners: string
  ) {
    return { id,accountId,accountName,acv,contractEndDate,contractStartDate, 
        adRevenue,audienceBuilder,controlCenter,fps,multitouch,pulse,purchaseVerification,subscription,uninstallReinstall,webAttribution,
        potentialUpsell,dataResidency,smartBanners};
  }

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
}

    const getUpsell = (value:string) =>{
        if(value === 'Yes'){
            return <CheckBoxIcon style={{color:'lightGreen'}}/>;
        }
        return <NumericFormat value={value} displayType={'text'} thousandSeparator={true}/>;
    }

  const columns: GridColDef[] = [
    { field: 'accountId', headerName: 'Account ID',flex: 1,minWidth: 150,
    renderCell: (params : any) => {
      return <Button variant="contained" component={Link} to={"/account/"+params.row.accountId} style={{width:'80px'}}>{params.row.accountId}</Button>;
        },
    },
    { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 150},
    { field: 'acv', headerName: 'Active Contract ACV',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
            return <NumericFormat value={params.row.acv} displayType={'text'} thousandSeparator={true}/>
    }},
    { field: 'contractStartDate', headerName: 'Contract Start Date',flex: 1, minWidth: 150},
    { field: 'contractEndDate', headerName: 'Contract End Date',flex: 1, minWidth: 150},
    { field: 'adRevenue', headerName: 'AdRevenue',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
            return getUpsell(params.row.adRevenue);
    }},
    { field: 'audienceBuilder', headerName: 'Audience Builder',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
            return getUpsell(params.row.audienceBuilder);
    }},
    { field: 'controlCenter', headerName: 'Control Center',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
            return getUpsell(params.row.controlCenter);
    }},
    { field: 'dataResidency', headerName: 'Data Residency',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
            return getUpsell(params.row.dataResidency);
    }},
    { field: 'fps', headerName: 'FPS',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
            return getUpsell(params.row.fps);
    }},
    { field: 'multitouch', headerName: 'Multitouch',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
            return getUpsell(params.row.multitouch);
    }},
    { field: 'pulse', headerName: 'Pulse',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
            return getUpsell(params.row.pulse);
    }},
    { field: 'purchaseVerification', headerName: 'Purchase Verification',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
            return getUpsell(params.row.purchaseVerification);
    }},
    { field: 'smartBanners', headerName: 'Smart Banners',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
            return getUpsell(params.row.smartBanners);
    }},
    { field: 'subscription', headerName: 'Subscription',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
            return getUpsell(params.row.subscription);
    }},
    { field: 'uninstallReinstall', headerName: 'Uninstall Reinstall',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
            return getUpsell(params.row.uninstallReinstall);
    }},
    { field: 'webAttribution', headerName: 'Web Attribution',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
            return getUpsell(params.row.webAttribution);
    }},
    { field: 'potentialUpsell', headerName: 'Potential Upsell',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
            return <NumericFormat value={params.row.potentialUpsell} displayType={'text'} thousandSeparator={true}/>
    }},
  ];

export const UpsellDashboardTable = (_props : any) =>{
    var data = _props.data;
    const rows : any = [];
    for (var i = 0; i < data.length; i++){
        rows.push(
            createData(
                i,
                String(data[i].accountId),
                String(data[i].accountName),
                String(data[i].acv),
                formatDate(data[i].contractEndDate),
                formatDate(data[i].contractStartDate),
                String(data[i].features.adRevenue),
                String(data[i].features.audienceBuilder),
                String(data[i].features.controlCenter),
                String(data[i].features.fps),
                String(data[i].features.multitouch),
                String(data[i].features.pulse),
                String(data[i].features.purchaseVerification),
                String(data[i].features.subscription),
                String(data[i].features.uninstallReinstall),
                String(data[i].features.webAttribution),
                String(data[i].features.potentialUpsell),
                String(data[i].features.dataResidency),
                String(data[i].features.smartBanners),
            )
        );
    }

    return (
        <div style={{ height: 600, width: '100%' , marginTop:'20px'}}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}