
import { Breadcrumbs } from '@adjust/components/build/Breadcrumbs/Breadcrumbs';
import { Box, CircularProgress} from '@mui/material';
import React from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from "react-query";
import { Account, AccountAnomaliesData } from '../../shared/components/Types/Types';
import { getData } from '../../shared/components/Hooks/AccountHooks';
import { AccountSalesSegmentedButton } from './AccountSalesSegmentedButton';
import { InfoTooltip } from '../../shared/components/infoTooltip/InfoTooltip';
import AccountAnomalies from '../../entities/components/Accounts/AccountAnomalies';

function MyBreadcrumbs(accountId:string,accountName: string,navigate:NavigateFunction) {
    const items = [
      { label: 'My Accounts', onClick: () => {navigate('/myaccounts')} },
      { label: accountName, onClick: () => {navigate('/account/' + accountId)} },
      { label: 'Anomalies', onClick: () => {navigate('/account/' + accountId + '/anomalies')}},
    ];
    return <Breadcrumbs items={items} />;
  }
  const infoText = "Source: Adjust DB / Data Science | Refreshed: instant";

export const AnomalieSalesPage = (_props:Account) => {
    let navigate = useNavigate();
    let params = useParams();let accountId  = "0";
    if (params.accountId !== undefined){
        accountId = String(params.accountId);
    }
    const { status, data } = useQuery<AccountAnomaliesData, Error>(
        ["anomaliesPage", { accountId }], () => getData(accountId,'anomaliessales'));

   
    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    {MyBreadcrumbs(accountId,_props.accountName,navigate)}
                    <AccountSalesSegmentedButton accountId = {accountId} tabValue='5' accountManager={_props.accountManager} competitor={_props.competitor} />
                    <br/>
                    {status === "loading" &&
                        <CircularProgress />
                    }
                    {status !== "loading" &&
                    <div>
                        <InfoTooltip message={infoText} />
                        <AccountAnomalies anomalies = {data?.anomalies} competitors={data?.competitors} />
                    </div>
                    }
                </Box>
    );
}