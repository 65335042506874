import { Card } from "@mui/material";
import { Chart } from "react-google-charts";

const formatDate = (dateString : string) =>{
    if(dateString === '' || dateString === null){
        return '';
    }
    return new Date(dateString).toISOString().slice(0, 10);
}

const setChartValues = (installs : any) =>{
    const data = [
        ["Date","Installs"],
        ["N/A",0],
      ];
    var rows : any = [["Date","Installs"]];
    if(installs !== undefined && installs !== '' ){
        for (let i = 0; i < installs.length; i++) {
            rows.push([
                formatDate(installs[i][0]),
                installs[i][1],
            ]);
        }
        return rows;
    }
    return data;
}

export const options = {
  chart: {
    title: "Applovin Installs"
  },
};

export const AccountApplovinInstallsChart = (props : any) =>{
    let installs = props.installs.reverse();

  return (
    <div style={{marginLeft:'5px',marginTop:'5px',marginRight:'5px'}}>
      <Card component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Chart
          chartType="Line"
          width="100%"
          height="300px"
          data={setChartValues(installs)}
          options={options}
        />
      </Card>
    </div>
  );
}
