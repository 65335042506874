import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';
import { InfoTooltip } from '../../../shared/components/infoTooltip/InfoTooltip';

const columns: GridColDef[] = [
  { field: 'action', headerName: 'Action',flex: 1, maxWidth: 150,sortable: false,
    renderCell: (params : any) => {
      return <>
          <Button variant="outlined" component={Link} to={"/account/app/"+params.row.appToken}>View App</Button>
      </>;
    },
  },
  { field: 'appName', headerName: 'App Name',flex: 1, minWidth: 100},
  { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 150},
  { field: 'enforceInstallSigning', headerName: 'Enforce SDK signature',flex: 1, minWidth: 150},
  { field: 'signatureNumber', headerName: 'Signature Number',flex: 1, minWidth: 100},
  { field: 'active', headerName: 'Is Active',flex: 1, minWidth: 100},
  { field: 'createdAt', headerName: 'Created At',flex: 1, minWidth: 100},
  { field: 'signatureVersion', headerName: 'Signature Version',flex: 1, minWidth: 100},
];

function createData(
    id: number,
    appName: string,
    appToken: string,
    signatureNumber: number,
    active: string,
    createdAt : string,
    signatureVersion: string,
    enforceInstallSigning: string

  ) {
    return { id,appName, appToken, signatureNumber, active, createdAt, signatureVersion, enforceInstallSigning };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const infoText = "Source: Adjust DB | Refreshed: instant";

export const AccountMobileSecretsTable = (_props : any) => {
    const rows : any = [];
    const secrets = _props.secrets;
    if(secrets !== undefined){
        for (var i = 0; i < secrets.length; i++){
            rows.push(
                createData(
                    i,
                    secrets[i][0],
                    secrets[i][1],
                    secrets[i][2],
                    secrets[i][3],
                    formatDate(secrets[i][4]),
                    secrets[i][5],
                    secrets[i][6],
                )
            );
        }
    }
    return (
        <div style={{ height: 800, width: '100%' }}>
            <InfoTooltip message={infoText} />
            <DataGrid
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}
