import { Box, Grid} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { AnomaliesOverviewTable } from './AnomaliesOverviewTable';
import { InfoTooltip } from '../../../shared/components/infoTooltip/InfoTooltip';

export const AnomaliesOverviewPage = () => {
    const [isLoading,setIsLoading]  = useState(false);
    const [anomalies,setAnomalies]  = useState([]);

    useEffect(() => {
        setIsLoading(true);
        fetch('/api/anomaliesoverview').then(res => res.json()).then(data => {
            setAnomalies(data["notifications"]);
            setIsLoading(false);
        });
    }, []);
    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                 <h1>My Anomalies Overview </h1>
                            </Grid>
                            <Grid item xs={12}>
                                {isLoading &&
                                <CircularProgress />
                                }

                                {!isLoading && 
                                    <>
                                        <InfoTooltip message="Source: Data Science DB | Refreshed: instant" />
                                        <AnomaliesOverviewTable anomalies={anomalies} />
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </div>
                </Box>
    );
}