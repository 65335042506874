import { Chart } from "react-google-charts";

const formatDate = (dateString : string) =>{
    if(dateString === '' || dateString === null){
        return '';
    }
    return new Date(dateString).toISOString().slice(0, 10);
}

const setChartValues = (dps : any) =>{
    const data = [
        ["Date","DPS"],
        ["N/A",0],
      ];
    var rows : any = [["Date", "Impressions", "Clicks", "Installs", "Reattributions", "Events"]];
    if(dps !== undefined && dps !== '' ){
        for (let i = 0; i < dps.length; i++) {
            rows.push([
                formatDate(dps[i][1]),
                dps[i][2],
                dps[i][3],
                dps[i][4],
                dps[i][5],
                dps[i][6],
            ]);
        }
        return rows;
    }
    return data;
}

export const options = {
  title: "Data Points splitted",
  isStacked: true,
  height: 300,
  legend: {position: 'bottom', maxLines: 3},
  vAxis: {minValue: 0}
};

export const DataPointsCompleteChart = (props : any) =>{
    let attributions = props.dps;

  return (
    <div style={{marginLeft:'5px',marginTop:'5px',marginRight:'5px'}}>
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="300px"
        data={setChartValues(attributions)}
        options={options}
      />
    </div>
  );
}

