import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

const columns: GridColDef[] = [
  { field: 'id', headerName: '',flex: 1, maxWidth:1},
  { field: 'appToken', headerName: 'App Token',flex: 1,
    renderCell: (params : any) => {
      return <Button variant="contained" component={Link} to={"/account/app/"+params.row.appToken} >{params.row.appToken}</Button>
    },
  },
  { field: 'accountId', headerName: 'Account ID',flex: 1,
    renderCell: (params : any) => {
      return <Button variant="contained" component={Link} to={"/account/"+params.row.accountId} >{params.row.accountId}</Button>
    },
  },
  { field: 'appStoreId', headerName: 'App Store Id',flex: 1},
  { field: 'version', headerName: 'Version',flex: 1,},
  { field: 'description', headerName: 'Description',flex: 1,minWidth: 400},
  { field: 'releaseDate', headerName: 'Release Date',flex: 1},
  
];

function createData(
    id: number,
    appToken: string,
    accountId: number,
    appStoreId: string,
    version: string,
    description: string,
    releaseDate: string,
  ) {
    return { id,appToken,accountId,appStoreId,version,description,releaseDate };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export default function ApptopiaAppVersionTable(_props : any) {
    const appVersions = _props.appVersions;
    const rows : any = [];
    if(appVersions !== undefined){
        for (var i = 0; i < appVersions.length; i++){
            rows.push(
                createData(
                    i,
                    appVersions[i][0],
                    appVersions[i][2],
                    appVersions[i][3],
                    appVersions[i][4],
                    appVersions[i][5],
                    formatDate(appVersions[i][6]),
                )
            );
        }
    }
    return (
        <div style={{ height: 600, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}