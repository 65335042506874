import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { EventsS2SOnlyTable } from "./EventsS2SOnlyTable";
import { InfoTooltip } from "../../../shared/components/infoTooltip/InfoTooltip";

const formatDate = (dateString : string) =>{
    if(dateString === '' || dateString === null){
        return '';
    }
    return new Date(dateString).toLocaleDateString();
}

export const EventsS2sOnlyPage = () => {
    const [events2s,setEventS2s] = useState({description:'',apps:[],updated_at: ''});
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true)
        fetch('/api/events2sonly').then(res => res.json()).then(data => {
            setEventS2s(data);
            setIsLoading(false); 
        });
    }, []);
    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                 <h1>event_s2s_only</h1>
                                 <h5>Loads for 2mins</h5>
                            
                            {isLoading &&
                            <CircularProgress />
                            }
                            {!isLoading &&
                            <>
                                <p>Description: {events2s.description}</p>
                                <p>Updated At: {formatDate(events2s.updated_at)}</p>
                                <InfoTooltip message="Source: Adjust DB | Refreshed: instant" />
                                <EventsS2SOnlyTable apps={events2s.apps} />
                            </>
                            }
                            </Grid>
                        </Grid>
                    </div>
                </Box>
    );
}