import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from '../../../../shared/components/formatDate/FormatDate';

function createData(
    id: number,
    amName: string,
    caseId: string,
    amMail: string,
    subject: string,
    createdAt: string,
    accountId: string,
    accountName: string,
    acvAmount: string,
  ) {
    return { id, amName, caseId, amMail, subject, createdAt, accountId, accountName, acvAmount };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export const CaseOverviewAllCasesTable = (_props : any) =>{
    let ams = _props.ams;
    let support = _props.support;
    const rows : any = [];
    let counter = 0;
    if(support.length > 0){
        support = support[0];
        for (var i = 0; i < support.length; i++){
            let amName = support[i][0];
            let cases = support[i][1];
            for (var j = 0; j < cases.length; j++){
                counter = counter + 1;
                rows.push(
                    createData(
                        counter,
                        amName,
                        cases[j][0][0],
                        cases[j][0][5],
                        cases[j][0][3],
                        formatDate(cases[j][0][4]),
                        cases[j][0][6],
                        cases[j][0][7],
                        cases[j][0][8],
                    )
                );
            }
        }
    }
    
    if(ams.length > 0){
        ams = ams[0];
        for (i = 0; i < ams.length; i++){
            let amName = ams[i][0];
            let cases = ams[i][1];
            console.log(cases)
            for (j = 0; j < cases.length; j++){
                counter = counter + 1;
                rows.push(
                    createData(
                        counter,
                        amName,
                        cases[j][0][0],
                        cases[j][0][5],
                        cases[j][0][3],
                        formatDate(cases[j][0][4]),
                        cases[j][0][6],
                        cases[j][0][7],
                        cases[j][0][8],
                    )
                );
            }
        }
    }
    console.log(rows)
    const columns: GridColDef[] = [
        { field: 'amName', headerName: 'Am Name',flex: 1, minWidth: 150},
        { field: 'caseId', headerName: 'Case Id',flex: 1, minWidth: 150},
        { field: 'amMail', headerName: 'AM',flex: 1, minWidth: 250},
        { field: 'subject', headerName: 'Subject',flex: 1, minWidth: 250},
        { field: 'createdAt', headerName: 'Created At',flex: 1, minWidth: 250},
        { field: 'accountId', headerName: 'Account ID',flex: 1, minWidth: 250},
        { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 250},
        { field: 'acvAmount', headerName: 'Acv Amount',flex: 1, minWidth: 250},
      ];
      
    return (
        <div style={{ height: 450, width: '100%', marginTop:'3%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}