import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { InfoTooltip } from '../../../shared/components/infoTooltip/InfoTooltip';

const setNumber = (value : any) => {
  if(value === '' || value === null || value === undefined){
    return '';
  }
  value = Math.round((parseFloat(value) / 24) * 100) / 100;
  return String(value) + " days";
}

function checkmark(value : string){
    if(String(value) === 'true'){
        return 'True';
    }
    return '';
}

const columns: GridColDef[] = [
  { field: 'action', headerName: 'Action',flex: 1, minWidth: 150,sortable: false,
    renderCell: (params : any) => {
      return <>
          <Button variant="outlined" component={Link} to={"/account/app/"+params.row.appToken}>View App</Button>
      </>;
    },
  },
  { field: 'appName', headerName: 'App Name',flex: 1, minWidth: 200},
  { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 150},
  { field: 'platform', headerName: 'Platform',flex: 1, minWidth: 150},

  { field: 'impressionWindow', headerName: 'Impression Window',flex: 1, minWidth: 220},
  { field: 'clickWindow', headerName: 'Click Window',flex: 1, minWidth: 220},
  { field: 'fingerprintWindow', headerName: 'Probabilistic Window',flex: 1, minWidth: 220},
  { field: 'reattributionWindow', headerName: 'Reattribution Window',flex: 1, minWidth: 220},
  { field: 'reattributionAttributionWindow', headerName: 'Reattribution Attribution Window',flex: 1, minWidth: 220},
  { field: 'impressionsTrackingEnabled', headerName: 'Impression Tracking Enabled',flex: 1, minWidth: 220},
  { field: 'fingerprintEnabled', headerName: 'Probabilistic Enabled',flex: 1, minWidth: 220},
  { field: 'reattributtionEnabled', headerName: 'Reattribution Enabled',flex: 1, minWidth: 220},
  { field: 'impressionFingerprintWindow', headerName: 'Impression Probabilistic Window',flex: 1, minWidth: 220},
  { field: 'impressionFingerprintEnabled', headerName: 'Impression Probabilistic Enabled',flex: 1, minWidth: 220},
  { field: 'impressionReattributionWindow', headerName: 'Impression Reattribution Window',flex: 1, minWidth: 220},
  { field: 'impressionReattributionAttributionWindow', headerName: 'Impression Reattribution Attribution Window',flex: 1, minWidth: 220},
  { field: 'impressionReattributionEnabled', headerName: 'Impression Reattribution Enabled',flex: 1, minWidth: 220},
  { field: 'clickTemporaryAttributionEnabled', headerName: 'Click Temporary Attribution Enabled',flex: 1, minWidth: 220},
  { field: 'clickTemporaryAttributionWindow', headerName: 'Click Temporary Attribution Window',flex: 1, minWidth: 220},
  { field: 'impressionTemporaryAttributionEnabled', headerName: 'Impression Temporary Attribution Enabled',flex: 1, minWidth: 220},
  { field: 'impressionTemporaryAttributionWindow', headerName: 'Impression Temporary Attribution Window',flex: 1, minWidth: 220},  
  { field: 'attributionPrivacyModel', headerName: 'Attribution Privacy Model',flex: 1, minWidth: 220},  
  
];

function createData(
    id: number,
    appToken: string,
    appName: string,
    accountId: string,

    impressionWindow: string,
    clickWindow : string,
    fingerprintWindow: string,
    reattributionWindow: string,
    reattributionAttributionWindow: string,
    impressionsTrackingEnabled: string,
    fingerprintEnabled: string,
    reattributtionEnabled: string,
    impressionFingerprintWindow: string,
    impressionFingerprintEnabled: string,
    impressionReattributionWindow: string,
    impressionReattributionAttributionWindow: string,
    impressionReattributionEnabled: string,
    clickTemporaryAttributionEnabled: string,
    clickTemporaryAttributionWindow: string,
    impressionTemporaryAttributionEnabled: string,
    impressionTemporaryAttributionWindow: string,
    platform: string,
    attributionPrivacyModel: number
  ) {
    return { id, appToken, appName, accountId, impressionWindow, clickWindow, fingerprintWindow, reattributionWindow,reattributionAttributionWindow,
        impressionsTrackingEnabled,fingerprintEnabled,reattributtionEnabled,impressionFingerprintWindow,impressionFingerprintEnabled,impressionReattributionWindow,impressionReattributionAttributionWindow,impressionReattributionEnabled,
        clickTemporaryAttributionEnabled,clickTemporaryAttributionWindow,impressionTemporaryAttributionEnabled,impressionTemporaryAttributionWindow,platform,attributionPrivacyModel };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const infoText = "Source: Adjust DB | Refreshed: instant";
  
export const AccountAttributionSettingsTable = (_props : any) =>{
    const apps = _props.apps
    const rows : any = [];
    if(apps !== undefined){
        for (var i = 0; i < apps.length; i++){
            rows.push(
                createData(
                    i,
                    apps[i][0],
                    apps[i][1],
                    apps[i][2],
                    setNumber(apps[i][3]),
                    setNumber(apps[i][4]),
                    setNumber(apps[i][5]),
                    setNumber(apps[i][6]),
                    setNumber(apps[i][7]),
                    checkmark(apps[i][8]),
                    checkmark(apps[i][9]),
                    checkmark(apps[i][10]),
                    setNumber(apps[i][11]),
                    checkmark(apps[i][12]),
                    setNumber(apps[i][13]),
                    setNumber(apps[i][14]),
                    checkmark(apps[i][15]),
                    apps[i][16]["enabled"],
                    setNumber(apps[i][16]["window"]),
                    apps[i][17]["enabled"],
                    setNumber(apps[i][17]["window"]),
                    apps[i][19],
                    apps[i][18]
                )
            );
        }
    }
    return (
        <div style={{ height: 800, width: '100%' }}>
            <InfoTooltip message={infoText} />
            <DataGrid
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}
