import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';


const formatDate = (dateString : string) =>{
    if(dateString === '' || dateString === null){
        return '';
    }
    return new Date(dateString).toLocaleDateString();
}

function createData(
    id: number,
    createdAt : string, 
    events : string, 
    itemId : number,
    itemType : string,
    object : string,
    objectChanges : string,
    whodunnit : string,
    user: string
    
  ) {
    return { id,createdAt, events, itemId, itemType, object, objectChanges,whodunnit,user};
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export const VersionsTable = (_props : any) => {
    var versions = _props.versions;
    console.log(versions)
    const rows : any = [];
    for (var i = 0; i < versions.length; i++){
        rows.push(
            createData(
              i,
              formatDate(versions[i][0]), 
              versions[i][1],
              versions[i][3],
              versions[i][4],
              versions[i][5],
              versions[i][6],
              versions[i][7],
              versions[i][8]
            )
        );
    }

    const columns: GridColDef[] = [
      { field: 'createdAt', headerName: 'Created At',flex: 1, minWidth: 50},
      { field: 'user', headerName: 'Changed By',flex: 1, minWidth: 50},
      { field: 'events', headerName: 'Event',flex: 1, minWidth: 50},
      { field: 'itemId', headerName: 'Account Id',flex: 1, minWidth: 50},
      { field: 'object', headerName: 'Object',flex: 1, minWidth: 250},
      { field: 'objectChanges', headerName: 'Object Changes',flex: 1, minWidth: 250},
      { field: 'whodunnit', headerName: 'Whodunnit',flex: 1, minWidth: 150},
    ];

    return (
        <div style={{ height: 650, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}