import { ComboBox } from '@adjust/components';
import React,{useState} from 'react'

function createData(
    id,
    value,
    label
  ) {
    return { id, value, label };
  }

export const LabelInput = (_props) =>{
    const rows = [];
    rows.push(createData('publisher','publisher',"publisher (client's callbacks)"));
    rows.push(createData('network','network',"network (dynamic integration's callbacks)"))
    for (var i = 0; i < _props.partners.length; i++){
        rows.push(
            createData(
                i,
                _props.partners[i][0],
                _props.partners[i][0]
            )
        );

    }
    const [value, setValue] = useState([]);
  
    const handleChange = (value) => {
      setValue(value);
      _props.onLabelsChange(value);
    }
  
    return (
      <ComboBox
        multiple
        value={value}
        options={rows}
        onChange={handleChange}
        placeholder="Label"
      />
    );
  }