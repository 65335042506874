import React from 'react';
import HistoryModalAccount from './HistoryModalAccount';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';
import { InfoTooltip } from '../../../shared/components/infoTooltip/InfoTooltip';

const columns: GridColDef[] = [
    { field: 'user', headerName: 'User',flex: 1, minWidth: 180},
    { field: 'activity', headerName: 'Activity',flex: 1, minWidth: 100},
    { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 150},
    { field: 'action', headerName: 'Settings',flex: 1, minWidth: 120,
        renderCell: (params : any) => {
            return isInternal(params.row.action, params.row.internal);
        }
    },
    { field: 'description', headerName: 'Description',flex: 1, minWidth: 420},
    { field: 'created_at', headerName: 'Created At',flex: 1, minWidth: 50},
    { field: 'internal', headerName: 'Action',flex: 1, minWidth: 180,sortable: false,
        renderCell: (params : any) => {
        return <>
            <HistoryModalAccount parameters={params.row.parameters} originalState={params.row.originalState} />
        </>;
        },
    },
    { field: 'parametersString', headerName: 'After Change', maxWidth: 0,
        renderCell: (params : any) => {
        return <>
            <>{params.row.parametersString}</>
        </>;
        },
    },
    { field: 'originalStateString', headerName: 'Before Change', maxWidth: 0,
        renderCell: (params : any) => {
        return <>
            <>{params.row.originalStateString}</>
        </>;
        },
    },
    
  ];

function createData(
    id: number,
    description: string,
    created_at: string,
    activity: string,
    user: string,
    internal: string,
    action: boolean,
    parameters: any,
    originalState: any,
    appToken: string,
    parametersString: string,
    originalStateString: string,
  ){
    return { id, description, created_at, activity, user, internal, action, parameters, originalState, appToken, parametersString, originalStateString };
  }

const isAppToken = (historyWithAppToken : any) => {
    if(historyWithAppToken === 'app') {
        if ("app_token" in historyWithAppToken){
            return historyWithAppToken["app_token"];
        }
    }
    return "-";  
}

const isInternal = (action : any, internal : any) =>{
    if(internal === true || internal === 'true'){
        return <div style={{color:'grey'}}>{action}</div>
    }
    return <div style={{color:'rgb(8, 209, 255)'}}>{action}</div>
}

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const infoText = "Source: Adjust DB | Refreshed: instant";
  
export default function AccountUsersHistoryTable(_props : any) {
    var history = _props.history;
    const historyData : any = [];
    if(history !== undefined){
        for (var i = 0; i < history.length; i++){
            historyData.push(
                createData(
                    i,
                    history[i][0].toUpperCase(),
                    formatDate(history[i][1]),
                    history[i][2].toUpperCase(),
                    history[i][3].toUpperCase(),
                    history[i][4],
                    history[i][5].toUpperCase(),
                    history[i][6],
                    history[i][7],
                    isAppToken(history[i][6]),
                    JSON.stringify(history[i][6]),
                    JSON.stringify(history[i][7]),
                )
            );
        }
    }
    return (
        <div style={{ height: 500, width: '100%' }}>
            <InfoTooltip message={infoText} />
            <DataGrid 
                rows={historyData} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            /> 
        </div>
    )
  }