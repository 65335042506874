import { Box, Button, Dialog, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { ImplementationDataTable } from "./ImplementationDataTable";

export const ImplementationModal = (_props : any) => {
    const data = _props.data;
    const type = _props.type;
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            {(data !== '' && data !== undefined && data.length !== 0) &&
            <>
                <Button onClick={handleOpen} variant="outlined">Inspect</Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth="xl"
                    fullWidth
                >
                    <Box >
                    <Typography id="dialog-description">
                        <Grid container xs={12} >
                            <Grid item xs={12}>
                            <div style={{ height: 600 }}>
                                <ImplementationDataTable data={data} type={type} />
                            </div>
                            </Grid>
                        </Grid>
                    </Typography>
                    </Box>
                </Dialog>
            </>
            }
        </div>
    );
}