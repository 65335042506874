import { Box, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { UpdatesTableSales } from '../../entities/components/Overview/UpdatesTableSales';

export const OverviewPageSales = () => {
    const [data, setData] = useState({'name':'','notifications':[]});
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        setIsLoading(true);
        fetch('/api/overviewsales').then(res => res.json()).then(data => {
            setData(data);
            setIsLoading(false);
        });
    }, []);

    return (
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <div>
                    <h1 style={{marginTop:'0px'}}>My Overview</h1>
                    <h3>Hi {data.name}, today you have {data.notifications.length} tasks to review</h3>
                    {isLoading &&
                        <CircularProgress />
                    }
                    {!isLoading &&
                    <>
                        <UpdatesTableSales updates = {data.notifications} />
                    </>
                    }
                </div>
            </Box>
    );
}