import { Button, Input } from '@adjust/components';
import { Tile } from '@adjust/components/build/Tile/Tile';
import { Box, Card, Grid} from '@mui/material';
import { useState } from 'react';

export default function FacebookSignedPage() {
    const [appToken, setAppToken] = useState('');
    const [fbSigned,setFbSigned] = useState({});

    function searchFbSignedInfo(appToken : any){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"appToken" : appToken })
        };
        fetch('/api/fbsigned', requestOptions)
            .then(response => response.json()).then(data => {
                setFbSigned(data);
            });
    }

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                 <h1>Check if FB T&C are signed for the app:</h1>
                            </Grid>
                            <Grid item xs={12}>
                                <Tile title="Check if FB T&C are signed for the app:">
                                <Input
                                    label="App Token"
                                    id="appToken" 
                                    name="appToken"
                                    value={appToken}
                                    css={{ width: "40%"}}
                                    onClear={() => {
                                        setAppToken('');
                                    }}
                                    onChange={(e) => {
                                        setAppToken(e.target.value);
                                    }}
                                />
                                <br/>
                                <Button  type="submit" kind="primary" label="Search"  onClick={() => searchFbSignedInfo(appToken)}></Button>{' '}
                                </Tile>
                            </Grid>

                            <Grid item xs={12}>
                                <Card>
                                    <pre>{JSON.stringify(fbSigned, null, 2)}</pre>
                                </Card>
                            </Grid>
                        </Grid>
                           
                    </div>
                </Box>
    );
}