import { Box} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ClickSpamNetworkTable } from '../../../entities/components/ClickSpam/ClickSpamNetworkTable';
import CircularProgress from '@mui/material/CircularProgress';
import { InfoTooltip } from '../../../shared/components/infoTooltip/InfoTooltip';

export default function ClickSpamNetworkPage() {
    const [clickSpam, setClickSpam] = useState([]);
    useEffect(() => {
        fetch('/api/clickspamnetwork').then(res => res.json()).then(data => {
            setClickSpam(data['clickSpam']);
        });
    }, []);
    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <h1>Click Spam Network</h1>
                    </div>
                    {clickSpam.length > 0 &&
                    <>
                        <InfoTooltip message="Source: Data Science DB | Refreshed: instant" />
                        <ClickSpamNetworkTable data={clickSpam}></ClickSpamNetworkTable>
                    </>
                    }
                    {clickSpam.length === 0 &&
                    <CircularProgress />
                    }
                </Box>
    );
}