import { Tile } from "@adjust/components";
import { Box, Button, Divider, Grid, Modal, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import { InfoTooltip } from "../../../../shared/components/infoTooltip/InfoTooltip";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const infoText = "Source: Salesforce | Refreshed: once a day";

export const AccountHandoffs = (_props : any) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    let handoffs = _props.handoffs;
    if(handoffs === undefined){
       return( <div></div>);
    }
    return(
        <Tile title="Handoffs">
            <InfoTooltip message={infoText} />
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Handoff</TableCell>
                        <TableCell>Preferable Support Language</TableCell>
                        <TableCell>Show</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {handoffs.map((handoff: any) => (
                    <TableRow
                    key={handoff[5]}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">{handoff[5]}</TableCell>
                        <TableCell component="th" scope="row">{handoff[10]}</TableCell>
                        <TableCell component="th" scope="row">
                            <Button variant="contained" onClick={handleOpen}>Show Handoff</Button>
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={style}>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Handoff: {handoff[5]}
                                    </Typography>
                                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                Kids App - COPPA needed: {handoff[9]}
                                                <br/><br/>
                                                Internal SDK Dev Team?: {handoff[11]}
                                                <br/>
                                                <Divider  sx={{ bgcolor: "black" }}/>
                                                Internal Marketing Team: {handoff[13]}
                                                <br/>
                                                <Divider  sx={{ bgcolor: "black" }}/>
                                                Internal BI Tech Team?: {handoff[12]}
                                                <br/><br/>
                                                Tech-Savviness: {handoff[14]}
                                                <br/>
                                                <Divider  sx={{ bgcolor: "black" }}/>
                                                App Monetization: {handoff[3]}
                                                <br/>
                                                <Divider  sx={{ bgcolor: "black" }}/>
                                                Revenue Tracking: {handoff[15]}
                                                <br/>
                                                <Divider  sx={{ bgcolor: "black" }}/>
                                                Development Platforms: {handoff[6]}
                                                <br/>
                                                <Divider  sx={{ bgcolor: "black" }}/>
                                                Internal Tracking System: {handoff[7]}
                                                <br/>
                                                <Divider  sx={{ bgcolor: "black" }}/>
                                                App Development Cycle: {handoff[2]}
                                                <br/>
                                                <Divider  sx={{ bgcolor: "black" }}/>
                                                SDK Development Cycle: {handoff[16]}
                                                <br/>
                                                <Divider  sx={{ bgcolor: "black" }}/>
                                                Vertical (from Account): {handoff[8]}
                                                <br/>

                                            </Grid>

                                            <Grid item xs={6}>
                                                Preferable Support Languages: {handoff[10]}
                                                <br/>
                                                <Divider  sx={{ bgcolor: "black" }}/>
                                                Office City: {handoff[21]}
                                                <br/>
                                                <Divider  sx={{ bgcolor: "black" }}/>
                                                Office Country: {handoff[20]}
                                                <br/><br/>
                                                Agency: {handoff[1]}
                                                <br/><br/>
                                                Which Cloud Services?: {handoff[17]}
                                                <br/>
                                                <Divider  sx={{ bgcolor: "black" }}/>
                                                App Monetization (Other): {handoff[4]}
                                                <br/>
                                                <Divider  sx={{ bgcolor: "black" }}/>
                                                Verify Purchases?: {handoff[18]}
                                                <br/>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <br/>
                                                Client Follow Ups: 
                                                <br/>
                                                {handoff[19]}
                                            </Grid>
                                        </Grid>
                                    </Typography>
                                </Box>
                            </Modal> 
                        </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </Tile>
    );
}