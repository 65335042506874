import { Breadcrumbs } from '@adjust/components/build/Breadcrumbs/Breadcrumbs';
import { useNavigate } from 'react-router-dom';
import { AppSegmentedButton } from '../../../shared/components/appSegmentedButton/AppSegmentedButton';
import { Grid } from '@mui/material';
import AppInfo from '../../../shared/components/appInfo/AppInfo';
import { Tile } from '@adjust/components';
import { useEffect, useState } from 'react';
import SdkModuleTable from '../../../entities/components/Apps/SdkModuleTable';
import { InfoTooltip } from '../../../shared/components/infoTooltip/InfoTooltip';

function MyBreadcrumbs(accountId:any,accountName:any,appToken:any,appName:any) {
    let navigate = useNavigate();
    const items = [
      { label: 'My Accounts', onClick: () => {navigate('/myaccounts')}},
      { label: accountName, onClick: () => {navigate('/account/' + accountId )}},
      { label: 'Apps', onClick: () => {navigate('/account/' + accountId + '/apps' )}},
      { label: appName, onClick: () => {navigate('/account/' + appToken + '/app' )}},
      { label: 'App Sdks', onClick: () => {navigate('/account/' + appToken + '/appsdks' )}},
    ];
    return <Breadcrumbs items={items} />;
}

export const AppSdks = (_props:any) => {
    const appToken = _props.appToken;
    const appInfo = _props.appInfo;
    const accountName = _props.accountName ;
    
    const [sdks, setSdks] = useState([]);
    useEffect(() => {
          fetch('/api/appsdks/'+appToken).then(res => res.json()).then(data => {
            setSdks(data["sdkModules"]);
          });
    }, [appToken]);
    return (
        <div>
            {MyBreadcrumbs(appInfo[3],accountName,appInfo[2],appInfo[1])}
            <AppSegmentedButton appToken = {appToken} tabValue='13' />
            <br/><br/>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Grid container spacing={6}>
                        <Grid item xs={4}>
                            <AppInfo appInfo={appInfo} accountName={accountName} />
                        </Grid> 
                        <Grid item xs={8}>
                            <Tile title="SDK Modules">
                                <InfoTooltip message="Source: Internal DB | Refreshed: manually" />
                                <SdkModuleTable data = {sdks} />
                            </Tile>
                        </Grid> 
                    </Grid>
                </Grid>
            </Grid>
        </div>
);
}