import React, { useContext } from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDate } from '../../shared/components/formatDate/FormatDate';
import { AuthContext } from '../../app/store/AuthContext';

function createData(
    id: number,
    accountId: number,
    accountName: string,
    appName: string,
    appToken: string,
    createdAt: string,
    type: string,
    typeSales: string
  ) {
    return { id,accountId,accountName,appName,appToken,createdAt,type, typeSales };
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const columnsSales: GridColDef[] = [
    { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 300},
    { field: 'accountId', headerName: 'Account ID',flex: 1, minWidth: 100,
    renderCell: (params : any) => {
      return <Button variant="contained" component={Link} to={"/account/"+params.row.accountId} >{params.row.accountId}</Button>;
        },
    },
    { field: 'type', headerName: 'Type',flex: 1, minWidth: 200},
    { field: 'createdAt', headerName: 'Created At',flex: 1, minWidth: 200},
    { field: 'typeSales', headerName: 'Type',flex: 1, minWidth: 150},
  ];

  const columns: GridColDef[] = [
    { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 300},
    { field: 'accountId', headerName: 'Account ID',flex: 1, minWidth: 100,
    renderCell: (params : any) => {
      return <Button variant="contained" component={Link} to={"/account/"+params.row.accountId} >{params.row.accountId}</Button>;
        },
    },
    { field: 'appName', headerName: 'App Name',flex: 1, minWidth: 200},
    { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 150,
    renderCell: (params : any) => {
      return <Button variant="contained" component={Link} to={"/account/app"+params.row.appToken} >{params.row.appToken}</Button>;
        },
    },
    { field: 'createdAt', headerName: 'Created At',flex: 1, minWidth: 200},
    { field: 'type', headerName: 'Type',flex: 1, minWidth: 150},
  ];


  const typeToText = (typeId: number, type: string) => {
    if(typeId === 1){
        return 'Anomalie';
    }
    if(typeId === 2){
        return 'Failed Callbacks';
    }
    if(typeId === 3){
        return 'Click Spam';
    }
    if(typeId === 4){
        return 'Bagger Api ' + type;
    }
    if(typeId === 5){
        return 'SAN Analysis';
    }
    if(typeId === 10){
      return 'Sales';
    }
    return ''
  }

export const NotificationTable = (_props : any) =>{
    const value = useContext(AuthContext);
    const role = value?.auth.role;
    var notifications = _props.notifications;
    const rows : any = [];
    for (var i = 0; i < notifications.length; i++){
        rows.push(
            createData(
                i,
                notifications[i]["accountId"],
                notifications[i]["accountName"],
                notifications[i]["appName"],
                notifications[i]["appToken"],
                formatDate(notifications[i]["createdAt"]),
                typeToText(notifications[i]["typeId"],notifications[i]["type"]),
                notifications[i]["type"]
            )
        );
    }
    return (
        <div style={{ height: 650, width: '100%' }}>
          {(role === '4' || role === '5' || role === '10') &&
            <DataGrid 
              rows={rows} 
              columns={columnsSales}
              components={{
                  Toolbar: CustomToolbar,
              }}
          />         
          }
          {(role !== '4' && role !== '5' && role !== '10') &&
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
            }
        </div>
    );
}