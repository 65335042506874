import { Breadcrumbs } from '@adjust/components/build/Breadcrumbs/Breadcrumbs';
import { Tile } from '@adjust/components/build/Tile/Tile';
import { Grid} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DeepLinkTable from '../../../entities/components/Apps/DeepLinkTable';
import { FraudTable } from '../../../entities/components/Apps/FraudTable';
import AppSetupTable from '../../../entities/components/Integration/AppSetup';
import AppInfo from '../../../shared/components/appInfo/AppInfo';
import { AppSegmentedButton } from '../../../shared/components/appSegmentedButton/AppSegmentedButton';
import { MobileAppSecretTable } from '../../../entities/components/Apps/MobileAppSecretTable';
import { InfoTooltip } from '../../../shared/components/infoTooltip/InfoTooltip';

function MyBreadcrumbs(accountId:any,accountName:any,appToken:any,appName:any) {
    let navigate = useNavigate();
    const items = [
      { label: 'My Accounts', onClick: () => {navigate('/myaccounts')}},
      { label: accountName, onClick: () => {navigate('/account/' + accountId)}},
      { label: 'Apps', onClick: () => {navigate('/account/' + accountId + '/apps')}},
      { label: appName, onClick: () => {navigate('/account/app/'+ appToken)}},
      { label: 'Settings', onClick: () => {navigate('/account/app/'+appToken + '/settings')}},
    ];
    return <Breadcrumbs items={items} />;
}

export const AppSettingsPage = (_props:any) => {
    const appToken = _props.appToken;
    const appInfo = _props.appInfo;
    const accountName = _props.accountName ;
    const [fraud, setFraud] = useState([]);
    const [deepLink, setDeepLink] = useState(['','']);
    const [mobileAppSecrets, setMobileAppSecrets] = useState([]);
    useEffect(() => {
        fetch('/api/appsettings/'+appToken).then(res => res.json()).then(data => {
            setFraud(data["fraud"]);
            setDeepLink(data["deepLink"]);
            setMobileAppSecrets(data["mobileAppSecrets"]);
        });
    }, [appToken]);

    const checkDeeplink = () =>{
        if(deepLink[0] === '' && deepLink[1] === '' && deepLink[2] === ''){
            return false;
        }
        return true;
    }
    return (
                    <div>
                        {MyBreadcrumbs(appInfo[3],accountName,appInfo[2],appInfo[1])}
                        <AppSegmentedButton appToken = {appToken} tabValue='2' />
                        <br/><br/>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Grid container spacing={6}>
                                    <Grid item xs={4}>
                                        <AppInfo appInfo={appInfo} accountName={accountName} />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Grid item xs={12}>
                                            <Tile title="App Setup">
                                                <InfoTooltip message="Source: Adjust DB | Refreshed: instant" />
                                                <AppSetupTable data={appInfo[2]} />
                                            </Tile>
                                        </Grid>
                                        <br/><br/>
                                        <Grid item xs={12}>
                                                <Tile title="Protection">
                                                    {fraud.length > 1 &&
                                                    <>
                                                        <InfoTooltip message="Source: Adjust DB | Refreshed: instant" />
                                                        <FraudTable fraud={fraud} android={appInfo[7][0]}/>
                                                    </>
                                                    }
                                                </Tile >
                                                <br/><br/>
                                                {checkDeeplink() &&
                                                <Tile title="Deeplink Settings">
                                                    <InfoTooltip message="Source: Adjust DB | Refreshed: instant" />
                                                    <DeepLinkTable deepLink={deepLink}/>
                                                </Tile >
                                                }
                                                <br/><br/>
                                                <Tile title="Mobile App Secrets">
                                                    <InfoTooltip message="Source: Adjust DB | Refreshed: instant" />
                                                    <MobileAppSecretTable secrets={mobileAppSecrets} />
                                                </Tile >
                                        </Grid>
                                        
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
    );
}