export async function getNotificationCenterData() {
    const response = await fetch('/api/notificationcenter');
    const data = await response.json();
    return data;
}


export async function setNotificationCenterAsRead() {
    await fetch('/api/readnotification');
    return;
}