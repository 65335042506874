import { Button, Input } from '@adjust/components';
import { Tile } from '@adjust/components/build/Tile/Tile';
import { Box, Card, Grid} from '@mui/material';
import React, { useState } from 'react';

export default function CheckDevicePage() {
    const [idfa, setIdfa] = useState('');
    const [deviceInfo,setDeviceInfo] = useState({});

    function searchDeviceInfo(idfa : any){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"idfa" : idfa })
        };
        fetch('/api/checkdevice', requestOptions)
            .then(response => response.json()).then(data => {
                setDeviceInfo(data);
            });
    }

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                 <h1>Check device across all apps:</h1>
                            </Grid>
                            <Grid item xs={12}>
                                <Tile title="Check device across all apps:">
                                <Input
                                    label="IDFA"
                                    id="idfa" 
                                    name="idfa"
                                    value={idfa}
                                    css={{ width: "40%"}}
                                    onClear={() => {
                                        setIdfa('');
                                    }}
                                    onChange={(e) => {
                                        setIdfa(e.target.value);
                                    }}
                                />
                                <br/>
                                <Button  type="submit" kind="primary" label="Search"  onClick={() => searchDeviceInfo(idfa)}></Button>{' '}
                                </Tile>
                            </Grid>

                            <Grid item xs={12}>
                                <Card>
                                    <pre>{JSON.stringify(deviceInfo, null, 2)}</pre>
                                </Card>
                            </Grid>
                        </Grid>
                           
                    </div>
                </Box>
    );
}