import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';
import { AppSanAnalysisModal } from './AppSanAnalysisModal';

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
}

const columns: GridColDef[] = [
    { field: 'accountId', headerName: 'Account ID',flex: 1, minWidth: 150,sortable: false,
        renderCell: (params : any) => {
            return <AppSanAnalysisModal text={params.row.text} advertisingid={params.row.advertisingid} ></AppSanAnalysisModal>;
        },
    },     
    { field: 'startTime', headerName: 'Start Date',flex: 1, minWidth: 180},
    { field: 'grepWord', headerName: 'Grep Word',flex: 1, minWidth: 150},
    { field: 'clickWindow', headerName: 'Click Window',flex: 1, minWidth: 150},
    { field: 'impressionWindow', headerName: 'Impression Window',flex: 1, minWidth: 150},
    { field: 'san', headerName: 'SAN',flex: 1, minWidth: 150},
    { field: 'idfa', headerName: 'idfa',flex: 1, minWidth: 150},
  ];

function createData(
    id: number,
    startTime: string,
    grepWord: string,
    clickWindow: number,
    impressionWindow: number,
    san: string,
    idfa: string,
    text : string,
    advertisingid: string,
  ){
    return { id, startTime, grepWord, clickWindow, impressionWindow, san, idfa, text, advertisingid };
  }

export const AppSanAnalysisTable = (props : any) =>{
    var sanData = props.data;
    const data : any = [];
    if(sanData !== undefined){
        for (var i = 0; i < sanData.length; i++){
            //console.log(sanData[i][5])
            data.push(
                createData(
                    i,
                    formatDate(sanData[i][0]),
                    sanData[i][1],
                    sanData[i][2],
                    sanData[i][3],
                    sanData[i][4],
                    sanData[i][5]["idfa"],
                    sanData[i][5]["text"],
                    sanData[i][5]["advertisingid"]
                )
            );
        }
    }
    return (
        <div style={{height: 600, width: '100%'}}>
                <DataGrid 
                    rows={data} 
                    columns={columns}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                /> 
        </div>
    );
}