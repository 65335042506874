import { Box, Button, Dialog, Grid } from "@mui/material";
import { useState } from "react";

export default function AdNetworkJsonModal(_props : any){
    let jsonData = _props.data;
    let jsonDataCleaned = '';
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    for (var i = 0; i < jsonData.length; i++){
        if(jsonData[i].action_type === "mobile_app_install"){
            jsonDataCleaned = jsonData[i];
        }
    }
    return <>
            <Button onClick={handleOpen} style={{width:'100%'}} variant="outlined">Show JSON</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
            >
                <Box >
                    <Grid container xs={12} >
                        <Grid item xs={12}>
                            <pre>{JSON.stringify(jsonDataCleaned, null, 2)}</pre>;
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </>
}