import { Button } from "@mui/material"
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'
import { formatDate } from "../../../shared/components/formatDate/FormatDate";
import { S2sEventCheck, S2sReportCheck, WebAttributionCheck } from "./StatusCheckOthers";


const getId = (ids : any) => {
    if(ids !== undefined){
        if(ids.length === 0 || ids === null){
            return '';
        }
        if(ids[0] !== ''){
            return ids[0];
        }
        if(ids[1] !== ''){
            return ids[1];
        }
    }
    return '';
}

const setStatusToText = (status: number) => {
    if(status === 0 || status === 3){
        return 'Data not Availble ';
    }
    return 'Data Availble';
}

export const PdfCreatorButtonOthers = (_props : any) => {
    let clientName = _props.clientName;
    let appName = _props.appName;
    let appToken = _props.appToken;
    let lastRunTime = _props.lastRunTime;
    let os = _props.os;
    let data = _props.data;

    const WebAttribution = WebAttributionCheck(data.lastRunTime,data.webAttribution);
    const S2sEvent = S2sEventCheck(data.lastRunTime,data.s2sEventTracking);
    const S2sReport = S2sReportCheck(data.lastRunTime,data.s2sErrorReport);
    let logo = "https://www.apptrace.com/api/app/"+getId(_props.logo)+"/artwork_url_small";

    const downloadPdfDocument = () => {
        const pdf = new jsPDF();
        pdf.text("CLient: " + clientName, 10, 10);
        pdf.text("App Name: " + appName, 10, 20);
        pdf.text("App Token: " + appToken, 10, 30);
        pdf.text("Last running time: " + formatDate(lastRunTime), 10, 40);
        pdf.text("OS: " + os, 10, 50);
        var img = new Image();
        img.src = logo;
        //pdf.addImage(img, "PNG", 150, 10, 32, 32);
        autoTable(pdf, {
            head: [["SDK Component","Last Time Checked","Status"]],
            body: [
                ['Webattribution',formatDate(lastRunTime),setStatusToText(WebAttribution)],
                ['S2S Events',formatDate(lastRunTime),setStatusToText(S2sEvent)],
                ['S2S Report',formatDate(lastRunTime),setStatusToText(S2sReport)],
            ],
            startY: 60
        })

        pdf.save(appName + "_" + os + "_" + lastRunTime + ".pdf");
    }

    return(
        <Button variant='outlined' onClick={downloadPdfDocument}>Download PDF </Button>
    )
}