import {Tile } from "@adjust/components";
import {Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import {useState } from "react";
import SearchIcon from '@mui/icons-material/Search';

export const QueryEmailsSearch = (props : any) => {
    const [emails,setEmails] = useState('');
    
    function searchEmails(emails : string){
        props.onIsLoadingChange(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "emails" : emails}) 
        };
        fetch('/api/queryemails', requestOptions)
            .then(response => response.json()).then(data => {
                props.onContactChange(data.contacts);
                props.onIsLoadingChange(false);
            });
    }

    const emailKeyDown = (event : any) => {
        if (event.key === 'Enter') {
            searchEmails(emails);
        }
    }

    return(
        <Tile>
            <Grid container spacing={6}>
                <Grid item xs={6}>
                    <TextField
                        style={{width:'60%'}}
                        label="Emails"
                        id="emails" 
                        name="emails"
                        value={emails}
                        onChange={(e) => {
                            setEmails(e.target.value);
                        }}
                        onKeyDown={emailKeyDown}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button onClick={(e) => searchEmails(emails)}><SearchIcon /></Button>
                              </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            </Grid>
        </Tile>
    );
}