import { Button, ComboBox, Input } from '@adjust/components';
import { Tile } from '@adjust/components/build/Tile/Tile';
import { Box, Card, Grid} from '@mui/material';
import React, { useState } from 'react';

export default function InspectDevicePage() {
    const [identifier, setIdentifier] = useState('');
    const [identifierType, setIdentifierType]:any = useState('');
    const [appToken, setAppToken] = useState('');
    const [inspectDevice,setInspectDevice] = useState({});
    const [message,setMessage] = useState('');

    function searchInspectDevice(appToken : any,identifier : any, identifierType : any){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "identifier": identifier,"appToken" : appToken, "identifierType" : identifierType  })
        };
        fetch('/api/inspectdevice', requestOptions)
            .then(response => response.json()).then(data => {
                setInspectDevice(data.deviceData);
                setMessage(data.message);
            });
    }

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                 <h1>Inspect Device</h1>
                                 <h5>*Only usable in the office IP range</h5>
                                 <h5>*from outside use a VPN</h5>
                            </Grid>
                            <Grid item xs={12}>
                                <Tile title="Inspect Device">
                                <Input
                                    label="App Token"
                                    id="appToken" 
                                    name="appToken"
                                    value={appToken}
                                    css={{ width: "40%"}}
                                    onClear={() => {
                                        setAppToken('');
                                    }}
                                    onChange={(e) => {
                                        setAppToken(e.target.value);
                                    }}
                                />
                                <ComboBox
                                    label="Identifier"
                                    options={[
                                        { label: 'idfa', value: 'idfa' },
                                        { label: 'gps_adid', value: 'gps_adid' },
                                        { label: 'adid', value: 'adid' },
                                        { label: 'android_id', value: 'android_id' },
                                        { label: 'google_app_set_id', value: 'google_app_set_id' },
                                        { label: 'win_adid', value: 'win_adid' },
                                        { label: 'idfv', value: 'idfv' },
                                        { label: 'ios_uuid', value: 'ios_uuid' },
                                        { label: 'android_uuid', value: 'android_uuid' },
                                        { label: 'web_uuid', value: 'web_uuid' },
                                    ]}
                                    placeholder="Choose Identifier"
                                    onChange={(e) => setIdentifierType(e)}
                                />
                                <Input
                                    label="Identifier Token"
                                    id="identifier" 
                                    name="identifier"
                                    value={identifier}
                                    css={{ width: "40%"}}
                                    onClear={() => {
                                        setIdentifier('');
                                    }}
                                    onChange={(e) => {
                                        setIdentifier(e.target.value);
                                    }}
                                />
                                <br/>
                                <Button  type="submit" kind="primary" label="Search"  onClick={() => searchInspectDevice(appToken,identifier,identifierType)}></Button>{' '}
                                </Tile>
                            </Grid>

                            <Grid item xs={12}>
                                <Card>
                                    <>{message}</>
                                    {message === '' &&
                                    <pre>{JSON.stringify(inspectDevice, null, 2)}</pre>
                                    }
                                </Card>
                            </Grid>
                        </Grid>
                           
                    </div>
                </Box>
    );
}