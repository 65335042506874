import React, { useContext } from 'react';
import {GoogleLogin} from 'react-google-login';
import { AuthContext } from '../../../app/store/AuthContext';
import { useLocation } from 'react-router-dom';

export const LoginButtonGoogle = (_props:any) => {
    const value = useContext(AuthContext);
    const auth = value?.auth.isLoggedIn;
    const role = value?.auth.role;
    const isManager = value?.auth.isManager;
    const location = useLocation();
    
    const onSuccess = (res: any) => {
        const loginData = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(res)
        }
        fetch('/api/login', loginData).then(response => response.json()).then(function(result) {
            if(result.login === true){
                value?.setAuthentification({
                  isLoggedIn:result.login,
                  apiToken: result.apiToken,
                  isManager: result.isManager,
                  role: result.role
                });
                if(location.pathname === '/myaccounts' || location.pathname === '/' || location.pathname === '/login' || location.pathname === '/logout' ){
                  if((result.role === '1' || result.role === '4' || result.role === '5' || result.role === '7') && result.isManager !== false){
                    window.location.href ="/myaccounts";
                  }
                  else{
                    window.location.href = "/tools";
                  }
                }
                else{
                  window.location.href  = location.pathname;
                }
      
              }
        }
    )
    .catch(function(error) {});
    }

  return (
    <div>
      <GoogleLogin
        clientId={_props.clientId}
        buttonText="Login"
        onSuccess={onSuccess}
        cookiePolicy={'single_host_origin'}
        style={{marginTop: '100px'}}
      />
    </div>
  );
}

