import { Breadcrumbs, DatePickerButton, Input } from '@adjust/components';
import { Tile } from '@adjust/components/build/Tile/Tile';
import { Box, Grid, Stack} from '@mui/material';
import Button from '@mui/material/Button';
import React, { useContext, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from '../../../app/store/AuthContext';
import { Link } from 'react-router-dom';
import { MetaPartnerCredentialsTable } from '../../../entities/components/Tools/MetaPartnerApi/MetaPartnerCredentialsTable';
import { MetaAdAccountTable } from '../../../entities/components/Tools/MetaPartnerApi/MetaAdAccountTable';
import { MetaPartnerApiTable } from '../../../entities/components/Tools/MetaPartnerApi/MetaPartnerApiTable';

export const formatDate = (dateString : any) => {
    if(dateString === '' || dateString === null || dateString === undefined){
        return '';
    }
    return new Date(dateString).toISOString().slice(0, 10);
}

type DateRange = {
    startDate: Date | null,
    endDate: Date | null
}

export const MetaPartnerApi = () =>{
    const [accountId, setAccountId] = useState('');
    const [credentialData, setCredentialData] = useState([]);
    const value = useContext(AuthContext);
    const apiToken = value?.auth.apiToken;
    const [isLoading, setIsLoading] = useState(false);
    const [accountInfo,setAccountInfo] = useState({'accountId':'','accountName': '','ctId': '','country': '','accountManager': ''});
    const [accessToken, setAccessToken] = useState('');
    const [adAccountData, setAdAccountData] = useState([]);
    const [metaPartnerData, setMetaPartnerData] = useState([]);
    const [dateRange, setDateRange] = useState<DateRange >({
        startDate: new Date(),
        endDate: new Date(),
      });
    const [error,setError] = useState(0);
    // 0 false
    // 1 true access token
    // 2 true credential token
    // 3 true campaign error
    
    const { startDate, endDate } = dateRange;
    function handleAccessTokenChange(value:string) {
        setAccessToken(value);
        searchAdAccounts(value);
    }
    function handleAdAccountChange(value:string) {
        searchFbPartnerApi(value);
    }

    function searchPartnerCredentials(accountId : any,partner : any, api : any){
        if(accountId !== '' && api !== ''){
            setIsLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ "accountId": accountId,"partner" : partner,'api':api })
            };
            fetch('/api/partnercredentials', requestOptions)
                .then(response => response.json()).then(data => {
                    setCredentialData(data["data"]);
                    setAccountInfo(data["accountInfo"]);
                    setIsLoading(false);
                    if(data["data"].length === 0){
                        setError(1);
                    }
                    else{
                        setError(0);
                    }
                });
        }
    }

    function searchAdAccounts(accessToken : string){
        if(accessToken !== ''){
            setIsLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ "accessToken": accessToken})
            };
            fetch('/api/metaadaccounts', requestOptions)
                .then(response => response.json()).then(data => {
                    if(data["data"]["data"] !== undefined){
                        setAdAccountData(data["data"]["data"]);
                        if(data["data"]["data"].length === 0){
                            setError(2);
                        }
                        else{
                            setError(0);
                        }
                    }
                    setIsLoading(false);
                });
        }
    }

    function searchFbPartnerApi(adAccountId : string){
        if(adAccountId !== ''){
            setIsLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ "adAccountId": adAccountId, 'accessToken': accessToken, 'startDate': formatDate(startDate?.toString()), 'stopDate': formatDate(endDate?.toString())})
            };
            fetch('/api/metapartnerapi', requestOptions)
                .then(response => response.json()).then(data => {
                    if(data["data"]["data"] !== undefined){
                        setMetaPartnerData(data["data"]["data"]);
                        if(data["data"]["data"].length === 0){
                            setError(3);
                        }
                        else{
                            setError(0);
                        }
                    }
                    setIsLoading(false);
                });
        }
    }

    const reset = () => {
        setAccessToken('');
        setAdAccountData([]);
        setMetaPartnerData([]);
    }

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Tile title="Meta Partner API">
                                <Input
                                    label="Account Id / Name"
                                    id="accountId" 
                                    name="accountId"
                                    value={accountId}
                                    placeholder="f.e. 65"
                                    onClear={() => {
                                        setAccountId('');
                                    }}
                                    onChange={(e) => {
                                        setAccountId(e.target.value);
                                    }}
                                />
                                <br/>
                                <br/>
                                <Stack spacing={2} direction="row">
                                    <Button variant="contained" style={{backgroundColor:'#005ff7'}} onClick={() => searchPartnerCredentials(accountId,'',apiToken)} >Search</Button>
                                    <Button variant="outlined" color="error" onClick={reset} >Reset</Button>
                                </Stack>
                                </Tile>
                            </Grid>
                            {accountInfo.accountId !== '' &&
                            <Grid item xs={6}>
                                <Tile style={{maxHeight: '100%'}} title="">
                                    <Grid container spacing={0}>
                                        <Grid item xs={3}>
                                            <div style={{fontWeight:'bold',marginRight:'4px'}}>Account Name: </div>
                                        </Grid>
                                        <Grid item xs={9}> {accountInfo.accountName}</Grid>

                                        <Grid item xs={3}>
                                            <div style={{fontWeight:'bold',marginRight:'4px'}}>Account ID: </div>
                                        </Grid>
                                        <Grid item xs={9}> {accountInfo.accountId}</Grid>

                                        <Grid item xs={3}>
                                            <div style={{fontWeight:'bold',marginRight:'4px'}}>Country Code: </div>
                                        </Grid>
                                        <Grid item xs={9}> {accountInfo.country}</Grid>

                                        <Grid item xs={3}>
                                            <div style={{fontWeight:'bold',marginRight:'4px'}}>Account Manager: </div>
                                        </Grid>
                                        <Grid item xs={9}> {accountInfo.accountManager}</Grid>
                                        <br/>
                                        <br/>
                                        <Button variant="contained" component={Link} to={"/account/"+accountInfo.accountId}>Account</Button>
                                    </Grid>
                                </Tile>
                            </Grid>
                            }
                        </Grid>
                        <br/>
                        {isLoading &&
                            <CircularProgress />
                        }
                        {error === 1 &&
                            <div style={{color:'red'}}> No data for Access Token</div>
                        }
                        {error === 2 &&
                            <div> style={{color:'red'}} No data for Ad Account</div>
                        }
                        {error === 3 &&
                            <div style={{color:'red'}}> No data for Partner Campaign</div>
                        }
                        {(credentialData.length > 0 && !isLoading && error !== 1) &&
                            <Grid item xs={12}>
                                {adAccountData.length === 0 && 
                                    <>
                                        <Breadcrumbs items={[{ label: 'Access Token'}]} />
                                        <MetaPartnerCredentialsTable data={credentialData} onAccessTokenChange={handleAccessTokenChange}/>
                                    </>
                                }
                            </Grid>
                        }
                        {(adAccountData.length > 0 && !isLoading && metaPartnerData.length === 0 && error  !== 2) &&
                            <Grid item xs={12}>
                                <Breadcrumbs items={[{ label: 'Access Token' },{ label: 'Ad Account'}]} />
                                {metaPartnerData.length === 0 && 
                                    <Tile>
                                        <DatePickerButton
                                            startDate={startDate}
                                            endDate={endDate}
                                            onApply={setDateRange}
                                        />
                                        <br/>
                                        <MetaAdAccountTable data={adAccountData} onAdAccountChange={handleAdAccountChange}/>
                                    </Tile>
                                 }
                            </Grid>
                        }
                        {(metaPartnerData.length > 0 && !isLoading && error  !== 2) &&
                            <Grid item xs={12}>
                                <Breadcrumbs items={[{ label: 'Access Token' },{ label: 'Ad Account'},{ label: 'Partner Campaigns'}]} />
                                <MetaPartnerApiTable data={metaPartnerData} />
                            </Grid>
                        }
                </Box>
    );
}