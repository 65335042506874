import { Tile } from "@adjust/components"
import { BaggerApiJRunJob } from "./BaggerApiRunJob";
import { BaggerApiCancelJob } from "./BaggerApiCancelJob";

export const BaggerApi = (_props: any) => {
    const appToken = _props.appToken;
    const lastRunTime= _props.lastRunTime;
    const jobIsRunning =_props.jobRunning
    const onMenuChange = _props.onMenuChange;
    return (
        <Tile>
            <div style={{height:'183px'}}>
                <h3>Actions</h3>
                <p>Last check date: {lastRunTime}</p>
                <BaggerApiJRunJob appToken={appToken} onMenuChange={onMenuChange}/>
                {jobIsRunning.running &&
                    <BaggerApiCancelJob jobId={jobIsRunning.jobId}/>
                }
            </div>
        </Tile>
    )
}