import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { ActionMenu } from '@adjust/components';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

function setRedirectButton( accountId : any){
  return <form action="https://api.adjust.com/accounts/accounts/switch.json" target="_blank" accept-charset="UTF-8" method="post">
    <input name="utf8" type="hidden" value="✓"/>
    <input type="hidden" name="authenticity_token" value="Voa+WSemHcT/nTfrgVofsDQ76DiflWnRQS8h7HmlOTl/DjaU4vt2Ky5b/beHORUMo0udGFLNQx4ibYqxLlWaXg=="/>
    <input type="hidden" name="account_id" id="account_id" value={accountId} />
    <Button type="submit" name="commit" value="switch account" >
      <img id="logo-adjust" src={process.env.PUBLIC_URL + "/favicon.ico"} width="40" height="40" alt="adjust.com" />
    </Button>
  </form>
}

function createBadge(number: any){
    if(number === 3){
      return "Paused";
    }
    else if (number === 4){
      return "Suspended";
    }
    else if (number === 5){
      return "Terminated";
    }
    else{
      return "Active";
    }
}

function checkDashboadType(value: boolean){
  if(value === false){
    return "Classic";
  }
  return "Suite";
}

const setImplementationFilter = (filterName: string) =>{
  if(filterName === '4 - Completed'){return 'Inactive';}
  else if(filterName === '1 - In Progress'){return 'Active';}
  else if(filterName === '2 - Basic Integration Completed'){return 'Active';}
  else if(filterName === '3 - Additional Feature Integration In Progress'){return 'Active';}
  else if(filterName === 'On hold'){return 'Active';}
  return '';
}

function createData(
    id: number,
    accountId: string,
    clientName: string,
    country: string,
    classification: string,
    vertical: string,
    csm: string,
    state: Number,
    assignedOn: string,
    implementationMail: string,
    stage: string,
    completion: string,
    documentation: string,
    endDate: string,
    startDate: string,
    status: string,
    estimatedHandoverDate: string,
    isSuite: boolean,
    solutionsConsultantMail: string,
    implementationStatus: string,
    implementationFilter: string
  ) {
    return { id,accountId, clientName, country, classification, vertical, csm, state, assignedOn, implementationMail, stage,
    completion, documentation, endDate, startDate, status, estimatedHandoverDate, isSuite, solutionsConsultantMail, implementationStatus, implementationFilter };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export default function MyAccountsTableImpl(_props : any) {
  let navigate = useNavigate();
  const columns: GridColDef[] = [
      { field: 'id', headerName: 'Dashboard',flex: 1, minWidth: 100,
      renderCell: (params : any) => {
          return <div>{setRedirectButton(params.row.accountId)}</div>
      },
    },
    { field: 'action', headerName: 'Action',flex: 1, minWidth: 100, sortable: false,
      renderCell: (params : any) => {
          return <div>
                    <ActionMenu 
                          items={[
                            { label: 'Overview', onClick: () => {navigate("/account/"+params.row.accountId) }},
                            { label: 'Apps', onClick: () => {navigate("/account/"+params.row.accountId+'/apps') }},
                          { label: 'Contact & History', onClick: () => {navigate("/account/"+params.row.accountId+'/users') }},
                          { label: 'Features', onClick: () => {navigate("/account/"+params.row.accountId+'/features') }},
                          ]}
                        >
                        {/* The trigger can be anything. We use a button here */}
                        <Button style={{backgroundColor:'#005ff7' }} variant="contained" >Open</Button>
                    </ActionMenu>
                </div>;
      },
    },
    { field: 'clientName', headerName: 'Client Name',flex: 1, minWidth: 250},
    { field: 'accountId', headerName: 'Account Id',flex: 1, minWidth: 150},
    { field: 'state', headerName: 'State',flex: 1, minWidth: 150,
      renderCell: (params : any) => {
          return <div>{createBadge(params.row.state)}</div>;
      },
    },
    { field: 'isSuite', headerName: 'Dashboard',flex: 1, minWidth: 150,
    renderCell: (params : any) => {
        return <div>{checkDashboadType(params.row.isSuite)}</div>;
    },
    },
    { field: 'country', headerName: 'Country',flex: 1, minWidth: 150},
    { field: 'implementationFilter', headerName: 'Implementation Filter',flex: 1, minWidth: 150},
    { field: 'implementationStatus', headerName: 'Implementation Status',flex: 1, minWidth: 150},
    { field: 'classification', headerName: 'Classification',flex: 1, minWidth: 150},
    { field: 'vertical', headerName: 'Vertical',flex: 1, minWidth: 150},
    { field: 'csm', headerName: 'CSM',flex: 1, minWidth: 150},
    { field: 'solutionsConsultantMail', headerName: 'SC',flex: 1, minWidth: 150},
    { field: 'stage', headerName: 'Stage',flex: 1, minWidth: 150},
    { field: 'completion', headerName: 'Completion',flex: 1, minWidth: 150},
    { field: 'documentation', headerName: 'Documentation',flex: 1, minWidth: 150,
    renderCell: (params : any) => {
      return <Button variant="contained" onClick={() => window.open(params.row.documentation)} >GoTo</Button>; 
      },
    },
    { field: 'startDate', headerName: 'Start Date',flex: 1, minWidth: 150},
    { field: 'endDate', headerName: 'End Date',flex: 1, minWidth: 150},
    { field: 'status', headerName: 'Status',flex: 1, minWidth: 150},
    { field: 'estimatedHandoverDate', headerName: 'Estimated Handover Date',flex: 1, minWidth: 150},
  ];
  
    const rows : any = [];
    for (var i = 0; i < _props.data.length; i++){
        rows.push(
            createData(
                i,
                _props.data[i]["accountId"],
                _props.data[i]["accountName"],
                _props.data[i]["country"],
                _props.data[i]["classification"],
                _props.data[i]["vertical"],
                _props.data[i]["csmName"],
                _props.data[i]["state"],
                _props.data[i]["assignedOn"],
                _props.data[i]["implementationMail"],
                _props.data[i]["stage"],
                _props.data[i]["completion"] + '%',
                _props.data[i]["documentation"],
                formatDate(_props.data[i]["end_date"]),
                formatDate(_props.data[i]["start_date"]),
                _props.data[i]["status"],
                formatDate(_props.data[i]["estimated_handover_date"]),
                _props.data[i]["isSuite"],
                _props.data[i]["solutionsConsultantMail"],
                _props.data[i]["implementationStatus"],
                setImplementationFilter(_props.data[i]["implementationStatus"])
            )
        );
    }
    return (
        <div style={{ height: 600, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
                initialState={{
                  filter: {
                    filterModel: {
                      items: [{
                          field: 'implementationFilter',
                          value: 'Active',
                          operator: 'equals',
                        },
                      ]},
                  },
                }}
                
            />
        </div>
    );
}