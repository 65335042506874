import { Button, Stack } from "@mui/material";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../app/store/AuthContext";
import LoginPage from "../login/Login";

const NotFound = (_props:any) => {
    const value = useContext(AuthContext);
    const auth = value?.auth.isLoggedIn;

    return(
        <div style={{width:'100%'}}>
            {auth &&
                <Stack
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="40vh"
                    width={'100%'}
                >    
                    <h1>404 Site is not existing</h1>
                    <Button variant="outlined" component={Link} to="/myaccounts">Go to my Accounts</Button>
                </Stack>

            }
            {!auth &&
                <LoginPage clientId={_props.clientId}/>
            }
        </div>    
  
           
            
       
    );
};

export default NotFound;