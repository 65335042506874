import { Box, Grid} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import HistoryModalAccount from '../../entities/components/Accounts/HistoryModalAccount';
import { formatDate } from '../../shared/components/formatDate/FormatDate';
import { InfoTooltip } from '../../shared/components/infoTooltip/InfoTooltip';
  
  const isAppToken = (historyWithAppToken : any) => {
    if(historyWithAppToken === 'app') {
        if ("app_token" in historyWithAppToken){
            return historyWithAppToken["app_token"];
        }
    }
    return "-";  
    }

    function isInternal(activity : any, internal : any){
        if(internal === true || internal === 'true'){
            return <div style={{color:'grey'}}>{activity}</div>
        }
        return <div style={{color:'rgb(8, 209, 255)'}}>{activity}</div>
    }

  const columns: GridColDef[] = [
    { field: 'created_at', headerName: 'Created At',flex: 1, minWidth: 50 , maxWidth: 150},
    { field: 'activity', headerName: 'Activity',flex: 1, minWidth: 50, maxWidth: 150,
        renderCell: (params : any) => {
            return <>{params.row.activity.toUpperCase()}</>;
        }
    },
    { field: 'action', headerName: 'Description',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
            return isInternal(params.row.action.toUpperCase(), params.row.internal);
        }
    },
    { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 50},
    { field: 'parameters', headerName: 'Action',flex: 1, minWidth: 180,sortable: false,
    renderCell: (params : any) => {
      return <>
          <HistoryModalAccount parameters = {params.row.parameters} originalState = {params.row.originalState} />
      </>;
    },
    },
  ];

function createData(
    id: number,
    created_at: string,
    activity: string,
    user: string,
    internal: string,
    action: boolean,
    parameters: any,
    originalState: any,
    appToken: string
  ){
    return { id, created_at, activity, user, internal, action, parameters, originalState, appToken };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export const UserHistoryPage = () => {
    let params = useParams();
    const queryParams = new URLSearchParams(window.location.search)
    const accountId = queryParams.get("accountid")
    const [history, setHistory] = useState([]);
    useEffect(() => {
        if(params.email !== null && accountId !== null){
            fetch('/api/userhistory/'+params.email+"/"+accountId).then(res => res.json()).then(data => {
                setHistory(data["history"]);
            });
        }
    }, [params.email,accountId]);

    const historyData : any = [];
    if(history !== undefined){
        for (var i = 0; i < history.length; i++){
            historyData.push(
                createData(
                    i,
                    formatDate(history[i][1]),
                    history[i][5],
                    history[i][3],
                    history[i][4],
                    history[i][0],
                    history[i][6],
                    history[i][7],
                    isAppToken(history[i][6]),
                )
            );
        }
    }

    return (
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <div>
                    <h2 style={{marginTop:'0px'}}>User History for: {params.email}</h2>
                    <InfoTooltip message="Source: Adjust DB | Refreshed: instant" />
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <div style={{ height: 650, width: '100%' }}>
                                <DataGrid 
                                    rows={historyData} 
                                    columns={columns}
                                    components={{
                                        Toolbar: CustomToolbar,
                                    }}
                                /> 
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Box>
    );
}