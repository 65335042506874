import { Button, Input } from '@adjust/components';
import { Box, Grid} from '@mui/material';
import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

export const TrackerApiPage = () => {
    const [trackerData,setTrackerData] = useState({message:'',trackerData:''});
    const [trackerToken,setTrackerTrackerToken] = useState('');
    const [isLoading,setIsLoading]  = useState(false);
    
    function searchTrackerApi(){
        if(trackerToken !== ''){
            setIsLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'trackerToken': trackerToken})
            };
            fetch('/api/trackerrapi', requestOptions)
                .then(response => response.json()).then(data => {
                    setTrackerData(data);
                    setIsLoading(false);
                });
        }
    }

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                 <h1>Tracker API</h1>
                            </Grid>
                            <Grid item xs={12}>
                                <Input
                                    label="Tracker Token"
                                    id="trackerToken" 
                                    name="trackerToken"
                                    value={trackerToken}
                                    placeholder=""
                                    onClear={() => {
                                        setTrackerTrackerToken('');
                                    }}
                                    onChange={(e) => {
                                        setTrackerTrackerToken(e.target.value);
                                    }}
                                />
                                
                                <br/><br/>
                                <Button  type="submit" kind="primary" label="Search" onClick={() => searchTrackerApi()} ></Button>{' '}      
                            </Grid>
                            <br/>
                            <Grid item xs={12}>
                                {isLoading &&
                                <CircularProgress />
                                }
                                {trackerData.message !== '' &&
                                    <p style={{color:'red'}}>{trackerData.message}</p>
                                }
                                {trackerData.trackerData !== '' &&
                                <pre>{JSON.stringify(trackerData.trackerData, null, 2)}</pre>
                                }
                            </Grid>
                        </Grid>
                    </div>
                </Box>
    );
}