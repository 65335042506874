import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

function formatNumber(value : any){
    if(value === '' || value === null){
        return value;
    }
    if(!isNaN(value)){
        return Math.round(value);
    }
    return value;
}

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
}

const columns: GridColDef[] = [
    { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 180},
    { field: 'type', headerName: 'Anomaly Type',flex: 1, minWidth: 100},
    { field: 'createdAt', headerName: 'Created At',flex: 1, minWidth: 150},
    { field: 'expected', headerName: 'Expected Value',flex: 1, minWidth: 120},
    { field: 'actual', headerName: 'Actual Value',flex: 1, minWidth: 100},
  ];

function createData(
    id: number,
    appToken: string,
    type: string,
    createdAt: string,
    expected: string,
    actual: string,
    
  ){
    return { id, appToken, type, createdAt, expected, actual };
  }

export default function AppAnomalies(props : any) {
    var anomalies = props.anomalies;
    anomalies.sort((a : any, b : any) => a[0].localeCompare(b[0]));
    anomalies.reverse();
    const anomaliesData : any = [];
    if(anomalies !== undefined){
        for (var i = 0; i < anomalies.length; i++){
            anomaliesData.push(
                createData(
                    i,
                    anomalies[i][1],
                    anomalies[i][3],
                    formatDate(anomalies[i][0]),
                    formatNumber(anomalies[i][4]),
                    formatNumber(anomalies[i][5]),
                )
            );
        }
    }
    return (
        <div style={{height: 600, width: '100%'}}>
                <DataGrid 
                    rows={anomaliesData} 
                    columns={columns}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                /> 
        </div>
    );
}