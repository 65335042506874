import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';



function createData(
    id: number,
    featureName: string,
    accountId: number,
    accountName: string,
    classification: string,
    am: string,
    csm: string,
    owner: string,
    newBilling: boolean
  ) {
    return { id,featureName,accountId,accountName,classification,am,csm,owner,newBilling};
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export const FeaturamaTable = (_props : any) => {
    var data = _props.data;
    const rows : any = [];
    for (var i = 0; i < data.length; i++){
        rows.push(
            createData(
              i,
              data[i].featureName,
              data[i].accountId,
              data[i].accountName,
              data[i].classification,
              data[i].am,
              data[i].csm,
              data[i].owner,
              data[i].newBilling
            )
        );
    }

    const columns: GridColDef[] = [
        { field: 'featureName', headerName: 'Feature Name',flex: 1, minWidth: 150},
        { field: 'accountId', headerName: 'Account ID',flex: 1, minWidth: 100,
        renderCell: (params : any) => {
            return <Button variant="contained" component={Link} to={"/account/"+params.row.accountId} >{params.row.accountId}</Button>;
            },
        },
        { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 250},
        { field: 'classification', headerName: 'Classification',flex: 1, minWidth: 150},
        { field: 'newBilling', headerName: 'New Billing',flex: 1, minWidth: 150},
        { field: 'am', headerName: 'Account Manager',flex: 1, minWidth: 150},
        { field: 'csm', headerName: 'CSM',flex: 1, minWidth: 150},
        { field: 'owner', headerName: 'Owner',flex: 1, minWidth: 150},

    ];

    return (
        <div style={{ height: 650, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}