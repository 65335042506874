import { Box, Button, Dialog, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useState } from "react";
import { AccountCoverageModal } from "../Accounts/AccountCoverage/AccountCoverageModal";
import { formatDate } from "../../../shared/components/formatDate/FormatDate";

export const WelcomeBackCasesModal = (_props : any) =>{
    const cases = _props.cases;
    console.log(cases)
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    if(cases.length === 0){
        return <div></div>;
    }
    return <>
            <Button variant="outlined" onClick={handleOpen} style={{width:'100%'}}>
                Show Cases
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xl"
                fullWidth
            >
                <Box >
                    <div>
                        <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 450 }} aria-label="simple table">
                                            <TableHead>
                                            <TableRow>
                                                <TableCell align="left">Link</TableCell>
                                                <TableCell align="left">Subject</TableCell>
                                                <TableCell align="left">Start Date</TableCell>
                                                <TableCell align="left">Status</TableCell>
                                                <TableCell align="left">Type</TableCell>
                                                <TableCell align="left">Coverage Notes</TableCell>
                                            </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {cases.map((row:any) => (
                                            <TableRow
                                                key={row[0]}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>
                                                    <Button variant="contained" href={"https://adjust.lightning.force.com/lightning/r/Case/"+row[0]+"/view"} target="_blank">Go to SF</Button>
                                                </TableCell>
                                                <TableCell>{row[2]}</TableCell>
                                                <TableCell>{formatDate(row[3])}</TableCell>
                                                <TableCell>{row[4]}</TableCell>
                                                <TableCell>{row[5]}</TableCell>
                                                <TableCell>
                                                    <AccountCoverageModal 
                                                        issueDescription={row[7]}
                                                        whatWasDone={row[8]}
                                                        appEventTrackerToken={row[9]}
                                                        slackDiscussionsJiraTicketsOpened={row[10]}
                                                        suggestedNextSteps={row[11]}
                                                        similarTopicsFoundInSlackJira={row[12]}
                                                        logsPathAndRequestExamples={row[13]}
                                                        linksToOtherTools={row[14]}
                                                        dashboardLink={row[15]}
                                                    />
                                            </TableCell>
                                            </TableRow>
                                            ))}
                                            </TableBody>
                                        </Table>
                        </TableContainer>
                    </div>
                </Box>
            </Dialog>
        </>
}