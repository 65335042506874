
export const S2sEventCheck = (lastRunTime:string,data:any) => {
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    let eventCount = data.length;
    if(eventCount > 0){
        return 1;
    }
    return 3;
}

export const S2sReportCheck = (lastRunTime:string,data:any) => {
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    let eventCount = data.length;
    if(eventCount > 0){
        return 1;
    }
    return 3;
}

export const WebAttributionCheck = (lastRunTime:string,data:any) => {
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    // installs count in production
    let installs = 0;
    for (var i = 0; i < data.length; i++){
        let topic = data[i]['topic'];
        if(topic === 'install'){
            installs = installs + parseInt(data[i]['count']);
        }
    }
    if(installs > 0){
        return 1;
    }
    return 3;
}