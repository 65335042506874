import { ComboBox } from '@adjust/components';
import React,{useState} from 'react'

function createData(
  id,
  value,
  label
) {
  return { id, value, label };
}

export const SkadNetworkInput = (_props) =>{
  const rows = [];
    for (var i = 0; i < _props.skadTokenList.length; i++){
        rows.push(
            createData(
                i,
                _props.skadTokenList[i][1],
                _props.skadTokenList[i][0]
            )
        );

    }
  
    const [value, setValue] = useState([]);
  
    const handleChange = (value) => {
      setValue(value);
      _props.onSkadTokensChange(value);
    }
  
    return (
      <ComboBox
        multiple
        value={value}
        options={rows}
        onChange={handleChange}
        placeholder="Skad Network Token"
      />
    );
  }