import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import { Avatar, Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';
import { InfoTooltip } from '../../../shared/components/infoTooltip/InfoTooltip';

function getOs(os : any){
  if(os === null){
    return <div></div>
  }
  if(os.includes('itunes') && os.includes('google')){
    return <div><AndroidIcon/><AppleIcon/></div>
  }
  else if(os === 'itunes'){
    return <div><AppleIcon/></div>
  }
  else if(os === 'google'){
    return <div><AndroidIcon/></div>
  }
  else{
    return <div>{os}</div>
  }
}

function getPlatform(platform : any){
  if(platform){
      return "Connected TV"
  }
  return "Mobile"
}

function isVerified(skAppId : string){
  if(skAppId !== null){
      return "Verified"
  }
  return "Not Verified"
}

function setRedirectButton(appToken : any, accountId : any){
    return <form action="https://api.adjust.com/accounts/accounts/switch.json" target="_blank" accept-charset="UTF-8" method="post">
    <input name="utf8" type="hidden" value="✓"/>
    <input type="hidden" name="authenticity_token" value="Voa+WSemHcT/nTfrgVofsDQ76DiflWnRQS8h7HmlOTl/DjaU4vt2Ky5b/beHORUMo0udGFLNQx4ibYqxLlWaXg=="/>
    <input type="hidden" name="account_id" id="account_id" value={accountId} />
    <Button type="submit" name="commit" value="switch account">
      <img id="logo-adjust" src={process.env.PUBLIC_URL + "/favicon.ico"} width="40" height="40" alt="adjust.com" />
    </Button>
  </form>
}

const getId = (ids : string) => {
  if(ids === '' || ids === null){
    return '';
  }
  let firstId = ids.split(',');
  return firstId[0];
}

const columns: GridColDef[] = [
  { field: 'action', headerName: 'Action',flex: 1, maxWidth: 150,sortable: false,
    renderCell: (params : any) => {
      return <>
          <Button variant="outlined" component={Link} to={"/account/app/"+params.row.appToken}>View App</Button>
      </>;
    },
  },
  { field: 'appName', headerName: 'App Name',flex: 1, minWidth: 450,
    renderCell: (params : any) => {
      return <div>
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item>
          <Avatar alt="image" src={"https://www.apptrace.com/api/app/"+getId(params.row.appStoreId)+"/artwork_url_small"} />
          </Grid>
          <Grid item>
            {params.row.appName}
          </Grid>
        </Grid>
     </div>;
    },
  },
  { field: 'appToken', headerName: 'App Token',flex: 1, maxWidth: 150},
  { field: 'os', headerName: 'OS',flex: 1, maxWidth: 100,
    renderCell: (params : any) => {
      return <div>{getOs(params.row.os)}</div>;
    },
  },
  { field: 'platform', headerName: 'Platform',flex: 1, maxWidth: 150},
  { field: 'skAppId', headerName: 'App State',flex: 1, maxWidth: 150},
  { field: 'deletedAt', headerName: 'Deleted At',flex: 1, maxWidth: 150},
  { field: 'dashboard', headerName: 'Dashboard Link',flex: 1, minWidth: 50, maxWidth: 150,sortable: false,
  renderCell: (params : any) => {
    return <div>{setRedirectButton(params.row.appToken,params.row.accountId)}</div>;
  },
},
  
];

function createData(
    id: number,
    accountId: string,
    appName: string,
    appToken: string,
    os: string,
    platform : string,
    appStoreId: string,
    deletedAt: string,
    skAppId: string
  ) {
    return { id,accountId, appName, appToken, os, platform, appStoreId, deletedAt,skAppId };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const infoText = "Source: Adjust DB | Refreshed: instant";

export default function AccountAppsTable(_props : any) {
  const apps = _props.apps;
  const rows : any = [];
  if(apps !== undefined){
    for (var i = 0; i < apps.length; i++){
      rows.push(
        createData(
        i,
        apps[i][3],
        apps[i][1],
        apps[i][2],
        apps[i][6],
        getPlatform(apps[i][9]),
        apps[i][7],
        formatDate(apps[i][10]),
        isVerified(apps[i][11]),
        )
      );
    }
  }
    return (
        <div style={{ height: 800, width: '100%' }}>
            <InfoTooltip message={infoText} />
            <DataGrid
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}
