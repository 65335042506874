import { formatDateForBQG, formatTimeForBQG } from "../../../../shared/components/formatDate/FormatDate";

const saveResultToDb = (queryString : string) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 'queryString': queryString})
    };
    fetch('/api/setbqghistory', requestOptions)
        .then(response => response.json()).then(data => {});
}

const formatFilter = (tokenList: any, filterName: string) => {
    if(tokenList.length === 0){
        return "";
    }
    if(tokenList.length === 1){
        return "log->'context'->'" + filterName + "' ? '" + tokenList[0]+ "'";
    }
    if(tokenList.length > 1){
        let tokenString = '(';
        for (var i = 0; i < tokenList.length; i++){
            if(i === 0){
                tokenString = tokenString + "log->'context'->'" + filterName + "' ? '" + tokenList[i]+ "'"
            }
            else{
                tokenString = tokenString + " OR log->'context'->'" + filterName + "' ? '" + tokenList[i]+ "'"
            }
        }
        return tokenString + ")";
    }
}
const formatFilterValue = (tokenList: any, filterName: string) => {
    if(tokenList.length === 0){
        return "";
    }
    if(tokenList.length === 1){
        return "log->'context'->'" + filterName + "' ? '" + tokenList[0].value + "'";
    }
    if(tokenList.length > 1){
        let tokenString = '(';
        for (var i = 0; i < tokenList.length; i++){
            if(i === 0){
                tokenString = tokenString + "log->'context'->'" + filterName + "' ? '" + tokenList[i].value + "'"
            }
            else{
                tokenString = tokenString + " OR log->'context'->'" + filterName + "' ? '" + tokenList[i].value + "'"
            }
        }
        return tokenString + ")";
    }
}

const formatFilterValueMessage = (tokenList: any, filterName: string) => {
    if(tokenList.length === 0){
        return "";
    }
    if(tokenList.length === 1){
        return "log->'" + filterName + "' ? '" + tokenList[0].value + "'";
    }
    if(tokenList.length > 1){
        let tokenString = '(';
        for (var i = 0; i < tokenList.length; i++){
            if(i === 0){
                tokenString = tokenString + "log->'" + filterName + "' ? '" + tokenList[i].value + "'"
            }
            else{
                tokenString = tokenString + " OR log->'" + filterName + "' ? '" + tokenList[i].value + "'"
            }
        }
        return tokenString + ")";
    }
}

const formatFilterValueLabel = (tokenList: any, filterName: string) => {
    if(tokenList.length === 0){
        return "";
    }
    if(tokenList.length === 1){
        return "log->'context'->'callback'->'" + filterName + "' ? '" + tokenList[0].value + "'";
    }
    if(tokenList.length > 1){
        let tokenString = '(';
        for (var i = 0; i < tokenList.length; i++){
            if(i === 0){
                tokenString = tokenString + "log->'context'->'callback'->'" + filterName + "' ? '" + tokenList[i].value + "'"
            }
            else{
                tokenString = tokenString + " OR log->'context'->'callback'->'" + filterName + "' ? '" + tokenList[i].value + "'"
            }
        }
        return tokenString + ")";
    }
}
const formatFilterRequestUrl = (token: string) => {
    if(token === ''){
        return '';
    }
    return "log->'context'->>'request_url' like '%" + token + "%'";
}

const formatFilterSanPartner = (sanPartner: string) => {
    switch(sanPartner) {
      case "Google Ads":
        return "log->'context'->'googleads' is not null"
      case "Apple Ads":
        return "log->'context'->'apple_ads' is not null"
      case "Facebook":
        return "log->'context'->'facebook' is not null"
      case "Snapchat":
        return "log->'context'->'snapchat' is not null"
      case "TikTok SAN":
        return "log->'context'->'tiktok_san' is not null"
      case "Twitter":
        return "log->'context'->'twitter' is not null"
      case "Doubleclick":
        return "log->'context'->'doubleclick' is not null"
      case "Google Marketing Platform":
        return "log->'context'->'google_marketing_platform' is not null"
      case "Yahoo Gemini":
        return "log->'context'->'yahoo_gemini' is not null"
      case "Yahoo Japan":
        return "log->'context'->'yahoo_japan' is not null"
    }
}
const formatFilterSanPartners = (sanPartners: any) => {
    if(sanPartners.length !== undefined){
        return "";
    }
    return formatFilterSanPartner(sanPartners.label);
}
const formatFilterDeviceIds = (deviceIds: any) => {
    if(deviceIds.length === 0){
        return "";
    }
    if(deviceIds.length === 1){
        return "log->'context'->'device_ids'->'" + deviceIds[0]["deviceType"] + "' ? '" + deviceIds[0]["deviceId"] + "'";
    }
    if(deviceIds.length > 1){
        let deviceString = '(';
        for (var i = 0; i < deviceIds.length; i++){
            if(i === 0){
                deviceString = deviceString + "log->'context'->'device_ids'->'" + deviceIds[0]["deviceType"] + "' ? '" + deviceIds[0]["deviceId"] + "'"
            }
            else{
                deviceString = deviceString + " OR log->'context'->'device_ids'->'" + deviceIds[i]["deviceType"] + "' ? '" + deviceIds[i]["deviceId"] + "'"
            }
        }
        return deviceString + ")";
    }
}

const shortError = (appTokens:string[],trackerTokens:string[],urlTrackers:string[]) => {
    for (var i = 0; i < appTokens.length; i++){
        if(appTokens[i].length !== 12){
          return 'App Token is not 12 chars'
        }
    }
    for (i = 0; i < trackerTokens.length; i++){
        if(trackerTokens[i].length  < 5){
          return 'Tracker Token is too short'
        }
    }
    for (i = 0; i < urlTrackers.length; i++){
        if(urlTrackers[i].length  < 5){
          return 'URL Tracker is too short'
        }
    }
    return '';
}
export const createResult = (tags:any,periodTime:any,options:any,appTokens:any,trackerTokens:any,urlTrackers:any,eventTokens:any,requestUrl:any,skadTokens:any,messages:any,deviceIds:any,labels:any,sanPartners:any) => {
    let result = '';
    let error = '';
    let noLabelError = '';
    let query = "pull.pl "
    //filter 
    let filterString = "";
    let atString = formatFilter(appTokens,'app_token');
    if(atString !== ''){
        filterString = filterString + atString;
    }
    let ttString = formatFilter(trackerTokens,'tracker_token');
    if(ttString !== ''){
        if(filterString !== ''){
            filterString = filterString + " AND " + ttString;
        }
        else{
            filterString = filterString + ttString;
        }
        
    }
    let utSring = formatFilter(urlTrackers,'url_tracker');
    if(utSring !== ''){
        if(filterString !== ''){
            filterString = filterString + " AND " + utSring;
        }
        else{
            filterString = filterString + utSring;
        }
        
    }
    let etString = formatFilter(eventTokens,'event_token');
    if(etString !== ''){
        if(filterString !== ''){
            filterString = filterString + " AND " + etString;
        }
        else{
            filterString = filterString + etString;
        }
        
    }
    let stString = formatFilterValue(skadTokens,'skadnetwork_token');
    if(stString !== ''){
        if(filterString !== ''){
            filterString = filterString + " AND " + stString;
        }
        else{
            filterString = filterString + stString;
        }
        
    }
    let mString = formatFilterValueMessage(messages,'message');
    if(mString !== ''){
        if(filterString !== ''){
            filterString = filterString + " AND " + mString;
        }
        else{
            filterString = filterString + mString;
        }
        
    }
    let lString = formatFilterValue(labels,"callback'->'Label");
    if(lString !== '' && options.service === 'callback_worker'){
        if(filterString !== ''){
            filterString = filterString + " AND " + lString;
        }
        else{
            filterString = filterString + lString;
        }
        noLabelError = '';
    }
    else{
        if(options.service === 'callback_worker'){
            noLabelError = 'No Label was chosen, are you sure?';
        }
    }
    let diString = formatFilterDeviceIds(deviceIds);
    if(diString !== ''){
        if(filterString !== ''){
            filterString = filterString + " AND " + diString;
        }
        else{
            filterString = filterString + diString;
        }
        
    }
    let ruString = formatFilterRequestUrl(requestUrl);
    if(ruString !== ''){
        if(filterString !== ''){
            filterString = filterString + " AND " + ruString;
        }
        else{
            filterString = filterString + ruString;
        }
        
    }

    let spString = formatFilterSanPartners(sanPartners);
    if(spString !== ''){
        if(filterString !== ''){
            filterString = filterString + " AND " + spString;
        }
        else{
            filterString = filterString + spString;
        }
        
    }
    if(filterString === ''){
        error = 'No Filter is selected';
        return {
            result,
            error,
            noLabelError
        }
    }
    let errorMessage = shortError(appTokens,trackerTokens,urlTrackers);
    if(errorMessage !== ''){
        error = errorMessage;
        return {
            result,
            error,
            noLabelError
        }
    }
    query = query + '--filter "' + filterString + '"';
    //service
    if(options.service === ''){
        error = 'No Service selected';
        return {
            result,
            error,
            noLabelError
        }
    }
    query = query + " --service " + options.service;
    // tags 
    let tagString = ''
    for (var key in tags) {
        if(tags[key]){
            if(tagString === ''){
                tagString = key;
            }
            else{
                tagString = tagString + "," + key
            }
        }
    }
    if(tagString === ''){
        error = 'No Tag is selected';
        return {
            result,
            error,
            noLabelError
        }
    }
    else{
        query = query + " --tag " + tagString;
    }
    //timeframes
    let timeFrameString = ''
    if(formatDateForBQG(periodTime.startDate) !== '' && formatDateForBQG(periodTime.endDate) !== '' && formatTimeForBQG(periodTime.startTime) !== '' && formatTimeForBQG(periodTime.endTime) !== ''){
        let from = " --from " + formatDateForBQG(periodTime.startDate) + 'T' + formatTimeForBQG(periodTime.startTime);
        let until = " --until " + formatDateForBQG(periodTime.endDate) + 'T' + formatTimeForBQG(periodTime.endTime);
        timeFrameString = from + until;
    }
    else{
        if(periodTime.unit !== '' && periodTime.unitValue !== ''){
            timeFrameString = " -since '" + periodTime.unitValue + " " + periodTime.unit + "'"
        }
    }
    if( timeFrameString === ''){
        error = 'Missing TimeFrames';
        return {
            result,
            error,
            noLabelError
        }
    }
    query = query + timeFrameString;
    // extras
    if(options.command === 'yes'){
        query = query + " --cmd "
    }
    if(options.count === 'yes'){
        query = query + " --count "
    }
    //output path
    let path = options.outputFilePath.replace(" ", "");
    let lastSign = path.slice(-1);
    if(lastSign !== '/'){
        path = path + '/'
    }
    query = query + " -o ~/" + options.baggerPath + path + options.outputFile;
    //random filename
    if(options.uniqueFile){
        query = query.slice(0,-4)
        var currentdate = new Date().getTime();
        query = query + currentdate + '.log';
    }
    error = '';
    result = query;
    saveResultToDb(query);
    return {
        result,
        error,
        noLabelError
    }
}

export const createResultBaggerApi = (tags:any,periodTime:any,options:any,appTokens:any,trackerTokens:any,urlTrackers:any,eventTokens:any,requestUrl:any,skadTokens:any,messages:any,deviceIds:any,labels:any,sanPartners:any) => {
    //filter 
    let filterString = "";
    let atString = formatFilter(appTokens,'app_token');
    if(atString !== ''){
        filterString = filterString + atString;
    }
    let ttString = formatFilter(trackerTokens,'tracker_token');
    if(ttString !== ''){
        if(filterString !== ''){
            filterString = filterString + " AND " + ttString;
        }
        else{
            filterString = filterString + ttString;
        }
        
    }
    let utSring = formatFilter(urlTrackers,'url_tracker');
    if(utSring !== ''){
        if(filterString !== ''){
            filterString = filterString + " AND " + utSring;
        }
        else{
            filterString = filterString + utSring;
        }
        
    }
    let etString = formatFilter(eventTokens,'event_token');
    if(etString !== ''){
        if(filterString !== ''){
            filterString = filterString + " AND " + etString;
        }
        else{
            filterString = filterString + etString;
        }
        
    }
    let stString = formatFilterValue(skadTokens,'skadnetwork_token');
    if(stString !== ''){
        if(filterString !== ''){
            filterString = filterString + " AND " + stString;
        }
        else{
            filterString = filterString + stString;
        }
        
    }
    let mString = formatFilterValueMessage(messages,'message');
    if(mString !== ''){
        if(filterString !== ''){
            filterString = filterString + " AND " + mString;
        }
        else{
            filterString = filterString + mString;
        }
        
    }
    let lString = formatFilterValueLabel(labels,'Label');
    if(lString !== ''){
        if(filterString !== ''){
            filterString = filterString + " AND " + lString;
        }
        else{
            filterString = filterString + lString;
        }
    }
    let diString = formatFilterDeviceIds(deviceIds);
    if(diString !== ''){
        if(filterString !== ''){
            filterString = filterString + " AND " + diString;
        }
        else{
            filterString = filterString + diString;
        }
        
    }
    let ruString = formatFilterRequestUrl(requestUrl);
    if(ruString !== ''){
        if(filterString !== ''){
            filterString = filterString + " AND " + ruString;
        }
        else{
            filterString = filterString + ruString;
        }
        
    }
    let spString = formatFilterSanPartners(sanPartners);
    if(spString !== ''){
        if(filterString !== ''){
            filterString = filterString + " AND " + spString;
        }
        else{
            filterString = filterString + spString;
        }
        
    }
    // tags 
    let tagString = ''
    for (var key in tags) {
        if(tags[key]){
            if(tagString === ''){
                tagString = key;
            }
            else{
                tagString = tagString + "," + key
            }
        }
    }
    //timeframes
    let from = '';
    let until = '';
    let since = '';
    if(formatDateForBQG(periodTime.startDate) !== '' && formatDateForBQG(periodTime.endDate) !== '' && formatTimeForBQG(periodTime.startTime) !== '' && formatTimeForBQG(periodTime.endTime) !== ''){
        from = formatDateForBQG(periodTime.startDate) + 'T' + formatTimeForBQG(periodTime.startTime);
        until = formatDateForBQG(periodTime.endDate) + 'T' + formatTimeForBQG(periodTime.endTime);
    }
    else{
        if(periodTime.unit !== '' && periodTime.unitValue !== ''){
            since = periodTime.unitValue + " " + periodTime.unit;
        }
    }
    let cmd = false;
    let count = false;
    if(options.command === 'yes'){
        cmd = true;
    }
    if(options.count === 'yes'){
        count = true;
    }
    return {
        'filterString': filterString,
        'tagString': tagString,
        'from': from,
        'until': until,
        'since': since,
        'service': options.service,
        'cmd': cmd,
        'count': count
    }
}