import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Badge } from '@adjust/components/build/Badge/Badge';
import { Button, Divider, Grid, List, ListItem, TextField } from '@mui/material';
import { useState } from 'react';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

function statusBadge(text: string){
  if(String(text) === "true" ){
      return <Badge label="Setup" color="positive" />
  }
  else{
      return <Badge label="Not Setup" color="warning" />
  }
}

function setActivities(value : any){
    let text = '';
    if(value.length > 0){
      if(value[0] !== null && value[0] !== ''){
        const values = Object.keys(value[0]["activities"]);
        for (let i = 0; i < values.length; i++) {
          text = text + values[i] + ', ';
        }
      }
    }
    return text;
}

function setRtc(events : any){
  var rows = [];
  rows.push(<div>
    <ListItem>
        <Grid container direction="row" alignItems="left" spacing={0}>
            <Grid item xs={4}>Callback URL</Grid>
            <Grid item xs={2}>Condition</Grid>
            <Grid item xs={2}>Activity Type</Grid>
            <Grid item xs={2}>Create Date</Grid>
            <Grid item xs={2}>Update Date</Grid>
        </Grid>
        <br/>
    </ListItem>
    <Divider light />
</div>)
  if(events !== undefined){
   for (var i = 0; i < events.length; i++) {
       rows.push(<div>
               <ListItem>
                   <Grid container direction="row" alignItems="left" spacing={0}>
                       <Grid item xs={4}> <TextField id={events[i][2]} defaultValue={events[i][0]} multiline rows={2} />
                       </Grid>
                       <Grid item xs={2}>{events[i][1]}</Grid>
                       <Grid item xs={2}>{events[i][2]}</Grid>
                       <Grid item xs={2}>{formatDate(events[i][3])}</Grid>
                       <Grid item xs={2}>{formatDate(events[i][4])}</Grid>
                   </Grid>
                   <br/>
               </ListItem>
               <Divider light />
           </div>)
      }
    }
    return <List  component="nav">
        {rows}
    </List>
}


function setCSV(value : any){
    let text = '[';
    if(value.length > 0){
      if(value[0] !== null && value[0] !== ''){
        value = value[0]["columns"];
        for (let i = 0; i < value.length; i++) {
            text = text + value[i]["value"] + ',';
          }
      }
    }
    return text + ']';
}

export default function RawDataTable(_props : any) {
  const activities = setActivities(_props["rawData"]);
  const storage = _props["rawData"][1];
  const csv = setCSV(_props["rawData"]);
  const rtc = setRtc(_props["rtc"]["events"]);
  const [rtcMenu, setRtcMenu] = useState(false);
  const [rawDataMenu, setRawDataMenu] = useState(false);
  return (
    <>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
                <TableCell>Setting</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

            <TableRow key="rtc">
              <TableCell component="th" scope="row">
                RTC
              </TableCell>
              <TableCell component="th" scope="row">
                {statusBadge(_props["rtc"]["active"])}
              </TableCell>
              <TableCell component="th" scope="row">
                <Button variant="contained" onClick = {() => setRtcMenu(!rtcMenu)}>
                  {rtcMenu === false && <>Show</>}
                  {rtcMenu === true && <>Not Show</>}
                </Button>
              </TableCell>

            </TableRow>

            <TableRow key="rawData">
              <TableCell component="th" scope="row">
                Raw Data Export
              </TableCell>
              <TableCell component="th" scope="row">
                {statusBadge(_props["rawData"][2])}
              </TableCell>
              <TableCell component="th" scope="row">
                <Button variant="contained" onClick = {() => setRawDataMenu(!rawDataMenu)}>
                  {rawDataMenu === false && <>Show</>}
                  {rawDataMenu === true && <>Not Show</>}
                </Button>
              </TableCell>

            </TableRow>

        </TableBody>
      </Table>
    </TableContainer>
    <div>
    {rtcMenu && <List >
        {rtc}
    </List>
    }
    {rawDataMenu && 
    <List >
        <ListItem>
          <div style={{fontWeight: "bold"}}>Activity:</div>{activities}
        </ListItem>
        <ListItem>
          <div style={{fontWeight: "bold"}}>Storage Service:</div>{storage}
          Storage Service: {storage}</ListItem>
        <ListItem>
          <div style={{fontWeight: "bold"}}>CSV Definition:</div>{csv}
        </ListItem>
    </List>
    }
    </div>
    </>
  );
}