import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import MyAccountsTableAM from '../../entities/components/MyAccounts/MyAccountsTableAM';
import { MyAccountsTableHolidayAM } from '../../entities/components/MyAccounts/MyAccountsTableHolidayAM';
import MyAccountsTableImpl from '../../entities/components/MyAccounts/MyAccountsTableImplementation';
import MyAccountsTableImplManager from '../../entities/components/MyAccounts/MyAccountsTableImplementationManager';
import MyAccountsTableSC from '../../entities/components/MyAccounts/MyAccountsTableSC';
import { MyAccountsTableSecondAM } from '../../entities/components/MyAccounts/MyAccountsTableSecondAM';
import { PieChart } from '../../entities/components/MyAccounts/PieChart';
import { InfoTooltip } from '../../shared/components/infoTooltip/InfoTooltip';
import { MyAccountsCard } from '../../entities/components/MyAccounts/MyAccountsCard';
 
function getPackages(data:any){
    var vip = 0;
    var enterprise = 0;
    var professional = 0;
    var strategic = 0;
    var support = 0;
    var globalSupport = 0;
    var freemium = 0;
    for (var i = 0; i < data.length; i++){
        var packages = data[i]['classification'];
        if(packages === 'VIP'){
            vip++;
        }
        else if (packages === 'Enterprise')
        {
            enterprise++;
        }
        else if (packages === 'Professional')
        {
            professional++;
        }
        else if (packages === 'Strategic')
        {
            strategic++;
        }
        else if (packages === 'Support')
        {
            support++;
        }
        else if (packages === 'Global Support')
        {
            globalSupport++;
        }
        else if (packages === 'Freemium')
        {
            freemium++;
        }
    }
    return [
        [
        ["Package", "Count"],
        ["VIP", vip],
        ["Enterprise", enterprise],
        ["Professional", professional],
        ["Strategic", strategic],
        ["Support", support],
        ["Global Support", globalSupport],
        ["Freemium", freemium]],
        "Total Clients " + data.length.toString()
      ];
}

function getVerticals(data:any){
    const verticals:any = [];
    verticals.push(["Vertical", "Count"],)
    for (var i = 0; i < data.length; i++){
        var vertical = data[i]['vertical'];
        if(vertical === null){
            vertical = "None";
        }
        var added = false;
        for (var j = 0; j < verticals.length; j++){
            if(verticals[j][0] === vertical){
                var count = verticals[j][1] + 1;
                verticals.splice(j, 1);
                verticals.push([vertical,count]);
                added = true;
            }
        }
        if(added !== true){
            verticals.push([vertical,1]);
        }
    }
    return [
            verticals,
        "Per Vertical "
      ];
}

function getImplementationStatus(data:any){
    const completion:any = [];
    completion.push(["Completion Status", "Count"],)
    for (var i = 0; i < data.length; i++){
        var status = data[i].completion;
        if(status === null){
            status = "None";
        }
        var added = false;
        for (var j = 0; j < completion.length; j++){
            if(completion[j][0] === status){
                var count = completion[j][1] + 1;
                completion.splice(j, 1);
                completion.push([status,count]);
                added = true;
            }
        }
        if(added !== true){
            completion.push([status,1]);
        }
    }

    return [
        completion,
        "Completion in %"
      ];
}

function getBob(data:any){
    var totalAmount = 0;
    for (var i = 0; i < data.length; i++){
        var acv = Number(data[i]['acv']);
        totalAmount = totalAmount + acv;
    }
    return totalAmount;
}

export default function MyAccountsPage() {
    const [accountData, setAccountData] = useState([]);
    const [role, setRole] = useState('0');
    const [managerView, setManagerView] = useState(false);
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        fetch('/api/myaccounts').then(res => res.json()).then(data => {
            setAccountData(data["accounts"]);
            setRole(data["role"]);
            setManagerView(data["managerView"]);
            setLoading(false);
        });
    }, []);

    return (
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <div>
                    <h1 style={{marginTop:'0px'}}>My Accounts</h1>
                    <InfoTooltip message="Source: Salesforce DB | Refreshed: once a day" />
                    <Grid container spacing={0}>
                        <Grid item xs={6}>
                            {(role === '1' || role === '2' || role === '3' || role === '7') &&
                            <PieChart data={getPackages(accountData)} />
                            }
                        </Grid>
                        <Grid item xs={6}>
                            {(role === '1' || role === '2' || role === '3') &&
                            <PieChart data={getVerticals(accountData)} />
                            }
                            {role === '7' &&
                            <PieChart data={getImplementationStatus(accountData)} />
                            }
                        </Grid>
                    </Grid>
                    {isLoading &&
                        <CircularProgress />
                    }
                    {!isLoading &&
                        <>
                        {(role === '1' || role === '2' || role === '3') &&
                            <>
                                <MyAccountsTableAM data={accountData}></MyAccountsTableAM>
                                <MyAccountsTableHolidayAM />
                                <MyAccountsTableSecondAM />
                            </>
                        }
                        {(role === '4' || role === '5' || role === '10') &&
                            <>
                                <MyAccountsCard data={getBob(accountData)} />
                                <br/>
                                <MyAccountsTableSC data={accountData}></MyAccountsTableSC>
                            </>
                        }
                        {role === '7' &&
                            <>
                            {!managerView &&
                            <MyAccountsTableImpl data={accountData}></MyAccountsTableImpl>
                            }
                            {managerView &&
                            <MyAccountsTableImplManager data={accountData}></MyAccountsTableImplManager>
                            }
                            </>
                        }
                        </>
                    }
                </div>
            </Box>
    );
}