import { Tile } from "@adjust/components";
import { Avatar, Divider, Grid } from "@mui/material";
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

function getOs(os : any){
    if(os === null){
        return <div></div>
    }
    if(os.includes('itunes') && os.includes('google')){
        return <div><AndroidIcon/><AppleIcon/></div>
    }
    else if(os.includes('itunes')){
        return <div><AppleIcon/></div>
    }
    else if(os.includes('google')){
        return <div><AndroidIcon/></div>
    }
    else{
        return <div></div>
    }
}

function getCoppa(isChild: boolean){
    if(isChild === true){
        return 'True'
    }
    return 'False'
}

const getId = (ids : any) => {
    if(ids !== undefined){
        if(ids.length === 0 || ids === null){
            return '';
        }
        if(ids[0] !== ''){
            return ids[0];
        }
        if(ids[1] !== ''){
            return ids[1];
        }
    }
    return '';
  }

export default function AppInfo(props : any) {
    let appInfo = props.appInfo;
    let os = '';
    let accountName = props.accountName;
    if(appInfo.length > 0){
        os = appInfo[6];
    }
    return (
        <Tile >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div style={{fontWeight:'bold',marginRight:'4px'}}>{appInfo[1]}</div>
                </Grid>
                <Grid item xs={12}><Avatar alt="AppLogo" src={"https://www.apptrace.com/api/app/"+getId(appInfo[7])+"/artwork_url_small"}  sx={{ width: 56, height: 56 }}/></Grid>
                
                <Grid item xs={12}><Divider light/></Grid>
                
                <Grid item xs={5}>
                    <div style={{fontWeight:'bold',marginRight:'4px'}}>App Token: </div>
                </Grid>
                <Grid item xs={7}> {appInfo[2]}</Grid>
                
                <Grid item xs={12}><Divider light/></Grid>
                
                <Grid item xs={5}>
                    <div style={{fontWeight:'bold',marginRight:'4px'}}>OS: </div>
                </Grid>
                <Grid item xs={7}> {getOs(os)}</Grid>
                
                <Grid item xs={12}><Divider light/></Grid>
                
                <Grid item xs={5}>
                    <div style={{fontWeight:'bold',marginRight:'4px'}}>Account Name: </div>
                </Grid>
                <Grid item xs={7}> {accountName}</Grid>

                <Grid item xs={12}><Divider light/></Grid>

                <Grid item xs={5}>
                    <div style={{fontWeight:'bold',marginRight:'4px'}}>Account ID: </div>
                </Grid>
                <Grid item xs={7}> {appInfo[3]}</Grid>

                <Grid item xs={12}><Divider light/></Grid>

                <Grid item xs={5}>
                    <div style={{fontWeight:'bold',marginRight:'4px'}}>Reporting Currency: </div>
                </Grid>
                <Grid item xs={7}> {appInfo[4]}</Grid>

                <Grid item xs={12}><Divider light/></Grid>

                <Grid item xs={5}>
                    <div style={{fontWeight:'bold',marginRight:'4px'}}>Created At: </div>
                </Grid>
                <Grid item xs={7}> {formatDate(appInfo[5])}</Grid>
                
                <Grid item xs={12}><Divider light/></Grid>

                <Grid item xs={5}>
                    <div style={{fontWeight:'bold',marginRight:'4px'}}>App ID: </div>
                </Grid>
                <Grid item xs={7}> {appInfo[0]}</Grid>

                <Grid item xs={12}><Divider light/></Grid>

                <Grid item xs={5}>
                    <div style={{fontWeight:'bold',marginRight:'4px'}}>Kids Directed apps & COPPA: </div>
                </Grid>
                <Grid item xs={7}> {getCoppa(appInfo[8])}</Grid>

                {appInfo[10] !== null &&
                <>
                    <Grid item xs={12}><Divider light/></Grid>
                    <Grid item xs={5}>
                        <div style={{fontWeight:'bold',marginRight:'4px'}}>Deleted at: </div>
                    </Grid>
                    <Grid item xs={7}>  <div style={{color:'red'}}>{formatDate(appInfo[10])}</div></Grid>
                </>
                }
            </Grid>    
        </Tile>
    );
}