import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { formatDate } from '../../../../shared/components/formatDate/FormatDate';

function createData(
    id: number,
    adjust_account_id: number,
    api_secret: string,
    app_id: string,
    created_at: string,
    credentials_id: string,
    display_name: string,
    enabled: boolean,
    is_external: boolean,
    name: string,
    network: string,
    slug: string,
    use_for_cost: boolean,
    use_for_metadata: boolean,
    use_for_skadnetwork: boolean,
    createdBy: string,
    errors: string,
    externalId: string,
    hasErrors: string,
    ids: string,
    updatedAt: string,
    useForAdRevenue: string,
    useForAggregatedAdRevenue: string,
    useForAudienceBuilder: string,
    useForExport: string,
    useForLiveIntegration: string,
    useForUninstallDetector: string,
    validTill: string,
    agency: boolean,
    access_token: string
  ) {
    return { id,adjust_account_id, api_secret, app_id, created_at, credentials_id,
    display_name, enabled, is_external, name, network, slug, use_for_cost, use_for_metadata, use_for_skadnetwork,
    createdBy,errors,externalId,hasErrors,ids,updatedAt,useForAdRevenue,useForAggregatedAdRevenue,useForAudienceBuilder,useForExport,useForLiveIntegration,useForUninstallDetector,validTill, agency,access_token };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export const MetaPartnerCredentialsTable = (_props : any) =>{
    const rows : any = [];
    if(_props.data !== undefined){
        for (var i = 0; i < _props.data.length; i++){
          if(_props.data[i]["slug"] === 'facebook'){
            rows.push(
                createData(
                    i,
                    _props.data[i]["adjust_account_id"],
                    _props.data[i]["api_secret"],
                    _props.data[i]["app_id"],
                    formatDate(_props.data[i]["created_at"]),
                    _props.data[i]["credentials_id"],
                    _props.data[i]["display_name"],
                    _props.data[i]["enabled"],
                    _props.data[i]["is_external"],
                    _props.data[i]["name"],
                    _props.data[i]["network"],
                    _props.data[i]["slug"],
                    _props.data[i]["use_for_cost"],
                    _props.data[i]["use_for_metadata"],
                    _props.data[i]["use_for_skadnetwork"],
                    _props.data[i]["created_by"],
                    JSON.stringify(_props.data[i]["errors"]),
                    _props.data[i]["external_id"],
                    _props.data[i]["has_errors"],
                    _props.data[i]["id"],
                    formatDate(_props.data[i]["updated_at"]),
                    _props.data[i]["use_for_ad_revenue"],
                    _props.data[i]["use_for_aggregated_ad_revenue"],
                    _props.data[i]["use_for_audience_builder"],
                    _props.data[i]["use_for_export"],
                    _props.data[i]["use_for_live_integration"],
                    _props.data[i]["use_for_uninstall_detector"],
                    _props.data[i]["valid_till"],
                    _props.data[i]["agency"],
                    _props.data[i]["access_token"]
                )
            );
          }
        }
    }
    
    const columns: GridColDef[] = [
        { field: 'actions', headerName: 'Actions',flex: 1,maxWidth:250,minWidth:250,
          renderCell: (params : any) => {
              if(params.row.access_token !== undefined && params.row.access_token !== ''){
                  return <Button variant="outlined" onClick={()=>_props.onAccessTokenChange(params.row.access_token)}>Choose Credentials ID</Button>
              }
            return '';
          },
        },
        { field: 'id', headerName: '',flex: 1, maxWidth:1},
        { field: 'slug', headerName: 'Slug',flex: 1, minWidth:1},
        { field: 'credentials_id', headerName: 'Credentials Id',flex: 1,minWidth:300,maxWidth:300},
        { field: 'created_at', headerName: 'Created at',flex: 1},
        { field: 'updatedAt', headerName: 'Updated At',flex: 1},
        { field: 'validTill', headerName: 'Valid Till',flex: 1,maxWidth:120,minWidth:100},
        { field: 'enabled', headerName: 'Enabled',flex: 1,maxWidth:100,minWidth:100},
        { field: 'createdBy', headerName: 'Created By',flex: 1,maxWidth:120,minWidth:100},
        { field: 'agency', headerName: 'Agency',flex: 1,minWidth:100},
      ];
    return (
        <div style={{ height: 800, width: '183vh' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}