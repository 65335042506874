import { Box, Grid} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { PartnerHostBlocklistTable } from '../../../entities/components/ClickSpam/PartnerHostBlocklistTable';
import { InfoTooltip } from '../../../shared/components/infoTooltip/InfoTooltip';


const formatDate = (dateString : string) =>{
    if(dateString === '' || dateString === null){
        return '';
    }
    return new Date(dateString).toLocaleDateString();
}

export const PartnerHostBlocklistPage = () => {
    const [hosts,setHosts] = useState({description:'',values:[],updated_at: ''});
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true)
        fetch('/api/partnerhostblocklist').then(res => res.json()).then(data => {
            setHosts(data);
            setIsLoading(false); 
        });
    }, []);


    
    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <h1>Partner Host Blocklist</h1>
                                {isLoading &&
                                <CircularProgress />
                                }
                                {!isLoading &&
                                <>
                                    <p>Description: {hosts.description}</p>
                                    <p>Updated At: {formatDate(hosts.updated_at)}</p>
                                    <InfoTooltip message="Source: Adjust DB | Refreshed: instant" />
                                    <PartnerHostBlocklistTable values={hosts.values} />
                                </>
                                }
                            </Grid>
                        </Grid>
                    </div>
                </Box>
    );
}