import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

const columns: GridColDef[] = [
  { field: 'command', headerName: 'Command',flex: 1,},
  { field: 'username', headerName: 'Username',flex: 1,maxWidth:180,minWidth:100},
  { field: 'status', headerName: 'Status',flex: 1,maxWidth:120,minWidth:100},
  { field: 'createdAt', headerName: 'Created at',flex: 1,},
  { field: 'finishedAt', headerName: 'Finished At',flex: 1,},
  { field: 'failReason', headerName: 'Fail Reason',flex: 1,maxWidth:400,minWidth:100},
  { field: 'jobId', headerName: 'Job ID',flex: 1,maxWidth:300,minWidth:100},
  { field: 'priority', headerName: 'Priority',flex: 1,maxWidth:120,minWidth:100},
  
];

function createData(
    id: number,
    command: string,
    createdAt: string,
    failReason: string,
    finishedAt: string,
    jobId: string,
    priority: string,
    startedAt: string,
    status: string,
    username: string,

  ) {
    return { id, command, createdAt, failReason, finishedAt, jobId, priority, startedAt, status, username};
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const cleandOutputPath = (command: string) =>{
    if(command.includes('pull_pl_output/')){
      let commandArray = command.split('pull_pl_output/');
      return commandArray[1].replace('.log','')
    }
    return ''
  }

export const BaggerOverviewTable = (_props : any) =>{
    const rows : any = [];
    if(_props.data !== undefined){
        for (var i = 0; i < _props.data.length; i++){
            rows.push(
                createData(
                    i,
                    cleandOutputPath(_props.data[i]["command"]),
                    _props.data[i]["created_at"],
                    _props.data[i]["fail_reason"],
                    _props.data[i]["finished_at"],
                    _props.data[i]["job_id"],
                    _props.data[i]["priority"],
                    _props.data[i]["started_at"],
                    _props.data[i]["status"],
                    _props.data[i]["username"],
                )
            );
        }
    }
  
    return (
        <div style={{ height: 800, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}