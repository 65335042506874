
import { Tile } from '@adjust/components/build/Tile/Tile';
import { Box, Button, Divider, Grid, List, ListItem} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

function setValues(data : any, linkValue : any){
    var rows = [];
    if(data !== undefined){
        for (var i = 0; i < data.length; i++) {
            rows.push(<div>
                    <ListItem key={data[i][1]}>
                        <Grid container direction="row" alignItems="left" spacing={0}>
                            <Grid item xs={8} key={data[i][1]} >{data[i][1]}</Grid>
                            <Grid item xs={4}key={data[i][0]} >
                                <Button variant="outlined" component={Link} to={"/"+linkValue+"/"+data[i][0]}>{data[i][0]}</Button>
                            </Grid>
                        </Grid>
                        <br/>
                    </ListItem>
                    <Divider light />
                </div>)
           }
        }
        return <>
            {rows}
        </>
}

export default function SearchPage() {
    let params = useParams();
    const [accounts, setAccounts] = useState([]);
    const [apps, setApps] = useState([]);
    const [partners, setPartner] = useState([]);
    useEffect(() => {
        fetch('/api/search/'+params.searchvalue).then(res => res.json()).then(data => {
            setAccounts(data["accounts"]);
            setApps(data["apps"]);
            setPartner(data["partners"]);
        });
    }, [params.searchvalue]);
    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <h2>Search Results for: {params.searchvalue}</h2>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Tile >
                                    <h3 style={{ fontWeight: 'bold' }}>Accounts</h3>
                                    <List  component="nav">
                                        <ListItem>
                                            <Grid container direction="row" alignItems="left" spacing={0}>
                                                <Grid item xs={8} key={1}>Account Name</Grid>
                                                <Grid item xs={4} key={2}>Account Id</Grid>
                                            </Grid>
                                        </ListItem>
                                        <Divider light />
                                        {setValues(accounts,'account')}
                                    </List>                                
                                </Tile>
                            </Grid>
                            <Grid item xs={4}>
                                <Tile>
                                    <h3 style={{ fontWeight: 'bold' }}>Apps</h3>
                                    <List  component="nav">
                                        <ListItem>
                                            <Grid container direction="row" alignItems="left" spacing={0}>
                                                <Grid item xs={8} key={3}>App Name</Grid>
                                                <Grid item xs={4} key={4}>App Token</Grid>
                                            </Grid>
                                        </ListItem>
                                        <Divider light />
                                        {setValues(apps,'account/app')}
                                    </List> 
                                </Tile>
                            </Grid>
                            <Grid item xs={4}>
                                <Tile >
                                    <h3 style={{ fontWeight: 'bold' }}>Partners</h3>
                                    <List  component="nav">
                                        <ListItem>
                                            <Grid container direction="row" alignItems="left" spacing={0}>
                                                <Grid item xs={8} key={5}>Partner Name</Grid>
                                                <Grid item xs={4} key={6}>Partner Id</Grid>
                                            </Grid>
                                        </ListItem>
                                        <Divider light />
                                        {setValues(partners,'partners')}
                                    </List> 
                                </Tile>
                            </Grid>
                        </Grid>
                    </div>
                </Box>
    );
}