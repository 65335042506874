import { Chart } from "react-google-charts";

const formatDate = (dateString : string) =>{
    if(dateString === '' || dateString === null){
        return '';
    }
    return new Date(dateString).toLocaleDateString();
}

const setChartValuesNoVisitors = (datas : any) => {
    const data = [
        ["Date","TimeSpent","Events"],
        ["N/A",0,0],
      ];
    var rows : any = [["Date","TimeSpent","Events"]];
    if(datas !== undefined && datas !== '' ){
        for (let i = 0; i < datas.length; i++) {
            rows.push([formatDate(datas[i][1]), datas[i][0], datas[i][2] ]);
        }
        return rows;
    }
    return data;
}

const setChartValuesVisitors = (datas : any) => {
    const data = [
        ["Date","Visitors"],
        ["N/A",0,0,0],
      ];
    var rows : any = [["Date","Visitors"]];
    if(datas !== undefined && datas !== '' ){
        for (let i = 0; i < datas.length; i++) {
            rows.push([formatDate(datas[i][1]), datas[i][3]]);
        }
        return rows;
    }
    return data;
}

export const options = {
  chart: {
    title: "Usage L6M (weekly)"
  },
  vAxis: {minValue: 0},
  
};

export const UsageChart = (props : any) =>{
    let usageMinimized = props.usageMinimized;
    return (
        <div style={{marginLeft:'5px',marginTop:'5px',marginRight:'5px'}}>
            {usageMinimized.length > 0 &&
            <>
                <Chart
                    chartType="Line"
                    width="100%"
                    height="400px"
                    data={setChartValuesNoVisitors(usageMinimized)}
                    options={options}
                />
                <Chart
                    chartType="Line"
                    width="100%"
                    height="400px"
                    data={setChartValuesVisitors(usageMinimized)}
                    options={options}
                />
            </>
            }
        </div>
    );
}