import { Box, Button, Dialog } from "@mui/material";
import { useState } from "react";
import { AccountSfCasesTable } from "../../entities/components/Accounts/AccountSfCasesTable";

export const AmCaseModal = (_props : any) =>{
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return <>
            <Button onClick={handleOpen} variant="outlined">Inspect</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xl"
                fullWidth
            >
                <Box >
                    <AccountSfCasesTable cases={_props?.data} />
                </Box>
            </Dialog>
        </>
}