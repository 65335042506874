import { Box, Grid} from '@mui/material';
import React, {useEffect, useState } from 'react';
import { MetaApiCallsTable } from '../../../entities/components/MetaApiCalls/MetaApiCallsTable';

export const MetaApiCalls = () =>{
    const [data, setData] : any = useState([]);
    useEffect(() => {
        fetch('/api/metaapicalls').then(res => res.json()).then(data => {
            setData(data);
        });
    }, []);

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <h1>Meta/FB AEM/SKAN Readiness API Overview</h1>
                                <MetaApiCallsTable data={data} />
                            </Grid>
                        </Grid>
                    </div>
                </Box>
    );
}