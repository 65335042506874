import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { BqgUsageTable } from './BqgUsageTable';

export const BqgUsagePage = () => {
    const [usage, setUsage] = useState([]);
    useEffect(() => {
        fetch('/api/bqgusage').then(res => res.json()).then(data => {
            setUsage(data);
        });
    }, []);

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <div>
                <h1>BQG Usage Last 30 days</h1>
                <BqgUsageTable usage={usage} />
            </div>
        </Box>
    );
}