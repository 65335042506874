import { Box, Button, Grid, InputLabel, MenuItem, Select, TextField, Tooltip} from '@mui/material';
import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { DatePickerButton, Timepicker } from '@adjust/components';
import { DateRange } from '@adjust/components/build/DatePickerButton/DatePickerButton.utils';

export const SanAnalysisPage = () => {
    const [appToken,setAppToken]  = useState('');
    const [san,setSan]  = useState('');
    const [os,setOs]  = useState('');
    const [clickWindow,setClickWindow]  = useState(168);
    const [impressionWindow,setImpressionWindow]  = useState(24);
    const [grepWord,setGrepWord]  = useState('');
    const [startTime, setStartTime] = useState('00:00');
    const [endTime, setEndTime] = useState('00:00');
    const [dateRange, setDateRange] = useState<DateRange>({
        startDate: null,
        endDate: null,
        period: undefined
    });
    const [error, setError] = useState('');
    const [isLoading,setIsLoading]  = useState(false);
    
    const handleChangeNumber = (event: string) => {
        if (event.match(/[^0-9]/)) {
          return Number(event)
        }
        return Number(event)
    }

    const onTimeChange = (inputValue:string, type:string) => {
        type === 'start' ? setStartTime(inputValue) : setEndTime(inputValue);
      }
    
    const createSanAnalysisPost = () => {
        if(appToken === '' || san === '' || dateRange.startDate === null || dateRange.endDate === null  )
        {
            setError('Missing arguments');
        }
        else{
            setIsLoading(true);
            const data = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    'appToken': appToken,
                    'san': san,
                    'os': os,
                    'clickWindow': clickWindow,
                    'impressionWindow': impressionWindow,
                    'grepWord': grepWord,
                    'startTime': startTime,
                    'endTime': endTime,
                    'startDate': dateRange.startDate,
                    'endDate': dateRange.endDate
                    }
                    ),
            }
            fetch('/api/sananalysis', data).then(response => response.json()).then(function (data) {setIsLoading(false)});
        }
    }

    return(
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                     <h1>San Analysis</h1>
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                
                <Grid item xs={2}>
                    <InputLabel id="appTokenLabel">App Token</InputLabel>
                    <TextField
                        id="appToken" 
                        name="appToken"
                        value={appToken}
                        onChange={(e) => {
                            setAppToken(e.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <InputLabel id="sanDropdown">SAN</InputLabel>
                    <Select
                        labelId="sanSelect"
                        id="san"
                        value={san}
                        label="san"
                        onChange={(e) => {
                            setSan(e.target.value);
                        }}
                        style={{width:'80%'}}
                    >
                        <MenuItem value="googleads" key="googleads">googleads</MenuItem>
                        <MenuItem value="facebook" key="facebook">facebook</MenuItem>
                        <MenuItem value="twitter" key="twitter">twitter</MenuItem>
                        <MenuItem value="snapchat" key="snapchat">snapchat</MenuItem>
                        <MenuItem value="doubleclick" key="doubleclick">doubleclick</MenuItem>
                        <MenuItem value="yahoo_japan_search" key="yahoo_japan">yahoo_japan_search</MenuItem>
                        <MenuItem value="yahoo_gemini" key="yahoo_gemini">yahoo_gemini</MenuItem>
                        <MenuItem value="google_marketing_platform" key="google_marketing_platform">google_marketing_platform</MenuItem>
                        <MenuItem value="apple_ads" key="apple_ads">apple_ads</MenuItem>
                        <MenuItem value="roku" key="roku">roku</MenuItem>
                    </Select>
                </Grid>
                
                <Grid item xs={2}>
                    <Tooltip placement="top" title="ios or android. If you don't specify this parameter, both os data will be analyzed.">
                        <InputLabel id="osDropdown">OS</InputLabel>
                    </Tooltip>
                    <Select
                        labelId="osSelect"
                        id="os"
                        value={os}
                        label="os"
                        onChange={(e) => {
                            setOs(e.target.value);
                        }}
                        style={{width:'80%'}}
                    >
                        <MenuItem value="android" key="Android">Android</MenuItem>
                        <MenuItem value="ios" key="iOS">iOS</MenuItem>
                    </Select>
                </Grid>

                <Grid item xs={2}>
                    <Tooltip placement="top" title="Click attribution window (hours). If you don't specify this value, 168 hours (7 days) is used as click attribution window for analysis. If custom attribution is set like 30 days for example, you can set 720 in this parameter (click_window=720).">
                        <InputLabel id="clickWindowLabel">Click Window</InputLabel>
                    </Tooltip>
                    <TextField
                        id="clickWindow" 
                        type='number'
                        name="clickWindow"
                        value={clickWindow}
                        onChange={(e) => setClickWindow(handleChangeNumber(e.target.value))}
                    />        
                </Grid>

                <Grid item xs={2}>
                    <Tooltip placement="top" title="Imp attribution window (hours). If you don't specify this value, 24 hours is used as imp attribution window for analysis. If custom attribution is set like 6 hours for example, you can set 6 in this parameter (imp_window=6).">
                        <InputLabel id="impressionWindowLabel">Impression Window</InputLabel>
                    </Tooltip>
                    <TextField
                        id="impressionWindow"  
                        type='number'
                        name="impressionWindow"
                        value={impressionWindow}
                        onChange={(e) => setImpressionWindow(handleChangeNumber(e.target.value))}
                    />
                </Grid>

                <Grid item xs={2}>
                    <Tooltip placement="top" title="f you want to analyze specific data only like specific campaign, you can set campaign id in this field. For example, if you want to check campaign id=12345 data, you can set grep_word=12345.">
                        <InputLabel id="grepWordLabel">Grep Word</InputLabel>
                    </Tooltip>
                    <TextField
                        id="grepWord" 
                        name="grepWord"
                        value={grepWord}
                        onChange={(e) => {
                            setGrepWord(e.target.value);
                        }}
                    />
                </Grid>
                
                <Grid item xs={3} style={{marginTop:'2%'}}>
                    <DatePickerButton
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        onApply={setDateRange}
                    />
                </Grid>

                <Grid item xs={2} style={{marginTop:'2%'}}>
                    <>
                        <Timepicker 
                            label="Start time"
                            value={startTime}
                            onChange={(value:string) => onTimeChange(value, 'start')}
                            onClick={(value:string) => onTimeChange(value, 'start')}
                        />
                    </>
                </Grid>

                <Grid item xs={2} style={{marginTop:'2%'}}>
                    <>
                        <Timepicker 
                            label="End time"
                            value={endTime}
                            onChange={(value: string) => onTimeChange(value, 'end')}
                            onClick={(value: string) => onTimeChange(value, 'end')}
                        />
                    </>
                </Grid>
  
            </Grid>
            <br/>
            <Button variant="contained" style={{backgroundColor:'#005ff7'}} onClick={createSanAnalysisPost}>Start tool</Button>
            {error !== '' &&
                <p style={{color:'red'}}>{error}</p>
            }
            
            
            {isLoading &&
            <CircularProgress />
            }
    </Box>
    );
}