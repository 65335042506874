import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button, Snackbar } from '@mui/material';

function createData(
    id: number,
    salesforceId: string,
    name: string,
    mail: string,
    role: string,
  ) {
    return { id, salesforceId, name, mail, role};
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export default function SalesforceUsersTable(_props : any) {
    let role = _props.role;
    const [users, setUsers] = useState([]);
    useEffect(() => {
        if(role === '2'){
            fetch('/api/alluserssalesforce').then(res => res.json()).then(data => {
                setUsers(data);
            });
        }
    }, [role]);
    const rows : any = [];
    for (var i = 0; i < users.length; i++){
        rows.push(
            createData(
                i,
                users[i][0],
                users[i][1],
                users[i][2],
                users[i][3]
            )
        );
    }

    // deletion
    const [deleted, setDeleted] = useState(false);
    const handleClickDeleted = () => {
        setDeleted(true);
      };
    
      const handleCloseDeleted = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setDeleted(false);
      };
    const deleteSfUser = (salesforceId: string) => {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ "salesforceId": salesforceId})
        };
        fetch('/api/deletesalesforceuser', requestOptions).then((response) => response.json()
        .then((user) => {
          if(user.error === 'none'){
            handleClickDeleted();
          }
        }));
      }
    const columns: GridColDef[] = [
        { field: 'salesforceId', headerName: 'Salesforce Id',flex: 1},
        { field: 'name', headerName: 'Name',flex: 1},
        { field: 'mail', headerName: 'Mail',flex: 1},
        { field: 'role', headerName: 'Role',flex: 1},
        { field: 'delete', headerName: 'Delete User',flex: 1, minWidth: 150, sortable: false,
                      renderCell: (params : any) => {
                          return <div>
                                  <Button variant="outlined" onClick={() => {deleteSfUser(params.row.salesforceId)}} >Delete User</Button>
                                </div>
                      },
          },
      ];
    return (
        <div style={{ height: 600, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
            <Snackbar
                open={deleted}
                autoHideDuration={6000}
                onClose={handleCloseDeleted}
                message="Salesforce User Deleted"
            />
        </div>
    );
}