import { Tile } from "@adjust/components";
import { Box, Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { NotificationTable } from "./NotificationTable";

export const NotificationPage = () => {
    const [data, setData] = useState([]);
    
    useEffect(() => {
        fetch('/api/notificationpage').then(res => res.json()).then(data => {
            setData(data["notifications"]);
        });
    },[]);

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Tile title="Notifications">
                    <div>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Card variant="outlined">
                                    <NotificationTable notifications={data} />
                                </Card>
                            </Grid>
                                
                        </Grid>
                    </div>
                    </Tile>
                </Box>
    );
}