import React, { createContext, useState } from "react";

interface IAuth {
    isLoggedIn: boolean;
    apiToken: string;
    isManager: boolean;
    role: string;
}

interface ILogin {
  name: string;
  url: string;
  wfh: boolean;
}

interface IAuthContext {
  auth: IAuth;
  login: ILogin;
  setAuthentification: (state: IAuth) => void;
  setLogout: () => void;
  setLogin: (state: ILogin) => void;
}

interface ILogin {
  name: string;
  url: string;
  wfh: boolean;
}
  
export const AuthContext = createContext<IAuthContext | null>(null);

export const AuthProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [auth, setAuthentification] = useState({ isLoggedIn: false, apiToken: '', isManager: false, role: '' });
  const [login, setLogin] = useState({ name: '', url: '', wfh: false });

  const setLogout = () => {
    setAuthentification({ isLoggedIn: false, apiToken: '',isManager: false, role: '' });
  }
  return (
    <AuthContext.Provider value={{ auth, setAuthentification, setLogout, login, setLogin }}>
      {children}
    </AuthContext.Provider>
  );
};