import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Badge } from '@adjust/components/build/Badge/Badge';

function statusBadge(text: string){
  if(text !== null){
      return <Badge label="Setup" color="positive" />
  }
  else{
      return <Badge label="Not Setup" color="warning" />
  }
}

function setUniversalLink(link : string){
  if(link !== null){
    return link+".adj.st";
  }
  return '';
}
export default function DeepLinkTable(_props : any) {
  const data = _props["deepLink"];
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
                <TableCell>Setting</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="android">
              <TableCell component="th" scope="row">Android App Scheme</TableCell>
              <TableCell component="th" scope="row">{statusBadge(data[2])}</TableCell>
              <TableCell component="th" scope="row">
                {data[2]}
              </TableCell>
          </TableRow>
          <TableRow key="ios">
              <TableCell component="th" scope="row">Raw Universal Link</TableCell>
              <TableCell component="th" scope="row">{statusBadge(data[4])}</TableCell>
              <TableCell component="th" scope="row">
                {setUniversalLink(data[4])}
              </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}