import { Route, Routes, useParams } from "react-router-dom";
import { AnomaliePage } from "../../pages/account/accountAnomalies/AccountAnomalies";
import { AppsPage } from "../../pages/account/accountApps/AccountApps";
import { FeaturePage } from "../../pages/account/accountFeatures/AccountFeatures";
import { AccountImplementationPage } from "../../pages/account/accountImplementation/AccountImplementation";
import { AccountKeyStats } from "../../pages/account/accountKeyStats/AccountKeyStats";
import { AccountOOOCoveragePage } from "../../pages/account/accountOOOCoverage/AccountOOOCoverage";
import { AccountPage } from "../../pages/account/accountOverview/Account";
import { UsersPage } from "../../pages/account/accountUsers/AccountUsers";
import { AccountNetworks } from "../../pages/account/accountNetworks/AccountNetworks";
import { useContext, useEffect, useState } from "react";
import { SideBar } from "../../shared/components/sideBar/SideBar";
import { Box } from "@mui/material";
import { AuthContext } from "../../app/store/AuthContext";
import { AccountSalesPage } from "../../pages/accountSales/AccountPageSales";
import { FeatureSalesPage } from "../../pages/accountSales/AccountFeaturePageSales";
import { AnomalieSalesPage } from "../../pages/accountSales/AccountAnomaliesPageSales";
import { AccountKeyStatsSalesPage } from "../../pages/accountSales/AccountKeyStatsPageSales";
import { ForecastSalesPage } from "../../pages/accountSales/AccountForecastPageSales";

export const AccountRouter = () => {
    const value = useContext(AuthContext);
    const role = value?.auth.role;
    let params = useParams();
    let accountId  = "";
    if (params.accountId !== undefined){
        accountId = String(params.accountId);
    }
    const [datas,setData] = useState({accountName:'N/A', accountManager:'',competitor:false})
    useEffect(() => {
        fetch('/api/accountheader/'+accountId).then(res => res.json()).then(data => {
            setData(data);
        });
    }, [accountId]);

    return(
        <Box sx={{ display: 'flex',marginTop:'4%',marginRight:'2%'}}>
            <SideBar/>
            {(role !== '4' && role !== '5' && role !== '10') &&
            <Routes>
                <Route path="/" element={<AccountPage accountName={datas?.accountName} accountManager={datas?.accountManager} competitor={datas?.competitor}/>} />
                <Route path="/apps" element={<AppsPage accountName={datas?.accountName} accountManager={datas?.accountManager} competitor={datas?.competitor}/>} />
                <Route path="/users" element={<UsersPage accountName={datas?.accountName} accountManager={datas?.accountManager} competitor={datas?.competitor}/>} />
                <Route path="/features" element={<FeaturePage accountName={datas?.accountName} accountManager={datas?.accountManager} competitor={datas?.competitor}/>} />
                <Route path="/anomalies" element={<AnomaliePage accountName={datas?.accountName} accountManager={datas?.accountManager} competitor={datas?.competitor}/>} />
                <Route path="/keystats" element={<AccountKeyStats accountName={datas?.accountName} accountManager={datas?.accountManager} competitor={datas?.competitor}/>} />
                <Route path="/implementation" element={<AccountImplementationPage accountName={datas?.accountName} accountManager={datas?.accountManager} competitor={datas?.competitor}/>} />
                <Route path="/coverage" element={<AccountOOOCoveragePage accountName={datas?.accountName} accountManager={datas?.accountManager} competitor={datas?.competitor}/>} />
                <Route path="/networks" element={<AccountNetworks accountName={datas?.accountName} accountManager={datas?.accountManager} competitor={datas?.competitor}/>} />
                <Route path="/forecast" element={<ForecastSalesPage accountName={datas?.accountName} accountManager={datas?.accountManager} competitor={datas?.competitor}/>} />
                <Route path="/*" element={<AccountPage accountName={datas?.accountName} accountManager={datas?.accountManager} competitor={datas?.competitor} />}></Route>
            </Routes>
            }
            {(role === '4' || role === '5' || role === '10') &&
            <Routes>
                <Route path="/" element={<AccountSalesPage accountName={datas?.accountName} accountManager={datas?.accountManager} competitor={datas?.competitor}/>} />
                <Route path="/features" element={<FeatureSalesPage accountName={datas?.accountName} accountManager={datas?.accountManager} competitor={datas?.competitor}/>} />
                <Route path="/anomalies" element={<AnomalieSalesPage accountName={datas?.accountName} accountManager={datas?.accountManager} competitor={datas?.competitor}/>} />
                <Route path="/keystats" element={<AccountKeyStatsSalesPage accountName={datas?.accountName} accountManager={datas?.accountManager} competitor={datas?.competitor}/>} />
                <Route path="/forecast" element={<ForecastSalesPage accountName={datas?.accountName} accountManager={datas?.accountManager} competitor={datas?.competitor}/>} />
               
                <Route path="/*" element={<AccountPage accountName={datas?.accountName} accountManager={datas?.accountManager} competitor={datas?.competitor} />}></Route>
            </Routes>
            }
        </Box>
    );
}
