import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from '../../shared/components/formatDate/FormatDate';
import {NumericFormat} from 'react-number-format';

const columns: GridColDef[] = [
    { field: 'month', headerName: 'Month',flex: 1, minWidth: 100},
    { field: 'acv', headerName: 'ACV',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
            return <NumericFormat value={params.row.acv} displayType={'text'} thousandSeparator={true}/>
          }
    },
    { field: 'actualAttributions', headerName: 'Actual Datapoints',flex: 1, minWidth: 200,
        renderCell: (params : any) => {
            return <NumericFormat value={params.row.actualAttributions} displayType={'text'} thousandSeparator={true}/>
          }
    },
    { field: 'avgAttributionsL3Month', headerName: 'Avg DPs L3 Month',flex: 1, minWidth: 200,
        renderCell: (params : any) => {
            return <NumericFormat value={params.row.avgAttributionsL3Month} displayType={'text'} thousandSeparator={true}/>
          }
    },
    { field: 'weigthedAvgAttributionsL12Month', headerName: 'Weigthed Avg DPs L12 Month',flex: 1, minWidth: 200,
        renderCell: (params : any) => {
            return <NumericFormat value={params.row.weigthedAvgAttributionsL12Month} displayType={'text'} thousandSeparator={true}/>
          }
    },
    { field: 'forecastNext12Month', headerName: 'Forecast Next 12 Month',flex: 1, minWidth: 200,
        renderCell: (params : any) => {
            return <NumericFormat value={params.row.forecastNext12Month} displayType={'text'} thousandSeparator={true}/>
          }
    },
    { field: 'estCostLast12Month', headerName: 'Estimated Cost Last 12 Month',flex: 1, minWidth: 200,
        renderCell: (params : any) => {
            return <NumericFormat value={params.row.estCostLast12Month} displayType={'text'} thousandSeparator={true}/>
          }
    },
    { field: 'estForecastCostNext12Month', headerName: 'Estimated Forecast Cost Next 12 Month',flex: 1, minWidth: 200,
        renderCell: (params : any) => {
            return <NumericFormat value={params.row.estForecastCostNext12Month} displayType={'text'} thousandSeparator={true}/>
          }
    },
    { field: 'recommendedAcvLast12Month', headerName: 'Recommended ACV Last 12 Month',flex: 1, minWidth: 200,
        renderCell: (params : any) => {
            return <NumericFormat value={params.row.recommendedAcvLast12Month} displayType={'text'} thousandSeparator={true}/>
          }
    },
    { field: 'recommendedAcvForecastNext12Month', headerName: 'Recommended ACV Forecast Next 12 Month',flex: 1, minWidth: 200,
        renderCell: (params : any) => {
            return <NumericFormat value={params.row.recommendedAcvForecastNext12Month} displayType={'text'} thousandSeparator={true}/>
          }
    },
    { field: 'lowerLimitAcvForecastNext12Month', headerName: 'Lower Limit ACV for Next 12 Month',flex: 1, minWidth: 200,
        renderCell: (params : any) => {
            return <NumericFormat value={params.row.lowerLimitAcvForecastNext12Month} displayType={'text'} thousandSeparator={true}/>
          }
    },
  ];

function createData(
    id: number,
    month: string,
    acv: string,
    actualAttributions: number,
    avgAttributionsL3Month: number,
    weigthedAvgAttributionsL12Month: number,
    forecastNext12Month: number,
    estCostLast12Month: number,
    estForecastCostNext12Month: number,
    recommendedAcvLast12Month: number,
    recommendedAcvForecastNext12Month: number,
    lowerLimitAcvForecastNext12Month: number,

  ){
    return { id, month, acv, actualAttributions, avgAttributionsL3Month, weigthedAvgAttributionsL12Month, forecastNext12Month, estCostLast12Month, estForecastCostNext12Month,
        recommendedAcvLast12Month, recommendedAcvForecastNext12Month, lowerLimitAcvForecastNext12Month
      };
  }

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export const DpForecastTable = (_props : any) =>{
    const data = _props.data;

    const maunData : any = [];
    if(data !== undefined){
        for (var i = 0; i < data.length; i++){
            maunData.push(
                createData(
                    i,
                    formatDate(data[i][1]),
                    data[i][2],
                    data[i][3],
                    data[i][4],
                    data[i][5],
                    data[i][6],
                    data[i][7],
                    data[i][8],
                    data[i][9],
                    data[i][10],
                    data[i][11],      
                )
            );
        }
    }

    return (
        <div style={{ height: 650, width: '100%' }}>
            <DataGrid 
                rows={maunData} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
                initialState={{
                    sorting: {
                      sortModel: [{ field: 'createdAt', sort: 'desc' }],
                    },
                }}
            />
        </div>
    );
}