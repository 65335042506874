import { formatDate } from "../../../shared/components/formatDate/FormatDate";
import { ImplementationModal } from "./ImplementationModal";
import { StatusCellOthers } from "./StatusCell";
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { S2sEventCheck, S2sReportCheck, WebAttributionCheck } from "./StatusCheckOthers";

const columns: GridColDef[] = [
    { field: 'sdkComponent', headerName: 'SDK Component',flex: 1},
    { field: 'lastTimeChecked', headerName: 'Last Time Checked',flex: 1},
    { field: 'status', headerName: 'Status',flex: 1, 
        renderCell: (params : any) => {
            return <StatusCellOthers status={params.row.status} />
        },
    },      
    { field: 'data', headerName: 'Check Data',flex: 1, 
        renderCell: (params : any) => {
            return <ImplementationModal data={params.row.data} type={params.row.type} />
        },
    }
];
  
function createData(
    id: string,
    sdkComponent: string,
    lastTimeChecked: string,
    status: number,
    data: any,
    type: string
  ) {
    return { id, sdkComponent, lastTimeChecked, status, data, type};
}

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export const IntegrationOverviewOther = (_props: any) =>{
    const data = _props.data;
    const lastRunTime = _props.lastRunTime;
    const WebAttribution = WebAttributionCheck(data.lastRunTime,data.webAttribution);
    const S2sEvent = S2sEventCheck(data.lastRunTime,data.s2sEventTracking);
    const S2sReport = S2sReportCheck(data.lastRunTime,data.s2sErrorReport);
    
    const rows : any = [];
    rows.push(
        createData(
            "web-attribution",
            "Web attribution",
            formatDate(lastRunTime),
            WebAttribution,
            data.webAttribution,
            "webAttribution"
        )
    );
    rows.push(
        createData(
            "s2s-event-tracking",
            "S2S Event tracking",
            formatDate(lastRunTime),
            S2sEvent,
            data.s2sEventTracking,
            "s2sEventTracking"
        )
    );
    rows.push(
        createData(
            "s2s-error-report",
            "S2S Error Report",
            formatDate(lastRunTime),
            S2sReport,
            data.s2sErrorReport,
            "s2sErrorReport"
        )
    );

    return(
        <div style={{ height: 300 }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}