import { Avatar, Box, Button, Grid} from '@mui/material';
import { Tile } from '@adjust/components';
import { Link } from 'react-router-dom';

export const ManagerViewPage = (_props:any) => {
    const users = _props.users;
    const team = _props.team;
    return (
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <div>
                    <Grid container spacing={2}>
                        {(team === 'Customer Success' || team === 'Sales') && 
                        <Grid item xs={3}>
                            <Tile title="All team accounts">
                                <Grid container spacing={2}>
                                    <Grid item xs={2}>

                                    </Grid>
                                    <Grid item xs={10}>
                                        <h5>Location: Team Range</h5>
                                    </Grid>
                                    <Button variant="contained" component={Link} to={"/reports/salesoverviewteam"} style={{marginLeft:'2%'}}>Overview</Button>
                                </Grid>
                            </Tile>
                        </Grid>
                        }
                        {users.map((user : any) => (
                            <Grid item xs={3}>
                                <Tile title={user[0] + " " + user[1]}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <Avatar alt="Remy Sharp" src={user[5]} />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <h5>Location: {user[2][0].toUpperCase() + user[2].slice(1)}</h5>
                                        </Grid>
                                        <Button variant="contained" component={Link} to={"/amoverview/"+user[4]} style={{marginLeft:'2%'}}>Overview</Button>
                                    </Grid>
                                </Tile>
                            </Grid>
                        ))}
                        
                    </Grid>
                </div>
            </Box>
    );
}