import { ComboBox } from '@adjust/components';
import React,{ useEffect,useState} from 'react'
import { Button, Card, Grid, TextField } from "@mui/material";


export const DeviceIdInput = (_props) =>{
    const options = [
        { value: 'adid', label: 'adid' },
        { value: 'idfa', label: 'idfa' },
        { value: 'idfv', label: 'idfv' },
        { value: 'gps_adid', label: 'gps_adid' },
        { value: 'android_id', label: 'android_id' },
        { value: 'oaid', label: 'oaid' },
        { value: 'web_uuid', label: 'web_uuid' },
        { value: 'win_adid', label: 'win_adid' },
        { value: 'win_hwid', label: 'win_hwid' },
        { value: 'ws_hwid', label: 'ws_hwid' },
      ];
    const [valueDeviceType, setValueDeviceType] = useState('');
    const [valueDeviceId, setValueDeviceId] = useState('');
    const [devices,setDevices] = useState([]);
  
    const handleChangeDeviceType = (value) => {
        setValueDeviceType(value);
    }
    const handleChangeDeviceId = (value) => {
        setValueDeviceId(value.target.value);
    }
    
    const addDevice = () => {
        if(valueDeviceType !== '' && valueDeviceId !== ''){
            setDevices(devices => [...devices, {'id':devices.length,'deviceType': valueDeviceType.value, 'deviceId': valueDeviceId}]);
            setValueDeviceType('');
            setValueDeviceId('');
        }
    }
    const removeDevice = (deviceId) => {
        setDevices(devices.filter(item => item.id !== deviceId));
    }
    useEffect(() => {
        _props.onDeviceIdsChange(devices);
    },[devices]);
    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <ComboBox
                    value={valueDeviceType}
                    options={options}
                    onChange={handleChangeDeviceType}
                    placeholder="Device Type"
                />
            </Grid>
            <Grid item xs={7}>
                <TextField label="Device Id" variant="outlined" value={valueDeviceId} onChange={handleChangeDeviceId} style={{width:'100%'}}/>
            </Grid>
            <Grid item xs={1}>
                <Button variant='contained' style={{backgroundColor:'#005ff7'}} onClick={addDevice}>Add</Button>
            </Grid>
            <Grid container spacing={4}>
                {devices.map((device) => (
                <Grid item xs={3}>
                    <Card>
                        <Button label="X" kind="feedback-negative-primary" onClick={() => removeDevice(device.id)}></Button>
                        <p style={{marginLeft:'2%'}}>Device Type: {device.deviceType}</p>
                        <p style={{marginLeft:'2%'}}>Device Id: {device.deviceId}</p>
                    </Card>
                </Grid>
                ))}
            </Grid>
        </Grid>
    );
  }