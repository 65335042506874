import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import DataResidencyTable from "./DataResidencyTable";
import { InfoTooltip } from "../../../shared/components/infoTooltip/InfoTooltip";

export const DataResidencyPage = () => {
    const [apps,setApps] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true)
        fetch('/api/dataresidency').then(res => res.json()).then(data => {
            setApps(data["apps"]);
            setIsLoading(false); 
        });
    }, []);

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                 <h1>Data Residency Accounts</h1>
                            </Grid>
                            {isLoading &&
                            <CircularProgress />
                            }
                            {!isLoading &&
                            <>
                                <InfoTooltip message="Source: Adjust DB | Refreshed: instant" />
                                <DataResidencyTable apps = {apps} />
                            </>
                            }
                        </Grid>
                    </div>
                </Box>
    );
}