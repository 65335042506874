import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import HouseIcon from '@mui/icons-material/House';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Box, InputAdornment, Menu, MenuItem, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useContext, useState } from 'react';
import { ManageSearch } from '@mui/icons-material';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { AuthContext } from '../../../app/store/AuthContext';
import { NotificationIcon } from './Notification';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  backgroundColor: '#04182b',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: '#04182b',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function setSideMenu(site: number, location: any) {
  if (location.pathname.includes("overview")) {
    if (site === 1) {
      return true;
    }
    else {
      return false;
    }
  }
  else if (location.pathname.includes("tools") || location.pathname.includes("reports")) {
    if (site === 3) {
      return true;
    }
    else {
      return false;
    }
  }
  else if (location.pathname.includes("team")) {
    if (site === 5) {
      return true;
    }
    else {
      return false;
    }
  }
  else if (location.pathname.includes("admin")) {
    if (site === 8) {
      return true;
    }
    else {
      return false;
    }
  }
  else {
    if (site === 2) {
      return true;
    }
    else {
      return false;
    }
  }
}

export const SideBar = () =>{
  const location = useLocation();
  let navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const value = useContext(AuthContext);
  const profile = value?.login || {'name':'','url':'','wfh':false};
  const role = value?.auth.role;

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const searchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  }

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  

  const searchForValue = (event:any) => {
    if (event.key === "Enter") {
      if (searchValue !== undefined && searchValue !== ''){
        navigate("/search/"+searchValue);
      }
    }
  }

  return (
    <div>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{ backgroundColor: '#04182b' }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <img id="logo-adjust" src={"https://a.storyblok.com/f/47007/x/2e8164e221/adjust-logo-white.svg"} width="200" height="50" alt="adjust.com" />
          <Typography variant="h6" noWrap component="div">
            Support HQ
          </Typography>
          <Box style={{ marginLeft: '10%', marginRight: 'auto' }}>
            <TextField id="searchfield" label="" color="info"
              style={{ width: '300px' }}
              onChange={searchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{color:'white'}}/>
                  </InputAdornment>
                ),
                sx: { 
                  color: "white", 
                  fieldset: { borderColor: "white" }
                }
              }}
              onKeyPress={searchForValue}
            />
          </Box>
          {(role !== '4' && role !== '5' && role !== '10') &&
          <NotificationIcon/>
          }
          <h5>{profile.name}</h5>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <Avatar alt="Remy Sharp" src={profile.url} />
          </IconButton>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem component={Link} to={"/settings"}>Settings</MenuItem>
            <MenuItem component={Link} to={"/logout"}>Logout</MenuItem>
            <Divider/>
            <MenuItem component={Link} to={"/updates"}>Updates<NotificationsIcon /></MenuItem>
            <p style={{marginLeft:"18px", color:'black'}}>Version: </p>
            <p style={{marginLeft:"18px", color:'grey'}}>2024-10</p>
          </Menu>

        </Toolbar>

      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon style={{color:'white'}} /> : <ChevronLeftIcon style={{color:'white'}} />}
          </IconButton >
        </DrawerHeader>
        <Divider />
        <List>
        {(role === '4' || role === '5' || role === '10') &&
          <ListItemButton
            key="overview"
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
            component={Link} to="/overviews"
            selected={setSideMenu(1, location)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <HouseIcon style={{color:'white'}} />
            </ListItemIcon>
            <ListItemText style={{color:'white'}} primary="My Overview" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
          }
          {(role !== '4' && role !== '5' && role !== '10') &&
          <ListItemButton
            key="overview"
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
            component={Link} to="/overview"
            selected={setSideMenu(1, location)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <HouseIcon style={{color:'white'}} />
            </ListItemIcon>
            <ListItemText style={{color:'white'}} primary="My Overview" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
          }
          <ListItemButton
            key="accounts"
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
            component={Link} to="/myaccounts"
            selected={setSideMenu(2, location)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <BusinessCenterIcon style={{color:'white'}} />
            </ListItemIcon>
            <ListItemText style={{color:'white'}} primary="My Accounts" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>

          <ListItemButton
            key="tools"
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
            component={Link} to="/tools"
            selected={setSideMenu(3, location)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <ManageSearch style={{color:'white'}} />
            </ListItemIcon >
            <ListItemText style={{color:'white'}} primary="Tools" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>

          <ListItemButton
            key="team"
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
            component={Link} to="/team"
            selected={setSideMenu(5, location)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <Diversity3Icon style={{color:'white'}}/>
            </ListItemIcon>
            <ListItemText style={{color:'white'}} primary="My Team" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>

        {value?.auth.role === '2' && 
          <ListItemButton
            key="adminview"
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
            component={Link} to="/admin"
            selected={setSideMenu(8, location)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
              }}
            >
              <AdminPanelSettingsIcon style={{color:'white'}} />
            </ListItemIcon>
            <ListItemText style={{color:'white'}} primary="Admin View" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
          }
        </List>
        <Divider />
      </Drawer>
    </div>
  );
}