import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {NumericFormat} from 'react-number-format';

export const MyAccountsCard = (_props : any) => {
  const data = _props.data;
  return (
    <Card sx={{ maxWidth: 345, marginTop:'0px' }}>
        <CardContent>
          <Typography gutterBottom variant="h4" component="div">
            Total ACV
          </Typography>
          <Typography variant="h6" sx={{ color: 'text.secondary' }}>
            <NumericFormat value={Math.round(data)} displayType={'text'} thousandSeparator={true}/>
          </Typography>
        </CardContent>
    </Card>
  );
}