import { Box, Grid} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { CaseOverviewTable } from '../../../entities/components/Tools/CaseOverview/CaseOverviewTable';
import { CaseOverviewAllCasesTable } from '../../../entities/components/Tools/CaseOverview/CaseOverviewAllCasesTable';
import { InfoTooltip } from '../../../shared/components/infoTooltip/InfoTooltip';

export const CaseOverviewPage = () => {
    const [isLoading,setIsLoading]  = useState(false);
    const [cases,setCases]  = useState({'am_cases':[],'support_cases':[]});
    const queryParameters = new URLSearchParams(window.location.search)
    const time = queryParameters.get("time")

    useEffect(() => {
        setIsLoading(true);
        fetch('/api/caseoverview/'+time).then(res => res.json()).then(data => {
            setCases(data);
            setIsLoading(false);
        });
    }, [time]);

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                 <h1>Case Overview </h1>
                            </Grid>
                            <Grid item xs={12}>
                                {isLoading &&
                                <CircularProgress />
                                }

                                {!isLoading && 
                                    <>
                                        <InfoTooltip message="Source: Salesforce | Refreshed: once a day" />
                                        <CaseOverviewTable title="Dedicated AMs" cases={cases.am_cases} />
                                        <br/><br/><br/><br/>
                                        <CaseOverviewTable title="Support AMs" cases={cases.support_cases} />
                                        <br/><br/><br/><br/>
                                        <CaseOverviewAllCasesTable ams={cases.am_cases} support={cases.support_cases}/>
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </div>
                </Box>
    );
}