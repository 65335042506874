import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Badge } from '@adjust/components/build/Badge/Badge';

const statusBadge = (text: string) =>{
  if(String(text) === "true"){
      return <Badge label="Active" color="positive" />
  }
  else{
      return <Badge label="Not Used" color="warning" />
  }
}
const levelBadge = (text: string) =>{
  if(String(text) === "advanced"){
      return <Badge label="Advanced" color="positive" />
  }
  if(String(text) === "standard"){
    return <Badge label="Standard" color="warning" />
  }
  else{
      return <Badge label="Not Used" color="neutral" />
  }
}

export const FraudTable = (_props : any) =>{
  const android = _props.android;
  const fraud = _props.fraud;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
                <TableCell>FPS Filter / Tool</TableCell>
                <TableCell align="right">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow key="ANONYMOUS">
              <TableCell component="th" scope="row">ANONYMOUS IP FILTERING</TableCell>
              <TableCell align="right" component="th" scope="row">{statusBadge(fraud[2])}</TableCell>
            </TableRow>
            {android !== "" &&
            <TableRow key="INJECTION">
              <TableCell component="th" scope="row">CLICK INJECTION FILTERING (ANDROID ONLY)</TableCell>
              <TableCell align="right" component="th" scope="row">{statusBadge(fraud[3])}</TableCell>
            </TableRow>
            }
            <TableRow key="DISTRIBUTION">
              <TableCell component="th" scope="row">DISTRIBUTION MODELING</TableCell>
              <TableCell align="right" component="th" scope="row">{statusBadge(fraud[1])}</TableCell>
            </TableRow>
            <TableRow key="LEVEL">
              <TableCell component="th" scope="row">LEVEL</TableCell>
              <TableCell align="right" component="th" scope="row">{levelBadge(fraud[0])}</TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}