import { Card, Divider, Grid } from "@mui/material";
import { AppTokenInput } from "./filterInputs/AppTokenInput";
import { TrackerTokenInput } from "./filterInputs/TrackerTokenInput";
import { UrlTrackerInput } from "./filterInputs/UrlTrackerInput";
import { EventTokenInput } from "./filterInputs/EventTokenInput";
import { RequestUrlInput } from "./filterInputs/RequestUrlInput";
import { SkadNetworkInput } from "./filterInputs/SkadNetworkInput";
import { LabelInput } from "./filterInputs/LabelInput";
import { SanPartnerInput } from "./filterInputs/SanPartnerInput";
import { MessageInput } from "./filterInputs/MessageInput";
import { DeviceIdInput } from "./filterInputs/DeviceIdInput";


export const MainFilters = (_props:any) =>{
    let service = _props.service;
    let tags = _props.tags;
    return(
        <Card variant="outlined">
            <Grid container spacing={6} >
                <Grid item xs={5} style={{marginLeft:'2%',marginRight:'-2%'}}>
                    <h2 style={{color: '#005ff7',marginTop:'0%'}}>Main Filters</h2>
                    <AppTokenInput onAppTokensChange={_props.onAppTokensChange}/>
                    <br/>
                    <TrackerTokenInput onTrackerTokensChange={_props.onTrackerTokensChange}/>
                    {(service === 'adjust_server' || ( tags.click || tags.impression )) &&
                    <div>
                        <br/>
                        <UrlTrackerInput onUrlTrackersChange={_props.onUrlTrackersChange}  />
                    </div>
                    }

                    {(tags.event === true && service !== 'attribution_worker') && 
                    <div>
                        <br/>
                        <EventTokenInput onEventTokensChange={_props.onEventTokensChange} />
                    </div>
                    }
                    
                    {((service === 'callback_worker') || (service === 'adjust_server' && ( tags.click || tags.impression ))) &&
                    <div>
                        <br/>
                        <RequestUrlInput onRequestUrlChange={_props.onRequestUrlChange}/>
                    </div>
                    }
                    {(tags.sk_install && service !== 'attribution_worker') && 
                    <div>
                        <br/><br/>
                        <SkadNetworkInput onSkadTokensChange={_props.onSkadTokensChange} skadTokenList={_props.skadTokenList} />
                    </div>
                    }
                </Grid>
                <Grid item xs={1}  justifyContent="center" alignItems="center" display="flex">
                    <Divider orientation="vertical"/>
                </Grid>
                <Grid item xs={6} style={{marginLeft:'-2%',marginRight:'-2%'}}>
                    <br/><br/>
                    <MessageInput onMessagesChange={_props.onMessagesChange} tags={_props.tags} />
                    <br/>
                    <DeviceIdInput onDeviceIdsChange={_props.onDeviceIdsChange} />
                    <br/>
                    {service === 'callback_worker' &&
                        <LabelInput onLabelsChange={_props.onLabelsChange} partners={_props.partners} />
                    }
                    
                    {service === 'attribution_worker' &&
                    <div>
                        <br/>
                        <SanPartnerInput onSanPartnersChange={_props.onSanPartnersChange} />
                    </div>
                    }

                </Grid>
            </Grid>
            <br/>
        </Card>
    );
}