import { Tabs } from '@adjust/components';
import { Tile } from '@adjust/components/build/Tile/Tile';
import { Box, Button, Grid} from '@mui/material';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { QueryPage } from '../query/Query';
import { ReportsPage } from '../reports/Reports';
import { AuthContext } from '../../app/store/AuthContext';

export const ToolsPage = () => {
    const [tabs, setTabs] = useState('1');
    const items = [
        { id: '1', label: 'Bagger Tools ' },
        { id: '2',label: 'APIs' },
        { id: '3',label: 'Reports' },
        { id: '4',label: 'Click Spam' },
        { id: '5',label: 'Data query' },
        { id: '6',label: 'Other Tools' },
        { id: '7',label: 'Sales Tools' },
    ]
    const value = useContext(AuthContext);
    const role = value?.auth.role;
    
    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
             {(role === '5' || role === '10') &&
                <div>
                    <h1>Tools</h1>
                    <br/>
                    <Grid container spacing={6}>
                        <Grid item xs={3}>
                            <Tile title="Upselling Dashboard">
                                <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/upsellingdashboard">Open</Button>
                            </Tile>
                        </Grid>
                        <Grid item xs={3}>
                            <Tile title="Regional Account Overview">
                                <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/salesoverviewregion">Open</Button>
                            </Tile>
                        </Grid>
                        <Grid item xs={3}>
                            <Tile title="Regional Account Anomalies">
                                <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/overviewregion">Open</Button>
                            </Tile>
                        </Grid>
                        <Grid item xs={3}>
                            <Tile title="My Accounts Usage">
                                <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/myaccountsusage">Open</Button>
                            </Tile>
                        </Grid>
                    </Grid>
                </div>
            }
            {(role !== "5" && role !== "10") &&
                    <div>
                        <h1>Tools</h1>
                        <Tabs items={items} onChange={setTabs} value={tabs} />
                        <br/>
                        <Grid container spacing={6}>
                            {tabs === '2' && 
                            <>
                                <Grid item xs={3}>
                                    <Tile title="Inspect device">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/inspectdevice">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="Inspect Mappings (shows all the id & app_token pairs)">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/inspectmappings">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3} >
                                    <Tile title="Apptopia Data Refresher">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/apptopia">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3} style={{display:'none'}}>
                                    <Tile title="Tracker API">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/trackerapi">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="Ad Revenue Refetch Api">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/adrevenuerefetch">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="Credentials Service API">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/credentialsserviceapi">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="Meta/FB AEM/SKAN Readiness API">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/metaapi">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="Ad Network Api">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/adnetworkapi">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="Meta Partner API">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/metapartnerapi">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="Check info about trackers">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/trackerinfo">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="CV Mapping">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/cvmapping">Open</Button>
                                    </Tile>
                                </Grid>
                            </>
                            }

                            {tabs === '4' && 
                            <>
                                <Grid item xs={3}>
                                    <Tile title="Click Spam">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/clickspam">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="Click Spam Network">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/clickspamnetwork" >Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="Set Tracker">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/clickspamallowlist" >Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="Click Spam History">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/clickspamhistory" >Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="Partner Domain Blocklist">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/partnerdomainblocklist" >Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="Partner Host Blocklist">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/partnerhostblocklist" >Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="Click Spam Checker">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/clickspamchecker" >Open</Button>
                                    </Tile>
                                </Grid>
                            </>
                            }

                            {tabs === '1' &&
                            <>
                                <Grid item xs={3}>
                                    <Tile title="Bagger API Overview">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/baggeroverview">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="Bagger Query Generator">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/bqg">Open</Button>
                                    </Tile>
                                </Grid>

                                <Grid item xs={3}>
                                    <Tile title="SAN Analysis">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/sananalysis">Open</Button>
                                    </Tile>
                                </Grid>
                            </>
                            }

                            {tabs === '5' &&
                            <>
                                <QueryPage />
                            </>
                            }

                            {tabs === '6' &&
                            <>
                                <Grid item xs={3}>
                                    <Tile title="App Icon Finder">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/logofinder">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="Ios App Verification">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/iosappverification">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="Verification Tool">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} href="https://dash.adjust.com/control-center/verification-tool/" target="_blank">Open</Button>
                                    </Tile>
                                </Grid>
                            </>
                            }

                            {tabs === '3' &&
                            <>
                                <ReportsPage />
                            </>
                            }

                            {tabs === '7' &&
                            <>
                                <Grid item xs={3} style={{display:'none'}}>
                                    <Tile title="Check engagement details via reftag">
                                        <Button variant="contained" component={Link} to="/tools/engagementdetails">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3} style={{display:'none'}}>
                                    <Tile title="Check device across all apps">
                                        <Button variant="contained" component={Link} to="/tools/checkdevice">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3} style={{display:'none'}}>
                                    <Tile title="Check if FB T&C are signed for the app">
                                        <Button variant="contained" component={Link} to="/tools/fbsigned">Open</Button>
                                    </Tile>
                                </Grid>
                                
                                <Grid item xs={3} style={{display:'none'}}>
                                    <Tile title="Forget Device">
                                        <Button variant="contained" component={Link} to="/tools/forgetdevice">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3} style={{display:'none'}}>
                                    <Tile title="Opt-out of tracking">
                                        <Button variant="contained" component={Link} to="/tools/optouttracking">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3} style={{display:'none'}}>
                                    <Tile title="GDPR Forget Me">
                                        <Button variant="contained" component={Link} to="/tools/gdprforgetme">Open</Button>
                                    </Tile>
                                </Grid>
                            </>
                            }

                            {tabs === '7' &&
                            <>
                                    <Grid item xs={3}>
                                        <Tile title="Regional Account Overview">
                                            <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/salesoverviewregion">Open</Button>
                                        </Tile>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Tile title="Regional Account Anomalies">
                                            <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/overviewregion">Open</Button>
                                        </Tile>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Tile title="My Accounts Usage">
                                            <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/myaccountsusage">Open</Button>
                                        </Tile>
                                    </Grid>
                            </>
                            }

                        </Grid>
                    </div>
                    }
                </Box>
    );
}