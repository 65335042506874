import * as React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

function createData(
  id: number,
  date: string,
  trackerName: string,
  trackerToken: number,
  clicks: number,
  attributions: number,
  conversionRate: string,
  allowed: string
) {
  return { id, date, trackerName, trackerToken, clicks, attributions, conversionRate, allowed };
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const columns: GridColDef[] = [
  { field: 'date', headerName: 'Date',flex: 1, minWidth: 100},
  { field: 'trackerName', headerName: 'Tracker Name',flex: 1, minWidth: 400},
  { field: 'trackerToken', headerName: 'Tracker Token',flex: 1, minWidth: 100},
  { field: 'clicks', headerName: 'Clicks',flex: 1, minWidth: 100},
  { field: 'attributions', headerName: 'Attributions',flex: 1, minWidth: 100},
  { field: 'conversionRate', headerName: 'Conversion Rate',flex: 1, minWidth: 100},
  { field: 'allowed', headerName: 'Is Allowed',flex: 1, minWidth: 100},
]

export const ClickSpamTable = (_props : any) =>{
  const clickSpam = _props.clickSpam;
  const rows : any = [];
  for (var i = 0; i < clickSpam.length; i++){
    rows.push(
      createData(
        i,
        formatDate(clickSpam[i][0]),
        clickSpam[i][1],
        clickSpam[i][2],
        clickSpam[i][3],
        clickSpam[i][4],
        clickSpam[i][5],
        clickSpam[i][6],
      )
      );
    }

  return (
    <div style={{ height: 425, width: '100%' }}>
      <DataGrid 
        rows={rows} 
        columns={columns}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}