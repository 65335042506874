import React from 'react';
import { Chart } from "react-google-charts";

export const PieChart = (_props : any) =>{
    const data = _props.data[0];
    const options = {
        title: _props.data[1],
        'legend':'bottom',
      };
    return (
        <div>
            <Chart
                chartType="PieChart"
                data={data}
                options={options}
                width={"800px"}
                height={"300px"}
            />
        </div>
    );
}