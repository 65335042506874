import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AmCaseTable } from "./AmCaseTable";

export const AmCaseViewPage = () => {
    const [data,setData] = useState([]);
    const [status,setStatus] = useState(false);
    let params = useParams();
    let mail  = "";
    if (params.amMail !== undefined){
        mail = String(params.amMail);
    }
    else{
        mail = 'me';
    }

    useEffect(() => {
        if(mail !== ""){
            setStatus(true);
            fetch('/api/amcaseview/'+mail).then(res => res.json()).then(data => {
                setData(data);
                setStatus(false);
            });
        }
    }, [mail]);

    return(
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <div>
                    <h1 style={{marginTop:'0px'}}>Case Overview for {mail}</h1>
                    {status &&
                    <CircularProgress />
                    }
                    {!status &&
                        <AmCaseTable data={data} />
                    }
                    
                </div>
            </Box>
    )
}