import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

function createData(
    id: number,
    username: string,
    appId: string,
    message: string,
    createdAt: string,
    status: string
  ) {
    return { id, username, appId, message, createdAt, status };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export const MetaApiCallsTable = (_props : any) =>{
    const rows : any = [];

    for (var i = 0; i < _props.data.length; i++){
        rows.push(
            createData(
                i,
                _props.data[i][1],
                _props.data[i][2],
                _props.data[i][3],
                formatDate(_props.data[i][4]),
                _props.data[i][5],
            )
        );
    }
    
      const columns: GridColDef[] = [
        { field: 'id', headerName: 'Id',flex: 1, minWidth: 50},
        { field: 'username', headerName: 'Added By',flex: 1, minWidth: 150},
        { field: 'appId', headerName: 'App Id',flex: 1, minWidth: 150},
        { field: 'message', headerName: 'Message',flex: 1, minWidth: 150},
        { field: 'createdAt', headerName: 'Created At',flex: 1, minWidth: 150},
        { field: 'status', headerName: 'Status',flex: 1, minWidth: 150},
    ];

    
    return (
        <div style={{ height: 700, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}