import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

function createData(
    id: number,
    accountId: number,
    appToken: string,
    source: string,
    startDate: string,
    endDate: string,
    name: string,
    answer: string,
  ) {
    return { id, accountId, appToken, source, startDate, endDate, name, answer};
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export const AdRevenueTable = (_props : any) =>{
    let adRevenue = _props.adRevenue;
    const adRevenueData : any = [];
    if(adRevenue !== undefined){
        for (var i = 0; i < adRevenue.length; i++){
            adRevenueData.push(
                createData(
                    i,
                    adRevenue[i][0],
                    adRevenue[i][1],
                    adRevenue[i][2],
                    formatDate(adRevenue[i][3]),
                    formatDate(adRevenue[i][4]),
                    adRevenue[i][5],
                    adRevenue[i][6],
                )
            );
        }
    }

    const columns: GridColDef[] = [
        { field: 'accountId', headerName: 'Account Id',flex: 1, minWidth: 150},
        { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 150},
        { field: 'source', headerName: 'Source',flex: 1, minWidth: 150},
        { field: 'startDate', headerName: 'Start Date',flex: 1, minWidth: 150},
        { field: 'endDate', headerName: 'End Date',flex: 1, minWidth: 150},
        { field: 'name', headerName: 'Name',flex: 1, minWidth: 150},
        { field: 'answer', headerName: 'Answer',flex: 1, minWidth: 150}
      ];
      
    return (
        <div style={{ height: 650, width: '100%' }}>
            <h2>Last Refetches</h2>
            <DataGrid 
                rows={adRevenueData} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}