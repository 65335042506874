import { Table, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import {NumericFormat} from 'react-number-format';

export const  UsageTable = (props: any) => {
    let sfUsage = props.sfUsage[0]
    
    return (
        <TableContainer component={Paper}>
            <h3 style={{marginLeft:'2%'}}>Package Limits</h3>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Limit</TableCell>
                    <TableCell>Used</TableCell>
                    <TableCell>Used in %</TableCell>
                </TableRow>
                </TableHead>

                <TableBody>
                    <TableRow   key={"attributions"} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                            Attributions
                        </TableCell>
                        <TableCell><NumericFormat value={0} displayType={'text'} thousandSeparator={true}/></TableCell>
                        <TableCell><NumericFormat value={sfUsage[1]}  displayType={'text'} thousandSeparator={true}/></TableCell>
                        <TableCell><NumericFormat value={0}  displayType={'text'} thousandSeparator={true}/></TableCell>
                    </TableRow>

                    <TableRow   key={"conversions"} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                            Conversions
                        </TableCell>
                        <TableCell><NumericFormat value={0} displayType={'text'} thousandSeparator={true}/></TableCell>
                        <TableCell><NumericFormat value={sfUsage[0]}  displayType={'text'} thousandSeparator={true}/></TableCell>
                        <TableCell><NumericFormat value={0}  displayType={'text'} thousandSeparator={true}/></TableCell>
                    </TableRow>

                    <TableRow   key={"maus"} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                            Maus
                        </TableCell>
                        <TableCell><NumericFormat value={0} displayType={'text'} thousandSeparator={true}/></TableCell>
                        <TableCell><NumericFormat value={sfUsage[4]} displayType={'text'} thousandSeparator={true}/></TableCell>
                        <TableCell><NumericFormat value={0} displayType={'text'} thousandSeparator={true}/></TableCell>
                    </TableRow>

                    <TableRow   key={"datapoints"} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell component="th" scope="row">
                            Data Points
                        </TableCell>
                        <TableCell><NumericFormat value={0} displayType={'text'} thousandSeparator={true}/></TableCell>
                        <TableCell><NumericFormat value={sfUsage[3]} displayType={'text'} thousandSeparator={true}/></TableCell>
                        <TableCell><NumericFormat value={0} displayType={'text'} thousandSeparator={true}/></TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        </TableContainer>
    );
}