import { Box, Button, Dialog } from "@mui/material";
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { useState } from "react";
import { formatDate } from "../../../shared/components/formatDate/FormatDate";

function createData(
    id: number,
    type: string,
    insertDate: string,

  ) {
    return { id, type, insertDate };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

const columns: GridColDef[] = [
    { field: 'type', headerName: 'Type',flex: 1, minWidth: 250},
    { field: 'insertDate', headerName: 'Insert Date',flex: 1, minWidth: 100},
    
]

export const UpdateModalSalesRegion = (_props : any) =>{
    let notifications = _props.notifications;
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const rows : any = [];
    if(notifications !== undefined){
        for (var i = 0; i < notifications.length; i++){
            rows.push(
                createData(
                    i,
                    notifications[i]["type"],
                    formatDate(notifications[i]["insertDate"]),
                )
            );
        }
    }

    return <>
            {(notifications.length > 0) && 
            <>
                <Button variant="outlined" onClick={handleOpen} style={{width:'100%'}}>
                    Show Notification
                </Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth="xl"
                    fullWidth
                >
                    <Box >
                    <div style={{ height: 700, width: '100%' }}>
                        <DataGrid 
                            rows={rows} 
                            columns={columns}
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                        />
                    </div>
                    </Box>
                </Dialog>
            </>
            }
        </>
}