import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';


const columns: GridColDef[] = [
    { field: 'accountId', headerName: 'Account ID',flex: 1},
    { field: 'accountName', headerName: 'Account Name',flex: 1},
];

function createData(
    id: number,
    accountId: string,
    accountName: string,

  ) {
    return { id, accountId, accountName};
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export const PartnerAccountsTable = (_props: any) => {
    const rows : any = [];
    if(_props.accounts !== undefined){
        for (var i = 0; i < _props.accounts.length; i++){
            rows.push(
                createData(
                    i,
                    _props.accounts[i][0],
                    _props.accounts[i][1]
                )
            );
        }
    }
    return (
        <div style={{ height: 500, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>

    );
}