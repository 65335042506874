import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { FeaturamaTable } from "./FeaturamaTable";
import { InfoTooltip } from "../../../shared/components/infoTooltip/InfoTooltip";


export const FeaturamaPage = () => {
    const [data,setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true)
        fetch('/api/featurama').then(res => res.json()).then(data => {
            setData(data);
            setIsLoading(false);
        });
    }, []);

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                 <h1>Featurama</h1>
                            </Grid>
                            {isLoading &&
                            <CircularProgress />
                            }
                            {!isLoading &&
                            <>
                                <InfoTooltip message="Source: Adjust DB | Refreshed: instant" />
                                <FeaturamaTable data={data} />
                            </>
                            }
                        </Grid>
                    </div>
                </Box>
    );
}