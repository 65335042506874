import { Button } from "@mui/material"
import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable'
import { formatVersion, formatVersionShort } from "../../../shared/components/formatVersion/FormatVersion";
import { formatDate } from "../../../shared/components/formatDate/FormatDate";
import { AdRevenueCheckAndroid, AdvertisingIdCheckAndroid, CallbackAndPartnerCheckAndroid, CoppaCheckAndroid, DeeplinkCheckAndroid, EventBufferingCheckAndroid, GoogleReferralApiCheckAndroid, InstallSessionCheckAndroid, KidsCheckAndroid, PushTokenCheckAndroid, SdkEventNonRevenueCheckAndroid, SdkEventRevenueCheckAndroid, SdkSignatureCheckAndroid, ThirdPartyCheckAndroid } from "./StatusCheckAndroid";

const getId = (ids : any) => {
    if(ids !== undefined){
        if(ids.length === 0 || ids === null){
            return '';
        }
        if(ids[0] !== ''){
            return ids[0];
        }
        if(ids[1] !== ''){
            return ids[1];
        }
    }
    return '';
}

const setStatusToText = (status: number) => {
    if(status === 0 || status === 3){
        return 'Data not Availble ';
    }
    return 'Data Availble';
}

export const PdfCreatorButtonAndroid = (_props : any) => {
    let clientName = _props.clientName;
    let appName = _props.appName;
    let appToken = _props.appToken;
    let lastRunTime = _props.lastRunTime;
    let os = _props.os;
    let data = _props.data;
    const newestAppVersionAndroid = _props.newestAppVersionAndroid;
    const AdRevenueCheck = AdRevenueCheckAndroid(data.lastRunTime,data.adRevenue);
    const AdvertisingIdCheck = AdvertisingIdCheckAndroid(data.lastRunTime,data.advertisingIdAndroid,newestAppVersionAndroid);
    const CallbackAndPartnerCheck = CallbackAndPartnerCheckAndroid(data.lastRunTime,data.callbackPartnerParams,newestAppVersionAndroid);
    const CoppaCheck = CoppaCheckAndroid(data.lastRunTime,data.coppa,newestAppVersionAndroid);
    const DeeplinkCheck = DeeplinkCheckAndroid(data.lastRunTime,data.deepLink,newestAppVersionAndroid);
    const EventBufferingCheck = EventBufferingCheckAndroid(data.lastRunTime,data.eventBuffering,newestAppVersionAndroid);
    const GoogleReferralApiCheck = GoogleReferralApiCheckAndroid(data.lastRunTime,data.googleReferralApi,newestAppVersionAndroid);
    const InstallSessionCheck = InstallSessionCheckAndroid(data.lastRunTime,data.installTracking,newestAppVersionAndroid);
    const KidsCheck = KidsCheckAndroid(data.lastRunTime,data.playStoreKids,newestAppVersionAndroid);
    const PushTokenCheck = PushTokenCheckAndroid(data.lastRunTime,data.pushTokens,newestAppVersionAndroid);
    const SdkEventNonRevenueCheck = SdkEventNonRevenueCheckAndroid(data.lastRunTime,data.sdkEventTrackingNonRevenue,newestAppVersionAndroid);
    const SdkEventRevenueCheck = SdkEventRevenueCheckAndroid(data.lastRunTime,data.sdkEventTrackingRevenue,newestAppVersionAndroid);
    const SdkSignatureCheck = SdkSignatureCheckAndroid(data.lastRunTime,data.sdkSignature,newestAppVersionAndroid);
    const ThirdPartyCheck = ThirdPartyCheckAndroid(data.lastRunTime,data.thirdPartySharing,newestAppVersionAndroid);
    let logoId = getId(_props.logo);
    let logo = '';
    if(logoId !== ''){
        logo = "https://www.apptrace.com/api/app/"+logoId+"/artwork_url_small";
    }
    const downloadPdfDocument = () => {
        const pdf = new jsPDF();
        pdf.text("Client: " + clientName, 10, 10);
        pdf.text("App Name: " + appName, 10, 20);
        pdf.text("App Token: " + appToken, 10, 30);
        pdf.text("Last running time: " + formatDate(lastRunTime), 10, 40);
        pdf.text("OS: " + os, 10, 50);
        var img = new Image();
        img.src = logo;
        //pdf.addImage(img, "PNG", 150, 10, 32, 32);
        autoTable(pdf, {
            head: [["SDK Component","Last Time Checked","Latest Available App Version","Status"]],
            body: [
                ['Install/Session tracking',formatDate(lastRunTime),formatVersion(data.installTracking),setStatusToText(InstallSessionCheck)],
                ['Advertising ID collection/tracking',formatDate(lastRunTime),formatVersion(data.advertisingIdAndroid),setStatusToText(AdvertisingIdCheck)],
                ['Push tokens',formatDate(lastRunTime),formatVersion(data.pushTokens),setStatusToText(PushTokenCheck)],
                ['SDK signature v2, v3',formatDate(lastRunTime),formatVersion(data.sdkSignature),setStatusToText(SdkSignatureCheck)],
                ['SDK Event tracking (non revenue)',formatDate(lastRunTime),formatVersion(data.sdkEventTrackingNonRevenue),setStatusToText(SdkEventNonRevenueCheck)],
                ['SDK Event tracking (revenue)',formatDate(lastRunTime),formatVersion(data.sdkEventTrackingRevenue),setStatusToText(SdkEventRevenueCheck)],
                ['Event buffering',formatDate(lastRunTime),formatVersion(data.eventBuffering),setStatusToText(EventBufferingCheck)],
                ['Callback and partner Params',formatDate(lastRunTime),formatVersion(data.callbackPartnerParams),setStatusToText(CallbackAndPartnerCheck)],
                ['Google Referral API',formatDate(lastRunTime),formatVersion(data.googleReferralApi),setStatusToText(GoogleReferralApiCheck)],
                ['Ad revenue',formatDate(lastRunTime),formatVersion(data.adRevenue),setStatusToText(AdRevenueCheck)],
                ['COPPA Compliance',formatDate(lastRunTime),formatVersionShort(data.coppa),setStatusToText(CoppaCheck)],
                ['3rd Party data sharing',formatDate(lastRunTime),formatVersion(data.thirdPartySharing),setStatusToText(ThirdPartyCheck)],
                ['Play Store Kids',formatDate(lastRunTime),formatVersion(data.playStoreKids),setStatusToText(KidsCheck)],
                ['Deeplink',formatDate(lastRunTime),formatVersion(data.deepLink),setStatusToText(DeeplinkCheck)],
            ],
            startY: 60
        })

        pdf.save(appName + "_" + os + "_" + lastRunTime + ".pdf");
    }

    return(
        <Button variant='outlined' onClick={downloadPdfDocument}>Download PDF </Button>
    )
}