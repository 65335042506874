import { Breadcrumbs } from "@adjust/components";
import { Box, CircularProgress} from "@mui/material";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { AccountSegmentedButton } from "../../../shared/components/accountSegmentedButton/AccountSegmentedButton";
import { Account, AccountNetworksData } from "../../../shared/components/Types/Types";
import { getData } from "../../../shared/components/Hooks/AccountHooks";
import { useQuery } from "react-query";
import { AccountNetworksTable } from "../../../entities/components/Accounts/AccountNetworks/AccountNetworksTable";
import { InfoTooltip } from "../../../shared/components/infoTooltip/InfoTooltip";

const MyBreadcrumbs = (accountId:string,accountName: string, navigate:NavigateFunction) => {
    
    const items = [
      { label: 'My Accounts', onClick: () => {navigate('/myaccounts')}},
      { label: accountName, onClick: () => {navigate('/account/' + accountId)}},
      { label: 'Networks', onClick: () => {navigate('/account/' + accountId + '/networks')}},
    ];
    return <Breadcrumbs items={items} />;
  }
  
export const AccountNetworks = (_props:Account) => {
    let navigate = useNavigate();
    let params = useParams();
    let accountId  = "0";
    if (params.accountId !== undefined){
        accountId = String(params.accountId);
    }

    const { status, data } = useQuery<AccountNetworksData[], Error>(
        ["networksPage", { accountId }], () => getData(accountId,'accountnetworks'));


    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    {MyBreadcrumbs(accountId,_props.accountName,navigate)}
                    <AccountSegmentedButton accountId = {accountId} tabValue='13' accountManager={_props.accountManager} competitor={_props.competitor} />
                    <br/>
                    {status === "loading" &&
                        <CircularProgress />
                    }
                    {status !== "loading" &&
                    <div>
                        <InfoTooltip message="Source: Adjust DB | Refreshed: instant" />
                        <AccountNetworksTable networks={data} />
                    </div>
                    }
                </Box>
    );
}
