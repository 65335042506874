import { Box, Button, Dialog, Divider, Grid, Typography } from "@mui/material";
import { useState } from "react";

export const AccountCoverageModal = (_props : any) =>{
    let issueDescription = _props.issueDescription;
    let whatWasDone = _props.whatWasDone;
    let appEventTrackerToken = _props.appEventTrackerToken;
    let slackDiscussionsJiraTicketsOpened = _props.slackDiscussionsJiraTicketsOpened;
    let suggestedNextSteps = _props.suggestedNextSteps;
    let similarTopicsFoundInSlackJira = _props.similarTopicsFoundInSlackJira;
    let logsPathAndRequestExamples = _props.logsPathAndRequestExamples;
    let linksToOtherTools = _props.linksToOtherTools;
    let dashboardLink = _props.dashboardLink;
    let anyOooNotes = _props.anyOooNotes;
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return <>
            {(anyOooNotes) && 
            <>
                <Button variant="outlined" onClick={handleOpen} style={{width:'100%'}}>
                    Coverage Notes
                </Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth="xl"
                    fullWidth
                >
                    <Box >
                        <Typography id="dialog-description">
                            <Grid container xs={12} >
                                <Grid item xs={1} />
                                <Grid item xs={4}>
                                    <h3 style={{textDecorationLine: "underline"}}>Issue Description</h3>
                                    <h5>{issueDescription}</h5>
                                    <h3 style={{textDecorationLine: "underline"}}>Suggested next steps?</h3>
                                    <h5>{suggestedNextSteps}</h5>
                                    <h3 style={{textDecorationLine: "underline"}}>App/Event/Tracker token</h3>
                                    <h5>{appEventTrackerToken}</h5>
                                    <h3 style={{textDecorationLine: "underline"}}>Similar topics found in Slack/Jira</h3>
                                    <h5>{similarTopicsFoundInSlackJira}</h5>
                                    <h3 style={{textDecorationLine: "underline"}}>Links to other tools</h3>
                                    <h5>{linksToOtherTools}</h5>
                                </Grid>
                                <Grid item xs={1} ><Divider orientation="vertical"/></Grid>
                                <Grid item xs={1} ></Grid>
                                <Grid item xs={4}>
                                    <h3 style={{textDecorationLine: "underline"}}>What was done?</h3>
                                    <h5>{whatWasDone}</h5>
                                    <h3 style={{textDecorationLine: "underline"}}>Slack discussions / Jira tickets opened</h3>
                                    <h5>{slackDiscussionsJiraTicketsOpened}</h5>
                                    <h3 style={{textDecorationLine: "underline"}}>Dashboard link</h3>
                                    <h5>{dashboardLink}</h5>
                                    <h3 style={{textDecorationLine: "underline"}}>Logs path</h3>
                                    <h5>{logsPathAndRequestExamples}</h5>
                                </Grid>
                            </Grid>
                        </Typography>
                    </Box>
                </Dialog>
            </>
            }
        </>
}