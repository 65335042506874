import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

function createData(
    id: number,
    accountId: number,
    accountName: string,
    appName: string,
    appToken: string,
    subdomain: string,
    region: string,
    gracePeriod: string,
    classification: string,
    smRegion: string,
    owner: string,
    am: string,
  ) {
    return { id,accountId,accountName,appName,appToken,subdomain,region,gracePeriod,classification,smRegion,owner,am};
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const columns: GridColDef[] = [
    { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 300},
    { field: 'accountId', headerName: 'Account ID',flex: 1, minWidth: 100,
    renderCell: (params : any) => {
      return <Button variant="contained" component={Link} to={"/account/"+params.row.accountId} >{params.row.accountId}</Button>;
        },
    },
    { field: 'appName', headerName: 'App Name',flex: 1, minWidth: 200},
    { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 150,
    renderCell: (params : any) => {
      return <Button variant="contained" component={Link} to={"/account/app"+params.row.appToken} >{params.row.appToken}</Button>;
        },
    },
    { field: 'subdomain', headerName: 'Subdomain',flex: 1, minWidth: 200},
    { field: 'region', headerName: 'Region',flex: 1, minWidth: 150},
    { field: 'gracePeriod', headerName: 'Grace Period',flex: 1, minWidth: 150},
    { field: 'classification', headerName: 'Client Classification',flex: 1, minWidth: 150},
    { field: 'am', headerName: 'Aaccount Manager',flex: 1, minWidth: 150},
    { field: 'owner', headerName: 'Account Owner',flex: 1, minWidth: 150},
    { field: 'smRegion', headerName: 'SM Region',flex: 1, minWidth: 150},
  ];


export default function DataResidencyTable(_props : any) {
    var apps = _props.apps
    const rows : any = [];
    for (var i = 0; i < apps.length; i++){
        rows.push(
            createData(
                i,
                apps[i][0],
                apps[i][1],
                apps[i][2],
                apps[i][3],
                apps[i][4],
                apps[i][5],
                apps[i][6],
                apps[i][7],
                apps[i][8],
                apps[i][9],
                apps[i][10]
            )
        );
    }
    return (
        <div style={{ height: 650, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}