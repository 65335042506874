import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

function createData(
    id: number,
    name: string,
    department: string,
    title: string,
    manager: string,
  ) {
    return { id, name, department, title, manager};
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export const MateUsersTable = (_props : any) =>{
    let role = _props.role;
    const [users, setUsers] = useState([]);
    useEffect(() => {
        if(role === '2'){
            fetch('/api/allmateuser').then(res => res.json()).then(data => {
                setUsers(data);
            });
        }
    }, [role]);
    const rows : any = [];
    for (var i = 0; i < users.length; i++){
        rows.push(
            createData(
                i,
                users[i][0],
                users[i][1],
                users[i][2],
                users[i][3]
            )
        );
    }
    const columns: GridColDef[] = [
        { field: 'name', headerName: 'Name',flex: 1},
        { field: 'department', headerName: 'Department',flex: 1},
        { field: 'title', headerName: 'Title',flex: 1},
        { field: 'manager', headerName: 'Manager',flex: 1},
        
      ];
    return (
        <div style={{ height: 600, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}