import { Breadcrumbs } from '@adjust/components/build/Breadcrumbs/Breadcrumbs';
import { Tile } from '@adjust/components/build/Tile/Tile';
import { Box, Button, CircularProgress, Divider, Grid, List, ListItem, Stack, TextField} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import EmailIcon from '@mui/icons-material/Email';
import MessageIcon from '@mui/icons-material/Message';
import { Badge } from '@adjust/components/build/Badge/Badge';
import { AccountSegmentedButton } from '../../../shared/components/accountSegmentedButton/AccountSegmentedButton';
import { Account, AccountData } from '../../../shared/components/Types/Types';
import { useQuery } from "react-query";
import { getData } from '../../../shared/components/Hooks/AccountHooks';
import { InfoTooltip } from '../../../shared/components/infoTooltip/InfoTooltip';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { AccountInvoices } from '../../../entities/components/Accounts/AccountOverview/AccountInvoices';
import { AccountOpportunities } from '../../../entities/components/Accounts/AccountOverview/AccountOpportunities';
import { AccountHandoffs } from '../../../entities/components/Accounts/AccountOverview/AccountHandoff';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';
import SpeedIcon from '@mui/icons-material/Speed';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';

const infoTextAccountInfo = "Source: Salesforce | Refreshed: once a day";
const infoTextContacts = "Source: Salesforce | Refreshed: once a day";
const infoTextMainContacts = "Source: Salesforce | Refreshed: once a day";

function HomeIcon(props: SvgIconProps) {
    return (
      <SvgIcon {...props}>
        <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
      </SvgIcon>
    );
  }

function createBadge(number: any){
    if(number === 3){
      return <Badge label="Paused" color="neutral" />
    }
    else if (number === 4){
      return <Badge label="Suspended" color="warning" />
    }
    else if (number === 5){
      return <Badge label="Terminated" color="negative" />
    }
    else{
      return <Badge label="Active" color="positive" />
    }
  }
  
function MyBreadcrumbs(accountId:string,accountName:string,navigate:NavigateFunction) {
    const items = [
      { label: 'My Accounts', onClick: () => {navigate('/myaccounts') }},
      { label: accountName, onClick: () => {navigate('/account/' + accountId )}}
    ];
    return <Breadcrumbs items={items} />;
}

function checkBilling(billing : string){
    if(billing){
        return 'New Billing';
    }
    return 'Old Billing';
}

function formatContact(data : any, role: string){
    if(data[0] === ""){
        return "";
    }
    const mail = "mailto:"+data[2];
    const slack = "https://slack.com/app_redirect?channel="+data[1];
    return <>
    <Grid id={role} container>
        <Grid item xs={3}>
        {data[0]}
        </Grid>
        <Grid item xs={3}>
        {role}
        </Grid>
        <Grid item xs={3}>
            {data[1] !== '' &&
            <Button id={data[1]} href={slack} target="_blank"><MessageIcon /> Slack</Button>
            }
        </Grid>
        <Grid item xs={1}>
            {data[2] !== '' &&
            <Button id={data[2]} href={mail} target="_top"><EmailIcon />Mail</Button>
            }
        </Grid>
    </Grid>
    </>
}

function createMainContacts(mainContact : any){
    var rows = [];
    for (var i = 0; i < mainContact.length; i++) {
        const mail = "mailto:"+mainContact[i][0];
        rows.push(<div>
                <ListItem>
                    <Grid container direction="row" alignItems="center" spacing={0}>
                        <Grid item xs={4}>
                            {mainContact[i][2]} 
                        </Grid>
                        <Grid item xs={4}>
                            {mainContact[i][1]} 
                        </Grid>
                        <Grid item xs={4}>
                            <Button id={mainContact[i][0]} href={mail} target="_top"><EmailIcon />Mail</Button>
                        </Grid>
                    </Grid>
                    <br/>
                </ListItem>
                <Divider light />
            </div>)
    }
    return <List  component="nav">
        {rows}
    </List>
}

const isValidUrl = (urlString : string) => {
    let url;
    try {
        url = new URL(urlString);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}

const formatLink = (docs : string) => {
    if (isValidUrl(docs)){
        return <Button variant="contained" href={docs} target="_blank">Show Documnetation</Button>
    }
    return docs;
}

function setRedirectButton(accountId : any){
    return <form action="https://api.adjust.com/accounts/accounts/switch.json" target="_blank" accept-charset="UTF-8" method="post">
    <input name="utf8" type="hidden" value="✓"/>
    <input type="hidden" name="authenticity_token" value="Voa+WSemHcT/nTfrgVofsDQ76DiflWnRQS8h7HmlOTl/DjaU4vt2Ky5b/beHORUMo0udGFLNQx4ibYqxLlWaXg=="/>
    <input type="hidden" name="account_id" id="account_id" value={accountId} />
    <Button type="submit" name="commit" value="switch account">
        <HomeIcon color="primary" />
        To Dashboard
    </Button>
  </form>
}
const controlTowerButton = (accountId: number) =>{
    return <Button href={"https://control.adjust.com/accounts/"+accountId} target="_blank"><SpeedIcon color="primary" />To Control Tower</Button>
}

const salesForceButton = (accountId: number) =>{
    return <Button href={"https://adjust.lightning.force.com/lightning/r/Account/"+accountId+"/view"} target="_blank"><CrisisAlertIcon color="primary" />To Salesforce</Button>
}

export const AccountPage = (_props:Account) => {
    let navigate = useNavigate();
    let params = useParams();
    let accountId  = "0";
    if (params.accountId !== undefined){
        accountId = String(params.accountId);
    }
    const { status, data } = useQuery<AccountData, Error>(
        ["accountPage", { accountId }], () => getData(accountId,'account'));

    const [description, setDescription] = useState('');
    useEffect(() => {
        if(data?.description !== undefined){
            setDescription(data?.description);
        }
    },[data]);
    
    const handleDescriptionChange = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "accountId": data?.accountInfo[0], "description": description })
        };
        fetch('/api/setaccountdescription', requestOptions)
            .then(response => response.json());
    };

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    {MyBreadcrumbs(accountId,_props.accountName,navigate)}
                    <AccountSegmentedButton accountId = {accountId} tabValue='1' accountManager={_props.accountManager} competitor={_props.competitor} />
                    <br/>
                    {status === "loading" &&
                            <CircularProgress />
                    }
                    {status !== "loading" &&
                    <div>
                        <Grid container spacing={6}>
                            <Grid item xs={6}>
                                <Tile title="">
                                    <InfoTooltip message={infoTextAccountInfo} />
                                    <h2 style={{marginTop:'0%', marginBottom: '0%'}}> {data?.accountInfo[1]} ({data?.accountInfo[0]})</h2>
                                    <Stack direction="row" spacing={2}>
                                        {setRedirectButton(data?.accountInfo[0])}
                                        {controlTowerButton(data?.accountInfo[0])}
                                        {salesForceButton(data?.accountInfo[17])}
                                    </Stack>
                                    <div>{createBadge(data?.state)}</div>
                                    <Stack direction="row" spacing={6} style={{marginTop: '10px'}}>
                                        <div style={{fontWeight:'bold',marginRight:'4px',width:'30%'}}>Classifier: </div> 
                                        <div>{data?.accountInfo[4]}</div>
                                    </Stack>
                                    <Divider light />
                                    <Stack direction="row" spacing={6} style={{marginTop: '10px'}}>
                                        <div style={{fontWeight:'bold',marginRight:'4px',width:'30%'}}>Country: </div> 
                                        <div>{data?.accountInfo[5]}</div>
                                    </Stack>
                                    <Divider light />
                                    <Stack direction="row" spacing={6} style={{marginTop: '10px'}}>
                                        <div style={{fontWeight:'bold',marginRight:'4px',width:'30%'}}>Region: </div> 
                                        <div>{data?.accountInfo[12]}</div>
                                    </Stack>
                                    <Divider light />
                                    <Stack direction="row" spacing={6} style={{marginTop: '10px'}}>
                                        <div style={{fontWeight:'bold',marginRight:'4px',width:'30%'}}>Subregion: </div> 
                                        <div>{data?.accountInfo[13]}</div>
                                    </Stack>
                                    <Divider light />
                                    <Stack direction="row" spacing={6} style={{marginTop: '10px'}}>
                                        <div style={{fontWeight:'bold',marginRight:'4px',width:'30%'}}>AM Region: </div> 
                                        <div>{data?.accountInfo[3]}</div>
                                    </Stack>
                                    <Divider light />
                                    <Stack direction="row" spacing={6} style={{marginTop: '10px'}}>
                                        <div style={{fontWeight:'bold',marginRight:'4px',width:'30%'}}>Number of Apps: </div> 
                                        <div>{data?.appCount}</div>
                                    </Stack>
                                    <Divider light />
                                    <Stack direction="row" spacing={6} style={{marginTop: '10px'}}>
                                        <div style={{fontWeight:'bold',marginRight:'4px',width:'30%'}}>Signup Date: </div> 
                                        <div>{formatDate(data?.signUpDate)}</div>
                                    </Stack>
                                    <Divider light />
                                    <Stack direction="row" spacing={6} style={{marginTop: '10px'}}>
                                        <div style={{fontWeight:'bold',marginRight:'4px',width:'30%'}}>Start Date: </div> 
                                        <div>{formatDate(data?.accountInfo[7])}</div>
                                    </Stack>
                                    <Divider light />
                                    <Stack direction="row" spacing={6} style={{marginTop: '10px'}}>
                                        <div style={{fontWeight:'bold',marginRight:'4px',width:'30%'}}>Contract End Date: </div> 
                                        <div>{formatDate(data?.accountInfo[6])}</div>
                                    </Stack>
                                    <Divider light />
                                    <Stack direction="row" spacing={6} style={{marginTop: '10px'}}>
                                        <div style={{fontWeight:'bold',marginRight:'4px',width:'30%'}}>Vertical: </div> 
                                        <div>{data?.accountInfo[2]}</div>
                                    </Stack>
                                    <Divider light />
                                    <Stack direction="row" spacing={6} style={{marginTop: '10px'}}>
                                        <div style={{fontWeight:'bold',marginRight:'4px',width:'30%'}}>Revenue Model: </div> 
                                        <div>{data?.accountInfo[8]}</div>
                                    </Stack>
                                    <Divider light />
                                    <Stack direction="row" spacing={6} style={{marginTop: '10px'}}>
                                        <div style={{fontWeight:'bold',marginRight:'4px',width:'30%'}}>ACV: </div> 
                                        <div>{data?.accountInfo[18]}</div>
                                    </Stack>
                                    <Divider light />
                                    <Stack direction="row" spacing={6} style={{marginTop: '10px'}}>
                                        <div style={{fontWeight:'bold',marginRight:'4px',width:'30%'}}>Billing: </div> 
                                        <div>{checkBilling(data?.accountInfo[11])}</div>
                                    </Stack>
                                    <Divider light />
                                    <Stack direction="row" spacing={6} style={{marginTop: '10px'}}>
                                        <div style={{fontWeight:'bold',marginRight:'4px',width:'30%'}}>Billing Method: </div> 
                                        <div>{data?.accountInfo[16]}</div>
                                    </Stack>
                                    <Divider light />
                                    <Stack direction="row" spacing={6} style={{marginTop: '10px'}}>
                                        <div style={{fontWeight:'bold',marginRight:'4px',width:'30%'}}>Dashboard: </div> 
                                        {data?.isSuite === true &&
                                            <div>Suite</div>
                                        }
                                        {data?.isSuite === false &&
                                            <div> Classic</div>
                                        }
                                    </Stack>
                                    <Divider light />
                                    {(data?.accountInfo[9] !== null && data?.accountInfo[9] !== 0) &&
                                    <Stack direction="row" spacing={6} style={{marginTop: '10px'}}>
                                        <div style={{fontWeight:'bold',marginRight:'4px',width:'30%'}}>Parent Account: </div> 
                                        <div>{data?.accountInfo[9]}</div>
                                    </Stack>
                                    }
                                    <div>
                                        <Divider light />
                                        <Stack direction="row" spacing={6} style={{marginTop: '10px'}}>
                                            <div style={{fontWeight:'bold',marginRight:'4px',width:'30%'}}>Implementation Status: </div> 
                                            <div>{data?.accountInfo[14]}</div>
                                        </Stack>
                                        <Divider light />
                                        <Stack direction="row" spacing={6} style={{marginTop: '10px'}}>
                                            <div style={{fontWeight:'bold',marginRight:'4px',width:'30%'}}>Implementation Documentation: </div> 
                                            <div>{formatLink(data?.accountInfo[15])}</div>
                                        </Stack>
                                    </div>
                                </Tile>
                                <br/><br/>
                                <AccountInvoices invoices={data?.invoices} />
                                <br/><br/>
                                <AccountOpportunities opportunities={data?.opportunities} />
                                <br/><br/>
                                <AccountHandoffs handoffs={data?.handoffs} />
                            </Grid>
                            <Grid item xs={6}>
                                <Tile title="Adjust Contacts">
                                    <InfoTooltip message={infoTextContacts} />
                                    <List  component="nav">
                                        {data?.adjustContacts["owner"][0] !== "" &&
                                        <div>
                                            <ListItem>{formatContact(data?.adjustContacts["owner"],"Owner")}</ListItem>
                                            <Divider light />
                                        </div>
                                        }
                                        {data?.adjustContacts["am"][0] !== "" &&
                                        <div>
                                            <ListItem>{formatContact(data?.adjustContacts["am"],"AM")}</ListItem>
                                            <Divider light />
                                        </div>
                                        }
                                        {data?.adjustContacts["secondAm"][0] !== "" &&
                                        <div>
                                            <ListItem>{formatContact(data?.adjustContacts["secondAm"],"2. AM")}</ListItem>
                                            <Divider light />
                                        </div>
                                        }
                                        {data?.adjustContacts["holidayAm"][0] !== "" &&
                                        <div>
                                            <ListItem>{formatContact(data?.adjustContacts["holidayAm"],"Holiday AM")}</ListItem>
                                            <Divider light />
                                        </div>
                                        }
                                        {data?.adjustContacts["implementation"][0] !== "" &&
                                        <div>
                                            <ListItem>{formatContact(data?.adjustContacts["implementation"],"Implementation")}</ListItem>
                                            <Divider light />
                                        </div>
                                        }
                                        {data?.adjustContacts["se"][0] !== "" &&
                                        <div>
                                            <ListItem>{formatContact(data?.adjustContacts["se"],"SC")}</ListItem>
                                            <Divider light />
                                        </div>
                                        }
                                        {data?.adjustContacts["tas"][0] !== "" &&
                                        <div>
                                            <ListItem>{formatContact(data?.adjustContacts["tas"],"TAS")}</ListItem>
                                        </div>
                                        }
                                    </List>
                                </Tile>
                                <br/><br/>
                                <Tile title="Client Main Contacts">
                                    <InfoTooltip message={infoTextMainContacts} />
                                    {createMainContacts(data?.mainContacts)}
                                </Tile>
                                <br/><br/>
                                <Tile title="Description">
                                    <TextField
                                        id="description-textfield"
                                        label="Description:"
                                        multiline
                                        maxRows={4}
                                        value={description}
                                        onChange={(event) =>setDescription(event.target.value)}
                                        style={{width:"600px"}}
                                    />
                                    <br/><br/>
                                    <Button variant='contained' style={{backgroundColor:'#005ff7'}} onClick={handleDescriptionChange}>Save</Button>
                                </Tile>
                               
                                
                            </Grid>
                        </Grid>
                    </div>
                    }
                </Box>
    );
}