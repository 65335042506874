import { useEffect, useState } from "react";
import { Button, Card, Checkbox, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from "@mui/material";
import { TagList } from "./TagList";


export const OptionsFilter = (_props:any) =>{
    const [service,setService] = useState('');
    const [baggerPath,setBaggerPath] = useState('');
    const [outputFilePath,setOutputFilePath] = useState('/logs/');
    const [outputFile,setOutputFile] = useState('output.log');
    const [uniqueFile,setUniqueFile] = useState(false);
    const [count,setCount] = useState('no');
    const [command,setCommand] = useState('no');

    const [advancedButton,setAdvancedButton] = useState(false);
    const advancedClick = () => {
        if(advancedButton){
            setAdvancedButton(false);
        }
        else{
            setAdvancedButton(true);
        }
    }
    const uniqueFileClick = () => {
        if(uniqueFile){
            setUniqueFile(false);
        }
        else{
            setUniqueFile(true);
        }
    }

    const baggerPathWorkflow = (path : string) => {
        setBaggerPath(path);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'baggerPath': path})
        };
        fetch('/api/updatebaggerpath', requestOptions)
            .then(response => response.json()).then(data => {});
    }

    useEffect(() => {
        _props.onOptionChange({"service": service, "baggerPath": baggerPath, "outputFilePath":outputFilePath,"outputFile":outputFile,"uniqueFile":uniqueFile,"count":count,"command":command});
    },[service,baggerPath,outputFilePath,outputFile,uniqueFile,count,command]);
    useEffect(() => {
            setBaggerPath(_props.baggerName);
    }, [_props.baggerName]);
    return(
        <Card variant="outlined">
            <Grid container spacing={6} >
                <Grid item xs={5} style={{marginLeft:'2%',marginRight:'-2%'}}>
                    <h2 style={{color: '#005ff7',marginTop:'0%'}}>Options</h2>
                    <FormControl>
                        <FormLabel id="service-label">Service:</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="buttons-group-label"
                            name="radio-buttons-group"
                            onChange={(newService:any) => setService(newService.target.value)}
                            value={service}
                        >
                            <FormControlLabel value="adjust_server" control={<Radio />} label="adjust_server" />
                            <FormControlLabel value="callback_worker" control={<Radio />} label="callback_worker" />
                            <FormControlLabel value="attribution_worker" control={<Radio />} label="attribution_worker" />
                        </RadioGroup>
                    </FormControl>
                    <br/>
                    <FormLabel id="username-label">Bagger path:</FormLabel>
                    <br/>
                    <TextField style={{width:'60%'}} label={'username'} margin="normal" value={baggerPath}  onChange={(newBaggerPath:any) => baggerPathWorkflow(newBaggerPath.target.value)}/>
                    <br/>
                    <FormLabel id="file-path-label">Output files path:</FormLabel>
                    <br/>
                    <TextField style={{width:'60%'}} margin="normal" value={outputFilePath}  onChange={(newOutputFilePath:any) => setOutputFilePath(newOutputFilePath.target.value)}/>
                    <br/>
                    <FormLabel id="file-path-label">Output file:</FormLabel>
                    <br/>
                    <TextField style={{width:'60%'}} margin="normal" value={outputFile}  onChange={(newOutputFile:any) => setOutputFile(newOutputFile.target.value)}/>

                    <br/>
                    <FormControlLabel control={<Checkbox value={uniqueFile} onClick={() => uniqueFileClick()}/>} label="Unique file name" />
                    <br/>
                    <Button variant='contained' style={{backgroundColor:'#005ff7'}} onClick={() => advancedClick()}>Advanced</Button>

                    {advancedButton &&
                    <>
                        <br/>
                        <FormControl>
                            <FormLabel id="count-label">Count:</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="buttons-count-label"
                                name="radio-count-group"
                                onChange={(newCount:any) => setCount(newCount.target.value)}
                                value={count}
                            >
                                <FormControlLabel value="yes" control={<Radio />} label="yes" />
                                <FormControlLabel value="no" control={<Radio />} label="no" />
                            </RadioGroup>
                        </FormControl>
                        <br/>
                        <FormControl>
                            <FormLabel id="count-label">Command:</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="buttons-command-label"
                                name="radio-command-group"
                                onChange={(newCommand:any) => setCommand(newCommand.target.value)}
                                value={command}
                            >
                                <FormControlLabel value="yes" control={<Radio />} label="yes" />
                                <FormControlLabel value="no" control={<Radio />} label="no" />
                            </RadioGroup>
                        </FormControl>
                    </>
                    }
                </Grid>


                <Grid item xs={1}  justifyContent="center" alignItems="center" display="flex" >
                    <Divider orientation="vertical"/>
                </Grid>


                <Grid item xs={6}>
                    <br/><br/>
                    <div>Tag:</div>
                    <TagList service={service} onTagsChange={_props.onTagsChange} />
                </Grid>
            </Grid>
        </Card>
    );
}