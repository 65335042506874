import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import {NumericFormat} from 'react-number-format';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

function createData(
    id: number,
    accountId: string,
    accountName: string,
    classification: string,
    accountManager: string,
    csm: string,
    sc: string,
    acv: string,
    parentAccount: string,
    
  ) {
    return { id,accountId,accountName,classification,accountManager,csm,sc,acv,parentAccount,};
  }

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
}
  const columns: GridColDef[] = [
    { field: 'accountId', headerName: 'Account ID',flex: 1,minWidth: 150,
    renderCell: (params : any) => {
      return <Button variant="contained" component={Link} to={"/account/"+params.row.accountId} >{params.row.accountId}</Button>;
        },
    },
    { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 150},
    { field: 'classification', headerName: 'Classification',flex: 1, minWidth: 150},
    { field: 'accountManager', headerName: 'Account Manager',flex: 1, minWidth: 150},
    { field: 'csm', headerName: 'CSM',flex: 1, minWidth: 150},
    { field: 'sc', headerName: 'SC',flex: 1, minWidth: 150},
    { field: 'acv', headerName: 'Active Contract ACV',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
            return <NumericFormat value={params.row.acv} displayType={'text'} thousandSeparator={true}/>
    }},
    { field: 'parentAccount', headerName: 'Parent Account',flex: 1, minWidth: 150}
  ];

export const AcvReportTable = (_props : any) =>{
    var data = _props.data;
    const rows : any = [];
    for (var i = 0; i < data.length; i++){
        rows.push(
            createData(
                i,
                data[i].accountId,
                data[i].accountName,
                data[i].classification,
                data[i].accountManager,
                data[i].csm,
                data[i].sc,
                String(data[i].acv),
                data[i].parentAccount,
                
            )
        );
    }

    return (
        <div style={{ height: 600, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
          />
        </div>
    );
}