import {Button} from '@mui/material';
import { Link } from 'react-router-dom';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

const accountLink = (accountId : any) => {
    if(accountId === ''){
        return '';
    }
    return <Button variant="contained" component={Link} to={"/account/"+accountId} >{accountId}</Button>
}

const appLink = (appToken : any) => {
    if(appToken === 'N/A'){
        return '';
    }
    return <Button variant="contained" component={Link} to={"/account/app/"+appToken} >{appToken}</Button>;
}

const updateColor = (update : string, appToken: string) => {
    if(update === 'Click Spam'){
        return <div style={{backgroundColor:'#b2a300', width:'100%', height:'100%', textAlign:'center', padding: '12px'}}><Link to={"/account/app/"+appToken+"/anomalies"}>{update}</Link></div>;
    }
    if(update === 'Failed Callback'){
        return <div style={{backgroundColor:'#ff9100', width:'100%', height:'100%', textAlign:'center', padding: '12px'}}><Link to={"/account/app/"+appToken+"/anomalies"}>{update}</Link></div>;
    }
    if(update === 'New Version Release'){
        return <div style={{backgroundColor:'#52b202', width:'100%', height:'100%', textAlign:'center', padding: '12px'}}><Link to={"/account/app/"+appToken+"/anomalies"}>{update}</Link></div>;
    }
    if(update === 'Jira Ticket'){
        return <div style={{backgroundColor:'lightgrey', width:'100%', height:'100%', textAlign:'center', padding: '12px'}}><Link to={"/account/app/"+appToken+"/anomalies"}>{update}</Link></div>;
    }
    return <div style={{backgroundColor:'#b2102f', width:'100%', height:'100%', textAlign:'center', padding: '12px'}}><Link to={"/account/app/"+appToken+"/anomalies"}>{update}</Link></div>;
}

function createData(
    id: number,
    update: string,
    type: string,
    date: string,
    accountId: string,
    accountName: string,
    appToken: any,
    info: any
  ) {
    return { id, update, type, date, accountId, accountName, appToken, info };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

const columns: GridColDef[] = [
    { field: 'update', headerName: 'Update',flex: 1, minWidth: 100,
        renderCell: (params : any) => {
            return updateColor(params.row.update,params.row.appToken);
        }
    },
    { field: 'type', headerName: 'Type',flex: 1, minWidth: 100},
    { field: 'date', headerName: 'Date',flex: 1, minWidth: 50},
    { field: 'accountId', headerName: 'Account Id',flex: 1, minWidth: 150, sortable: false,
      renderCell: (params : any) => {
          return accountLink(params.row.accountId);
      }
    },
    { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 250},
    { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 100, sortable: false,
      renderCell: (params : any) => {
          return appLink(params.row.appToken);
      }
    },
    { field: 'info', headerName: 'Info',flex: 1, minWidth: 250},
]
export const UpdatesTable = (props : any) =>{
    var updates = props.updates;
    const rows : any = [];
    for (var i = 0; i < updates.length; i++){
        rows.push(
            createData(
                i,
                updates[i]["update"],
                updates[i]["type"],
                formatDate(updates[i]["insertDate"]),
                updates[i]["accountId"],
                updates[i]["accountName"],
                updates[i]["appToken"],
                updates[i]["info"],
            )
        );
    }

    return (
        <div style={{ height: 600, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}