import { Box, Grid, Card, CardContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import UpcomingVacationTable from '../../entities/components/Team/UpcomingVacationTable';
import VacationTable from '../../entities/components/Team/VacationTable';
import { Tabs } from '@adjust/components';
import { ManagerViewPage } from '../managerView/ManagerViewPage';
import { ManagerCasesPage } from '../managerView/ManagerCases';
import { InfoTooltip } from '../../shared/components/infoTooltip/InfoTooltip';

export const TeamPage = () => {
    const [tabs, setTabs] = useState('1');
    const [vacationData, setVacationData] = useState([]);
    const [upcomingVacationData, setUpcomingVacationData] = useState([]);
    const [users, setUsers] = useState([]);
    const [team, setTeam] = useState('');

    useEffect(() => {
        fetch('/api/team').then(res => res.json()).then(data => {
            setVacationData(data.vacation);
            setUpcomingVacationData(data.vacationUpcoming);
            setUsers(data.users);
            setTeam(data.division)
        });
    }, []);

    const getItems = () =>{
        if(users.length === 0){
            return [{ id: '1', label: 'Vacation' }]
        }
        if(team === 'Customer Success' || team === 'Sales'){
            return[
                { id: '1', label: 'Vacation' },
                { id: '2',label: 'User' },
            ];
        }
        return[
            { id: '1', label: 'Vacation' },
            { id: '2',label: 'User' },
            { id: '3',label: 'Cases' },
        ];
    }

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <h1>Your Team</h1>
                        <Tabs items={getItems()} onChange={setTabs} value={tabs} />
                        <br/>
                        {tabs === '1' &&
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <h3>Vacation</h3>
                                        <InfoTooltip message="Source: Mate script | Refreshed: manually" />
                                        <VacationTable vacation={vacationData} />
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={6}>
                                <Card variant="outlined">
                                    <CardContent>
                                        <h3>Upcoming Vacation</h3>
                                        <InfoTooltip message="Source: Mate script | Refreshed: manually" />
                                        <UpcomingVacationTable vacation={upcomingVacationData} />
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                        }
                        {tabs === '2' &&
                        <ManagerViewPage users={users} team={team} />
                        }
                        {tabs === '3' &&
                        <ManagerCasesPage users={users}/>
                        }
                        
                    </div>
                </Box>
    );
}