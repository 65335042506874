import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

function createData(
    id: number,
    trackerToken: string,
    date: string,
    appToken: string,
    accountId: string,
    accountName: string,
  ) {
    return { id, trackerToken, date, appToken, accountId, accountName };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export default function ClickSpamAllowListTrackerTable(_props : any) {
    const trackers = _props.trackers;
    const rows : any = [];

    for (var i = 0; i < trackers.length; i++){
        rows.push(
            createData(
                i,
                trackers[i].trackerToken,
                formatDate(trackers[i].addedAt),
                trackers[i].appToken,
                trackers[i].accountId,
                trackers[i].accountName,
            )
        );
    }

    const columns: GridColDef[] = [
        { field: 'date', headerName: 'Created At',flex: 1, minWidth: 150},
        { field: 'trackerToken', headerName: 'Tracker Token',flex: 1, minWidth: 150},
        { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 150},
        { field: 'accountId', headerName: 'Account ID',flex: 1, minWidth: 150},
        { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 150},
      ];

    return (
        <div style={{ height: 600, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}