import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

function createData(
    id: number,
    accountName: string,
    accountId: string,
    appToken: string,
    appName: string,
    competitor: string,
    competitorInstallDate: string,
  ) {
    return { id, accountName, accountId, appToken, appName, competitor, competitorInstallDate};
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export const CompetitorSdksTable = (_props : any) => {
    var sdks = _props.sdks;
    const rows : any = [];
    for (var i = 0; i < sdks.length; i++){
        rows.push(
            createData(
              i,
              sdks[i][0],
              sdks[i][1],
              sdks[i][2],
              sdks[i][3],
              sdks[i][4],
              sdks[i][5],
              
            )
        );
    }

    const columns: GridColDef[] = [
        { field: 'accountId', headerName: 'Account ID',flex: 1, minWidth: 100,
            renderCell: (params : any) => {
                return <Button variant="contained" component={Link} to={"/account/"+params.row.accountId} >{params.row.accountId}</Button>;
            },
        },
        { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 250},
        { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 100,
            renderCell: (params : any) => {
                return  <div>
                            {params.row.appToken !== null &&
                            <Button variant="contained" component={Link} to={"/account/app/"+params.row.appToken} >{params.row.appToken}</Button>
                            }
                        </div>;
            },
        },
        { field: 'appName', headerName: 'App Name',flex: 1, minWidth: 250},
        { field: 'competitor', headerName: 'Competitor',flex: 1, minWidth: 150},
        { field: 'competitorInstallDate', headerName: 'Competitor Install Date',flex: 1, minWidth: 150},
    ];

    return (
        <div style={{ height: 650, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}