import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import AdNetworkJsonModal from './AdNetworkJsonModal';


const columns: GridColDef[] = [
  { field: 'id', headerName: '',flex: 1, maxWidth:1},
  { field: 'campaign_id', headerName: 'Campaign Id',flex: 1,maxWidth:200},
  { field: 'campaign_name', headerName: 'Campaign Name',flex: 1},
  { field: 'date_start', headerName: 'StartDate',flex: 1,maxWidth:100},
  { field: 'date_stop', headerName: 'EndDate',flex: 1,maxWidth:100},
  { field: 'attribution_setting', headerName: 'Attribution Setting',flex: 1,maxWidth:100},
  { field: 'actions', headerName: 'Actions',flex: 1,maxWidth:150,
  renderCell: (params : any) => {
      return<AdNetworkJsonModal data={params.row.actions} />;
  },
},
  
];

function createData(
    id: number,
    campaign_id: string,
    campaign_name: string,
    date_start: Date,
    date_stop: Date,
    attribution_setting: string,
    actions: any,
    
  ) {
    return { id,campaign_id,campaign_name,date_start,date_stop,attribution_setting,actions};
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export default function AdNetworkTable(_props : any) {
    const rows : any = [];
    if(_props.data.data !== undefined){
        for (var i = 0; i < _props.data.data.length; i++){
            rows.push(
                createData(
                    i,
                    _props.data.data[i]["campaign_id"],
                    _props.data.data[i]["campaign_name"],
                    _props.data.data[i]["date_start"],
                    _props.data.data[i]["date_stop"],
                    _props.data.data[i]["attribution_setting"],
                    _props.data.data[i]["actions"],
                )
            );
        }
    }
    return (
        <div style={{ height: 800, width: '180vh' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}