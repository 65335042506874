
import { Badge, Button, Input } from '@adjust/components';
import { Tile } from '@adjust/components/build/Tile/Tile';
import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';

function setError(errorText: any) {
    if (errorText === 'Password was updated') {
        return <Badge label={errorText} color="positive" />
    }
    else if (errorText === 'Invalid old password') {
        return <Badge label={errorText} color="negative" />
    }
    return <></>
}
export default function SettingsPage() {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [errorData, setErrorData] = useState('');

    function changePassword() {
        const loginData = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ oldPassword, newPassword }),
        }
        fetch('/api/changepassword', loginData).then(response => response.json()).then(function (data) {
            setErrorData(data["error"]);
        });
    }

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <h1>Settings</h1>
                        <Grid style={{ width: '30%' }} container direction="column" justifyContent="center" >
                            <Tile title='Change Password' >
                                <Input
                                    css={{ width: '100%' }}
                                    label="Old Password"
                                    id="oldPassword"
                                    name="oldPassword"
                                    value={oldPassword}
                                    onClear={() => {
                                        setOldPassword('');
                                    }}
                                    onChange={(e) => {
                                        setOldPassword(e.target.value);
                                    }}
                                />
                                <br />
                                <Input
                                    css={{ width: '100%' }}
                                    label="New Password"
                                    id="newPassword"
                                    name="newPassword"
                                    value={newPassword}
                                    onClear={() => {
                                        setNewPassword('');
                                    }}
                                    onChange={(e) => {
                                        setNewPassword(e.target.value);
                                    }}
                                />
                                <br />
                                {setError(errorData)}
                                <br />
                                <Button type="submit" kind="primary" label="Change Password" onClick={() => { changePassword() }}>Login</Button>{' '}
                            </Tile>
                        </Grid>
                    </div>
                </Box>
    );
}