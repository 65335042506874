import { Route, Routes } from "react-router-dom";
import { ApplovinIstallsPage } from "../../pages/reports/appLovinInstalls/AppLovinInstallsPage";
import { AttributionSettingsPage } from "../../pages/reports/attributionSettings/AttributionSettingsPage";
import { DataResidencyPage } from "../../pages/reports/dataResidency/DataResidencyPage";
import { ReportsPage } from "../../pages/reports/Reports";
import { AdjustSuitePage } from "../../pages/reports/suite/SuitePage";
import { AdjustSuitePageMyAccounts } from "../../pages/reports/suite/SuitePageMyAccounts";
import { FailedCallbacksPage } from "../../pages/reports/failedCallbacks/FailedCallbacksPage";
import { UpsellingDashboard } from "../../pages/reportsCsm/upsellingDashboard/UpsellingDashboardPage";
import { EventsS2sOnlyPage } from "../../pages/reports/eventss2sonly/EventsS2SOnlyPage";
import { DigitalTurbineWhitelistPage } from "../../pages/reports/digitalTurbineWhitelist/DigitalTurbineWhitelist";
import { ProbabilisticMatchingPage } from "../../pages/reports/ctvProbabilisticMatching/CtvProbabilisticMatching";
import { TwoFaEnabledAccountsPage } from "../../pages/reports/twoFaEnabledAccounts/TwoFaEnabledAccountsPgae";
import { AcvReportPage } from "../../pages/reportsCsm/acvReport/AcvReportPage";
import { TrackersAllowEmulatorsPage } from "../../pages/reports/trackersAllowEmulators/TrackersAllowEmulatorsPage";
import { FeaturamaPage } from "../../pages/reports/featurama/FeaturamaPage";
import { AdNetworksReportPage } from "../../pages/reportsCsm/adNetworksReport/AdNetworksReportPage";
import { Box } from "@mui/material";
import { SideBar } from "../../shared/components/sideBar/SideBar";
import { KpiErrorsReportPage } from "../../pages/reports/kpiErrors/KpiErrorsPage";
import { EventAnomaliesPage } from "../../pages/reports/eventAnomalies/EventAnomaliesPage";
import { CompetitorSdksPage } from "../../pages/reports/competitorSdks/CompetitorSdksPage";
import { SalesOverviewTeamPage } from "../../pages/salesAccounts/SalesOverviewTeam";
import { SalesOverviewRegionPage } from "../../pages/salesAccounts/SalesOverviewRegion";
import { MyAccountsUsagePage } from "../../pages/Tools/myAccountsUsage/MyAccountsUsagePage";
import { OverviewPageSalesRegion } from "../../pages/overview/OverviewPagesSalesRegion";

export const ReportsRouter = () => {
    return(
        <Box sx={{ display: 'flex',marginTop:'4%', marginRight:'2%'}}>
            <SideBar/>
            <Routes>
                <Route path="/" element={<ReportsPage />} />
                <Route path="/attributionsettings" element={<AttributionSettingsPage />} />
                <Route path="/applovininstalls" element={<ApplovinIstallsPage />} />
                <Route path="/adjustsuite" element={<AdjustSuitePage />} />
                <Route path="/adjustsuitemyaccounts" element={<AdjustSuitePageMyAccounts />} />
                <Route path="/dataresidency" element={<DataResidencyPage />} />
                <Route path="/failedcallbacks" element={<FailedCallbacksPage />} />
                <Route path="/2faenabledaccounts" element={<TwoFaEnabledAccountsPage />} />

                <Route path="/events2sonly" element={<EventsS2sOnlyPage  />} />
                <Route path="/digitalturbinewhitelist" element={<DigitalTurbineWhitelistPage />} />
                <Route path="/ctvprobabilisticmatchingenabledlist" element={<ProbabilisticMatchingPage />} />
                <Route path="/trackersallowemulators" element={<TrackersAllowEmulatorsPage />} />
                <Route path="/acvreport" element={<AcvReportPage />} />
                <Route path="/featurama" element={<FeaturamaPage />} />

                <Route path="/upsellingdashboard" element={<UpsellingDashboard />} />
                <Route path="/adnetworks" element={<AdNetworksReportPage />} />
                <Route path="/kpierrors" element={<KpiErrorsReportPage />} />
                <Route path="/eventanomalies" element={<EventAnomaliesPage />} />
                <Route path="/competitorsdks" element={<CompetitorSdksPage />} />
                <Route path="/salesoverviewteam" element={<SalesOverviewTeamPage />} />
                <Route path="/salesoverviewregion" element={<SalesOverviewRegionPage />} />
                <Route path="/overviewregion" element={<OverviewPageSalesRegion />} />
                <Route path="/myaccountsusage" element={<MyAccountsUsagePage/>} />

                <Route path="/*" element={<ReportsPage />} />
            </Routes>
        </Box>
    );
}