import { Tile } from "@adjust/components";
import { Box, Card, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PartnerAccountsTable } from "../../entities/components/Partner/PartnerAccountsTable";
import { PartnerAppsTable } from "../../entities/components/Partner/PartnerAppsTable";
import { InfoTooltip } from "../../shared/components/infoTooltip/InfoTooltip";

export const PartnerPage = () => {
    let params = useParams();
    let partnerId  = "0";
    if (params.partnerId !== undefined){
        partnerId = String(params.partnerId);
    }
    const [partnerInformation, setPartnerInformation] = useState({"name":'N/A'});
    const [partnerApps, setPartnerApps] = useState([]);
    const [partnerAccounts, setPartnerAccounts] = useState([]);
    useEffect(() => {
        fetch('/api/partner/'+partnerId).then(res => res.json()).then(data => {
            if(data["partnerInformation"] !== null){
                setPartnerInformation(data["partnerInformation"][0]);
            }
            setPartnerApps(data["partnerApps"]);
            setPartnerAccounts(data["partnerAccounts"]);
        });
    }, [partnerId]);

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Tile title={"Partner: " + partnerInformation.name}>
                    <div>
                        <Grid container spacing={6}>
                            <Grid item xs={6}>
                                <Card variant="outlined">
                                    <h4 style={{marginLeft:'2%'}}>Accounts with the Partner</h4>
                                    <InfoTooltip message="Source: Adjust DB | Refreshed: instant" />
                                    <PartnerAccountsTable accounts = {partnerAccounts} />
                                </Card>
                                
                            </Grid>
                            <Grid item xs={6}>
                                <Card variant="outlined">
                                    <h4 style={{marginLeft:'2%'}}>Apps with the Partner</h4>
                                    <InfoTooltip message="Source: Adjust DB | Refreshed: instant" />
                                    <PartnerAppsTable apps = {partnerApps} />
                                </Card>
                            </Grid>
                                
                        </Grid>
                    </div>
                    </Tile>
                </Box>
    );
}