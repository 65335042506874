import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { AmCaseModal } from './AmCaseModal';

const columns: GridColDef[] = [
  { field: 'accountId', headerName: 'Account ID',flex: 1, minWidth: 100},
  { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 100},
  { field: 'caseCountWithNotes', headerName: 'Case Count With Notes',flex: 1, minWidth: 100},
  { field: 'caseCount', headerName: 'Case Count',flex: 1, minWidth: 100},
  { field: 'cases', headerName: 'Cases',flex: 1, minWidth: 150,sortable: false,
    renderCell: (params : any) => {
        return <AmCaseModal data={params.row.cases} />;
    },
},     
];

function createData(
    id: number,
    accountId: number,
    accountName: string,
    cases: any,
    caseCount: number,
    caseCountWithNotes: number,
  ) {
    return { id,accountId, accountName, cases, caseCount, caseCountWithNotes };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export const AmCaseTable = (_props : any) => {
    const rows : any = [];
    const accounts = _props.data;
    if(accounts !== undefined){
        for (var i = 0; i < accounts.length; i++){
            rows.push(
                createData(
                    i,
                    accounts[i]["accountId"],
                    accounts[i]["accountName"],
                    accounts[i]["cases"]["cases"],
                    accounts[i]["cases"]["caseCount"],
                    accounts[i]["cases"]["caseCountWithNotes"],
                )
            );
        }
    }
    return (
        <div style={{ height: 800, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}
