import { Input } from '@adjust/components';
import { Tile } from '@adjust/components/build/Tile/Tile';
import { Box, Grid} from '@mui/material';
import Button from '@mui/material/Button';
import React, { useContext, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from '../../../app/store/AuthContext';
import PartnerCredentialsTable from '../../../entities/components/Tools/PartnerCredentialsTable';
import { Link } from 'react-router-dom';


export const PartnerCredentials = () =>{
    const [accountId, setAccountId] = useState('');
    //const [partner, setPartner] = useState('');
    const [credentialData, setCredentialData] = useState([]);
    const value = useContext(AuthContext);
    const apiToken = value?.auth.apiToken;
    const [isLoading, setIsLoading] = useState(false);
    const [accountInfo,setAccountInfo] = useState({'accountId':'','accountName': '','ctId': '','country': '','accountManager': ''});

    function searchPartnerCredentials(accountId : any,partner : any, api : any){
        if(accountId !== '' && api !== ''){
            //&& partner !== ''
            setIsLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ "accountId": accountId,"partner" : partner,'api':api })
            };
            fetch('/api/partnercredentials', requestOptions)
                .then(response => response.json()).then(data => {
                    setCredentialData(data["data"]);
                    setAccountInfo(data["accountInfo"]);
                    setIsLoading(false);
                });
        }
    }

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Tile title="Credentials Service API">
                                <Input
                                    label="Account Id / Name"
                                    id="accountId" 
                                    name="accountId"
                                    value={accountId}
                                    placeholder="f.e. 65"
                                    onClear={() => {
                                        setAccountId('');
                                    }}
                                    onChange={(e) => {
                                        setAccountId(e.target.value);
                                    }}
                                />
                                <br/>
                                <br/>
                                <Button variant="contained" style={{backgroundColor:'#005ff7'}} onClick={() => searchPartnerCredentials(accountId,'',apiToken)} >Search</Button>
                                </Tile>
                            </Grid>
                            {accountInfo.accountId !== '' &&
                            <Grid item xs={6}>
                                <Tile style={{maxHeight: '100%'}} title="">
                                    <Grid container spacing={0}>
                                        <Grid item xs={3}>
                                            <div style={{fontWeight:'bold',marginRight:'4px'}}>Account Name: </div>
                                        </Grid>
                                        <Grid item xs={9}> {accountInfo.accountName}</Grid>

                                        <Grid item xs={3}>
                                            <div style={{fontWeight:'bold',marginRight:'4px'}}>Account ID: </div>
                                        </Grid>
                                        <Grid item xs={9}> {accountInfo.accountId}</Grid>

                                        <Grid item xs={3}>
                                            <div style={{fontWeight:'bold',marginRight:'4px'}}>Country Code: </div>
                                        </Grid>
                                        <Grid item xs={9}> {accountInfo.country}</Grid>

                                        <Grid item xs={3}>
                                            <div style={{fontWeight:'bold',marginRight:'4px'}}>Account Manager: </div>
                                        </Grid>
                                        <Grid item xs={9}> {accountInfo.accountManager}</Grid>
                                        <br/>
                                        <br/>
                                        <Button variant="contained" component={Link} to={"/account/"+accountInfo.accountId}>Account</Button>
                                    </Grid>
                                </Tile>
                            </Grid>
                            }
                        </Grid>
                        <br/>
                        {isLoading &&
                            <CircularProgress />
                        }
                        {(credentialData.length > 0 && !isLoading) &&
                            <Grid item xs={12}>
                                <PartnerCredentialsTable data={credentialData}/>
                            </Grid>
                        }
                </Box>
    );
}