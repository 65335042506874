import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Badge } from '@adjust/components/build/Badge/Badge';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

function statusBadge(text: string){
    if(String(text) === "true" ){
        return <Badge label="Setup" color="warning" />
    }
    else{
        return <Badge label="Active" color="positive" />
    }
}

function createData(
    name: string,
    updated: string,
    partnerId: string,
) {
    return { name,updated,partnerId};
}

export default function PartnerTable(_props : any) {
const analytics : any = [];
const network : any = [];
const san : any = [];
const appToken = _props["appToken"];
if(_props["partner"] !== undefined){
    for (var i = 0; i < _props["partner"][0].length; i++){
        analytics.push(
            createData(
                _props["partner"][0][i][0],
                _props["partner"][0][i][1],
                _props["partner"][0][i][2]
            )
        );
    }
    for (i = 0; i < _props["partner"][1].length; i++){
        network.push(
            createData(
                _props["partner"][1][i][0],
                _props["partner"][1][i][1],
                _props["partner"][1][i][2]
            )
        );
    }
    for (i = 0; i < _props["partner"][2].length; i++){
        san.push(
            createData(
                _props["partner"][2][i][0],
                _props["partner"][2][i][1],
                _props["partner"][2][i][2]
            )
        );
    }
}
  return (
    <>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
                <TableCell>Partner</TableCell>
                <TableCell >Status</TableCell>
                <TableCell >Action</TableCell>
                <TableCell >Last Time Changed</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
        {san.length > 0 &&
        <>
          <p style={{marginLeft:'20px'}}>SAN</p>
          {san.map((net : any) => (
              <TableRow key={net.name}>
                <TableCell component="th" scope="row">
                  {net.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {statusBadge(net.name)}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Button variant="outlined" size="small" component={Link} to={"/account/app/settings/partner/"+appToken+"/"+net.partnerId}>
                    Check Setup
                  </Button>
                </TableCell>
                <TableCell component="th" scope="row">
                  {formatDate(net.updated)}
                </TableCell>
              </TableRow>
            ))}
          <br/>
        </>
        }
        {network.length > 0 &&
        <>
          <p style={{marginLeft:'20px'}}>Network</p>
          {network.map((net : any) => (
              <TableRow key={net.name}>
                <TableCell component="th" scope="row">
                  {net.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {statusBadge(net.name)}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Button variant="outlined" size="small" component={Link} to={"/account/app/settings/partner/"+appToken+"/"+net.partnerId}>
                    Check Setup
                  </Button>
                </TableCell>
                <TableCell component="th" scope="row">
                  {formatDate(net.updated)}
                </TableCell>

              </TableRow>
            ))}
          <br/>
        </>
        }
        
        {analytics.length > 0 &&
        <>
          <p style={{marginLeft:'20px'}}>Analytics</p>
          {analytics.map((net : any) => (
              <TableRow key={net.name}>
                <TableCell component="th" scope="row">
                  {net.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {statusBadge(net.name)}
                </TableCell>
                <TableCell component="th" scope="row">
                    <Button variant="outlined" size="small" component={Link} to={"/account/app/settings/partner/"+appToken+"/"+net.partnerId}>
                      Check Setup
                    </Button>
                </TableCell>
                <TableCell component="th" scope="row">
                  {formatDate(net.updated)}
                </TableCell>

              </TableRow>
          ))}
        </>
        }
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}
