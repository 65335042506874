import { Breadcrumbs } from '@adjust/components/build/Breadcrumbs/Breadcrumbs';
import { Box, Button, ButtonGroup} from '@mui/material';
import React, { useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import AccountAppsTable from '../../../entities/components/Accounts/AccountAppsTable';
import { AccountMobileSecretsTable } from '../../../entities/components/Accounts/AccountMobileAppSecretsTable';
import { AccountSegmentedButton } from '../../../shared/components/accountSegmentedButton/AccountSegmentedButton';
import { useQuery } from "react-query";
import CircularProgress from '@mui/material/CircularProgress';
import { Account, AccountAppsData } from '../../../shared/components/Types/Types';
import { getData } from '../../../shared/components/Hooks/AccountHooks';
import { AccountAttributionSettingsTable } from '../../../entities/components/Accounts/AccountAttributionSettingsTable';
import { AccountRawDataTable } from '../../../entities/components/Accounts/AccountRawDataTable';
import { AccountFraudSettingsTable } from '../../../entities/components/Accounts/AccountFraudSettingsTable';

function MyBreadcrumbs(accountId:string,accountName:string,navigate:NavigateFunction) {
    const items = [
      { label: 'My Accounts', onClick: () => {navigate('/myaccounts')}},
      { label: accountName, onClick: () => {navigate('/account/' + accountId)}},
      { label: 'Apps and Settings', onClick: () => {navigate('/account/' + accountId + '/apps')}}
    ];
    return <Breadcrumbs items={items} />;
}
  
export const AppsPage = (_props:Account) => {
    let navigate = useNavigate();
    let params = useParams();
    let accountId  = '0';
    if (params.accountId !== undefined){
        accountId = String(params.accountId);
    }
    const { status, data } = useQuery<AccountAppsData, Error>(
        ["appsPage", { accountId }], () => getData(accountId,'accountapps'));

    const [appButtonIdentifier,setAppButtonIdentifier] = useState(0);
    
    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    {MyBreadcrumbs(accountId,_props.accountName,navigate)}
                    <AccountSegmentedButton accountId = {accountId} tabValue='2' accountManager={_props.accountManager}  competitor={_props.competitor} />
                    <br/>
                    {status === "loading" &&
                            <CircularProgress />
                    }
                    {status !== "loading" &&
                    <div>
                        
                        <ButtonGroup
                            disableElevation
                            variant="contained"
                            style={{marginLeft:'5px',marginTop:'5px'}}
                        >
                            {(appButtonIdentifier === 0) &&
                            <>
                                <Button style={{backgroundColor:'#005ff7'}} onClick={() => setAppButtonIdentifier(0)}>Apps</Button>
                                <Button variant="outlined" onClick={() => setAppButtonIdentifier(1)}>Mobile App Secrets</Button>
                                <Button variant="outlined" onClick={() => setAppButtonIdentifier(2)}>Attribution Settings</Button>
                                <Button variant="outlined" onClick={() => setAppButtonIdentifier(3)}>Raw Data</Button>
                                <Button variant="outlined" onClick={() => setAppButtonIdentifier(4)}>Fraud Settings</Button>
                            </>

                            }
                            {appButtonIdentifier === 1 &&
                            <>
                                <Button variant="outlined" onClick={() => setAppButtonIdentifier(0)}>Apps</Button>
                                <Button style={{backgroundColor:'#005ff7'}} onClick={() => setAppButtonIdentifier(1)}>Mobile App Secrets</Button>
                                <Button variant="outlined" onClick={() => setAppButtonIdentifier(2)}>Attribution Settings</Button>
                                <Button variant="outlined" onClick={() => setAppButtonIdentifier(3)}>Raw Data</Button>
                                <Button variant="outlined" onClick={() => setAppButtonIdentifier(4)}>Fraud Settings</Button>
                            </>
                            }
                            {appButtonIdentifier === 2 &&
                            <>
                                <Button variant="outlined" onClick={() => setAppButtonIdentifier(0)}>Apps</Button>
                                <Button variant="outlined" onClick={() => setAppButtonIdentifier(1)}>Mobile App Secrets</Button>
                                <Button style={{backgroundColor:'#005ff7'}} onClick={() => setAppButtonIdentifier(2)}>Attribution Settings</Button>
                                <Button variant="outlined" onClick={() => setAppButtonIdentifier(3)}>Raw Data</Button>
                                <Button variant="outlined" onClick={() => setAppButtonIdentifier(4)}>Fraud Settings</Button>
                            </>
                            }
                            {appButtonIdentifier === 3 &&
                            <>
                                <Button variant="outlined" onClick={() => setAppButtonIdentifier(0)}>Apps</Button>
                                <Button variant="outlined" onClick={() => setAppButtonIdentifier(1)}>Mobile App Secrets</Button>
                                <Button variant="outlined" onClick={() => setAppButtonIdentifier(2)}>Attribution Settings</Button>
                                <Button style={{backgroundColor:'#005ff7'}} onClick={() => setAppButtonIdentifier(3)}>Raw Data</Button>
                                <Button variant="outlined" onClick={() => setAppButtonIdentifier(4)}>Fraud Settings</Button>
                            </>
                            }
                            {appButtonIdentifier === 4 &&
                            <>
                                <Button variant="outlined" onClick={() => setAppButtonIdentifier(0)}>Apps</Button>
                                <Button variant="outlined" onClick={() => setAppButtonIdentifier(1)}>Mobile App Secrets</Button>
                                <Button variant="outlined" onClick={() => setAppButtonIdentifier(2)}>Attribution Settings</Button>
                                <Button variant="outlined" onClick={() => setAppButtonIdentifier(3)}>Raw Data</Button>
                                <Button style={{backgroundColor:'#005ff7'}} onClick={() => setAppButtonIdentifier(4)}>Fraud Settings</Button>
                            </>
                            }
                            
                        </ButtonGroup>

                        {appButtonIdentifier === 0 &&
                        <AccountAppsTable apps={data?.apps} />
                        }
                        {appButtonIdentifier === 1 &&
                        <AccountMobileSecretsTable secrets={data?.mobileAppSecrets} />
                        }
                        {appButtonIdentifier === 2 &&
                        <AccountAttributionSettingsTable apps={data?.attributionSettings} />
                        }
                        {appButtonIdentifier === 3 &&
                        <AccountRawDataTable apps={data?.rawData} />
                        }
                        {appButtonIdentifier === 4 &&
                        <AccountFraudSettingsTable apps={data?.fraudSettings} />
                        }
                    </div>
                    }
                </Box>
    );
}