import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { ActionMenu } from '@adjust/components/build/ActionMenu/ActionMenu';
import { Button, Snackbar } from '@mui/material';

function createData(
    id: number,
    firstName: string,
    lastName: string,
    mail: string,
    role: string,
  ) {
    return { id, firstName, lastName, mail, role};
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export const UsersTable = (_props : any) =>{
    let role = _props.role;
    const [users, setUsers] = useState([]);
    useEffect(() => {
        if(role === '2'){
            fetch('/api/allusers').then(res => res.json()).then(data => {
                setUsers(data["users"]);
            });
        }
    }, [role]);
    const rows : any = [];
    for (var i = 0; i < users.length; i++){
        rows.push(
            createData(
                i,
                users[i][0],
                users[i][1],
                users[i][2],
                users[i][3]
            )
        );
    }

  // notification
  const [changed, setChanged] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const handleClickChanged = () => {
    setChanged(true);
  };

  const handleCloseChanged = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setChanged(false);
  };

  const handleClickDeleted = () => {
    setDeleted(true);
  };

  const handleCloseDeleted = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setDeleted(false);
  };

  const ChangeRole = (user: string,role: string) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "user": user, "role": role })
    };
    fetch('/api/changeid', requestOptions).then((response) => response.json()
    .then((user) => {
      if(user.error === 'none'){
        handleClickChanged();
      }
    }));
  }

  const deleteUser = (user: string) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "user": user})
    };
    fetch('/api/deleteuser', requestOptions).then((response) => response.json()
    .then((user) => {
      if(user.error === 'none'){
        handleClickDeleted();
      }
    }));
  }

  const loginAs = (user: string) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "user": user})
    };
    fetch('/api/loginasotheruser', requestOptions).then((response) => response.json()
    .then((user) => {
      if(user.error === ''){
        window.location.href ="/myaccounts";
      }
    }));
  }

  const getRoleAsName = (roleId: number) => {
    if(roleId === 1){
      return 'Dedicated AM';
    }
    else if(roleId === 2){
      return 'Admin';
    }
    else if(roleId === 3){
      return 'Support AM';
    }
    else if(roleId === 4){
      return 'Solutions Consultant';
    }
    else if(roleId === 5){
      return 'CSM';
    }
    else if(roleId === 6){
      return 'TAS';
    }
    else if(roleId === 7){
      return 'Implementation';
    }
    else if(roleId === 8){
      return 'Integration';
    }
    else if(roleId === 9){
      return 'Data';
    }
    else if(roleId === 10){
      return 'Sales';
    }
    return "No Role";
  }
  const columns: GridColDef[] = [
    { field: 'firstName', headerName: 'First Name',flex: 1},
    { field: 'lastName', headerName: 'Last Name',flex: 1},
    { field: 'mail', headerName: 'Mail',flex: 1},
    { field: 'role', headerName: 'Role',flex: 1,sortable: true,
      renderCell: (params : any) => {
        return <div>
                  {getRoleAsName(Number(params.row.role))}
              </div>;
    },
    },
    { field: 'action', headerName: 'Change Role',flex: 1, minWidth: 150, sortable: false,
      renderCell: (params : any) => {
          return <div>
                  <ActionMenu
                        items={[
                          { label: 'Admin', onClick: () => {ChangeRole(params.row.mail,'2')} },
                          { label: 'Dedicated', onClick: () => {ChangeRole(params.row.mail,'1')} },
                          { label: 'Support', onClick: () => {ChangeRole(params.row.mail,'3')} },
                          { label: 'SC', onClick: () => {ChangeRole(params.row.mail,'4')} },
                          { label: 'CSM', onClick: () => {ChangeRole(params.row.mail,'5')} },
                          { label: 'TAS', onClick: () => {ChangeRole(params.row.mail,'6')} },
                          { label: 'Implementation', onClick: () => {ChangeRole(params.row.mail,'7')} },
                          { label: 'Integration', onClick: () => {ChangeRole(params.row.mail,'8')} },
                          { label: 'Data', onClick: () => {ChangeRole(params.row.mail,'9')} },
                          { label: 'Sales', onClick: () => {ChangeRole(params.row.mail,'10')} }
                        ]}
                      >
                      {/* The trigger can be anything. We use a button here */}
                        <Button variant="outlined">Change Role</Button>
                    </ActionMenu>
                </div>;
      },
    },
    { field: 'delete', headerName: 'Delete User',flex: 1, minWidth: 150, sortable: false,
      renderCell: (params : any) => {
        return <div>
                  <Button variant="outlined" onClick={() => {deleteUser(params.row.mail)}} >Delete User</Button>
                </div>
      },
    },
    { field: 'loginAs', headerName: 'Login As',flex: 1, minWidth: 100, sortable: false,
      renderCell: (params : any) => {
        return <div>
                  <Button variant="outlined" onClick={() => {loginAs(params.row.mail)}} >Login</Button>
                </div>
      },
    },
  ];
  
    return (
        <div style={{ height: 600, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
          <Snackbar
            open={changed}
            autoHideDuration={6000}
            onClose={handleCloseChanged}
            message="Role Changed"
          />
          <Snackbar
            open={deleted}
            autoHideDuration={6000}
            onClose={handleCloseDeleted}
            message="User Deleted"
          />
        </div>
    );
}