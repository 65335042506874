import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

function createData(
    id: number,
    domain: string,
  ) {
    return { id,domain};
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export const PartnerDomainBlocklistTable = (_props : any) => {
    var values = _props.values;
    const rows : any = [];
    for (var i = 0; i < values.length; i++){
        rows.push(
            createData(
              i,
              values[i],
            )
        );
    }

    const columns: GridColDef[] = [
        { field: 'domain', headerName: 'Domain Name',flex: 1},
    ];

    return (
        <div style={{ height: 650, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}