import { formatDate } from "../../../shared/components/formatDate/FormatDate";
import { formatVersion } from "../../../shared/components/formatVersion/FormatVersion";
import { ImplementationModal } from "./ImplementationModal";
import { AdRevenueCheckAndroid, AdvertisingIdCheckAndroid, CallbackAndPartnerCheckAndroid, CoppaCheckAndroid, DeeplinkCheckAndroid, EventBufferingCheckAndroid, GoogleReferralApiCheckAndroid, InstallSessionCheckAndroid, KidsCheckAndroid, PushTokenCheckAndroid, SdkEventNonRevenueCheckAndroid, SdkEventRevenueCheckAndroid, SdkSignatureCheckAndroid, ThirdPartyCheckAndroid } from "./StatusCheckAndroid";
import { StatusCellAndroid } from "./StatusCell";
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

const columns: GridColDef[] = [
    { field: 'sdkComponent', headerName: 'SDK Component',flex: 1},
    { field: 'lastTimeChecked', headerName: 'Last Time Checked',flex: 1, maxWidth: 150,},
    { field: 'version', headerName: 'Version',flex: 1, maxWidth: 100,},
    { field: 'status', headerName: 'Status',flex: 1, 
        renderCell: (params : any) => {
            return <StatusCellAndroid status={params.row.status} />
        },
    },      
    { field: 'data', headerName: 'Check Data',flex: 1, maxWidth: 150,
        renderCell: (params : any) => {
            return <ImplementationModal data={params.row.data} type={params.row.type} />
        },
    }
];

function createData(
    id: string,
    sdkComponent: string,
    lastTimeChecked: string,
    status: number,
    data: any,
    type: string,
    version: string
  ) {
    return { id, sdkComponent, lastTimeChecked, status, data, type, version};
}

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
}

export const IntegrationOverviewAndroid = (_props: any) =>{
    const data = _props.data; 
    const newestAppVersionAndroid = _props.newestAppVersionAndroid;
    const AdRevenueCheck = AdRevenueCheckAndroid(data.lastRunTime,data.adRevenue);
    const AdvertisingIdCheck = AdvertisingIdCheckAndroid(data.lastRunTime,data.advertisingIdAndroid,newestAppVersionAndroid);
    const CallbackAndPartnerCheck = CallbackAndPartnerCheckAndroid(data.lastRunTime,data.callbackPartnerParams,newestAppVersionAndroid);
    const CoppaCheck = CoppaCheckAndroid(data.lastRunTime,data.coppa,newestAppVersionAndroid);
    const DeeplinkCheck = DeeplinkCheckAndroid(data.lastRunTime,data.deepLink,newestAppVersionAndroid);
    const EventBufferingCheck = EventBufferingCheckAndroid(data.lastRunTime,data.eventBuffering,newestAppVersionAndroid);
    const GoogleReferralApiCheck = GoogleReferralApiCheckAndroid(data.lastRunTime,data.googleReferralApi,newestAppVersionAndroid);
    const InstallSessionCheck = InstallSessionCheckAndroid(data.lastRunTime,data.installTracking,newestAppVersionAndroid);
    const KidsCheck = KidsCheckAndroid(data.lastRunTime,data.playStoreKids,newestAppVersionAndroid);
    const PushTokenCheck = PushTokenCheckAndroid(data.lastRunTime,data.pushTokens,newestAppVersionAndroid);
    const SdkEventNonRevenueCheck = SdkEventNonRevenueCheckAndroid(data.lastRunTime,data.sdkEventTrackingNonRevenue,newestAppVersionAndroid);
    const SdkEventRevenueCheck = SdkEventRevenueCheckAndroid(data.lastRunTime,data.sdkEventTrackingRevenue,newestAppVersionAndroid);
    const SdkSignatureCheck = SdkSignatureCheckAndroid(data.lastRunTime,data.sdkSignature,newestAppVersionAndroid);
    const ThirdPartyCheck = ThirdPartyCheckAndroid(data.lastRunTime,data.thirdPartySharing,newestAppVersionAndroid);
    const rows : any = [];
    rows.push(
        createData(
            "install-session-tracking",
            "Install/Session tracking",
            formatDate(data.lastRunTime),
            InstallSessionCheck,
            data.installTracking,
            "installTracking",
            formatVersion(data.installTracking)
        )
    )

    rows.push(
        createData(
            "advertising-id-tracking",
            "Advertising ID collection/tracking",
            formatDate(data.lastRunTime),
            AdvertisingIdCheck,
            data.advertisingIdAndroid,
            "advertisingId",
            formatVersion(data.advertisingIdAndroid)
        )
    );

    rows.push(
        createData(
            "push-tokens",
            "Push tokens",
            formatDate(data.lastRunTime),
            PushTokenCheck,
            data.pushTokens,
            "pushTokens",
            formatVersion(data.pushTokens)
        )
    );

    rows.push(
        createData(
            "sdk-signature",
            "SDK Signature",
            formatDate(data.lastRunTime),
            SdkSignatureCheck,
            data.sdkSignature,
            "sdkSignature",
            formatVersion(data.sdkSignature)
        )
    );

    rows.push(
        createData(
            "sdk-event-tracking-non-revenue",
            "SDK Event tracking (non revenue)",
            formatDate(data.lastRunTime),
            SdkEventNonRevenueCheck,
            data.sdkEventTrackingNonRevenue,
            "sdkEventTrackingNonRevenue",
            formatVersion(data.sdkEventTrackingNonRevenue)
        )
    );

    rows.push(
        createData(
            "sdk-event-tracking-revenue",
            "SDK Event tracking (revenue)",
            formatDate(data.lastRunTime),
            SdkEventRevenueCheck,
            data.sdkEventTrackingRevenue,
            "sdkEventTrackingRevenue",
            formatVersion(data.sdkEventTrackingRevenue)
        )
    );

    rows.push(
        createData(
            "event-buffering",
            "Event Buffering",
            formatDate(data.lastRunTime),
            EventBufferingCheck,
            data.eventBuffering,
            "eventBuffering",
            formatVersion(data.eventBuffering)
        )
    );

    rows.push(
        createData(
            "callback-partner-params",
            "Callback and partner Params",
            formatDate(data.lastRunTime),
            CallbackAndPartnerCheck,
            data.callbackPartnerParams,
            "callbackPartnerParams",
            formatVersion(data.callbackPartnerParams)
        )
    );

    rows.push(
        createData(
            "google-referral-api",
            "Google Referral API",
            formatDate(data.lastRunTime),
            GoogleReferralApiCheck,
            data.googleReferralApi,
            "googleReferralApi",
            formatVersion(data.googleReferralApi)
        )
    );

    rows.push(
        createData(
            "ad-revenue",
            "Ad Revenue",
            formatDate(data.lastRunTime),
            AdRevenueCheck,
            data.adRevenue,
            "adRevenue",
            formatVersion(data.adRevenue)
        )
    );

    rows.push(
        createData(
            "coppa",
            "COPPA Compliance",
            formatDate(data.lastRunTime),
            CoppaCheck,
            data.coppa,
            "coppa",
            formatVersion(data.coppa)
        )
    );

    rows.push(
        createData(
            "third-party-sharing",
            "3rd Party data sharing",
            formatDate(data.lastRunTime),
            ThirdPartyCheck,
            data.thirdPartySharing,
            "thirdPartySharing",
            formatVersion(data.thirdPartySharing)
        )
    );

    rows.push(
        createData(
            "play-store-kids",
            "Play Store Kids",
            formatDate(data.lastRunTime),
            KidsCheck,
            data.playStoreKids,
            "playStoreKids",
            formatVersion(data.playStoreKids)
        )
    );

    rows.push(
        createData(
            "deeplink",
            "Deeplink",
            formatDate(data.lastRunTime),
            DeeplinkCheck,
            data.deepLink,
            "deeplink",
            formatVersion(data.deepLink)
        )
    );

    return(
            <div style={{ height: 1200 }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}