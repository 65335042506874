import { Box, Button, Grid, TextField } from "@mui/material"
import { useState } from "react"
import { Tile } from "@adjust/components";

export const ClickspamChecker = () => {
    const [callbackUrl,setCallbackUrl] = useState('');
    const [error,setError] = useState('');
    const [datas,setData] = useState({'trackerToken': '',
    'callbackUrl': '',
    'clickSpam': false,
    'reason': 'host blocklisted',
    'host': '',
    'domain': '',
    'campaignLevelTracker': '',
    'networkLevelTracker': ''});
 
    const checkUrl = () => {
        if(callbackUrl !== ''){
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ "callbackUrl": callbackUrl}) 
            };
            fetch('/api/clickspamchecker', requestOptions)
                .then(response => response.json()).then(data => {
                    setData(data);
                    setError('');
            });
        }
        else{
            setError('Callback URL is empty');
        }
    }

    return(
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <div>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <h1>Click Spam Checker</h1>
                            
                        </Grid>
                        <Grid item xs={10}>
                            <TextField
                                id="Textfield"
                                label="Callback URL"
                                multiline
                                rows={5}
                                value={callbackUrl}
                                onChange={(e) => {setCallbackUrl(e.target.value);}}
                                style={{width:'100%',marginBottom:'2%'}}
                            />
                            <Button variant="contained" style={{backgroundColor:'#005ff7'}} onClick={checkUrl}>Run Check</Button>
                            {error !== '' &&
                                <div style={{color:'red'}}>{error}</div>
                            }
                            <br/><br/>
                        </Grid>
                        <Grid item xs={12}>
                            <Tile>
                                <Grid container spacing={0}>
                                    <Grid item xs={3}>Click Spam:</Grid>
                                    <Grid item xs={9}>{datas.clickSpam}</Grid>
                                    {datas.reason !== '' &&
                                    <>
                                        <Grid item xs={3}>Reason:</Grid>
                                        <Grid item xs={9}>{datas.reason}</Grid>
                                    </>   
                                    }
                                    {datas.host !== '' &&
                                    <>
                                        <Grid item xs={3}>Host:</Grid>
                                        <Grid item xs={9}>{datas.host}</Grid>
                                    </>   
                                    }

                                    {datas.domain !== '' &&
                                    <>
                                        <Grid item xs={3}>Domain:</Grid>
                                        <Grid item xs={9}>{datas.domain}</Grid>
                                    </>   
                                    }
                                    {datas.campaignLevelTracker !== '' &&
                                    <>
                                        <Grid item xs={3}>Campaign Level Tracker:</Grid>
                                        <Grid item xs={9}>{datas.campaignLevelTracker} </Grid>
                                    </>   
                                    }
                                    {datas.networkLevelTracker !== '' &&
                                    <>
                                        <Grid item xs={3}>Network Level Tracker:</Grid>
                                        <Grid item xs={9}>{datas.networkLevelTracker}</Grid>
                                    </>   
                                    }
                                </Grid>
                            </Tile>
                        </Grid>
                    </Grid>
                </div>
            </Box>
    )

}