import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { CaseOverviewModal } from './CaseOverviewModal';

function createData(
    id: number,
    amName: string,
    cases: string,
    caseCount: string,
  ) {
    return { id, amName, cases, caseCount };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export const CaseOverviewTable = (_props : any) =>{
    const rows : any = [];
    let caseCount = 0;
    let amCount = 0;
    if(_props.cases.length > 0){
        let data = _props.cases[0];
        caseCount = _props.cases[1];
        amCount = _props.cases[2];
        for (var i = 0; i < data.length; i++){
            rows.push(
                createData(
                    i,
                    data[i][0],
                    data[i][1],
                    data[i][2]
                )
            );
        }
    }

    const columns: GridColDef[] = [
        { field: 'amName', headerName: 'Am Name',flex: 1, minWidth: 150},
        { field: 'caseCount', headerName: 'Case Count',flex: 1, minWidth: 150},
        { field: 'cases', headerName: 'Cases',flex: 1, minWidth: 150,
            renderCell: (params : any) => {
                return <CaseOverviewModal data={params.row.cases}/>; 
            },
        },
      ];
      
    return (
        <div style={{ height: 450, width: '100%', marginTop:'3%' }}>
            <h3>{_props.title}</h3>
            <p>AM Count: {amCount}</p>
            <p>Case Count: {caseCount}</p>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}