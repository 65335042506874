import React, { useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { AuthContext } from '../../app/store/AuthContext';
import { useGoogleLogout } from 'react-google-login'

const onLogoutSuccess = () => {
  console.log("logout success");
}

const onFailure = () => {
  console.log("logout failure");
}

export const Logout = (_props: any) => {
  const navigate = useNavigate();
  const value = useContext(AuthContext);
  const clientId = _props.clientId;
  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
    onFailure,
  });

  useEffect(() => {
    fetch('/api/logout').then(res => res.json()).then(data => {
      if(data.login === false){
        value?.setLogout();
        navigate("/login");
      }
    });
  });

  return (
    <header/>
  );
}
