import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { MetaPartnerApiModal } from './MetaPartnerApiModal';

function createData(
    id: number,
    dateStart: string,
    dateStop: string,
    objective: string,
    accountCurrency: string,
    attributionSetting: string,
    campaignId: string,
    campaignName: string,
    spend: string,
  ) {
    return { id, dateStart, dateStop, objective, accountCurrency, attributionSetting, campaignId, campaignName, spend };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export const MetaPartnerApiTable = (_props : any) =>{
    const rows : any = [];
    
    const data = _props.data;
    if(data !== undefined){
        for (var i = 0; i < data.length; i++){
            rows.push(
                createData(
                    i,
                    data[i]["date_start"],
                    data[i]["date_stop"],
                    data[i]["objective"],
                    data[i]["account_currency"],
                    data[i]["attribution_setting"],
                    data[i]["campaign_id"],
                    data[i]["campaign_name"],
                    data[i]["spend"],
                )
            );
        }
    }

    const columns: GridColDef[] = [
        { field: 'actions', headerName: 'Actions',flex: 1,maxWidth:250,minWidth:250,
        renderCell: (params : any) => {
                return <MetaPartnerApiModal campaignId={params.row.campaignId} data={data}></MetaPartnerApiModal>
        },
      },
        { field: 'campaignId', headerName: 'Campaign Id',flex: 1},
        { field: 'campaignName', headerName: 'Campaign Name',flex: 1},
        { field: 'id', headerName: '',flex: 1, maxWidth:1},
        { field: 'dateStart', headerName: 'Start Date',flex: 1, maxWidth:200},
        { field: 'dateStop', headerName:'Stop Date',flex: 1,  maxWidth:200},
        { field: 'spend', headerName: 'Spend',flex: 1},
        { field: 'accountCurrency', headerName: 'Account Currency',flex: 1},
        { field: 'attributionSetting', headerName: 'Attribution Setting',flex: 1},
      ];

    return (
        <div style={{ height: 800, width: '183vh' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                disableRowSelectionOnClick
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}