import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

function createData(
    id: number,
    date: string,
    accountName: string,
    accountId: string,
    salesforceId: string,
    appName: string,
    appToken: string,
    trackerName: Number,
    trackerToken: string,
    accountOwner: string,
    accountManager: string,
    salesManager: string,
    salesEngineer: string,
    clicks: number,
    attributions:  number,
    conversionRate: string,
    lowQualityWindow: string,
    allowed: string,
  ) {
    return { id, date, accountName, accountId, salesforceId, appName, appToken, trackerName, trackerToken,  accountOwner, accountManager, salesManager, salesEngineer, clicks, attributions, conversionRate, lowQualityWindow, allowed };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export default function ClickSpamHistoryTable(_props : any) {
    const rows : any = [];

    for (var i = 0; i < _props.data.length; i++){
        rows.push(
            createData(
                i,
                formatDate(_props.data[i]["date"]),
                _props.data[i]["accountName"],
                _props.data[i]["accountId"],
                _props.data[i]["salesforceId"],
                _props.data[i]["appName"],
                _props.data[i]["appToken"],
                _props.data[i]["trackerName"],
                _props.data[i]["trackerToken"],
                _props.data[i]["accountOwner"],
                _props.data[i]["accountManager"],
                _props.data[i]["salesManager"],
                _props.data[i]["salesEngineer"],
                _props.data[i]["clicks"],
                _props.data[i]["attributions"],
                _props.data[i]["conversionRate"],
                _props.data[i]["lowQualityWindow"],
                _props.data[i]["allowed"]
            )
        );
    }

    const columns: GridColDef[] = [
        { field: 'date', headerName: 'Created At',flex: 1, minWidth: 150},
        { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 150},
        { field: 'accountId', headerName: 'Account Id',flex: 1, minWidth: 150},
        { field: 'appName', headerName: 'App Name',flex: 1, minWidth: 150},
        { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 150},
        { field: 'trackerName', headerName: 'Tracker Name',flex: 1, minWidth: 150},
        { field: 'trackerToken', headerName: 'Tracker Token',flex: 1, minWidth: 150},
        { field: 'attributions', headerName: 'Attributions',flex: 1, minWidth: 150},
        { field: 'clicks', headerName: 'Clicks',flex: 1, minWidth: 150},
        { field: 'conversionRate', headerName: 'Conversion Rate',flex: 1, minWidth: 150},
        { field: 'accountOwner', headerName: 'Account Owner',flex: 1, minWidth: 150},
        { field: 'accountManager', headerName: 'Account Manager',flex: 1, minWidth: 150},
        { field: 'salesManager', headerName: 'Sales Manager',flex: 1, minWidth: 150},
        { field: 'salesEngineer', headerName: 'Sales Engineer',flex: 1, minWidth: 150},
        { field: 'lowQualityWindow', headerName: 'Low Quality Window',flex: 1, minWidth: 150},
        { field: 'allowed', headerName: 'Allowed',flex: 1, minWidth: 150},
      ];

    return (
        <div style={{ height: 700, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}