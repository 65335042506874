import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

const columns: GridColDef[] = [
  { field: 'sigNumber', headerName: 'Signature Number',flex: 1},
  { field: 'active', headerName: 'Is Active',flex: 1},
  { field: 'createdAt', headerName: 'Created At',flex: 1},
  { field: 'sigVersion', headerName: 'Signature Version',flex: 1},
  { field: 'platform', headerName: 'Platform',flex: 1},
];

function createData(
    id: number,
    sigNumber: number,
    active: boolean,
    createdAt: string,
    sigVersion: string,
    platform: string
  ) {
    return { id,sigNumber, active, createdAt, sigVersion, platform };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  function checkAlgorithm (algorithm: string){
    if(algorithm === 'adj4'){
      return '3';
    }
    return algorithm;
  }
export const MobileAppSecretTable = (_props : any) => {
    console.log(_props)
    const rows : any = [];
    const secrets = _props.secrets;
    if(secrets["secrets"] !== undefined){
      for (var i = 0; i < secrets["secrets"].length; i++){
          rows.push(
              createData(
                  i,
                  secrets["secrets"][i].label,
                  secrets["secrets"][i].active,
                  formatDate(secrets["secrets"][i].created_at),
                  checkAlgorithm(secrets["secrets"][i].algorithm),
                  secrets["secrets"][i].platform
              )
          );
      }
  }
    return (
        <div style={{ height: 800, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}
