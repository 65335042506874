import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import {NumericFormat} from 'react-number-format';
import { formatDate } from '../../../../shared/components/formatDate/FormatDate';

const columns: GridColDef[] = [
  { field: 'date', headerName: 'Date',flex: 1},
  { field: 'san_engagements', headerName: 'San Engagements',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.san_engagements} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'all_engagements', headerName: 'All Engagements',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.all_engagements} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'other_clicks', headerName: 'Other Clicks',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.other_clicks} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'san_clicks', headerName: 'San Clicks',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.san_clicks} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'other_network_clicks', headerName: 'Other Network Clicks',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.other_network_clicks} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'events', headerName: 'Events',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.events} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'san_network_clicks', headerName: 'San Network Clicks',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.san_network_clicks} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'other_impressions', headerName: 'Other Impressions',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.other_impressions} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'revenue_events', headerName: 'Revenue Events',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.revenue_events} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'san_impressions', headerName: 'San Impressions',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.san_impressions} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'sessions', headerName: 'Sessions',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.sessions} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'other_network_impressions', headerName: 'Other Network Impressions',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.other_network_impressions} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'other_installs', headerName: 'Sessions',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.other_installs} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'san_installs', headerName: 'Sessions',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.san_installs} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'other_network_installs', headerName: 'Sessions',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.other_network_installs} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'san_network_installs', headerName: 'San Network Installs',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.san_network_installs} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'uninstalls', headerName: 'Uninstalls',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.uninstalls} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'reinstalls', headerName: 'Reinstalls',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.reinstalls} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'reattributions', headerName: 'Reattributions',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.reattributions} displayType={'text'} thousandSeparator={true}/>
  }},
  
];

function createData(

    id: number,
    date: string,
    other_clicks: number,
    san_clicks: number,
    other_network_clicks: number,
    events: number,
    san_network_clicks: number,
    other_impressions: number,
    revenue_events: number,
    san_impressions: number,
    sessions: number,
    other_network_impressions: number,
    paid_installs: number,
    san_network_impressions: number,
    other_installs: number,
    san_installs: number,
    other_network_installs: number,
    san_network_installs: number,
    uninstalls: number,
    reinstalls: number,
    reattributions: number,
    san_engagements: number,
    all_engagements: number
  ) {
    return { id,date, other_clicks, san_clicks, other_network_clicks, events, san_network_clicks, other_impressions, revenue_events, san_impressions,
    sessions, other_network_impressions,paid_installs,san_network_impressions,other_installs,san_installs,other_network_installs,san_network_installs,uninstalls,reinstalls,reattributions,san_engagements,all_engagements};
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export default function DailyDataPointsTable(_props : any) {
    const datas = _props.dps;
    const rows : any = [];
    if(datas !== undefined){
        for (var i = 0; i < datas.length; i++){
            rows.push(
                createData(
                    datas[i][0],
                    formatDate(datas[i][2]), 
                    datas[i][3], 
                    datas[i][4],
                    datas[i][5],
                    datas[i][6],
                    datas[i][7],
                    datas[i][8],
                    datas[i][9],
                    datas[i][10],
                    datas[i][11],
                    datas[i][12],
                    datas[i][13],
                    datas[i][14],
                    datas[i][15],
                    datas[i][16],
                    datas[i][17],
                    datas[i][18],
                    datas[i][19],
                    datas[i][20],
                    datas[i][21],
                    datas[i][22],
                    datas[i][23],
                )
            );
        }
    }
    return (
        <div style={{ height: 300, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}
