import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
}

const columns: GridColDef[] = [
    { field: 'eventId', headerName: 'Event Id',flex: 1, minWidth: 100},
    { field: 'name', headerName: 'Name',flex: 1, minWidth: 350},
    { field: 'createdAt', headerName: 'Created At',flex: 1, minWidth: 50},
    { field: 'currency', headerName: 'Currency',flex: 1, minWidth: 50},
    { field: 'unique', headerName: 'Unique',flex: 1, minWidth: 50},
  ];

function createData(
    id: number,
    eventId: string,
    name: string,
    createdAt: string,
    currency: string,
    unique: string,
  ){
    return { id, eventId, name, createdAt, currency, unique };
  }

export const AppEventsTable = (props : any) =>{
    var events = props.events;
    const data : any = [];
    if(events !== undefined){
        for (var i = 0; i < events.length; i++){
            data.push(
                createData(
                    i,
                    events[i][0],
                    events[i][1],
                    formatDate(events[i][2]),
                    events[i][3],
                    events[i][4],
                )
            );
        }
    }
    return (
        <div style={{height: 600, width: '100%'}}>
                <DataGrid 
                    rows={data} 
                    columns={columns}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                /> 
        </div>
    );
}