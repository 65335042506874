import { GridColDef } from "@mui/x-data-grid";


function createDataSummary(
    id: number,
    count: string,
    tag: string,
    environment: string,
    platform: string,
    os: string,
    message: string,
    installsPercent: number
  ) {
    return { id, count, tag, environment, platform, os, message, installsPercent};
}

export const SummaryDefinition = (_props:any) => {
    const data = _props;
    const rows : any = [];
    if(data !== undefined && data.length > 0){
        let installs = 0;
        for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['count']);
        }
        for (i = 0; i < data.length; i++){
            let installsPercent = Math.round(parseInt(data[i]['count']) / installs * 100 * 10) / 10;
            rows.push(
                createDataSummary(
                    i,
                    data[i]['count'],
                    data[i]['tag'],
                    data[i]['environment'],
                    data[i]['platform'],
                    data[i]['os'],
                    data[i]['message'],
                    installsPercent
                )
            );
        }
    }
    const columns: GridColDef[] = [
        { field: 'count', headerName: 'Count',flex: 1},
        { field: 'installsPercent', headerName: 'Installs in %',flex: 1, type: "number",align:'left',headerAlign: 'left'},
        { field: 'tag', headerName: 'Tag',flex: 1},
        { field: 'environment', headerName: 'Environment',flex: 1},
        { field: 'os', headerName: 'OS',flex: 1},
        { field: 'platform', headerName: 'Platform',flex: 1},
        { field: 'message', headerName: 'Message',flex: 1},
    ];

    return({
        columns,
        rows
    });
}

function createDataInstallTracking(
    id: number,
    appVersion: string,
    environment: string,
    host: string,
    message: string,
    numberOfInstalls: number,
    os: string,
    sdkVersion: string,
    installsPercent: number
  ) {
    return { id, appVersion, environment, host,  message, numberOfInstalls, os, sdkVersion, installsPercent};
}

export const InstallTrackingDefinition = (_props:any) => {
    const data = _props;
    const rows : any = [];
    if(data !== undefined && data.length > 0){
        let installs = 0;
        for (var i = 0; i < data.length; i++){
            let environment = data[i]['environment'];
            if(environment === 'production'){
                installs = installs + parseInt(data[i]['numOfInstalls']);
            }
        }
        for (i = 0; i < data.length; i++){
            let installsPercent = Math.round(parseInt(data[i]['numOfInstalls']) / installs * 100 * 10) / 10;
            if(installsPercent >= 1){
                rows.push(
                    createDataInstallTracking(
                        i,
                        data[i]['appVersion'],
                        data[i]['environment'],
                        data[i]['host'],
                        data[i]['message'],
                        data[i]['numOfInstalls'],
                        data[i]['os'],
                        data[i]['sdkVersion'],
                        installsPercent
                    )
                );
            }
        }
    }
    const columns: GridColDef[] = [
        { field: 'appVersion', headerName: 'App Version',flex: 1},
        { field: 'environment', headerName: 'Environment',flex: 1},
        { field: 'host', headerName: 'Host',flex: 1},
        { field: 'message', headerName: 'Message',flex: 1},
        { field: 'numberOfInstalls', headerName: 'Installs',flex: 1, type: "number",align:'left',headerAlign: 'left'},
        { field: 'installsPercent', headerName: 'Installs in %',flex: 1, type: "number",align:'left',headerAlign: 'left'},
        { field: 'os', headerName: 'OS',flex: 1},
        { field: 'sdkVersion', headerName: 'SDK Version',flex: 1},
    ];

    return({
        columns,
        rows
    });
}

function createDataAdvertisingId(
    id: number,
    deviceId: string,
    appVersion: string,
    numOfOccurences: string,
    installsPercent: number
  ) {
    return { id, deviceId, appVersion,  numOfOccurences, installsPercent };
}

export const AdvertisingIdDefinition = (_props:any) => {
    const data = _props;
    const rows : any = [];
    if(data !== undefined && data.length > 0){
        let installs = 0;
        for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['numOfOccurences']);
        }
        for (i = 0; i < data.length; i++){
            let installsPercent = Math.round(parseInt(data[i]['numOfOccurences']) / installs * 100 * 10) / 10;
            rows.push(
                createDataAdvertisingId(
                    i,
                    data[i]['deviceId'],
                    data[i]['appVersion'],
                    data[i]['numOfOccurences'],
                    installsPercent
                )
            );
        }
    }
    const columns: GridColDef[] = [
        { field: 'numOfOccurences', headerName: 'Num of Occurences',flex: 1},
        { field: 'installsPercent', headerName: 'Occurences in %',flex: 1, type: "number",align:'left',headerAlign: 'left'},
        { field: 'appVersion', headerName: 'App Version',flex: 1},
        { field: 'deviceId', headerName: 'Device Id',flex: 1}
    ];

    return({
        columns,
        rows
    });
}

function createDataPushTokens(
    id: number,
    appVersion: string,
    numOfInstalls: string,
    pushToken: string,
    installsPercent: number
  ) {
    return { id, appVersion, numOfInstalls, pushToken, installsPercent };
}

export const PushTokensDefinition = (_props:any) => {
    const data = _props;
    const rows : any = [];
    if(data !== undefined && data.length > 0){
        let installs = 0;
        for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['numOfInstalls']);
        }
        for (i = 0; i < data.length; i++){
            let installsPercent = Math.round(parseInt(data[i]['numOfInstalls']) / installs * 100 * 10) / 10;
            rows.push(
                createDataPushTokens(
                    i,
                    data[i]['appVersion'],
                    data[i]['numOfInstalls'],
                    data[i]['pushToken'],
                    installsPercent
                )
            );
        }
    }
    const columns: GridColDef[] = [
        { field: 'appVersion', headerName: 'App Version',flex: 1},
        { field: 'numOfInstalls', headerName: 'Num of Installs',flex: 1},
        { field: 'installsPercent', headerName: 'Installs in %',flex: 1, type: "number",align:'left',headerAlign: 'left'},
        { field: 'pushToken', headerName: 'Push Token',flex: 1}
    ];

    return({
        columns,
        rows
    });
}

function createDataSdkSignature(
    id: number,
    appVersion: string,
    count: string,
    environment: string,
    os: string,
    sdkSignatureAlgorithm: string,
    sdkSignatureValid: string,
    sdkSignatureVersion: string,
    installsPercent: number
  ) {
    return { id, appVersion, count, environment, os , sdkSignatureAlgorithm, sdkSignatureValid, sdkSignatureVersion, installsPercent };
}

export const SdkSignatureDefinition = (_props:any) => {
    const data = _props;
    const rows : any = [];
    if(data !== undefined && data.length > 0){
        let installs = 0;
        for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['count']);
        }
        for (i = 0; i < data.length; i++){
            let installsPercent = Math.round(parseInt(data[i]['count']) / installs * 100 * 10) / 10;
            let sdkSignatureAlgorithm = data[i]['sdkSignatureAlgorithm'];
            if(sdkSignatureAlgorithm === 'adj2'){
                sdkSignatureAlgorithm = 'sdk signature v2';
            }
            else if(sdkSignatureAlgorithm === 'adj3'){
                sdkSignatureAlgorithm = 'sdk signature v3';
            }
            else if(sdkSignatureAlgorithm === 'sha256'){
                sdkSignatureAlgorithm = 'sdk signature v1';
            }
            rows.push(
                createDataSdkSignature(
                    i,
                    data[i]['appVersion'],
                    data[i]['count'],
                    data[i]['environment'],
                    data[i]['os'],
                    sdkSignatureAlgorithm,
                    data[i]['sdkSignatureValid'],
                    data[i]['sdkSignatureVersion'],
                    installsPercent
                )
            );
        }
    }
    const columns: GridColDef[] = [
        { field: 'appVersion', headerName: 'App Version',flex: 1},
        { field: 'count', headerName: 'Count',flex: 1},
        { field: 'installsPercent', headerName: 'Count in %',flex: 1, type: "number",align:'left',headerAlign: 'left'},
        { field: 'environment', headerName: 'Environment',flex: 1},
        { field: 'os', headerName: 'Environment',flex: 1},
        { field: 'sdkSignatureAlgorithm', headerName: 'SDK Signature Algorithm',flex: 1},
        { field: 'sdkSignatureValid', headerName: 'SDK Signature Valid',flex: 1},
        { field: 'sdkSignatureVersion', headerName: 'SDK Signature Version',flex: 1},
    ];

    return({
        columns,
        rows
    });
}

function createDataWebAttribution(
    id: number,
    count: string,
    platform: string,
    environment: string,
    message: string,
    topic: string,
    installsPercent: number
  ) {
    return { id, count, platform, environment , message, topic, installsPercent };
}

export const WebAttributionDefinition = (_props:any) => {
    const data = _props;
    const rows : any = [];
    if(data !== undefined && data.length > 0){
        let installs = 0;
        for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['count']);
        }
        for (i = 0; i < data.length; i++){
            let installsPercent = Math.round(parseInt(data[i]['count']) / installs * 100 * 10) / 10;
            rows.push(
                createDataWebAttribution(
                    i,
                    data[i]['count'],
                    data[i]['platform'],
                    data[i]['environment'],
                    data[i]['message'],
                    data[i]['topic'],
                    installsPercent
                )
            );
        }
    }
    const columns: GridColDef[] = [
        { field: 'count', headerName: 'Count',flex: 1},
        { field: 'installsPercent', headerName: 'Count in %',flex: 1, type: "number",align:'left',headerAlign: 'left'},
        { field: 'environment', headerName: 'Environment',flex: 1},
        { field: 'platform', headerName: 'Platform',flex: 1},
        { field: 'message', headerName: 'Message',flex: 1},
        { field: 'topic', headerName: 'Topic',flex: 1},
    ];

    return({
        columns,
        rows
    });
}

function createDataSdkEventTrackingNonRevenue(
    id: number,
    appToken: string,
    appVersion: string,
    clientSdk: string,
    count: string,
    environment: string,
    eventName: string,
    eventToken: string,
    host: string,
    message: string,
    os: string,
    installsPercent: number
  ) {
    return { id, appToken, appVersion, clientSdk , count, environment, eventName, eventToken, host, message, os, installsPercent };
}

export const SdkEventTrackingDefinitionNonRevenue = (_props:any) => {
    const data = _props;
    const rows : any = [];
    if(data !== undefined && data.length > 0){
        let installs = 0;
        for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['count']);
        }
        for (i = 0; i < data.length; i++){
            let installsPercent = Math.round(parseInt(data[i]['count']) / installs * 100 * 10) / 10;
            rows.push(
                createDataSdkEventTrackingNonRevenue(
                    i,
                    data[i]['appToken'],
                    data[i]['appVersion'],
                    data[i]['clientSdk'],
                    data[i]['count'],
                    data[i]['environment'],
                    data[i]['eventName'],
                    data[i]['eventToken'],
                    data[i]['host'],
                    data[i]['message'],
                    data[i]['os'],
                    installsPercent
                )
            );
        }
    }
    const columns: GridColDef[] = [
        { field: 'count', headerName: 'Count',flex: 1},
        { field: 'installsPercent', headerName: 'Count in %',flex: 1, type: "number",align:'left',headerAlign: 'left'},
        { field: 'appToken', headerName: 'App Token',flex: 1},
        { field: 'appVersion', headerName: 'App Version',flex: 1},
        { field: 'clientSdk', headerName: 'Client SDK',flex: 1},
        { field: 'environment', headerName: 'Environment',flex: 1},
        { field: 'eventName', headerName: 'Event Name',flex: 1},
        { field: 'eventToken', headerName: 'Event Token',flex: 1},
        { field: 'host', headerName: 'Host',flex: 1},
        { field: 'message', headerName: 'Message',flex: 1},
        { field: 'os', headerName: 'OS',flex: 1},
    ];

    return({
        columns,
        rows
    });
}

function createDataSdkEventTrackingRevenue(
    id: number,
    appToken: string,
    appVersion: string,
    clientSdk: string,
    count: string,
    currency: string,
    environment: string,
    eventName: string,
    eventToken: string,
    host: string,
    message: string,
    os: string,
    revenue: string,
    installsPercent: number
  ) {
    return { id, appToken, appVersion, clientSdk , count, currency, environment, eventName, eventToken, host, message, os, revenue, installsPercent };
}

export const SdkEventTrackingDefinitionRevenue = (_props:any) => {
    const data = _props;
    const rows : any = [];
    if(data !== undefined && data.length > 0){
        let installs = 0;
        for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['count']);
        }
        for (i = 0; i < data.length; i++){
            let installsPercent = Math.round(parseInt(data[i]['count']) / installs * 100 * 10) / 10;
            let revenue = data[i]['revenue'];
            let currency = data[i]['currency'];
            if(revenue !== "null" && currency !== "null"){
                rows.push(
                    createDataSdkEventTrackingRevenue(
                        i,
                        data[i]['appToken'],
                        data[i]['appVersion'],
                        data[i]['clientSdk'],
                        data[i]['count'],
                        data[i]['currency'],
                        data[i]['environment'],
                        data[i]['eventName'],
                        data[i]['eventToken'],
                        data[i]['host'],
                        data[i]['message'],
                        data[i]['os'],
                        data[i]['revenue'],
                        installsPercent
                    )
                );
            }
        }
    }
    const columns: GridColDef[] = [
        { field: 'count', headerName: 'Count',flex: 1},
        { field: 'installsPercent', headerName: 'Count in %',flex: 1, type: "number",align:'left',headerAlign: 'left'},
        { field: 'appToken', headerName: 'App Token',flex: 1},
        { field: 'appVersion', headerName: 'App Version',flex: 1},
        { field: 'clientSdk', headerName: 'Client SDK',flex: 1},
        { field: 'currency', headerName: 'Currency',flex: 1},
        { field: 'environment', headerName: 'Environment',flex: 1},
        { field: 'eventName', headerName: 'Event Name',flex: 1},
        { field: 'eventToken', headerName: 'Event Token',flex: 1},
        { field: 'host', headerName: 'Host',flex: 1},
        { field: 'message', headerName: 'Message',flex: 1},
        { field: 'os', headerName: 'OS',flex: 1},
        { field: 'revenue', headerName: 'Revenue',flex: 1},
    ];

    return({
        columns,
        rows
    });
}

function createDataS2sEventTracking(
    id: number,
    count: string,
    appToken: string,
    currency: string,
    environment: string,
    eventName: string,
    eventToken: string,
    host: string,
    message: string,
    revenue: string,
    s2s: string,
    installsPercent: number
  ) {
    return { id, count, appToken, currency, environment, eventName, eventToken, host, message, revenue, s2s, installsPercent };
}

export const S2sEventTrackingDefinition = (_props:any) => {
    const data = _props;
    const rows : any = [];
    if(data !== undefined && data.length > 0){
        let installs = 0;
        for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['count']);
        }
        for (i = 0; i < data.length; i++){
            let installsPercent = Math.round(parseInt(data[i]['count']) / installs * 100 * 10) / 10;
            rows.push(
                createDataS2sEventTracking(
                    i,
                    data[i]['count'],
                    data[i]['appToken'],
                    data[i]['currency'],
                    data[i]['environment'],
                    data[i]['eventName'],
                    data[i]['eventToken'],
                    data[i]['host'],
                    data[i]['message'],
                    data[i]['revenue'],
                    data[i]['s2s'],
                    installsPercent
                )
            );
        }
    }
    const columns: GridColDef[] = [
        { field: 'count', headerName: 'Count',flex: 1},
        { field: 'installsPercent', headerName: 'Count in %',flex: 1, type: "number",align:'left',headerAlign: 'left'},
        { field: 'appToken', headerName: 'App Token',flex: 1},
        { field: 'environment', headerName: 'Environment',flex: 1},
        { field: 'eventName', headerName: 'Event Name',flex: 1},
        { field: 'eventToken', headerName: 'Event Token',flex: 1},
        { field: 'host', headerName: 'Host',flex: 1},
        { field: 'message', headerName: 'Message',flex: 1},
        { field: 'revenue', headerName: 'Revenue',flex: 1},
        { field: 'currency', headerName: 'Currency',flex: 1},
        { field: 's2s', headerName: 'S2S',flex: 1},
    ];

    return({
        columns,
        rows
    });
}

function createDataS2sErrorReport(
    id: number,
    topic: string,
    message: string,
    error: string,
  ) {
    return { id, topic, message, error};
}

export const S2sErrorReportDefinition = (_props:any) => {
    const data = _props;
    const rows : any = [];
    if(data !== undefined && data.length > 0){
        for (var i = 0; i < data.length; i++){
            rows.push(
                createDataS2sErrorReport(
                    i,
                    data[i]['topic'],
                    data[i]['message'],
                    data[i]['error'],
                )
            );
        }
    }
    const columns: GridColDef[] = [
        { field: 'topic', headerName: 'Topic',flex: 1},
        { field: 'message', headerName: 'Message',flex: 1},
        { field: 'error', headerName: 'Error',flex: 1},
    ];

    return({
        columns,
        rows
    });
}

function createDataEventBuffering(
    id: number,
    count: string,
    os: string,
    environment: string,
    appVersion: string,
    eventBufferingEnabled: string,
    message: string,
    installsPercent: number
  ) {
    return { id, count, os, environment, appVersion, eventBufferingEnabled, message, installsPercent};
}

export const EventBufferingDefinition = (_props:any) => {
    const data = _props;
    const rows : any = [];
    if(data !== undefined && data.length > 0){
        let installs = 0;
        for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['count']);
        }
        for (i = 0; i < data.length; i++){
            let installsPercent = Math.round(parseInt(data[i]['count']) / installs * 100 * 10) / 10;
            rows.push(
                createDataEventBuffering(
                    i,
                    data[i]['count'],
                    data[i]['os'],
                    data[i]['environment'],
                    data[i]['appVersion'],
                    data[i]['eventBufferingEnabled'],
                    data[i]['message'],
                    installsPercent
                )
            );
        }
    }
    const columns: GridColDef[] = [
        { field: 'count', headerName: 'Count',flex: 1},
        { field: 'installsPercent', headerName: 'Count in %',flex: 1, type: "number",align:'left',headerAlign: 'left'},
        { field: 'os', headerName: 'OS',flex: 1},
        { field: 'environment', headerName: 'Environment',flex: 1},
        { field: 'appVersion', headerName: 'App Version',flex: 1},
        { field: 'eventBufferingEnabled', headerName: 'Event Buffering Enabled',flex: 1},
        { field: 'message', headerName: 'Message',flex: 1},
    ];

    return({
        columns,
        rows
    });
}

function createDataCallbackPartnerParams(
    id: number,
    count: string,
    eventName: string,
    eventToken: string,
    os: string,
    appVersion: string,
    partnerParams: string,
    callbackParams: string,
    installsPercent: number
  ) {
    return { id, count, eventName, eventToken, os, appVersion, partnerParams, callbackParams, installsPercent};
}

export const CallbackPartnerParamsDefinition = (_props:any) => {
    const data = _props;
    const rows : any = [];
    if(data !== undefined && data.length > 0){
        let installs = 0;
        for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['count']);
        }
        for (i = 0; i < data.length; i++){
            let installsPercent = Math.round(parseInt(data[i]['count']) / installs * 100 * 10) / 10;
            let callbackParams = data[i]['callbackParams'];
            let partnerParams = data[i]['partnerParams'];
            if(callbackParams !== 'null' && partnerParams !== 'null'){
                rows.push(
                    createDataCallbackPartnerParams(
                        i,
                        data[i]['count'],
                        data[i]['eventName'],
                        data[i]['eventToken'],
                        data[i]['os'],
                        data[i]['appVersion'],
                        data[i]['partnerParams'],
                        data[i]['callbackParams'],
                        installsPercent
                    )
                );
            }
        }
    }
    const columns: GridColDef[] = [
        { field: 'count', headerName: 'Count',flex: 1},
        { field: 'installsPercent', headerName: 'Count in %',flex: 1, type: "number",align:'left',headerAlign: 'left'},
        { field: 'eventName', headerName: 'Event Name',flex: 1},
        { field: 'eventToken', headerName: 'Event Token',flex: 1},
        { field: 'os', headerName: 'OS',flex: 1},
        { field: 'appVersion', headerName: 'App Version',flex: 1},
        { field: 'partnerParams', headerName: 'Partner Params',flex: 1},
        { field: 'callbackParams', headerName: 'Callback Params',flex: 1},
    ];

    return({
        columns,
        rows
    });
}

function createDataGoogleReferralApi(
    id: number,
    count: string,
    appVersion: string,
    environment: string,
    source: string,
    clientSdk: string,
    message: string,
    installsPercent: number
  ) {
    return { id, count, appVersion, environment, source, clientSdk, message, installsPercent};
}

export const GoogleReferralApiDefinition = (_props:any) => {
    const data = _props;
    const rows : any = [];
    if(data !== undefined && data.length > 0){
        let installs = 0;
        for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['count']);
        }
        for (i = 0; i < data.length; i++){
            let installsPercent = Math.round(parseInt(data[i]['count']) / installs * 100 * 10) / 10;
            rows.push(
                createDataGoogleReferralApi(
                    i,
                    data[i]['count'],
                    data[i]['appVersion'],
                    data[i]['environment'],
                    data[i]['source'],
                    data[i]['clientSdk'],
                    data[i]['message'],
                    installsPercent
                )
            );
        }
    }
    const columns: GridColDef[] = [
        { field: 'count', headerName: 'Count',flex: 1},
        { field: 'installsPercent', headerName: 'Count in %',flex: 1, type: "number",align:'left',headerAlign: 'left'},
        { field: 'appVersion', headerName: 'App Version',flex: 1},
        { field: 'environment', headerName: 'Environment',flex: 1},
        { field: 'source', headerName: 'Source',flex: 1},
        { field: 'clientSdk', headerName: 'Client SDK',flex: 1},
        { field: 'message', headerName: 'Message',flex: 1},
    ];

    return({
        columns,
        rows
    });
}

function createDataDirectPostback(
    id: number,
    appId: string,
    appToken: string,
    host: string,
    path: string,
    message: string,
    count: string
  ) {
    return { id, appId, appToken, host, path, message, count};
}

export const DirectPostbackDefinition = (_props:any) => {
    const data = _props;
    const rows : any = [];
    if(data !== undefined && data.length > 0){
        for (var i = 0; i < data.length; i++){
            rows.push(
                createDataDirectPostback(
                    i,
                    data[i]['appId'],
                    data[i]['appToken'],
                    data[i]['host'],
                    data[i]['path'],
                    data[i]['message'],
                    data[i]['count'],
                )
            );
        }
    }
    const columns: GridColDef[] = [
        { field: 'count', headerName: 'Count',flex: 1},
        { field: 'appId', headerName: 'App Id',flex: 1},
        { field: 'appToken', headerName: 'App Token',flex: 1},
        { field: 'host', headerName: 'Host',flex: 1},
        { field: 'path', headerName: 'Path',flex: 1},
        { field: 'message', headerName: 'Message',flex: 1},
    ];

    return({
        columns,
        rows
    });
}

function createDataAdRevenue(
    id: number,
    count: string,
    s2s: string,
    source: string,
    adRevenueNetwork: string,
    message: string,
    error: string,
    installsPercent: number
  ) {
    return { id, count, s2s, source, adRevenueNetwork, message, error, installsPercent};
}

export const AdRevenueDefinition = (_props:any) => {
    const data = _props;
    const rows : any = [];
    if(data !== undefined && data.length > 0){
        let installs = 0;
        for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['count']);
        }
        for (i = 0; i < data.length; i++){
            let installsPercent = Math.round(parseInt(data[i]['count']) / installs * 100 * 10) / 10;
            rows.push(
                createDataAdRevenue(
                    i,
                    data[i]['count'],
                    data[i]['s2s'],
                    data[i]['source'],
                    data[i]['adRevenueNetwork'],
                    data[i]['message'],
                    data[i]['error'],
                    installsPercent
                )
            );
        }
    }
    const columns: GridColDef[] = [
        { field: 'count', headerName: 'Count',flex: 1},
        { field: 'installsPercent', headerName: 'Count in %',flex: 1, type: "number",align:'left',headerAlign: 'left'},
        { field: 's2s', headerName: 'S2S',flex: 1},
        { field: 'source', headerName: 'Source',flex: 1},
        { field: 'adRevenueNetwork', headerName: 'Ad Revenue Network',flex: 1},
        { field: 'message', headerName: 'Message',flex: 1},
        { field: 'error', headerName: 'Error',flex: 1},
    ];

    return({
        columns,
        rows
    });
}

function createDataCoppa(
    id: number,
    count: string,
    os: string,
    appVersion: string,
    appVersionShort: string,
    clientSdk: string,
    host: string,
    message: string,
    environment: string,
    ffCoppa: string,
    installsPercent: number
  ) {
    return { id, count, os, appVersion, appVersionShort, clientSdk, host, message, environment, ffCoppa, installsPercent};
}

export const CoppaDefinition = (_props:any) => {
    const data = _props;
    const rows : any = [];
    if(data !== undefined && data.length > 0){
        let installs = 0;
        for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['count']);
        }
        for (i = 0; i < data.length; i++){
            let installsPercent = Math.round(parseInt(data[i]['count']) / installs * 100 * 10) / 10;
            rows.push(
                createDataCoppa(
                    i,
                    data[i]['count'],
                    data[i]['os'],
                    data[i]['appVersion'],
                    data[i]['appVersionShort'],
                    data[i]['clientSdk'],
                    data[i]['host'],
                    data[i]['message'],
                    data[i]['environment'],
                    data[i]['ffCoppa'],
                    installsPercent
                )
            );
        }
    }
    const columns: GridColDef[] = [
        { field: 'count', headerName: 'Count',flex: 1},
        { field: 'installsPercent', headerName: 'Count in %',flex: 1, type: "number",align:'left',headerAlign: 'left'},
        { field: 'os', headerName: 'OS',flex: 1},
        { field: 'appVersion', headerName: 'App Version',flex: 1},
        { field: 'appVersionShort', headerName: 'App Version Short',flex: 1},
        { field: 'clientSdk', headerName: 'Client SDK',flex: 1},
        { field: 'host', headerName: 'Host',flex: 1},
        { field: 'message', headerName: 'Message',flex: 1},
        { field: 'environment', headerName: 'Environment',flex: 1},
        { field: 'ffCoppa', headerName: 'FF Coppa',flex: 1},
    ];

    return({
        columns,
        rows
    });
}

function createDataThirdPartySharing(
    id: number,
    count: string,
    os: string,
    appVersion: string,
    appVersionShort: string,
    clientSdk: string,
    host: string,
    message: string,
    environment: string,
    thirdPartySharingDisabled: string,
    installsPercent: number
  ) {
    return { id, count, os, appVersion, appVersionShort, clientSdk, host, message, environment, thirdPartySharingDisabled, installsPercent};
}

export const ThirdPartySharingDefinition = (_props:any) => {
    const data = _props;
    const rows : any = [];
    if(data !== undefined && data.length > 0){
        let installs = 0;
        for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['count']);
        }
        for (i = 0; i < data.length; i++){
            let installsPercent = Math.round(parseInt(data[i]['count']) / installs * 100 * 10) / 10;
            rows.push(
                createDataThirdPartySharing(
                    i,
                    data[i]['count'],
                    data[i]['os'],
                    data[i]['appVersion'],
                    data[i]['appVersionShort'],
                    data[i]['clientSdk'],
                    data[i]['host'],
                    data[i]['message'],
                    data[i]['environment'],
                    data[i]['thirdPartySharingDisabled'],
                    installsPercent
                )
            );
        }
    }
    const columns: GridColDef[] = [
        { field: 'count', headerName: 'Count',flex: 1},
        { field: 'installsPercent', headerName: 'Count in %',flex: 1, type: "number",align:'left',headerAlign: 'left'},
        { field: 'os', headerName: 'OS',flex: 1},
        { field: 'appVersion', headerName: 'App Version',flex: 1},
        { field: 'appVersionShort', headerName: 'App Version Short',flex: 1},
        { field: 'clientSdk', headerName: 'Client SDK',flex: 1},
        { field: 'host', headerName: 'Host',flex: 1},
        { field: 'message', headerName: 'Message',flex: 1},
        { field: 'environment', headerName: 'Environment',flex: 1},
        { field: 'thirdPartySharingDisabled', headerName: 'Third Party Sharing Disabled',flex: 1},
    ];

    return({
        columns,
        rows
    });
}

function createDataPlaystoreKids(
    id: number,
    count: string,
    os: string,
    appVersion: string,
    appVersionShort: string,
    sdk: string,
    host: string,
    message: string,
    environment: string,
    ffPlayStoreKidsApp: string,
    installsPercent: number
  ) {
    return { id, count, os, appVersion, appVersionShort, sdk, host, message, environment, ffPlayStoreKidsApp, installsPercent};
}

export const PlaystoreKidsDefinition = (_props:any) => {
    const data = _props;
    const rows : any = [];
    if(data !== undefined && data.length > 0){
        let installs = 0;
        for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['count']);
        }
        for (i = 0; i < data.length; i++){
            let installsPercent = Math.round(parseInt(data[i]['count']) / installs * 100 * 10) / 10;
            rows.push(
                createDataPlaystoreKids(
                    i,
                    data[i]['count'],
                    data[i]['os'],
                    data[i]['appVersion'],
                    data[i]['appVersionShort'],
                    data[i]['sdk'],
                    data[i]['host'],
                    data[i]['message'],
                    data[i]['environment'],
                    data[i]['ffPlayStoreKidsApp'],
                    installsPercent
                )
            );
        }
    }
    const columns: GridColDef[] = [
        { field: 'count', headerName: 'Count',flex: 1},
        { field: 'installsPercent', headerName: 'Count in %',flex: 1, type: "number",align:'left',headerAlign: 'left'},
        { field: 'os', headerName: 'OS',flex: 1},
        { field: 'appVersion', headerName: 'App Version',flex: 1},
        { field: 'appVersionShort', headerName: 'App Version Short',flex: 1},
        { field: 'sdk', headerName: 'SDK',flex: 1},
        { field: 'host', headerName: 'Host',flex: 1},
        { field: 'message', headerName: 'Message',flex: 1},
        { field: 'environment', headerName: 'Environment',flex: 1},
        { field: 'ffPlayStoreKidsApp', headerName: 'ff Play Store Kids App',flex: 1},
    ];

    return({
        columns,
        rows
    });
}

function createDataAttFramework(
    id: number,
    numOfInstalls: string,
    os: string,
    environment: string,
    appVersion: string,
    attStatus: string,
    installsPercent: number,
    message: string
  ) {
    return { id, numOfInstalls, os, environment, appVersion, attStatus, installsPercent,message};
}

export const AttFrameworkDefinition = (_props:any) => {
    const data = _props;
    const rows : any = [];
    if(data !== undefined && data.length > 0){
        let installs = 0;
        for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['numOfInstalls']);
        }
        for (i = 0; i < data.length; i++){
            let installsPercent = Math.round(parseInt(data[i]['numOfInstalls']) / installs * 100 * 10) / 10;
            let environment = data[i]['environment'];
            let message = data[i]['message'];
            if(environment === 'production' && message === "install tracked"){
                rows.push(
                    createDataAttFramework(
                        i,
                        data[i]['numOfInstalls'],
                        data[i]['os'],
                        data[i]['environment'],
                        data[i]['appVersion'],
                        data[i]['attStatus'],
                        installsPercent,
                        data[i]['message'],
                    )
                );
            }
        }
    }
    const columns: GridColDef[] = [
        { field: 'numOfInstalls', headerName: 'Num of Installs',flex: 1},
        { field: 'installsPercent', headerName: 'Installs in %',flex: 1, type: "number",align:'left',headerAlign: 'left'},
        { field: 'os', headerName: 'OS',flex: 1},
        { field: 'appVersion', headerName: 'App Version',flex: 1},
        { field: 'environment', headerName: 'Environment',flex: 1},
        { field: 'attStatus', headerName: 'ATT Status',flex: 1},
        { field: 'message', headerName: 'Message',flex: 1},
    ];

    return({
        columns,
        rows
    });
}

function createDataAppleAdsFramework(
    id: number,
    count: string,
    appVersion: string,
    clientSdk: string,
    source: string,
    message: string,
    errorCode: string,
    error: string,
    installsPercent: number
  ) {
    return { id, count, appVersion, clientSdk, source, message, errorCode, error, installsPercent};
}

export const AppleAdsFrameworkDefinition = (_props:any) => {
    const data = _props;
    const rows : any = [];
    if(data !== undefined && data.length > 0){
        let installs = 0;
        for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['count']);
        }
        for (i = 0; i < data.length; i++){
            let installsPercent = Math.round(parseInt(data[i]['count']) / installs * 100 * 10) / 10;
            rows.push(
                createDataAppleAdsFramework(
                    i,
                    data[i]['count'],
                    data[i]['appVersion'],
                    data[i]['clientSdk'],
                    data[i]['source'],
                    data[i]['message'],
                    data[i]['errorCode'],
                    data[i]['error'],
                    installsPercent
                )
            );
        }
    }
    const columns: GridColDef[] = [
        { field: 'count', headerName: 'Count',flex: 1},
        { field: 'installsPercent', headerName: 'Count in %',flex: 1, type: "number",align:'left',headerAlign: 'left'},
        { field: 'appVersion', headerName: 'App Version',flex: 1},
        { field: 'clientSdk', headerName: 'Client SDK',flex: 1},
        { field: 'source', headerName: 'Source',flex: 1},
        { field: 'message', headerName: 'Message',flex: 1},
        { field: 'errorCode', headerName: 'Error Code',flex: 1},
        { field: 'error', headerName: 'Error',flex: 1},
    ];

    return({
        columns,
        rows
    });
}

function createDataSkadnetworkIntegration(
    id: number,
    count: string,
    osVersion: string,
    appVersion: string,
    skadNetworkRegistered: string,
    installsPercent: number
  ) {
    return { id, count, osVersion, appVersion, skadNetworkRegistered, installsPercent};
}

export const SkadnetworkIntegrationDefinition = (_props:any) => {
    const data = _props;
    const rows : any = [];
    if(data !== undefined && data.length > 0){
        let installs = 0;
        for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['count']);
        }
        for (i = 0; i < data.length; i++){
            let installsPercent = Math.round(parseInt(data[i]['count']) / installs * 100 * 10) / 10;
            rows.push(
                createDataSkadnetworkIntegration(
                    i,
                    data[i]['count'],
                    data[i]['osVersion'],
                    data[i]['appVersion'],
                    data[i]['skadNetworkRegistered'],
                    installsPercent
                )
            );
        }
    }
    const columns: GridColDef[] = [
        { field: 'count', headerName: 'Count',flex: 1},
        { field: 'installsPercent', headerName: 'Count in %',flex: 1, type: "number",align:'left',headerAlign: 'left'},
        { field: 'appVersion', headerName: 'App Version',flex: 1},
        { field: 'osVersion', headerName: 'OS Version',flex: 1},
        { field: 'skadNetworkRegistered', headerName: 'SKAD Network Registered',flex: 1},
    ];

    return({
        columns,
        rows
    });
}

function createDataConversionValues(
    id: number,
    count: string,
    eventName: string,
    s2s: string,
    appVersion: string,
    skConversionValue: string,
    installsPercent: number
  ) {
    return { id, count, eventName, s2s, appVersion, skConversionValue, installsPercent};
}

export const ConversionValuesDefinition = (_props:any) => {
    const data = _props;
    const rows : any = [];
    if(data !== undefined && data.length > 0){
        let installs = 0;
        for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['count']);
        }
        for (i = 0; i < data.length; i++){
            let installsPercent = Math.round(parseInt(data[i]['count']) / installs * 100 * 10) / 10;
            rows.push(
                createDataConversionValues(
                    i,
                    data[i]['count'],
                    data[i]['eventName'],
                    data[i]['s2s'],
                    data[i]['appVersion'],
                    data[i]['skConversionValue'],
                    installsPercent
                )
            );
        }
    }
    const columns: GridColDef[] = [
        { field: 'count', headerName: 'Count',flex: 1},
        { field: 'installsPercent', headerName: 'Count in %',flex: 1, type: "number",align:'left',headerAlign: 'left'},
        { field: 'appVersion', headerName: 'App Version',flex: 1},
        { field: 'eventName', headerName: 'Event Name',flex: 1},
        { field: 's2s', headerName: 'S2s',flex: 1},
        { field: 'skConversionValue', headerName: 'SK Conversion Value',flex: 1},
    ];

    return({
        columns,
        rows
    });
}

function createDeeplinkValues(
    id: number,
    count: string,
    source: string,
    message: string,
    clientSdk: string,
    appVersion: string,
    statusCode: string,
    deeplinkUrl: string,
    environment: string,
    attributionDeeplink: string
  ) {
    return { id, count, source, message, clientSdk, appVersion, statusCode, deeplinkUrl, environment, attributionDeeplink};
}

export const DeeplinkDefinition = (_props:any) => {
    const data = _props;
    const rows : any = [];
    if(data !== undefined && data.length > 0){
        for (var i = 0; i < data.length; i++){
            rows.push(
                createDeeplinkValues(
                    i,
                    data[i]['count'],
                    data[i]['source'],
                    data[i]['message'],
                    data[i]['clientSdk'],
                    data[i]['appVersion'],
                    data[i]['statusCode'],
                    data[i]['deeplinkUrl'],
                    data[i]['environment'],
                    data[i]['attributionDeeplink']
                )
            );
        }
    }
    const columns: GridColDef[] = [
        { field: 'count', headerName: 'Count',flex: 1},
        { field: 'source', headerName: 'Source',flex: 1, type: "number",align:'left',headerAlign: 'left'},
        { field: 'message', headerName: 'Message',flex: 1},
        { field: 'clientSdk', headerName: 'Client SDK',flex: 1},
        { field: 'appVersion', headerName: 'App Version',flex: 1},
        { field: 'statusCode', headerName: 'Status Code',flex: 1},
        { field: 'deeplinkUrl', headerName: 'Deeplink URL',flex: 1},
        { field: 'environment', headerName: 'Environment',flex: 1},
        { field: 'attributionDeeplink', headerName: 'Attribution Deeplink',flex: 1},
    ];

    return({
        columns,
        rows
    });
}

