export const formatDate = (dateString : string) => {
    if(dateString === '' || dateString === null || dateString === undefined){
        return '';
    }
    return new Date(dateString).toISOString().slice(0, 10);
}
  
export const formatDateForBQG = (dateString : string) => {
    if(dateString === '' || dateString === null || dateString === undefined){
        return '';
    }
    let d = new Date(dateString),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-') ;
}

export const formatTimeForBQG = (timeList : any) => {
    if(timeList === '' || timeList === null || timeList === undefined){
        return '';
    }
    let hour = String(timeList.$H);
    if(hour.length === 1){
        hour = '0' + hour;
    }
    let minute = String(timeList.$m);
    if(minute.length === 1){
        minute = '0' + minute;
    }
    return hour + ":" + minute + ":00";
}