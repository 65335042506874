import { Box } from '@mui/material';
import React, { useContext, useState } from 'react';
import { Tabs } from '@adjust/components/build/Tabs/Tabs';
import CreateUserModal from '../../entities/components/Admin/CreateUserModal';
import SalesforceUsersTable from '../../entities/components/Admin/SfUsersTable';
import {UsersTable} from '../../entities/components/Admin/UsersTable';
import { AuthContext } from '../../app/store/AuthContext';
import { MateUsersTable } from '../../entities/components/Admin/MateUserTable';

export const AdminPage = () =>{
    const value = useContext(AuthContext);
    const role = value?.auth.role;
    const [tabs, setTabs] = useState('1');
    const items = [
    {
        id: '1',
        label: 'Dashboard Users'
    },
    {   
        id: '2',
        label: 'SF Users'
    },
    {   
        id: '3',
        label: 'Mate Users'
    }
    ];

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <div>
                <h1 style={{marginTop:'0px'}}>Admin Panel</h1>
                {(role === '2') &&
                <div>
                    <CreateUserModal />
                    <br/>
                    <Tabs items={items} onChange={setTabs} value={tabs} />
                    {(tabs === '1') &&
                        <UsersTable role={role} />
                    }
                    {(tabs === '2') &&
                        <SalesforceUsersTable role={role} />
                    }
                    {(tabs === '3') &&
                        <MateUsersTable role={role} />
                    }
                </div>
                }
                {role !== '2' &&
                    <div>You are not an admin</div>
                }
                
            </div>
        </Box>
    );
}
