import { Tile } from '@adjust/components/build/Tile/Tile';
import { Box, Button, Grid} from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { SegmentedButton } from '@adjust/components';

export const ReportsPage = () => {
    const [value, setValue] = useState('1');
    const items = [
    {value: '1', label: 'Anomalies' },
    {value: '2', label: 'Control Tower' },
    ];
    
    return (         
        <Box component="main" sx={{ flexGrow: 1, p: 3 }} style={{marginLeft:'2%', marginTop:'1%'}}>
                    <div>
                        <SegmentedButton
                        items={items}
                        value={value}
                        onChange={event => setValue(event.target.value)}
                        />
                        <br/><br/>
                        <Grid container spacing={6}>
                            {value === '2' &&
                            <>

                                <Grid item xs={3}>
                                    <Tile title="Trackers Allow Emulators">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/trackersallowemulators">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="Featurama">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/featurama">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="CTV Probabilistic Matching Enabled List">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/ctvprobabilisticmatchingenabledlist">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3} >
                                    <Tile title="Event S2S only">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/events2sonly">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="DigitalTurbine Whitelist">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/digitalturbinewhitelist">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3} >
                                    <Tile title="Data Residency Accounts">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/dataresidency">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="2FA-Enabled Accounts">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/2faenabledaccounts">Open</Button>
                                    </Tile>
                                </Grid>
                            </>
                            }

                            {value === '1' &&
                            <>
                                <Grid item xs={3}>
                                    <Tile title="Failed Callbacks">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/failedcallbacks">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="My Anomalies Overview">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/anomaliesoverview">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="KPI Errors">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/kpierrors">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="Event Anamolies">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/eventanomalies">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3}>
                                    <Tile title="Competitor SDK">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/competitorsdks">Open</Button>
                                    </Tile>
                                </Grid>
                            </>
                            }

                            {value === '3' &&
                            <>
                                <Grid item xs={3} style={{display:'none'}}>
                                    <Tile title="Upselling Dashboard">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/upsellingdashboard">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3} style={{display:'none'}}>
                                    <Tile title="ACV Report">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/acvreport">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3} style={{display:'none'}}>
                                    <Tile title="Ad Networks Report">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/adnetworks">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3} style={{display:'none'}}>
                                    <Tile title="Attribution Settings">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/attributionsettings">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3} style={{display:'none'}}>
                                    <Tile title="Applovin Installs Last Month">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/applovininstalls">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3} style={{display:'none'}}>
                                    <Tile title="Adjust Suite Accounts">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/adjustsuite">Open</Button>
                                    </Tile>
                                </Grid>
                                <Grid item xs={3} style={{display:'none'}}>
                                    <Tile title="My Adjust Suite Accounts">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/reports/adjustsuitemyaccounts">Open</Button>
                                    </Tile>
                                </Grid>
                                
                                
                                
                                <Grid item xs={3} style={{display:'none'}}>
                                    <Tile title="Case Overview">
                                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to="/tools/caseoverview">Open</Button>
                                    </Tile>
                                </Grid>
                            </>
                            }
                        </Grid>
                    </div>
        </Box>
    );
}