import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';


const columns: GridColDef[] = [
    { field: 'appToken', headerName: 'App Token',flex: 1},
    { field: 'appName', headerName: 'App Name',flex: 1},
    { field: 'accountId', headerName: 'Account ID',flex: 1},
];

function createData(
    id: number,
    appToken: string,
    appName: string,
    accountId: string,

  ) {
    return { id, appToken, appName, accountId};
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export const PartnerAppsTable = (_props: any) => {
    const rows : any = [];

    if(_props.apps !== undefined){
        for (var i = 0; i < _props.apps.length; i++){
            rows.push(
                createData(
                    i,
                    _props.apps[i][0],
                    _props.apps[i][1],
                    _props.apps[i][2]
                )
            );
        }
    }
    return (
        <div style={{ height: 500, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>

    );
}