import { Chart } from "react-google-charts";

const formatDate = (dateString : string) =>{
    if(dateString === '' || dateString === null){
        return '';
    }
    return new Date(dateString).toLocaleDateString();
}

const setChartValues =(datas : any) =>{
    const data = [
        ["Date","Other Clicks","San Clicks","Other Network Clicks","Events","San Network Clicks","Other Impressions","Revenue Events","San Impressions","Sessions","Other Network Impressions","Paid Installs","San Network Impressions","Other Installs","San Installs","Other Network Installs","San Network Installs","Uninstalls","Reinstalls","Reattributions","San Engagements","All engagements"],
        ["N/A",0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      ];
      
    var rows : any = [["Date","Other Clicks","San Clicks","Other Network Clicks","Events","San Network Clicks","Other Impressions","Revenue Events","San Impressions","Sessions","Other Network Impressions","Paid Installs","San Network Impressions","Other Installs","San Installs","Other Network Installs","San Network Installs","Uninstalls","Reinstalls","Reattributions","San Engagements","All engagements"],];
    if(datas !== undefined && datas !== '' ){
        for (let i = 0; i < datas.length; i++) {
            rows.push([
                formatDate(
                    datas[i][2]), 
                    datas[i][3], 
                    datas[i][4],
                    datas[i][5],
                    datas[i][6],
                    datas[i][7],
                    datas[i][8],
                    datas[i][9],
                    datas[i][10],
                    datas[i][11],
                    datas[i][12],
                    datas[i][13],
                    datas[i][14],
                    datas[i][15],
                    datas[i][16],
                    datas[i][17],
                    datas[i][18],
                    datas[i][19],
                    datas[i][20],
                    datas[i][21],
                    datas[i][22],
                    datas[i][23],
                ]);

        }
        return rows;
    }
    return data;
}

export const options = {
  title: "Daily Data Points",
  isStacked: true,
  height: 300,
  legend: {position: 'bottom', maxLines: 3},
  vAxis: {minValue: 0}
};

export const DailyDataPointsChart = (props : any) =>{
    let dps = props.dps;

  return (
    <div style={{marginLeft:'5px',marginTop:'5px',marginRight:'5px'}}>
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="300px"
        data={setChartValues(dps)}
        options={options}
      />
    </div>
  );
}
