import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

const columns: GridColDef[] = [
  { field: 'id', headerName: '',flex: 1, maxWidth:1},
  { field: 'appToken', headerName: 'App Token',flex: 1,
    renderCell: (params : any) => {
      return <Button variant="contained" component={Link} to={"/account/app/"+params.row.appToken} >{params.row.appToken}</Button>
    },
  },
  { field: 'accountId', headerName: 'Account ID',flex: 1,
    renderCell: (params : any) => {
      return <Button variant="contained" component={Link} to={"/account/"+params.row.accountId} >{params.row.accountId}</Button>
    },
  },
  { field: 'present', headerName: 'SDK present',flex: 1},
  { field: 'name', headerName: 'SDK name',flex: 1,},
  { field: 'active', headerName: 'Is SDK active',flex: 1},
  { field: 'activationDate', headerName: 'Activation Date',flex: 1},
  { field: 'installDate', headerName: 'Install Date',flex: 1},
  
];

function createData(
    id: number,
    appToken: string,
    accountId: number,
    present: string,
    name: string,
    active: string,
    activationDate: string,
    installDate: string
  ) {
    return { id,appToken,accountId,present,name,active,activationDate,installDate };
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export default function ApptopiaSdkTable(_props : any) {
    const sdks = _props.sdks;
    const rows : any = [];
    if(sdks !== undefined){
        for (var i = 0; i < sdks.length; i++){
            rows.push(
                createData(
                    i,
                    sdks[i][0],
                    sdks[i][1],
                    sdks[i][2],
                    sdks[i][3],
                    sdks[i][4],
                    formatDate(sdks[i][5]),
                    formatDate(sdks[i][6]),
                )
            );
        }
    }
    return (
        <div style={{ height: 600, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}