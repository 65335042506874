import { Button, Input } from '@adjust/components';
import { Tile } from '@adjust/components/build/Tile/Tile';
import { Box, Card, Grid} from '@mui/material';
import React, { useState } from 'react';

export default function EngagementDetailsPage() {
    const [refTag, setRefTag] = useState('');
    const [engagementInfo,setEngagementInfo] = useState({});

    function searchEngagementDetails(refTag : any){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"refTag" : refTag })
        };
        fetch('/api/engagementdetails', requestOptions)
            .then(response => response.json()).then(data => {
                setEngagementInfo(data);
            });
    }

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                 <h1>Check engagement details via reftag:</h1>
                            </Grid>
                            <Grid item xs={12}>
                                <Tile title="Check engagement details via reftag:">
                                <Input
                                    label="Ref Tag"
                                    id="refTag" 
                                    name="refTag"
                                    value={refTag}
                                    css={{ width: "40%"}}
                                    onClear={() => {
                                        setRefTag('');
                                    }}
                                    onChange={(e) => {
                                        setRefTag(e.target.value);
                                    }}
                                />
                                <br/>
                                <Button  type="submit" kind="primary" label="Search"  onClick={() => searchEngagementDetails(refTag)}></Button>{' '}
                                </Tile>
                            </Grid>

                            <Grid item xs={12}>
                                <Card>
                                    <pre>{JSON.stringify(engagementInfo, null, 2)}</pre>
                                </Card>
                            </Grid>
                        </Grid>
                           
                    </div>
                </Box>
    );
}