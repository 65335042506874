export const InstallSessionCheckIos = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(data === undefined){
        return 0;
    }
    if(lastRunTime === ''){
        return 0;
    }
    for (var i = 0; i < data.length; i++){
        let environment = data[i]['environment'];
        let message = data[i]['message'];
        let installs = parseInt(data[i]['numOfInstalls']);
        if(environment === 'production' && message === 'install tracked' && installs > 0){
            return 1
        }
    }
    return 3;
}

export const AdvertisingIdCheckIos = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    // installs count in production
    let installs = 0;
    for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['numOfOccurences']);
    }
    if(installs > 0){
        return 1
    }
    return 3;
}

export const PushTokenCheckIos = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    // installs count in production
    let installs = 0;
    for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['numOfInstalls']);
    }
    if(installs > 0){
        return 1
    }
    return 3;
}

export const AttFrameworkCheckIos = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    // installs count in production
    let installs = 0;
    for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['numOfInstalls']);
    }
    if(installs > 0){
        return 1
    }
    return 3;
}

export const SdkSignatureCheckIos = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    // installs count in production
    let installs = 0;
    for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['count']);
    }
    if(installs > 0){
        return 1
    }
    return 3;
}

export const SdkEventNonRevenueCheckIos = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    let eventCount = data.length;
    if(eventCount > 0){
        return 1;
    }
    return 3;
}

export const SdkEventRevenueCheckIos = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    let counter = 0;
    for (let i = 0; i < data.length; i++){
        let revenue = data[i]['revenue'];
        let currency = data[i]['currency'];
        if(revenue !== "null" && currency !== "null"){
            counter = counter + 1;
        }
    }
    if(counter > 0){
        return 1;
    }
    return 3;
}

export const EventBufferingCheckIos = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    let eventCount = data.length;
    if(eventCount > 0){
        return 1;
    }
    return 3;
}

export const CallbackAndPartnerCheckIos = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    for (var i = 0; i < data.length; i++){
        let partnerParams = data[i]['partnerParams'];
        let callbackParams = data[i]['callbackParams'];
        if(partnerParams !== 'null' && callbackParams !== 'null'){
            return 1;
        }
    }
    return 3;
}

export const AppleAdsFrameworkCheckIos = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    if(data.length > 0){
        return 1;
    }
    return 3;
}

export const SkadNetworkIntegrationCheckIos = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    if(data.length > 0){
        return 1;
    }
    return 3;
}

export const DirectPostbackCheckIos = (lastRunTime:string,data:any) => {
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    if(data.length > 0){
        return 1;
    }
    return 3;
}

export const ConversionValuesCheckIos = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    if(data.length > 0){
        return 1;
    }
    return 3;
}

export const AdRevenueCheckIos = (lastRunTime:string,data:any) => {
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }

    if(data.length > 0){
        if(data[0]["date"] !== ""){
            return 1;
        }
    }
    return 3;
}

export const CoppaCheckIos = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    if(data.length > 0){
        return 1;
    }
    return 3;
}

export const ThirdPartyCheckIos = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    if(data.length > 0){
        return 1;
    }
    return 3;
}

export const KidsCheckIos = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    if(data.length > 0){
        return 1;
    }
    return 3;
}

export const DeeplinkCheckIos = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    if(data.length > 0){
        return 1;
    }
    return 3;
}