import { Box, Divider} from '@mui/material';
import { AdRevenueRefetchJob } from './AdRevenueRefetchJob';
import { useEffect, useState } from 'react';
import { AdRevenueTable } from './AdRevenueTable';

export const AdRevenueRefetchPage = () => {
    const [adRevenue,setAdRevenue]  = useState([]);

    useEffect(() => {
        fetch('/api/adrevenue').then(res => res.json()).then(data => {
            setAdRevenue(data);
        });
    }, []);

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <h1>Ad Revenue Refetcher</h1>
            <h5>The Ad Revenue Refetcher will refetch Callbacks + CSVs</h5>
            <AdRevenueRefetchJob />
            <br/><br/>
            <Divider/>
            <AdRevenueTable adRevenue={adRevenue} />
        </Box>
    );
}

