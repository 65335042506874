import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { KpiErrorsTable } from "./KpiErrorsTable";
import { InfoTooltip } from "../../../shared/components/infoTooltip/InfoTooltip";

export const KpiErrorsReportPage = () => {
    const [data,setData] = useState({'errors400': [],'errors403': [],'errors404': []});
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true)
        fetch('/api/kpierrors').then(res => res.json()).then(data => {
            setData(data);
            setIsLoading(false);
        });
    }, []);
    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                 <h1>KPI Errors Report</h1>
                            </Grid>
                            {isLoading &&
                            <CircularProgress />
                            }
                            {!isLoading &&
                            <>
                                <InfoTooltip message="Source: Internal DB / Backend Team | Refreshed: bi-weekly" />
                                <KpiErrorsTable errors={data.errors400} name={"Error 400: bad request"} marginTop={'0%'} />
                                <KpiErrorsTable errors={data.errors403} name={"Error 403: Forbidden"} marginTop={'4%'} />
                                <KpiErrorsTable errors={data.errors404} name={"Error 404: Not Found"} marginTop={'4%'} />
                            </>
                            }
                        </Grid>
                    </div>
                </Box>
    );
}