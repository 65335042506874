import { Breadcrumbs } from '@adjust/components/build/Breadcrumbs/Breadcrumbs';
import { Tabs } from '@adjust/components/build/Tabs/Tabs';
import { Tile } from '@adjust/components/build/Tile/Tile';
import { Box, Grid} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PartnerModuleSettings from '../../entities/components/Partners/PartnerModuleSettings';
import PartnerSettingsEventTable from '../../entities/components/Partners/PartnerSettingsEventTable';
import AppInfo from '../../shared/components/appInfo/AppInfo';

function MyBreadcrumbs(accountId:any,accountName:any,appToken:any,appName:any,partnerName:any,partnerId:any) {
    let navigate = useNavigate();
    const items = [
      { label: 'My Accounts', onClick: () => {navigate('/myaccounts')}},
      { label: accountName, onClick: () => {navigate('/account/'+accountId)}},
      { label: 'Apps', onClick: () => {navigate('/account/apps/'+accountId)}},
      { label: appName, onClick: () => {navigate('/account/app/'+appToken)}},
      { label: 'Settings', onClick: () => {navigate('/account/app/settings/'+appToken)}},
      { label: 'Partner settings', onClick: () => {navigate('/account/app/settings/partner/'+appToken+'/'+partnerId)}},
      { label: partnerName, onClick: () => {navigate('/account/app/settings/partner/'+appToken+'/'+partnerId)}},
    ];
    return <Breadcrumbs items={items} />;
}

export default function AppPartnerSettingsPage() {
    let params = useParams();
    const appToken = params.appToken;
    const partnerId = params.partnerId;
    const items = [
        {
             id: '1',
            label: 'Settings',
            url: '/account/app/settings/partner/'+params.appToken+"/"+params.partnerName
        },
      ];
    
    const [appInfo, setAppInfo] = useState([]);
    const [accountName, setAccountName] = useState([]);
    const [partnerSettings, setPartnerSettings] : any = useState([]);
    const [partnerSettingsEvent, setPartnerSettingsEvent] = useState([]);

    useEffect(() => {
        if(appToken !== '' && partnerId !== ''){
            fetch('/api/apppartnersettings/'+appToken+'?partner='+partnerId).then(res => res.json()).then(data => {
                setAppInfo(data["appData"]);
                setAccountName(data["accountName"]);
                setPartnerSettings(data["partnerSettings"]);
                setPartnerSettingsEvent(data["partnerSettingsEvent"]);
            });
        }
    }, [appToken,partnerId]);
    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                    {MyBreadcrumbs(appInfo[3],accountName,appInfo[2],appInfo[1],partnerSettings["partnerName"],params.partnerId)}
                        <Tabs items={items} value="1" />
                        <br/><br/>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Grid container spacing={6}>
                                    <Grid item xs={4}>
                                        <AppInfo appInfo={appInfo} accountName={accountName} />
                                        <br/>
                                        <div style={{display:'none'}}>
                                            <Tile title="Discrepancy Check">
                                                <h2>Launch SAN Discrepancy Check</h2>
                                                <h5>Check last results</h5>
                                            </Tile >
                                        </div>
                                    </Grid> 
                                    <Grid item xs={8}>
                                        <h2>{partnerSettings["partnerName"]}</h2>
                                        <Tile title="Module Settings">
                                            <PartnerModuleSettings partnerSettings={partnerSettings} appInfo={appInfo}/>
                                        </Tile >
                                        <br/>
                                        <Tile title="Event Linking">
                                            <PartnerSettingsEventTable events={partnerSettingsEvent} />
                                        </Tile >
                                    </Grid> 
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </Box>

    );
}