import { Box, Button, Dialog, Grid, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { Input } from '@adjust/components';
import { useState } from "react";


export default function CreateUserModal(){
    const [emailValue, setEmailValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [role, setRole] = useState('1');

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [inputError, setInputError] = useState(false);
    const [errorMsg, setError] = useState('');
    
    function CreateUser(){
        if(emailValue === '' || passwordValue  === '' || firstName === '' || lastName === ''){
            setInputError(true);
        }
        else{
            setInputError(false);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ "email": emailValue, "firstName": firstName, "lastName": lastName, "password": passwordValue, "role": role })
            };
            fetch('/api/register', requestOptions)
                .then(response => response.json())
                .then(responseJSON => {
                    setError(responseJSON.error)
                    if(responseJSON.error === ''){
                        setEmailValue('');
                        setPasswordValue('');
                        setFirstName('');
                        setLastName('');
                        setRole('1');
                        handleClose();
                    }    
                }
                )
        }
    }

    const roleChange = (event: SelectChangeEvent) => {
        setRole(event.target.value as string);
      };

    return <>
            <Button variant="contained" style={{backgroundColor:'#005ff7'}} onClick={handleOpen}>Add User</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
            >
                <Box >
                <Typography id="dialog-description">
                <Grid style={{width: '100%',textAlign: "center" }} container  direction="column" justifyContent="center" alignItems="center" > 
                    <Grid container xs={12} >
                        <Grid item xs={12}>
                        <div style={{ height: 500 }}>
                        <h4 style={{width: '100%',color:'#005ff7'}}>Create new Support Dashboard User</h4>
                        <Input
                            css={{width: '50%'}}
                            label="Firstname"
                            id="firstname" 
                            name="firstname"
                            value={firstName}
                            onClear={() => {
                                setFirstName('');
                            }}
                            onChange={(e) => {
                                setFirstName(e.target.value);
                            }}
                        />
                        <br/>
                         <Input
                            css={{width: '50%'}}
                            label="Lastname"
                            id="lastname" 
                            name="lastname"
                            value={lastName}
                            onClear={() => {
                                setLastName('');
                            }}
                            onChange={(e) => {
                                setLastName(e.target.value);
                            }}
                        />
                        <br/>
                        <Input
                            css={{width: '50%'}}
                            label="Email"
                            id="username" 
                            name="username"
                            value={emailValue}
                            onClear={() => {
                                setEmailValue('');
                            }}
                            onChange={(e) => {
                                setEmailValue(e.target.value);
                            }}
                        />
                        <br/>
                        <Input
                            css={{width: '50%'}}
                            label="Password"
                            id="pasword" 
                            name="password"
                            value={passwordValue}
                            type="password"
                            onClear={() => {
                                setPasswordValue('');
                            }}
                            onChange={(e) => {
                                setPasswordValue(e.target.value);
                            }}
                        />
                        <br/>
                        <br/>
                        <Select
                            label="Role"
                            onChange={roleChange}
                            value={role}
                        >
                            <MenuItem value={1}>Dedicated</MenuItem>
                            <MenuItem value={2}>Admin</MenuItem>
                            <MenuItem value={3}>Support</MenuItem>
                            <MenuItem value={4}>Solutions Consultant</MenuItem>
                            <MenuItem value={5}>CSM</MenuItem>
                            <MenuItem value={6}>TAS</MenuItem>
                            <MenuItem value={7}>Implementation</MenuItem>
                            <MenuItem value={8}>Integration</MenuItem>
                            <MenuItem value={9}>Data</MenuItem>
                            <MenuItem value={10}>Sales</MenuItem>
                        </Select>
                        <br/>
                        {inputError &&
                            <p style={{width: '100%',color:"red"}} id="inputError">Please fill all fields</p>
                        }
                        {errorMsg !== '' &&
                            <p style={{width: '100%',color:"red"}} id="errorMsg">{errorMsg}</p>
                        }
                        <br/>
                        <Button variant="contained" style={{backgroundColor:'#005ff7'}} onClick={CreateUser}>Create User</Button>
                        </div>
                        </Grid>
                    </Grid>
                </Grid>
                </Typography>
                </Box>
            </Dialog>
        </>
}