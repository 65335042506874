import {Tile } from "@adjust/components";
import {Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import {useState } from "react";
import SearchIcon from '@mui/icons-material/Search';

export default function QueryAccountSearch (props : any){
    const [accountId,setAccountId] = useState('');
    const [accountName,setAccountName] = useState('');
    const [manager,setManager] = useState('');
    const [classification,setClassification] = useState('');
    const [region,setRegion] = useState('');
    const [vertical,setVertical] = useState('');
    const [partner,setPartner] = useState('');
    let queryClassifier  = props.queryClassifier;
    let queryRegion  = props.queryRegion;
    let queryVertical  = props.queryVertical;
    let queryPartner  = props.queryPartner;
    
    function searchAccounts(accountId : any, accountName : any, manager : any, classification: any,region: any,vertical: any,partner: any){
        props.onIsLoadingChange(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "accountId": accountId, "accountName" : accountName, "manager": manager,"classification": classification,"region": region,"vertical": vertical,"partner": partner}) 
        };
        fetch('/api/queryaccount', requestOptions)
            .then(response => response.json()).then(data => {
                props.onAccountChange(data);
                props.onIsLoadingChange(false);
            });
    }
    
    const accountIdKeyDown = (event : any) => {
        if (event.key === 'Enter') {
            searchAccounts(event.target.value,accountName,manager,classification,region,vertical,partner);
        }
    }
    const accountNameKeyDown = (event : any) => {
        if (event.key === 'Enter') {
            searchAccounts(accountId,event.target.value,manager,classification,region,vertical,partner);
        }
    }
    const managerKeyDown = (event : any) => {
        if (event.key === 'Enter') {
            searchAccounts(accountId,accountName,event.target.value,classification,region,vertical,partner);
        }
    }
    
    const checkBlock = (value : any) => {
        if(value === 'id'){
            if(accountName === '' && manager === ''){
                return false;
            }
            return true;
        }   
        if(value === 'name'){
            if(accountId === '' && manager === ''){
                return false;
            }
            return true;
        }
        if(value === 'manager'){
            if(accountId === '' && accountName === ''){
                return false;
            }
            return true;
        }
        return false;
    }
    return(
        <Tile>
            <Grid container spacing={6}>
                <Grid item xs={6}>
                    <TextField
                        style={{width:'60%'}}
                        label="Account Id's"
                        id="accountId" 
                        name="accountId"
                        value={accountId}
                        disabled={checkBlock('id')}
                        onChange={(e) => {
                            setAccountId(e.target.value);
                        }}
                        onKeyDown={accountIdKeyDown}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button onClick={() => searchAccounts(accountId,accountName,manager,classification,region,vertical,partner)}><SearchIcon /></Button>
                              </InputAdornment>
                            ),
                        }}
                    />
                    <br/><br/>
                    <TextField
                        style={{width:'60%'}}
                        label="Account Name"
                        id="accountName" 
                        name="accountName"
                        value={accountName}
                        disabled={checkBlock('name')}
                        onChange={(e) => {
                            setAccountName(e.target.value);
                        }}
                        onKeyDown={accountNameKeyDown}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button onClick={() => searchAccounts(accountId,accountName,manager,classification,region,vertical,partner)}><SearchIcon /></Button>
                              </InputAdornment>
                            ),
                        }}
                    />
                    <br/><br/>
                    <TextField
                        style={{width:'60%'}}
                        label="Manager Mail"
                        id="manager" 
                        name="manager"
                        value={manager}
                        disabled={checkBlock('manager')}
                        onChange={(e) => {
                            setManager(e.target.value);
                        }}
                        onKeyDown={managerKeyDown}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button onClick={() => searchAccounts(accountId,accountName,manager,classification,region,vertical,partner)}><SearchIcon /></Button>
                              </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                <Grid item xs={6}>

                <FormControl style={{width:'60%'}}>
                    <InputLabel id="classificationDropdown">Client Classification</InputLabel>
                    <Select
                        labelId="SelectClassificationDropdown"
                        id="SelectClassificationDropdown"
                        value={classification}
                        label="Client Classification"
                        onChange={(e) => {
                            setClassification(e.target.value);
                            searchAccounts(accountId,accountName,manager,e.target.value,region,vertical,partner);
                        }}
                    >
                    {queryClassifier.map((classifier  : any) =>
                        <MenuItem value={classifier} key={classifier}>{classifier}</MenuItem>
                    )}
                    </Select>
                </FormControl>
                <br/><br/>
                <FormControl style={{width:'60%'}}>
                    <InputLabel id="regionDropdown">Region</InputLabel>
                    <Select
                        labelId="SelectRegionDropdown"
                        id="SelectRegionDropdown"
                        value={region}
                        label="Region"
                        onChange={(e) => {
                            setRegion(e.target.value);
                            searchAccounts(accountId,accountName,manager,classification,e.target.value,vertical,partner);
                        }}
                    >
                    {queryRegion.map((region  : any) =>
                        <MenuItem value={region} key={region}>{region}</MenuItem>
                    )}
                    </Select>
                </FormControl>
                <br/><br/>
                <FormControl style={{width:'60%'}}>
                    <InputLabel id="verticalDropdown">Vertical</InputLabel>
                    <Select
                        labelId="SelectVerticalDropdown"
                        id="SelectVerticalDropdown"
                        value={vertical}
                        label="Vertical"
                        onChange={(e) => {
                            setVertical(e.target.value);
                            searchAccounts(accountId,accountName,manager,classification,region,e.target.value,partner);
                        }}
                    >
                    {queryVertical.map((vertical : any) =>
                        <MenuItem value={vertical} key={vertical}>{vertical}</MenuItem>
                    )}
                    </Select>
                </FormControl>
                <br/><br/>
                <FormControl style={{width:'60%'}}>
                    <InputLabel id="partnerDropdown">Module Partner</InputLabel>
                    <Select
                        labelId="SelectPartnerDropdown"
                        id="SelectPartnerDropdown"
                        value={partner}
                        label="Module Partner"
                        onChange={(e) => {
                            setPartner(e.target.value);
                            searchAccounts(accountId,accountName,manager,classification,region,vertical,e.target.value);
                        }}
                    >
                    {queryPartner.map((partner : any) =>
                        <MenuItem value={partner} key={partner}>{partner}</MenuItem>
                    )}
                    </Select>
                </FormControl>

                </Grid>
            </Grid>
        </Tile>
    );
}