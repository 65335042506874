import * as React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID',flex: 1},
    { field: 'user', headerName: 'User',flex: 1},
    { field: 'timeInH', headerName: 'Time in hours',flex: 1},
    { field: 'createdAt', headerName: 'Created At',flex: 1},
    { field: 'status', headerName: 'Status',flex: 1},
  ];

function createData(
    id: number,
    user: string,
    timeInH: number,
    createdAt: string,
    status: string,
  ) {
    return { id, user, timeInH, createdAt, status};
}

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export const BaggerApiHistoryTable = (_props : any) => {
    const history = _props["history"];
    const rows : any = [];
    if(history !== undefined && history.length > 0){
        for (var i = 0; i < history.length; i++){
        rows.push(
            createData(
                history[i][0],
                history[i][1],
                history[i][2],
                formatDate(history[i][3]),
                history[i][4],
            )
        );
    }
}
  return (
            <div style={{ height: 600 }}>
                <DataGrid 
                    rows={rows} 
                    columns={columns}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                />
            </div>
  );
}