import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DeliveryHeroTable } from '../../entities/components/deliveryHeroTable/DeliveryHeroTable';

export const DeliveryHeroPage = () =>{
    
    const [user, setUser] = useState([]);
    useEffect(() => {
        fetch('/api/deliveryhero').then(res => res.json()).then(data => {
            setUser(data["user"]);
        });
    }, []);
    
    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DeliveryHeroTable user={user} />
        </Box>
    );
}
