import { Box, Button, ButtonGroup, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { AccountPricingAcvTable } from '../../entities/components/accountPricingAcvTable/AccountPricingAcvTable';

const calculateCompleteAcv = (data : any) => {
    let count = 0;
    for (const element of data) {
        count += element[5];
      }
    return count.toLocaleString();
}
export const AccountPricingPage = () => {
    const [all,setAll] = useState(true);

    const [accounts, setAccounts] = useState([]);
    useEffect(() => {
        fetch('/api/ampricing').then(res => res.json()).then(data => {
            setAccounts(data["accounts"]);
        });
    }, []);

    const [accountIds,setAccountIds] = useState('');
    const [validAccounts, setValidAccounts] = useState([]);
    const search = () =>{
        if(accountIds !== '' ){
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'accountIds': accountIds })
            };
            fetch('/api/ampricingsearch', requestOptions)
                .then(response => response.json()).then(data => {
                    setValidAccounts(data["accounts"]);
                });
        }
    }

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <h1>Account ACV Overview</h1>
                        <ButtonGroup
                            disableElevation
                            variant="contained"
                            style={{marginLeft:'5px',marginTop:'5px'}}
                        >
                            {all &&
                            <>
                                <Button onClick={() => setAll(true)}>All accounts</Button>
                                <Button variant="outlined" onClick={() => setAll(false)}>Account Search</Button>
                            </>
                            }
                            {!all &&
                            <>
                                <Button variant="outlined" onClick={() => setAll(true)}>All Accounts</Button>
                                <Button onClick={() => setAll(false)}>Account Search</Button>
                            </>
                            }
                        </ButtonGroup>

                        {all &&
                        <AccountPricingAcvTable accounts={accounts} />
                        }
                        {!all &&
                        <>
                            <br/><br/>
                            <TextField id="account-id-textfield" label="Account Id's" variant="outlined" value={accountIds} style={{width:'50%'}}
                                onChange={(e) => {
                                    setAccountIds(e.target.value);
                                }}
                            />
                            <br/>
                            <Button variant="contained" onClick={() => search()}>Search</Button>
                            <br/><br/>
                            <p>Total ACV: {calculateCompleteAcv(validAccounts)}</p>
                            <br/>
                            <AccountPricingAcvTable accounts={validAccounts} />
                        </>
                        }
                    </div>
                </Box>
    );
}