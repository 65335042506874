import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';
import { InfoTooltip } from '../../../shared/components/infoTooltip/InfoTooltip';

function checkmark(value : string){
    if(String(value) === 'true'){
        return 'True';
    }
    return '';
}

const columns: GridColDef[] = [
    { field: 'action', headerName: 'Action',flex: 1, minWidth: 150,sortable: false,
        renderCell: (params : any) => {
            return <>
                <Button variant="outlined" component={Link} to={"/account/app/"+params.row.appToken}>View App</Button>
            </>;
        },
    },
    { field: 'appName', headerName: 'App Name',flex: 1, minWidth: 200},
    { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 150},
    { field: 'createdAt', headerName: 'RTC Created At',flex: 1, minWidth: 220},
    { field: 'deletedAt', headerName: 'RTC Deleted At',flex: 1, minWidth: 220},
    { field: 'csvUpload', headerName: 'CSV Upload Active',flex: 1, minWidth: 220},
];

function createData(
    id: number,
    appToken: string,
    appName: string,
    createdAt: string,
    deletedAt: string,
    csvUpload : string,
  ) {
    return { id, appToken, appName, createdAt, deletedAt, csvUpload};
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const infoText = "Source: Adjust DB | Refreshed: instant";
  
export const AccountRawDataTable = (_props : any) =>{
    const apps = _props.apps;
    const rows : any = [];
    if(apps !== undefined){
        for (var i = 0; i < apps.length; i++){
            rows.push(
                createData(
                    i,
                    apps[i][1],
                    apps[i][0],
                    formatDate(apps[i][2]),
                    formatDate(apps[i][3]),
                    checkmark(apps[i][4])
                )
            );
        }
    }
    return (
        <div style={{ height: 800, width: '100%' }}>
            <InfoTooltip message={infoText} />
            <DataGrid
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}
