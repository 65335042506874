import { Box, CircularProgress, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import MyAccountsTableAM from '../../entities/components/MyAccounts/MyAccountsTableAM';
import MyAccountsTableImpl from '../../entities/components/MyAccounts/MyAccountsTableImplementation';
import MyAccountsTableSC from '../../entities/components/MyAccounts/MyAccountsTableSC';
import { PieChart } from '../../entities/components/MyAccounts/PieChart';
import { ManagerHolidayAmTable } from '../../entities/components/MyAccounts/ManagerHolidayAmTable';
import { ManagerSecondAmTable } from '../../entities/components/MyAccounts/ManagerSecondAmTable';
import { MyAccountsCard } from '../../entities/components/MyAccounts/MyAccountsCard';


function getPackages(data:any){
    var vip = 0;
    var enterprise = 0;
    var professional = 0;
    var strategic = 0;
    var support = 0;
    var globalSupport = 0;
    var freemium = 0;
    for (var i = 0; i < data.length; i++){
        var packages = data[i]['classification'];
        if(packages === '1 VIP'){
            vip++;
        }
        else if (packages === '2 Enterprise')
        {
            enterprise++;
        }
        else if (packages === '3 Professional')
        {
            professional++;
        }
        else if (packages === '3 Strategic')
        {
            strategic++;
        }
        else if (packages === '4 Support')
        {
            support++;
        }
        else if (packages === '5 Global Support')
        {
            globalSupport++;
        }
        else if (packages === '6 Freemium')
        {
            freemium++;
        }
    }
    return [
        [
        ["Package", "Count"],
        ["1 VIP", vip],
        ["2 Enterprise", enterprise],
        ["3 Professional", professional],
        ["3 Strategic", strategic],
        ["4 Support", support],
        ["5 Global Support", globalSupport],
        ["6 Freemium", freemium]],
        "Total Clients " + data.length.toString()
      ];
}

function getVerticals(data:any){
    const verticals:any = [];
    verticals.push(["Vertical", "Count"],)
    for (var i = 0; i < data.length; i++){
        var vertical = data[i]['vertical'];
        if(vertical === null){
            vertical = "None";
        }
        var added = false;
        for (var j = 0; j < verticals.length; j++){
            if(verticals[j][0] === vertical){
                var count = verticals[j][1] + 1;
                verticals.splice(j, 1);
                verticals.push([vertical,count]);
                added = true;
            }
        }
        if(added !== true){
            verticals.push([vertical,1]);
        }
    }
    return [
            verticals,
        "Per Vertical "
      ];
}

function getBob(data:any){
    var totalAmount = 0;
    for (var i = 0; i < data.length; i++){
        var acv = Number(data[i]['acv']);
        totalAmount = totalAmount + acv;
    }
    return totalAmount;
}

export default function ManageraccountsPage() {
    const [accountData, setAccountData] = useState([]);
    const [role, setRole] = useState('2');
    const [mail, setMail] = useState('');
    const [status,setStatus] = useState(false);
    let params = useParams();
    let managerMail  = "";
    if (params.amMail !== undefined){
        managerMail = String(params.amMail);
    }
    useEffect(() => {
        if(managerMail !== ""){
            setStatus(true);
            fetch('/api/manageraccounts/'+managerMail).then(res => res.json()).then(data => {
                setAccountData(data["accounts"]);
                setRole(data["role"]);
                setMail(data["mail"]);
                setStatus(false);
            });
        }
    }, [managerMail]);

    return (
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <div>
                    <h1 style={{marginTop:'0px'}}>Accounts Overview for {managerMail}</h1>
                    {status &&
                    <CircularProgress />
                    }
                    {!status &&
                    <>
                        <Grid container spacing={0}>
                            {(role !== '7') &&
                            <Grid item xs={6}>
                                {(role === '1' || role === '2' || role === '3' || role === '7') &&
                                <PieChart data={getPackages(accountData)} />
                                }
                            </Grid>
                            }
                            <Grid item xs={6}>
                                {(role === '1' || role === '2' || role === '3' || role === '7') &&
                                <PieChart data={getVerticals(accountData)}></PieChart>
                                }
                            </Grid>
                        </Grid>
                    
                        {(role === '1' || role === '2' || role === '3') &&
                            <>
                                <MyAccountsTableAM data={accountData} />
                                <ManagerHolidayAmTable mail={mail} />
                                <ManagerSecondAmTable mail={mail} />
                            </>
                        }
                        {(role === '4' || role === '5' || role === '10') &&
                            <>
                                <MyAccountsCard data={getBob(accountData)} />
                                <br/>
                                <MyAccountsTableSC data={accountData}></MyAccountsTableSC>
                            </>
                        }
                        {role === '7' &&
                            <MyAccountsTableImpl data={accountData}></MyAccountsTableImpl>
                        }
                    </>
                    }
                    
                </div>
            </Box>
    );
}