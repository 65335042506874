import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

function createData(
    id: number,
    appToken: string,
    appName: string,
    accountId: number,
    impressionWindow: string,
    clickWindow: string,
    fingerprintWindow: string,
    reattributionWindow: string,
    reattributionAttributionWindow: string,
    impressionTrackingEnabled: string,
    fingerprintEnabled: string,
    reattributionEnabled: string,
    impressionFingerprintWindow: string,
    impressionFingerprintEnabled: string,
    impressionReattributionWindow: string,
    impressionReattributionAttributionWindow: string,
    impressionReattributionEnabled: string,
  ) {
    return { id,appToken,appName,accountId, impressionWindow,clickWindow,fingerprintWindow,reattributionWindow,reattributionAttributionWindow,impressionTrackingEnabled,fingerprintEnabled,reattributionEnabled,impressionFingerprintWindow,impressionFingerprintEnabled,impressionReattributionWindow,impressionReattributionAttributionWindow,impressionReattributionEnabled };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }



  const columns: GridColDef[] = [
    { field: 'appToken', headerName: 'App Token',flex: 1,minWidth: 200,
    renderCell: (params : any) => {
      return <Button variant="contained" component={Link} to={"/account/app/"+params.row.appToken} >{params.row.appToken}</Button>;
        },
    },
    { field: 'appName', headerName: 'App Name',flex: 1, minWidth: 200},
    { field: 'accountId', headerName: 'Account ID',flex: 1,minWidth: 200,
    renderCell: (params : any) => {
      return <Button variant="contained" component={Link} to={"/account/"+params.row.accountId} >{params.row.accountId}</Button>;
        },
    },
    { field: 'impressionWindow', headerName: 'Impression Window',flex: 1, minWidth: 200},
    { field: 'clickWindow', headerName: 'Click Window',flex: 1, minWidth: 200},
    { field: 'fingerprintWindow', headerName: 'Fingerprint Window',flex: 1, minWidth: 200},
    { field: 'reattributionWindow', headerName: 'Reattribution Window',flex: 1, minWidth: 200},
    { field: 'reattributionAttributionWindow', headerName: 'Reattribution Attribution Window',flex: 1, minWidth: 250},
    { field: 'impressionTrackingEnabled', headerName: 'Impression Tracking Enabled',flex: 1, minWidth: 200},
    { field: 'fingerprintEnabled', headerName: 'Fingerprint Enabled',flex: 1, minWidth: 200},
    { field: 'reattributionEnabled', headerName: 'Reattribution Enabled',flex: 1, minWidth: 200},
    { field: 'impressionFingerprintWindow', headerName: 'Impression Fingerprint Window',flex: 1, minWidth: 250},
    { field: 'impressionFingerprintEnabled', headerName: 'Impression Fingerprint Enabled',flex: 1, minWidth: 250},
    { field: 'impressionReattributionWindow', headerName: 'Impression Reattribution Window',flex: 1, minWidth: 250},
    { field: 'impressionReattributionAttributionWindow', headerName: 'Impression Reattribution Attribution Window',flex: 1, minWidth: 250},
    { field: 'impressionReattributionEnabled', headerName: 'Impression Reattribution Enabled',flex: 1, minWidth: 250},
  ];

export default function AttributionSettingsTable(_props : any) {
    var attributionsettings = _props.attributionsettings
    const rows : any = [];
    for (var i = 0; i < attributionsettings.length; i++){
        rows.push(
            createData(
                i,
                attributionsettings[i][0],
                attributionsettings[i][1],
                attributionsettings[i][2],
                attributionsettings[i][3],
                attributionsettings[i][4],
                attributionsettings[i][5],
                attributionsettings[i][6],
                attributionsettings[i][7],
                attributionsettings[i][8],
                attributionsettings[i][9],
                attributionsettings[i][10],
                attributionsettings[i][11],
                attributionsettings[i][12],
                attributionsettings[i][13],
                attributionsettings[i][14],
                attributionsettings[i][15]
            )
        );
    }
    return (
        <div style={{ height: 600, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}