import { Box, Grid} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { BaggerOverviewTable } from '../../../entities/components/Tools/BaggerOverviewTable';
import { InfoTooltip } from '../../../shared/components/infoTooltip/InfoTooltip';

export const BaggerOverviewPage = () => {
    const [baggerData,setBaggerData] = useState('');
    const [isLoading,setIsLoading]  = useState(false);

    
    useEffect(() => {
        setIsLoading(true);
        fetch('/api/getbaggeroverview').then(res => res.json()).then(data => {
            setBaggerData(data["data"]);
            setIsLoading(false);
        });
    }, []);

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                 <h1>Bagger API</h1>
                            </Grid>
                            <Grid item xs={12}>
                                {isLoading &&
                                <CircularProgress />
                                }
                                {!isLoading &&
                                    <>
                                        <InfoTooltip message="Source: Internal DB | Refreshed: instant" />
                                        <BaggerOverviewTable data={baggerData}/>
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </div>
                </Box>
    );
}