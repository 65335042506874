import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { AppIcon } from '@adjust/components/build/AppIcon/AppIcon';
import { Button } from '@mui/material';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import InteractiveCheckBox from './UserInteractiveCheckBox';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';
import { InfoTooltip } from '../../../shared/components/infoTooltip/InfoTooltip';

function setCheckBox(confirm : any){
    if(confirm === true){
        return <CheckBoxOutlinedIcon color="success"></CheckBoxOutlinedIcon>
    }
    return <></>
}

function setRedirectButton(userId : any){
    return <form action="https://api.adjust.com/accounts/users/switch.json" target="_blank" accept-charset="UTF-8" method="post">
    <input name="utf8" type="hidden" value="✓"/>
    <input type="hidden" name="authenticity_token" value="Voa+WSemHcT/nTfrgVofsDQ76DiflWnRQS8h7HmlOTl/DjaU4vt2Ky5b/beHORUMo0udGFLNQx4ibYqxLlWaXg=="/>
    <input type="hidden" name="user_id" id="user_id" value={userId} />
    <Button type="submit" name="commit" value="login as"><AppIcon appId="com.adjust.insights" appName="Support Dash"/></Button>
  </form>
}

const columns: GridColDef[] = [
  { field: 'email', headerName: 'Email Address',flex: 1, minWidth: 350},
  { field: 'name', headerName: 'User Name',flex: 1, minWidth: 250},
  { field: 'role', headerName: 'Permissions',flex: 1, minWidth: 150},
  { field: 'createdAt', headerName: 'Created At',flex: 1, minWidth: 150},
  { field: 'agency', headerName: 'Agency',flex: 1, minWidth: 75,
  renderCell: (params : any) => {
    return <InteractiveCheckBox agency={params.row.agency} email={params.row.email}></InteractiveCheckBox>
  }  
  },
  { field: 'mainContact', headerName: 'Main Contact',flex: 1, minWidth: 75,
  renderCell: (params : any) => {
    return <div>{setCheckBox(params.row.mainContact)}</div>
  }  
  },
  { field: 'confirmed', headerName: 'Confirmed',flex: 1, minWidth: 75,
  renderCell: (params : any) => {
    return <div>{setCheckBox(params.row.confirmed)}</div>
  }  
  },
  { field: 'login', headerName: 'Login as',flex: 1, minWidth: 100,sortable: false,
  renderCell: (params : any) => {
    return <div>{setRedirectButton(params.row.id)}</div>
  }  
  },
  { field: 'action', headerName: 'Action',flex: 1, minWidth: 200,sortable: false,
  renderCell: (params : any) => {
    return <>
        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to={"/userhistory/"+params.row.email+"?accountid="+params.row.accountId} >Check History</Button>
    </>;
  },
  },
];

function createData(
    id: number,
    email: string,
    name: string,
    role: string,
    usesDash: string,
    confirmed: string,
    mainContact: boolean,
    agency: boolean,
    createdAt: string,
    accountId: string
  ) {
    return { id,email, name, role, usesDash, confirmed,mainContact,agency,createdAt,accountId };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const infoText = "Source: Adjust DB | Refreshed: instant";

export default function AccountUsersTable(_props : any) {
    const users = _props.users;
    const rows : any = [];
    const accountId = _props.accountId;
    if(users !== undefined){
        for (var i = 0; i < users.length; i++){
            rows.push(
                createData(
                  users[i][0],
                  users[i][1],
                  users[i][2],
                  users[i][3],
                  users[i][4],
                  users[i][5],
                  users[i][7],
                  users[i][8],
                  formatDate(users[i][6]),
                  accountId
                )
            );
        }
    }
    return (
        <div style={{ height: 500, width: '100%' }}>
            <InfoTooltip message={infoText} />
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}