import { Box, Button, Dialog, Grid } from "@mui/material";
import { useState } from "react";
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from "../../../../shared/components/formatDate/FormatDate";

function createData(
    id: number,
    caseId: string,
    amMail: string,
    subject: string,
    createdAt: string,
    accountId: string,
    accountName: string,
    acvAmount: string,
  ) {
    return { id, caseId, amMail, subject, createdAt, accountId, accountName, acvAmount };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

const columns: GridColDef[] = [
    { field: 'caseId', headerName: 'Case Id',flex: 1, minWidth: 150},
    { field: 'amMail', headerName: 'AM',flex: 1, minWidth: 250},
    { field: 'subject', headerName: 'Subject',flex: 1, minWidth: 250},
    { field: 'createdAt', headerName: 'Created At',flex: 1, minWidth: 250},
    { field: 'accountId', headerName: 'Account ID',flex: 1, minWidth: 250},
    { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 250},
    { field: 'acvAmount', headerName: 'Acv Amount',flex: 1, minWidth: 250},

  ];

export const CaseOverviewModal = (_props : any) =>{
    let data = _props.data;
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const rows : any = [];
    if(data.length > 0){
        for (var i = 0; i < data.length; i++){
            rows.push(
                createData(
                    i,
                    data[i][0][0],
                    data[i][0][5],
                    data[i][0][3],
                    formatDate(data[i][0][4]),
                    data[i][0][6],
                    data[i][0][7],
                    data[i][0][8],
                )
            );
        }
    }

    return <>
            <Button onClick={handleOpen} style={{width:'100%'}} variant="outlined">Show Cases</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xl"
                fullWidth
            >
                <Box >
                    <Grid container xs={12} >
                        <Grid item xs={12}>
                            <div style={{ height: 800, width: '100%' }}>
                                <DataGrid 
                                    rows={rows} 
                                    columns={columns}
                                    components={{
                                        Toolbar: CustomToolbar,
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </>
}