import { Box, Button, Dialog, Grid, Typography } from "@mui/material";
import { useState } from "react";

function NewlineText(_props: any) {
    const text = _props.text;
    return text.split('\n').map((str : string) => <h5>{str}</h5>);
  }

export const AppSanAnalysisModal = (_props : any) => {
    const text = _props.text;
    const advertisingid =  '' ;// _props.advertisingid;
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return (
            <>
                <Button onClick={handleOpen} variant="outlined">Show Data</Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    maxWidth="xl"
                    fullWidth
                >
                    <Box >
                    <Typography id="dialog-description">
                        <Grid container xs={12} >
                            <Grid item xs={12}>
                            <div style={{ height: 600 }}>
                                <NewlineText text={text} />
                                <br/><br/>
                                <p>{advertisingid}</p>
                            </div>
                            </Grid>
                        </Grid>
                    </Typography>
                    </Box>
                </Dialog>
            </>
    );
}