import { Chart } from "react-google-charts";

const formatDate = (dateString : string) =>{
    if(dateString === '' || dateString === null){
        return '';
    }
    return new Date(dateString).toLocaleDateString();
}

const setChartValues =(datas : any) =>{
    const data = [
        ["Date","Maus"],
        ["N/A",0],
      ];
    var rows : any = [["Date","Maus"]];
    if(datas !== undefined && datas !== '' ){
        for (let i = 0; i < datas.length; i++) {
            rows.push([formatDate(datas[i][1]), datas[i][2]]);
        }
        return rows;
    }
    return data;
}

export const options = {
    title: "MAUs L12M",
    legend: { 
      position: 'bottom', 
      alignment: 'center' 
    },
    seriesType: "bars",
    series: { 1: { type: "line" } },
    vAxis: {minValue: 0}
  };

export const MausChart = (props : any) =>{
    let maus = props.maus;

  return (
    <div style={{marginLeft:'5px',marginTop:'5px',marginRight:'5px'}}>
      <Chart
        chartType="ComboChart"
        width="100%"
        height="300px"
        data={setChartValues(maus)}
        options={options}
      />
    </div>
  );
}
