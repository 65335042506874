import { Tile } from '@adjust/components';
import { Box, Grid} from '@mui/material';
import { useEffect, useState } from 'react';
import MyAccountsTable from '../../entities/components/MyAccounts/MyAccountsTableAM';
import MyAppsTable from '../../entities/components/Query/MyAppsTable';
import MyContactsTable from '../../entities/components/Query/MyContactsTable';
import QueryAccountSearch from '../../entities/components/Query/QueryAccountSearch';
import QueryAppSearch from '../../entities/components/Query/QueryAppSearch';
import QueryContactSearch from '../../entities/components/Query/QueryContactSearch';
import QueryDropdown from '../../entities/components/Query/QueryDropdown';
import QueryFeatureSearch from '../../entities/components/Query/QueryFeatureSearch';
import { QueryEmailsSearch } from '../../entities/components/Query/QueryEmailsSearch';
import { MyEmailsTable } from '../../entities/components/Query/MyEmailsTable';
  
export const QueryPage = () =>{
    const [tool,setTool] = useState('');
    const [accountData,setAccountData] = useState([]);
    const [appData,setAppData] = useState([]);
    const [feature,setFeature] = useState([]);
    const [contact,setContact]  = useState([]);

    const [queryClassifier,setQueryClassifier]  = useState([]);
    const [queryRegion,setQueryRegion]  = useState([]);
    const [queryVertical,setQueryVertical]  = useState([]);
    const [queryPartner,setQueryPartner]  = useState([]);
    const [queryArea,setQueryArea]  = useState([]);
    
    const [isLoading,setIsLoading]  = useState(false);
   
    useEffect(() => {
      fetch('/api/queryprops').then(res => res.json()).then(data => {
            setQueryClassifier(data["classification"]);
            setQueryRegion(data["region"]);
            setQueryVertical(data["vertical"]);
            setQueryPartner(data["partners"]);
            setQueryArea(data["area"]);
      });
    }, []);

    function handleToolChange(value : string) {setTool(value);}
    function handleAccountDataChange(value : any) {setAccountData(value);}
    function handleAppDataChange(value : any) {setAppData(value);}
    function handleFeatureDataChange(value : any) {setFeature(value);}
    function handleIsLoadingChange(value : any) {setIsLoading(value);}
    function handleContactChange(value : any) {setContact(value);}

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }} style={{marginLeft:'1%', marginTop:'1%'}}>
                    <div>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Tile title="">
                                    <QueryDropdown 
                                    onToolChange={handleToolChange}
                                    tool={tool}
                                    />
                                <br/><br/>
                                {tool === 'Accounts' &&
                                    <>
                                        <QueryAccountSearch 
                                            onAccountChange = {handleAccountDataChange}
                                            queryClassifier = {queryClassifier}
                                            queryRegion = {queryRegion}
                                            queryVertical = {queryVertical}
                                            queryPartner = {queryPartner}
                                            onIsLoadingChange = {handleIsLoadingChange}
                                        />
                                        <MyAccountsTable data = {accountData} isLoading={isLoading}></MyAccountsTable>
                                    </>
                                }
                                {tool === 'Apps' &&
                                    <>
                                        <QueryAppSearch 
                                            onAppChange = {handleAppDataChange}
                                            queryPartner = {queryPartner}
                                            onIsLoadingChange = {handleIsLoadingChange}
                                        />
                                        <MyAppsTable data = {appData} isLoading={isLoading}></MyAppsTable>
                                    </>
                                }
                                {tool === 'Features' &&
                                    <>
                                        <QueryFeatureSearch 
                                            onFeatureChange = {handleFeatureDataChange}
                                            onIsLoadingChange = {handleIsLoadingChange}
                                        />
                                        <MyAccountsTable data = {feature} isLoading={isLoading}></MyAccountsTable>
                                    </>
                                }
                                {tool === 'Contacts' &&
                                    <>
                                        <QueryContactSearch 
                                            onContactChange = {handleContactChange}
                                            onIsLoadingChange = {handleIsLoadingChange}
                                            queryRegion = {queryArea}
                                        />
                                        <MyContactsTable contact = {contact} />
                                    </>
                                }
                                {tool === 'Emails' &&
                                    <>
                                        <QueryEmailsSearch 
                                            onContactChange = {handleContactChange}
                                            onIsLoadingChange = {handleIsLoadingChange}
                                        />
                                        <MyEmailsTable emails = {contact} />
                                    </>
                                }
                                </Tile>
                            </Grid>
                        </Grid>
                        
                    </div>
                </Box>
    );
}