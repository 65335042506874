import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

function setEvents(events : any){
    var rows : any = []
    if(events !== undefined){
        for (var i = 0; i < events.length; i++) {
            rows.push(<TableRow key={events[i][1]+events[i][2]}>
                <TableCell component="th" scope="row">{events[i][2]}</TableCell>
                <TableCell component="th" scope="row">{events[i][1]}</TableCell>
                <TableCell component="th" scope="row">{events[i][3]}</TableCell>
                <TableCell component="th" scope="row">{formatDate(events[i][0])}</TableCell>
            </TableRow>
            )
           }
         }
         return <>
             {rows}
         </>
}
export default function PartnerSettingsEventTable(_props : any) {
  const events = _props["events"];

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
                <TableCell>Event</TableCell>
                <TableCell>Event Token</TableCell>
                <TableCell>OS</TableCell>
                <TableCell>Updated At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {setEvents(events)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}