import { Badge } from '@adjust/components/build/Badge/Badge';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { InfoTooltip } from '../../../shared/components/infoTooltip/InfoTooltip';

function createBadgePositive(number: number, name: string){
    if (number === 1){
      return <TableRow
                key={name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell >{name}</TableCell>
                <TableCell align="center"><div><Badge label="Enabled" color="positive" /></div></TableCell>
            </TableRow>
    }
}
function createBadgeNegative(number: number, name: string){
    if (number !== 1){
        return <TableRow
                    key={name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell >{name}</TableCell>
                    <TableCell align="center"><div><Badge label="Not Activated" color="negative" /></div></TableCell>
                </TableRow>
    }
}

const infoText = "Source: Metabase / Data Science | Refreshed: once a day";

export const FeatureTable = (_props : any) =>{
    const features = _props.features;
    return(
        <div>
            <InfoTooltip message={infoText} />
            <TableContainer component={Paper}>
                 <Table sx={{ minWidth: 650 }} aria-label="table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Feature</TableCell>
                            <TableCell align="center">Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {features.map((row:any) => (
                        createBadgePositive(row.active, row.name)
                    ))}
                    {features.map((row:any) => (
                        createBadgeNegative(row.active, row.name)
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}