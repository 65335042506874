import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

const columns: GridColDef[] = [
  { field: 'accountId', headerName: 'Account ID',flex: 1, minWidth: 100},
  { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 100},
  { field: 'email', headerName: 'Email',flex: 1, minWidth: 100},
  { field: 'userName', headerName: 'Username',flex: 1, minWidth: 100},
  { field: 'role', headerName: 'Role',flex: 1, minWidth: 100},
  { field: 'createdAt', headerName: 'Created At',flex: 1, minWidth: 100},
];

function createData(
    id: number,
    accountId: number,
    accountName: string,
    email: string,
    userName: string,
    role: string,
    createdAt: string
  ) {
    return { id,accountId, accountName, email, userName,role,createdAt };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export const DeliveryHeroTable = (_props : any) => {
    const rows : any = [];
    const user = _props.user;
    if(user !== undefined){
        for (var i = 0; i < user.length; i++){
            rows.push(
                createData(
                    i,
                    user[i][0],
                    user[i][1],
                    user[i][2],
                    user[i][3],
                    user[i][4],
                    formatDate(user[i][5]),
                )
            );
        }
    }
    return (
        <div style={{ height: 800, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}
