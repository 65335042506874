import { Breadcrumbs } from '@adjust/components/build/Breadcrumbs/Breadcrumbs';
import { Tile } from '@adjust/components/build/Tile/Tile';
import { Grid} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppCsvUploads from '../../../entities/components/Apps/AppCsvUploads';
import AppUninstallDetector from '../../../entities/components/Apps/AppUninstallDetector';
import AppInfo from '../../../shared/components/appInfo/AppInfo';
import { AppSegmentedButton } from '../../../shared/components/appSegmentedButton/AppSegmentedButton';
import { InfoTooltip } from '../../../shared/components/infoTooltip/InfoTooltip';

function MyBreadcrumbs(accountId:any,accountName:any,appToken:any,appName:any) {
    let navigate = useNavigate();
    const items = [
        { label: 'My Accounts', onClick: () => {navigate('/myaccounts')}},
        { label: accountName, onClick: () => {navigate('/account/' + accountId)}},
        { label: 'Apps', onClick: () => {navigate('/account/' + accountId + '/apps')}},
        { label: appName, onClick: () => {navigate('/account/app/'+ appToken)}},
        { label: 'Ceph Datapoints', onClick: () => {navigate('/account/app/' + appToken + '/ceph')}},
    ];
    return <Breadcrumbs items={items} />;
}

export const AppCephDatapointsPage = (_props:any) => {
    const appToken = _props.appToken;
    const appInfo = _props.appInfo;
    const accountName = _props.accountName ;
    
      const [csvUploads, setCsvUploads] = useState([]);
      const [uninstallDetector, setUninstallDetector] = useState([]);
      useEffect(() => {
          fetch('/api/appceph/'+appToken).then(res => res.json()).then(data => {
            setCsvUploads(data["csvUploads"]);
            setUninstallDetector(data["uninstallDetector"]);
          });
      }, [appToken]);
    
    return (
                    <div>
                        {MyBreadcrumbs(appInfo[3],accountName,appInfo[2],appInfo[1])}
                        <AppSegmentedButton appToken = {appToken} tabValue='6' />
                        <br/><br/>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Grid container spacing={6}>
                                    <Grid item xs={4}>
                                        <AppInfo appInfo={appInfo} accountName={accountName} />
                                    </Grid> 
                                    {csvUploads.length > 0 && 
                                    <Grid item xs={8}>
                                        <Tile title="CSV uploads to S3 and GCS">
                                            <InfoTooltip message="Source: Internal Ceph Script | Refreshed: daily" />
                                            <AppCsvUploads csvUploads={csvUploads} />
                                        </Tile>
                                    </Grid> 
                                    }
                                    {uninstallDetector.length > 0 && 
                                    <Grid item xs={8}>
                                        <Tile title="Uninstall Detector">
                                            <InfoTooltip message="Source: Internal Ceph Script | Refreshed: daily" />
                                            <AppUninstallDetector uninstallDetector={uninstallDetector} />
                                        </Tile>
                                    </Grid> 
                                    }
                                </Grid>
                            </Grid>


                        </Grid>
                    </div>
    );
}