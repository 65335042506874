import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
}

const columns: GridColDef[] = [
    { field: 'date', headerName: 'Date',flex: 1, minWidth: 180},
    { field: 'uploadedFiles', headerName: 'Uploaded Files',flex: 1, minWidth: 150},
  ];

function createData(
    id: number,
    date: string,
    status: string,
    uploadedFiles: string,
    
  ){
    return { id, date, status, uploadedFiles };
  }

export default function AppCsvUploads(props : any) {
    var csvUploads = props.csvUploads;
    const csvData : any = [];
    if(csvUploads !== undefined){
        for (var i = 0; i < csvUploads.length; i++){
            csvData.push(
                createData(
                    i,
                    formatDate(csvUploads[i][0]),
                    csvUploads[i][1],
                    csvUploads[i][2],
                )
            );
        }
    }
    return (
        <div style={{height: 600, width: '100%'}}>
                <DataGrid 
                    rows={csvData} 
                    columns={columns}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                /> 
        </div>
    );
}