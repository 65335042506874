import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import {NumericFormat} from 'react-number-format';
import { formatDate } from '../../../../shared/components/formatDate/FormatDate';

const columns: GridColDef[] = [
  { field: 'date', headerName: 'Date',flex: 1, maxWidth: 150},
  { field: 'actualValue', headerName: 'Actual Value',flex: 1, maxWidth: 150,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.actualValue} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'maxValue', headerName: 'Max Value',flex: 1, maxWidth: 150,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.maxValue} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'id', headerName: 'Used in %',flex: 1, maxWidth: 150, 
  renderCell: (params : any) => {
    return <>{ Math.round((params.row.actualValue / params.row.maxValue) * 100)}</>;
  }}
];

function createData(
    id: number,
    date: string,
    actualValue: string,
    maxValue: string
  ) {
    return { id,date, actualValue, maxValue};
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export default function KeyStatsTable(_props : any) {
    const attributions = _props.keystat;
    const rows : any = [];
    if(attributions !== undefined){
        for (var i = 0; i < attributions.length; i++){
            rows.push(
                createData(
                    attributions[i][0],
                    formatDate(attributions[i][1]),
                    attributions[i][2],
                    attributions[i][3],
                    
                )
            );
        }
    }
    return (
        <div style={{ height: 300, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}
