import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { formatDate } from "../../../shared/components/formatDate/FormatDate";
import { ImplementationModal } from "./ImplementationModal";


export const SummaryTable = (_props: any) =>{
    const data = _props.data;
    const lastRunTime = _props.lastRunTime;
    return(
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 150 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>SDK Component</TableCell>
                        <TableCell>Last Time Checked</TableCell>
                        <TableCell>Check Data</TableCell>
                    </TableRow>
                </TableHead>
                    <TableBody>
                        <TableRow
                            key="Summary"
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell>Summary</TableCell>
                            <TableCell>{formatDate(lastRunTime)}</TableCell>
                            <TableCell><ImplementationModal data={data.summary} type="summary" /></TableCell>
                        </TableRow>


                </TableBody>
            </Table>
        </TableContainer>
    );
}