import { TableCell } from "@mui/material";

export const StatusCellAndroid = (_props : any) => {
    const status = _props.status;
    return (
        <>
            {status === 3 &&
            <TableCell style={{backgroundColor:"#ef5350", color:'white', width: '300px' }}> No available data</TableCell>
            }
            {status === 1 &&
            <TableCell style={{backgroundColor:"#4caf50", color:'white', width: '300px' }}> Data Available</TableCell>
            }
            {status === 0 &&
            <TableCell style={{backgroundColor:"#03a9f4", color:'white', width: '300px' }}> No data yet!</TableCell>
            }
        </>
    );
}

export const StatusCellIos = (_props : any) => {
    const status = _props.status;

    return (
        <>
            {status === 3 &&
            <TableCell style={{backgroundColor:"#ef5350", color:'white', width: '300px' }}> No available data</TableCell>
            }
            {status === 1 &&
            <TableCell style={{backgroundColor:"#4caf50", color:'white', width: '300px' }}> Data Available</TableCell>
            }
            {status === 0 &&
            <TableCell style={{backgroundColor:"#03a9f4", color:'white', width: '300px' }}> No data yet!</TableCell>
            }
        </>
    );
}

export const StatusCellOthers = (_props : any) => {
    const status = _props.status;
    return (
        <>
            {status === 3 &&
            <TableCell style={{backgroundColor:"#ef5350", color:'white', width: '150px' }}> No available data</TableCell>
            }
            {status === 1 &&
            <TableCell style={{backgroundColor:"#4caf50", color:'white', width: '150px' }}> Data Available</TableCell>
            }
            {status === 0 &&
            <TableCell style={{backgroundColor:"#03a9f4", color:'white', width: '150px' }}> No data yet!</TableCell>
            }
        </>
    );
}