import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

const columns: GridColDef[] = [
    { field: 'userId', headerName: 'User Id',flex: 1},
    { field: 'accountIds', headerName: 'Account IDs',flex: 1},
    { field: 'totalErrors', headerName: 'Total Errors',flex: 1},
    { field: 'errorsPerDay', headerName: 'Errors Per Day',flex: 1},
    { field: 'errorType', headerName: 'Error Type',flex: 1},
    { field: 'requestSource', headerName: 'Request Source',flex: 1},
];

function createData(
    id: number,
    userId: number,
    accountIds: string,
    totalErrors: number,
    errorsPerDay: number,
    errorType: string,
    requestSource: string
  ) {
    return { id,userId, accountIds, totalErrors, errorsPerDay, errorType, requestSource};
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export const KpiErrorsTable = (_props : any) => {
    const errors = _props.errors;
    const rows : any = [];
    if(errors !== undefined){
        for (var i = 0; i < errors.length; i++){
            rows.push(
                createData(
                    i,
                    errors[i][2],
                    errors[i][4],
                    errors[i][0],
                    errors[i][1],
                    errors[i][6],
                    errors[i][5],
                )
            );
        }
    }
    return (
        <div style={{ height: 450, width: '100%', marginTop:_props.marginTop}}>
            <h3>{_props.name}</h3>
            <DataGrid
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}
