import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const formatDate = (dateString : string) =>{
    if(dateString === '' || dateString === null){
        return '';
    }
    return new Date(dateString).toLocaleDateString();
}

function createData(
    id: number,
    accountId: number,
    accountName: string,
    classification: string,
    region: string,
    owner: string,
    am: string,
    signedUp: string,
  ) {
    return { id,accountId,accountName,classification,region,owner,am,signedUp};
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const columns: GridColDef[] = [
    { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 350,
    renderCell: (params : any) => {
      return <Button variant="contained" component={Link} to={"/account/"+params.row.accountId} >{params.row.accountName}</Button>;
        },
    },
    { field: 'accountId', headerName: 'Account ID',flex: 1, minWidth: 150},
    { field: 'am', headerName: 'Aaccount Manager',flex: 1, minWidth: 150},
    { field: 'owner', headerName: 'Account Owner',flex: 1, minWidth: 150},
    { field: 'region', headerName: 'Region',flex: 1, minWidth: 150},
    { field: 'signedUp', headerName: 'Signed Up Date',flex: 1, minWidth: 150},
    { field: 'classification', headerName: 'Client Classification',flex: 1, minWidth: 150}
  ];

export default function MySuiteAccountTable(_props : any) {
    var accounts = _props.accounts
    const rows : any = [];
    for (var i = 0; i < accounts.length; i++){
        rows.push(
            createData(
                i,
                accounts[i][0],
                accounts[i][1],
                accounts[i][2],
                accounts[i][3],
                accounts[i][4],
                accounts[i][5],
                formatDate(accounts[i][6]),
            )
        );
    }
    return (
        <div style={{ height: 500, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}