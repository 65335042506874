import * as React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

function createData(
  id: number,
  trackerToken: number
) {
  return { id, trackerToken };
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const columns: GridColDef[] = [
  { field: 'trackerToken', headerName: 'Tracker Token',flex: 1, minWidth: 100},
]

export const AllowedTrackerTable = (_props : any) =>{
  const tracker = _props.tracker;
  const rows : any = [];
  for (var i = 0; i < tracker.length; i++){
    rows.push(
      createData(
        i,
        tracker[i][0],
      )
      );
    }

  return (
    <div style={{ height: 425, width: '100%' }}>
      <DataGrid 
        rows={rows} 
        columns={columns}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}