import { Button, CircularProgress, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import { SingleDatePicker } from "@adjust/components";

const dateWithoutTimezone = (date: Date) => {
    const tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
    const withoutTimezone = new Date(date.valueOf() - tzoffset)
      .toISOString()
      .slice(0, -1);
    return withoutTimezone;
}

export const AdRevenueRefetchJob = () => {
    const [accountId,setAccountId] = useState(0);
    const [appToken,setAppToken]  = useState('');
    const [platform,setPlatform]  = useState('android');
    const [source,setSource]  = useState('');
    const [startDate,setStartDate]  = useState<Date>(new Date());
    const [endDate,setEndDate]  = useState<Date>(new Date());
    const [resendCallbacks,setResendCallbacks]  = useState('True');
    const [error,setError]  = useState('');
    const [isLoading,setIsLoading]  = useState(false);
    const [data,setData]  = useState({data:'', message:''});
    
    const refetchJob = () => {
        if(accountId === 0 || appToken === '' || source === ''){
            setError('Please fill all the fields above');
        }
        else{
            setError('');
            setIsLoading(true);
            const refetchData = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({accountId,appToken,platform,source,"startDate":dateWithoutTimezone(startDate),"endDate":dateWithoutTimezone(endDate),resendCallbacks}),
            }
            console.log(refetchData)
            fetch('/api/adrevenuerefetchjob', refetchData).then(response => response.json())
            .then((data) => {
                setIsLoading(false);
                setData(data);
            });
        }
    }

    const refetchJobStatus = () => {
        if(accountId === 0 || appToken === '' || source === ''){
            setError('Please fill all the fields above');
        }
        else{
            setError('');
            setIsLoading(true);
            const refetchData = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({accountId,appToken,platform,source,startDate,endDate}),
            }
            fetch('/api/adrevenuerefetchjobstatus', refetchData).then(response => response.json())
            .then((data) => {
                setIsLoading(false);
                setData(data);
            });
        }
    }

    return(
        <>
            <Grid container spacing={0}>
                <Grid item xs={1}>
                    <InputLabel id="accountIdLabel">Account Id</InputLabel>
                    <TextField
                        id="accountId" 
                        name="accountId"
                        value={accountId}
                        type='number'
                        style={{width:'80%'}}
                        onChange={(e) => {
                            setAccountId(parseInt(e.target.value));
                        }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <InputLabel id="appTokenLabel">App Token</InputLabel>
                    <TextField
                        id="appToken" 
                        name="appToken"
                        value={appToken}
                        onChange={(e) => {
                            setAppToken(e.target.value);
                        }}
                    />
                </Grid>
                <Grid item xs={1}>
                    <InputLabel id="platformDropdown">Platform</InputLabel>
                    <Select
                        labelId="platformSelect"
                        id="platformSelect"
                        value={platform}
                        label="platform"
                        onChange={(e) => {
                            setPlatform(e.target.value);
                        }}
                    >
                        <MenuItem value="android" key="android">Android</MenuItem>
                        <MenuItem value="ios" key="ios">iOS</MenuItem>
                        <MenuItem value="multi" key="multi">Multiplatform</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={2}>
                    <InputLabel id="sourceDropdown">Source</InputLabel>
                    <Select
                        labelId="sourceSelect"
                        id="source"
                        value={source}
                        label="source"
                        onChange={(e) => {
                            setSource(e.target.value);
                        }}
                        style={{width:'80%'}}
                    >
                        <MenuItem value="admost" key="Admost">Admost</MenuItem>
                        <MenuItem value="applovin" key="AppLovin Max">AppLovin Max</MenuItem>
                        <MenuItem value="applovin_max_fb_only" key="AppLovin FB only">AppLovin FB only</MenuItem>
                        <MenuItem value="ironsource" key="IronSource">IronSource</MenuItem>
                        <MenuItem value="topon" key="TopOn">TopOn</MenuItem>
                    </Select>
                </Grid>

                <Grid item xs={2}>
                    <InputLabel id="startDateLabel">Start Date</InputLabel>
                    <SingleDatePicker
                        date={startDate}
                        onChange={(date) => {
                        setStartDate(date);
                        }}
                    />
                </Grid>

                <Grid item xs={2}>
                    <InputLabel id="endDateLabel">End Date</InputLabel>
                    <SingleDatePicker
                        date={endDate}
                        onChange={(date) => {
                        setEndDate(date);
                        }}
                    />
                    
                </Grid>

                <Grid item xs={2}>
                    <InputLabel id="resendCallbacksDropdown">Resend Raw-Data Exports</InputLabel>
                    <Select
                        labelId="resendCallbacksSelect"
                        id="resendCallbacksSelect"
                        value={resendCallbacks}
                        label="resendCallbacks"
                        onChange={(e) => {
                            setResendCallbacks(e.target.value);
                        }}
                    >
                        <MenuItem value='False' >False</MenuItem>
                        <MenuItem value='True' >True</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            <br/>
            <Button variant="contained" onClick={refetchJob} color="error">Refetch</Button>
            <Button variant="contained" onClick={refetchJobStatus} color="success" style={{marginLeft:'5px'}}>Last Refetch Status</Button>
            
            {error.length > 0 &&
            <p style={{color:'red'}}>{error}</p>
            }
            {isLoading &&
            <CircularProgress />
            }
            {data.message !== '' &&
            <p style={{color:'red'}}>{data.message}</p>
            }
            {data.data !== '' &&
            <pre>{JSON.stringify(data, null, 2)}</pre>
            }
    </>
    );
}