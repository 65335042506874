import { Route, Routes } from "react-router-dom";
import { AccountPricingPage } from "../../pages/accountPricing/AccountPricingPage";
import { AdminPage } from "../../pages/admin/Admin";
import AppPartnerSettingsPage from "../../pages/appPartnerSettings/AppPartnerSettings";
import { DeliveryHeroPage } from "../../pages/deliveryHero/DeliveryHero";
import Domo from "../../pages/domo/Domo";
import ManageraccountsPage from "../../pages/managerAccounts/MangerAccounts";
import MyAccountsPage from "../../pages/myAccounts/MyAccounts";
import { OverviewPage } from "../../pages/overview/OverviewPage";
import { PartnerPage } from "../../pages/partner/PartnerPage";
import SearchPage from "../../pages/search/Search";
import SettingsPage from "../../pages/settings/Settings";
import { TeamPage } from "../../pages/team/Team";
import { UpdatesPage } from "../../pages/updates/Updates";
import { UserHistoryPage } from "../../pages/userHistory/UserHistory";
import { WelcomeBackPage } from "../../pages/welcomeBack/WelcomeBackPage";
import { BqgUsagePage } from "../../pages/managerPages/BqgUsagePage";
import NotFound from "../../pages/notFound/NotFound";
import { Box } from "@mui/material";
import { SideBar } from "../../shared/components/sideBar/SideBar";
import { NotificationPage } from "../../pages/notifications/NotificationPage";
import { AmCaseViewPage } from "../../pages/amCaseView/AmCaseViewPage";
import { OverviewPageSales } from "../../pages/overview/OverviewPageSales";

export const SitesWithAuthRouter = () => {
    return(
        <Box sx={{ display: 'flex',marginTop:'4%',marginLeft:'0%',marginRight:'2%'}}>
            <SideBar/>
            <Routes>
                <Route path="/settings" element={<SettingsPage />} /> 
                <Route path="/myaccounts" element={<MyAccountsPage />} />
                <Route path="/search/:searchvalue" element={<SearchPage />} /> 
                <Route path="/account/app/settings/partner/:appToken/:partnerId" element={<AppPartnerSettingsPage />} />
                <Route path="/partners/:partnerId" element={<PartnerPage />} />
                <Route path="/userhistory/:email" element={<UserHistoryPage />} />
                <Route path="/team/" element={<TeamPage/>} />
                <Route path="/domo" element={<Domo/>} />
                <Route path="/admin" element={<AdminPage />} />
                <Route path="/overview" element={<OverviewPage />} />
                <Route path="/overviews" element={<OverviewPageSales />} />
                <Route path="/amoverview/:amMail" element={<ManageraccountsPage />} />
                <Route path="/amcaseview/:amMail" element={<AmCaseViewPage />} />
                <Route path="/amcaseview" element={<AmCaseViewPage />} />
                <Route path="/updates" element={<UpdatesPage />} />
                <Route path="/deliveryhero" element={<DeliveryHeroPage />} />
                <Route path="/welcomeback" element={<WelcomeBackPage />} />
                <Route path="/accountpricing" element={<AccountPricingPage />} />
                <Route path="/bqgusage" element={<BqgUsagePage />} />
                <Route path="/notifications" element={<NotificationPage />} />
                
                <Route path="*" element={<NotFound />}></Route>
            </Routes>
        </Box>
    );
}