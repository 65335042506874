import { Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";

export const TagList = (_props: any) => {
    const service = _props.service;

    const [tags,setTags] = useState({
        install: false,
        session: false,
        reattribution: false,
        event: false,
        click: false,
        impression: false,
        rejected_install: false,
        rejected_reattribution: false,
        sdk_click: false,
        uninstall: false,
        reinstall: false,
        sk_install: false,
        sk_install_direct: false,
        sk_event: false,
        sk_qualifier: false,

        ad_revenue: false,
        attribution: false,
        att_update: false,
        callback: false,
        cost_update: false,
        error: false,
        forget_device: false,
        gdpr_forget_device: false,
        inspect_device: false,
        inspect_device_generic: false,
        inspect_engagement: false,
        optout: false,
        reattribution_reinstall: false,
        redirect: false,
        san_click: false,
        san_impression: false,
        sdk_info: false,
        subscription: false,
        third_party_sharing: false,
        unbotify_obvservation: false,
        unbotify_prediction: false,
        update: false,
        sdk_debug: false
    });

    const { install, session, reattribution, event, click,impression, rejected_install, rejected_reattribution, sdk_click, uninstall,
            reinstall, sk_install, sk_install_direct, sk_event, sk_qualifier,
            ad_revenue, attribution,att_update,callback,cost_update,error,forget_device,gdpr_forget_device,inspect_device,inspect_device_generic,
            inspect_engagement,optout,reattribution_reinstall,redirect,san_click,san_impression,sdk_info,subscription,third_party_sharing,
            unbotify_obvservation,unbotify_prediction,update,sdk_debug } = tags;


    useEffect(() => {
        _props.onTagsChange(tags);
    },[tags]);
    
    const labelIsActive = (adjustServer : boolean, callbackWorker: boolean, attributionWorker : boolean) => {
        if(adjustServer && service === "adjust_server"){
            return false;
        }
        if(callbackWorker && service === "callback_worker"){
            return false;
        }
        if(attributionWorker && service === "attribution_worker"){
            return false;
        }
        return true;
    }
    return (
        <div>
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(true,true,true)} value={install} onClick={(newValue:any) => setTags({...tags, install:newValue.target.checked})}/>} label="install" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(true,true,true)} value={session} onClick={(newValue:any) => setTags({...tags, session:newValue.target.checked})}/>} label="session" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(false,true,true)} value={reattribution} onClick={(newValue:any) => setTags({...tags, reattribution:newValue.target.checked})}/>} label="reattribution" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(true,true,false)} value={event} onClick={(newValue:any) => setTags({...tags, event:newValue.target.checked})}/>} label="event" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(true,true,false)} value={click} onClick={(newValue:any) => setTags({...tags, click:newValue.target.checked})}/>} label="click" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(true,true,false)} value={impression} onClick={(newValue:any) => setTags({...tags, impression:newValue.target.checked})}/>} label="impression" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(false,true,true)} value={rejected_install} onClick={(newValue:any) => setTags({...tags, rejected_install:newValue.target.checked})}/>} label="rejected_install" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(false,true,true)} value={rejected_reattribution} onClick={(newValue:any) => setTags({...tags, rejected_reattribution:newValue.target.checked})}/>} label="rejected_reattribution" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(true,false,false)} value={sdk_click} onClick={(newValue:any) => setTags({...tags, sdk_click:newValue.target.checked})}/>} label="sdk_click" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(false,true,false)} value={uninstall} onClick={(newValue:any) => setTags({...tags, uninstall:newValue.target.checked})}/>} label="uninstall" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(false,true,true)} value={reinstall} onClick={(newValue:any) => setTags({...tags, reinstall:newValue.target.checked})}/>} label="reinstall" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(true,true,false)} value={sk_install} onClick={(newValue:any) => setTags({...tags, sk_install:newValue.target.checked})}/>} label="sk_install" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(true,true,false)} value={sk_install_direct} onClick={(newValue:any) => setTags({...tags, sk_install_direct:newValue.target.checked})}/>} label="sk_install_direct" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(false,true,false)} value={sk_event} onClick={(newValue:any) => setTags({...tags, sk_event:newValue.target.checked})}/>} label="sk_event" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(false,true,false)} value={sk_qualifier} onClick={(newValue:any) => setTags({...tags, sk_qualifier:newValue.target.checked})}/>} label="sk_qualifier" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(true,true,false)} value={sdk_debug} onClick={(newValue:any) => setTags({...tags, sdk_debug:newValue.target.checked})}/>} label="sdk_debug" />
            <br/><br/>
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(true,true,false)} value={ad_revenue} onClick={(newValue:any) => setTags({...tags, ad_revenue:newValue.target.checked})}/>} label="ad_revenue" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(true,false,true)} value={attribution} onClick={(newValue:any) => setTags({...tags, attribution:newValue.target.checked})}/>} label="attribution" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(false,true,false)} value={att_update} onClick={(newValue:any) => setTags({...tags, att_update:newValue.target.checked})}/>} label="att_update" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(false,true,false)} value={callback} onClick={(newValue:any) => setTags({...tags, callback:newValue.target.checked})}/>} label="callback" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(false,true,false)} value={cost_update} onClick={(newValue:any) => setTags({...tags, cost_update:newValue.target.checked})}/>} label="cost_update" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(true,false,false)} value={error} onClick={(newValue:any) => setTags({...tags, error:newValue.target.checked})}/>} label="error" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(true,false,false)} value={forget_device} onClick={(newValue:any) => setTags({...tags, forget_device:newValue.target.checked})}/>} label="forget_device" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(true,true,false)} value={gdpr_forget_device} onClick={(newValue:any) => setTags({...tags, gdpr_forget_device:newValue.target.checked})}/>} label="gdpr_forget_device" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(true,false,false)} value={inspect_device} onClick={(newValue:any) => setTags({...tags, inspect_device:newValue.target.checked})}/>} label="inspect_device" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(true,false,false)} value={inspect_device_generic} onClick={(newValue:any) => setTags({...tags, inspect_device_generic:newValue.target.checked})}/>} label="inspect_device_generic" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(true,false,false)} value={inspect_engagement} onClick={(newValue:any) => setTags({...tags, inspect_engagement:newValue.target.checked})}/>} label="inspect_engagement" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(true,false,false)} value={optout} onClick={(newValue:any) => setTags({...tags, optout:newValue.target.checked})}/>} label="optout" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(false,true,true)} value={reattribution_reinstall} onClick={(newValue:any) => setTags({...tags, reattribution_reinstall:newValue.target.checked})}/>} label="reattribution_reinstall" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(true,false,false)} value={redirect} onClick={(newValue:any) => setTags({...tags, redirect:newValue.target.checked})}/>} label="redirect" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(false,true,false)} value={san_click} onClick={(newValue:any) => setTags({...tags, san_click:newValue.target.checked})}/>} label="san_click" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(false,true,false)} value={san_impression} onClick={(newValue:any) => setTags({...tags, san_impression:newValue.target.checked})}/>} label="san_impression" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(true,false,false)} value={sdk_info} onClick={(newValue:any) => setTags({...tags, sdk_info:newValue.target.checked})}/>} label="sdk_info" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(false,true,false)} value={subscription} onClick={(newValue:any) => setTags({...tags, subscription:newValue.target.checked})}/>} label="subscription" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(true,false,false)} value={third_party_sharing} onClick={(newValue:any) => setTags({...tags, third_party_sharing:newValue.target.checked})}/>} label="third_party_sharing" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(true,true,false)} value={unbotify_obvservation} onClick={(newValue:any) => setTags({...tags, unbotify_obvservation:newValue.target.checked})}/>} label="unbotify_obvservation" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(true,true,false)} value={unbotify_prediction} onClick={(newValue:any) => setTags({...tags, unbotify_prediction:newValue.target.checked})}/>} label="unbotify_prediction" />
            <FormControlLabel style={{width:'30%'}} control={<Checkbox disabled={labelIsActive(false,true,false)} value={update} onClick={(newValue:any) => setTags({...tags, update:newValue.target.checked})}/>} label="update" />

        </div>
    )
}