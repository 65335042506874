import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { NumericFormat } from 'react-number-format';

function createData(
    id: number,
    accountId: number,
    accountName: string,
    usage: number,
  ) {
    return { id, accountId, accountName, usage };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export const MyAccountsUsageTable = (_props : any) =>{
    let accounts = _props.accounts;
    const accountData : any = [];
    if(accounts !== undefined){
        for (var i = 0; i < accounts.length; i++){
            accountData.push(
                createData(
                    i,
                    accounts[i]["accountId"],
                    accounts[i]["accountName"],
                    accounts[i]["usage"],
                )
            );
        }
    }

    const columns: GridColDef[] = [
        { field: 'accountId', headerName: 'Account Id',flex: 1, minWidth: 150},
        { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 150},
        { field: 'usage', headerName: 'Usage',flex: 1, minWidth: 150,
            renderCell: (params : any) => {
              return <NumericFormat value={params.row.usage} displayType={'text'} thousandSeparator={true}/>
            }},
      ];
      
    return (
        <div style={{ height: 650, width: '100%' }}>
            <DataGrid 
                rows={accountData} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}