import React, { useContext } from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button } from '@adjust/components';
import { Alert, Snackbar } from '@mui/material';
import { AuthContext } from '../../../app/store/AuthContext';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

function createData(
    id: number,
    date: string,
    accountName: string,
    accountId: string,
    salesforceId: string,
    appName: string,
    appToken: string,
    trackerName: Number,
    trackerToken: string,
    accountOwner: string,
    accountManager: string,
    salesManager: string,
    salesEngineer: string,
    clicks: number,
    attributions:  number,
    conversionRate: string,
    lowQualityWindow: string,
    allowed: string,
    partnerId: string,
    partnerName: string
  ) {
    return { id, date, accountName, accountId, salesforceId, appName, appToken, trackerName, trackerToken,  accountOwner, accountManager, salesManager, salesEngineer, clicks, attributions, conversionRate, lowQualityWindow, allowed, partnerId, partnerName };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export default function ClickSpamTable(_props : any) {
    const [openSnackBarApp, setOpenSnackBarApp] = React.useState(false);
    const [openSnackBarTracker, setOpenSnackBarTracker] = React.useState(false);
    const [openSnackBarUnallow, setOpenSnackBarUnallow] = React.useState(false);
    const [openSnackBarTrackerUnallow, setOpenSnackBarTrackerUnallow] = React.useState(false);
    const value = useContext(AuthContext);
    const role = value?.auth.role;
    const rows : any = [];

    for (var i = 0; i < _props.data.length; i++){
        rows.push(
            createData(
                i,
                formatDate(_props.data[i]["date"]),
                _props.data[i]["accountName"],
                _props.data[i]["accountId"],
                _props.data[i]["salesforceId"],
                _props.data[i]["appName"],
                _props.data[i]["appToken"],
                _props.data[i]["trackerName"],
                _props.data[i]["trackerToken"],
                _props.data[i]["accountOwner"],
                _props.data[i]["accountManager"],
                _props.data[i]["salesManager"],
                _props.data[i]["salesEngineer"],
                _props.data[i]["clicks"],
                _props.data[i]["attributions"],
                _props.data[i]["conversionRate"],
                _props.data[i]["lowQualityWindow"],
                _props.data[i]["allowed"],
                _props.data[i]["partnerId"],
                _props.data[i]["partnerName"]
            )
        );
    }

    function allowAppToken(appToken : any) {
        const tracker = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ appToken }),
        }
        fetch('/api/setallowedclickspamapp', tracker).then(response => response.json()).then(function (data) {
            setOpenSnackBarApp(true);
        });
    }

    function unAllowAppToken(appToken : any) {
        const tracker = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ appToken }),
        }
        fetch('/api/setunallowedclickspamapp', tracker).then(response => response.json()).then(function (data) {
            setOpenSnackBarUnallow(true);
        });
        
    }

    function allowTrackerToken(trackerToken : any) {
        const tracker = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ trackerToken }),
        }
        fetch('/api/setallowedclickspamtracker', tracker).then(response => response.json()).then(function (data) {
            setOpenSnackBarTracker(true);
        });
    }

    function unAllowTracker(trackerToken : any) {
        const tracker = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ trackerToken }),
        }
        fetch('/api/setunallowedclickspamtracker', tracker).then(response => response.json()).then(function (data) {
            setOpenSnackBarTrackerUnallow(true);
        });
        
    }

    const columns_allowed: GridColDef[] = [
        { field: 'date', headerName: 'Created At',flex: 1, minWidth: 150},
        { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 150},
        { field: 'accountId', headerName: 'Account Id',flex: 1, minWidth: 150},
        { field: 'appName', headerName: 'App Name',flex: 1, minWidth: 150},
        { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 150},
        { field: 'trackerName', headerName: 'Tracker Name',flex: 1, minWidth: 150},
        { field: 'trackerToken', headerName: 'Tracker Token',flex: 1, minWidth: 150},
        { field: 'partnerName', headerName: 'Partner Name',flex: 1, minWidth: 150},
        { field: 'attributions', headerName: 'Attributions',flex: 1, minWidth: 150},
        { field: 'clicks', headerName: 'Clicks',flex: 1, minWidth: 150},
        { field: 'conversionRate', headerName: 'Conversion Rate',flex: 1, minWidth: 150},
        { field: 'accountOwner', headerName: 'Account Owner',flex: 1, minWidth: 150},
        { field: 'accountManager', headerName: 'Account Manager',flex: 1, minWidth: 150},
        { field: 'salesManager', headerName: 'Sales Manager',flex: 1, minWidth: 150},
        { field: 'salesEngineer', headerName: 'Sales Engineer',flex: 1, minWidth: 150},
        { field: 'lowQualityWindow', headerName: 'Low Quality Window',flex: 1, minWidth: 150},
        { field: 'allowed', headerName: 'Allowed',flex: 1, minWidth: 150},
        { field: 'abc', headerName: 'Allow App Token',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
          return <Button label="Allow" onClick={() => allowAppToken(params.row.appToken)} ></Button>; 
          },
        },
        { field: 'cab', headerName: 'Unallow App Token',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
          return <Button label="Unallow" onClick={() => unAllowAppToken(params.row.appToken)} ></Button>; 
          },
        },
        { field: 'd', headerName: 'Allow Tracker Token',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
          return <Button label="Allow" onClick={() => allowTrackerToken(params.row.trackerToken)} ></Button>; 
          },
        },
        { field: 'dd', headerName: 'Unallow Tracker Token',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
          return <Button label="Unallow" onClick={() => unAllowTracker(params.row.trackerToken)} ></Button>; 
          },
        }
      ];
      const columns_not_allowed: GridColDef[] = [
        { field: 'date', headerName: 'Created At',flex: 1, minWidth: 150},
        { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 150},
        { field: 'accountId', headerName: 'Account Id',flex: 1, minWidth: 150},
        { field: 'appName', headerName: 'App Name',flex: 1, minWidth: 150},
        { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 150},
        { field: 'trackerName', headerName: 'Tracker Name',flex: 1, minWidth: 150},
        { field: 'trackerToken', headerName: 'Tracker Token',flex: 1, minWidth: 150},
        { field: 'partnerName', headerName: 'Partner Name',flex: 1, minWidth: 150},
        { field: 'attributions', headerName: 'Attributions',flex: 1, minWidth: 150},
        { field: 'clicks', headerName: 'Clicks',flex: 1, minWidth: 150},
        { field: 'conversionRate', headerName: 'Conversion Rate',flex: 1, minWidth: 150},
        { field: 'accountOwner', headerName: 'Account Owner',flex: 1, minWidth: 150},
        { field: 'accountManager', headerName: 'Account Manager',flex: 1, minWidth: 150},
        { field: 'salesManager', headerName: 'Sales Manager',flex: 1, minWidth: 150},
        { field: 'salesEngineer', headerName: 'Sales Engineer',flex: 1, minWidth: 150},
        { field: 'lowQualityWindow', headerName: 'Low Quality Window',flex: 1, minWidth: 150},
        { field: 'allowed', headerName: 'Allowed',flex: 1, minWidth: 150},
        { field: 'abc', headerName: 'Allow App Token',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
          return <Button label="Allow" onClick={() => allowAppToken(params.row.appToken)} disabled></Button>; 
          },
        },
        { field: 'cab', headerName: 'Unallow App Token',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
          return <Button label="Unallow" onClick={() => unAllowAppToken(params.row.appToken)} disabled></Button>; 
          },
        },
        { field: 'd', headerName: 'Allow Tracker Token',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
          return <Button label="Allow" onClick={() => allowTrackerToken(params.row.trackerToken)} disabled></Button>; 
          },
        },
        { field: 'dd', headerName: 'Unallow Tracker Token',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
          return <Button label="Unallow" onClick={() => unAllowTracker(params.row.trackerToken)} disabled></Button>; 
          },
        }
    ];

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        setOpenSnackBarApp(false);
        setOpenSnackBarTracker(false);
      };

    const isAllowed = () => {
        if(role === '2' || role === '8'){
            return columns_allowed;
        }
        return columns_not_allowed;
    }
    return (
        <div style={{ height: 700, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={isAllowed()}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
            <Snackbar
                open={openSnackBarApp}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Note archived"
            >
                <Alert severity="success">The App was successfully Allowed!</Alert>
            </Snackbar>

            <Snackbar
                open={openSnackBarTracker}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Note archived"
            >
                <Alert severity="success">The Tracker was successfully Allowed!</Alert>
            </Snackbar>
            <Snackbar
                open={openSnackBarUnallow}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Note archived"
            >
                <Alert severity="success">The App was successfully Unallowed!</Alert>
            </Snackbar>
            <Snackbar
                open={openSnackBarTrackerUnallow}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Note archived"
            >
                <Alert severity="success">The Tracker was successfully Unallowed!</Alert>
            </Snackbar>
        </div>
    );
}