import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button } from '@mui/material';

function createData(
    id: number,
    accountId: number,
    accountStatus: string,
    adId: string,
    name: string,
  ) {
    return { id, accountId, accountStatus, adId, name };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export const MetaAdAccountTable = (_props : any) =>{
    const rows : any = [];
    if(_props.data !== undefined){
        for (var i = 0; i < _props.data.length; i++){
            rows.push(
                createData(
                    i,
                    _props.data[i]["account_id"],
                    _props.data[i]["account_status"],
                    _props.data[i]["id"],
                    _props.data[i]["name"],
                )
            );
        }
    }
    const columns: GridColDef[] = [
        { field: 'actions', headerName: 'Actions',flex: 1,maxWidth:250,minWidth:250,
          renderCell: (params : any) => {
              if(params.row.adId !== undefined && params.row.adId !== ''){
                  return <Button variant="outlined" onClick={()=>_props.onAdAccountChange(params.row.adId)}>Choose ID</Button>
              }
            return <div/>;
          },
        },
        { field: 'id', headerName: '',flex: 1, maxWidth:1},
        { field: 'accountId', headerName: 'Account ID',flex: 1},
        { field: 'name', headerName: 'Name',flex: 1},
        { field: 'accountStatus', headerName:'Account Status',flex: 1},
        { field: 'adId', headerName: 'Id',flex: 1},
      ];
    return (
        <div style={{ height: 800, width: '175vh' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}