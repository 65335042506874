import { Badge, Button, IconButton, Menu, MenuItem } from "@mui/material";
import { Link} from "react-router-dom";
import { useState } from "react";
import MailIcon from '@mui/icons-material/Mail';
import { Notification,NotificationData } from "../Types/Types";
import { getNotificationCenterData, setNotificationCenterAsRead } from "../Hooks/NotificationCenterHooks";
import { useQuery } from "react-query";
import { Icon, Panel, Tile } from "@adjust/components";

const redirectToAppPage = (typeId : number, appToken: string) => {
    if(typeId === 1){
        return '/account/app/' + appToken + '/anomalies';
    }
    else if(typeId === 2){
        return '/account/app/' + appToken;
    }
    else if(typeId === 3){
        return '/account/app/' + appToken + '/clickspamhistory';
    }
    else if(typeId === 4){
        if(appToken !== '' && appToken !== null){
            return '/account/app/' + appToken + '/implementation';
        }
        return '/tools/baggeroverview';
    }
    else if(typeId === 5){
        if(appToken !== '' && appToken !== null){
            return '/account/app/' + appToken + '/sananalysis';
        }
        return '/tools/baggeroverview';
    }

    else{
        return '/overview';
    }

}

const countUnreadNotifications = (notifications: Notification[] | undefined) => {
    let unreads = 0;
    if(notifications === undefined){
        return unreads;
    }
    for (var i = 0; i < notifications.length; i++){
        if(notifications[i].wasSeen === 0){
            unreads += 1;
        }
    }
    return unreads;
}

const tileHeader = (notification:Notification) =>{
    let text = '';
    let title = '';
    if (notification.appToken === '' || notification.appToken === null) {
        text = 'Your BQG result is finished';
        title = 'Bagger API Job ' + notification.type;
    }
    else{
        text = "App: " + notification.appName + " (" + notification.appToken + ")";
        title = "Implementation Checker Job "  + notification.type;
    }
    if(notification.wasSeen === 1){
        if(notification.typeId === 4){
            return <Tile 
                css={{ width: '100%'}}
                title={title}
                description={notification.createdAt}
                actions={[
                    <Icon name="Check" color="rgb(8, 209, 255)"/>
                ]}
                >
                    {text}
            </Tile>
        }
        else{
            return <Tile 
                css={{ width: '100%'}}
                title={notification.type}
                description={notification.createdAt}
                actions={[
                    <Icon name="Check" color="rgb(8, 209, 255)"/>
                ]}
                >
                    App: {notification.appName} ({notification.appToken})
                    <br/>
                    Account: {notification.accountName} ({notification.accountId})
            </Tile>
        }
    }
    if(notification.typeId === 4){
        return <Tile 
                    css={{ width: '100%'}}
                    title={notification.type}
                    description={notification.createdAt}
                >
                    {text}
                </Tile>
    }
    if(notification.typeId === 10){
        return <Tile 
                    css={{ width: '100%'}}
                    title={notification.type}
                    description={notification.createdAt}
                >
                    {notification.accountName}
                </Tile>
    }
    return <Tile 
                css={{ width: '100%'}}
                title={title}
                description={notification.createdAt}
            >
                App Token: {notification.appToken}
                <br/>
                Account: {notification.accountName} ({notification.accountId})
            </Tile>
}

export const NotificationIcon = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const { data } = useQuery<NotificationData, Error>(
        ["notificationCenter"], 
        () => getNotificationCenterData(),
        {
            refetchInterval: 60000,
        });

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setNotificationCenterAsRead();
      };
    
      const handleClose = () => {
        setAnchorEl(null);
      };


    return(
        <div>
            <IconButton
                size="large"
                aria-label="openMenu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
            >
               <Badge badgeContent={countUnreadNotifications(data?.notifications)} color="primary">
                    <MailIcon color="action" style={{color:'white'}}/>
                </Badge>
            </IconButton>

            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
            <Panel title="Notification Center"> 
                <Button variant="contained" style={{backgroundColor:'#005ff7' }} component={Link} to="/notifications">See all Notifications</Button>
                {data?.notifications.map((notification) => (
                    <MenuItem 
                        component={Link} 
                        to={redirectToAppPage(notification.typeId,notification.appToken)}
                        style={{width:'100%'}}
                        
                    >
                        {tileHeader(notification)}
                    </MenuItem>
                ))}
            </Panel>
            </Menu>
        </div>
    );
}