import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { WelcomeBackCasesModal } from '../welcomeBackCasesModal.tsx/WelcomeBackCasesModal';


const columns: GridColDef[] = [
    { field: 'accountId', headerName: 'Account ID',flex: 1, minWidth: 150,sortable: false,
        renderCell: (params : any) => {
            return <Button variant="outlined" component={Link} to={"/account/"+params.row.accountId}>{params.row.accountId}</Button>;
        },
    },      
    { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 100},
    { field: 'holidayManager', headerName: 'Holiday Manager',flex: 1, minWidth: 150},
    { field: 'cases', headerName: 'Cases',flex: 1, minWidth: 100,sortable: false,
        renderCell: (params : any) => {
            return <WelcomeBackCasesModal cases={params.row.cases} />;
        },
    },
  ];

function createData(
    id: number,
    accountId: string,
    accountName: string,
    holidayManager: string,
    cases: any
  ){
    return { id, accountId, accountName, holidayManager, cases};
  }

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export const WelcomeBackTable = (_props : any) => {
    var data = _props.data;
    const accountData : any = [];
    if(data !== undefined){
        for (var i = 0; i < data.length; i++){
            accountData.push(
                createData(
                    i,
                    data[i].accountId,
                    data[i].accountName,
                    data[i].holidayManager,
                    data[i].cases
                )
            );
        }
    }
    
    return (
        <div style={{ height: 650, width: '100%' }}>
            <DataGrid 
                rows={accountData} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            /> 
        </div>
    )
  }