import {Checkbox, Tile } from "@adjust/components";
import {FormControlLabel, Grid} from "@mui/material";
import {useEffect, useState } from "react";


export default function QueryFeatureSearch (props : any){
    const [spendWorks,setSpendWorks] = useState(false);
    const [uninstallReinstall,setUninstallReinstall] = useState(false);
    const [fps,setFps] = useState(false);
    const [audienceBuilder,setAudienceBuilder] = useState(false);
    const [adRevenue,setAdRevenue] = useState(false);
    const [purchaseVerification,setPurchaseVerification] = useState(false);
    const [multitouch,setMultitouch] = useState(false);
    const [acquired,setAcquired] = useState(false);
    
    const [subscription,setSubscription] = useState(false);
    const [advancedPermissions,setAdvancedPermissions] = useState(false);
    const [controlCenter,setControlCenter] = useState(false);
    const [webAttribution,setWebAttribution] = useState(false);
    const [security,setSecurity] = useState(false);
    const [allowNewDashboardByDefault,setAllowNewDashboardByDefault] = useState(false);
    const [customSubdomainLink,setCustomSubdomainLink] = useState(false);
    
    const [displayClassicDashboards,setDisplayClassicDashboards] = useState(false);
    const [smartBanners,setSmartBanners] = useState(false);
    const [sdkSignature,setSdkSignature] = useState(false);
    const [datastudio,setDatastudio] = useState(false);
    const [pulse,setPulse] = useState(false);
    const [defaultSuiteAccess,setDefaultSuiteAccess] = useState(false);
    const [optionalSuiteAccess,setOptionalSuiteAccess] = useState(false);
    const [customBrandedLinks,setCustomBrandedLinks] = useState(false);
    const [dataResidency,setDataResidency] = useState(false);

    useEffect(() => {
        props.onIsLoadingChange(true);
        let searchValue = [spendWorks,uninstallReinstall,fps,audienceBuilder,adRevenue,purchaseVerification,multitouch,acquired,
            subscription,advancedPermissions,controlCenter,webAttribution,security,allowNewDashboardByDefault,customSubdomainLink,
            displayClassicDashboards,smartBanners,sdkSignature,datastudio,pulse,defaultSuiteAccess,optionalSuiteAccess,customBrandedLinks,dataResidency];
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "features": searchValue}) 
        };
        fetch('/api/queryfeature', requestOptions).then(response => response.json()).then(data => {
            props.onFeatureChange(data);
            props.onIsLoadingChange(false);
        });
      }, [spendWorks,uninstallReinstall,fps,audienceBuilder,adRevenue,purchaseVerification,multitouch,acquired,
        subscription,advancedPermissions,controlCenter,webAttribution,security,allowNewDashboardByDefault,customSubdomainLink,
        displayClassicDashboards,smartBanners,sdkSignature,datastudio,pulse,defaultSuiteAccess,optionalSuiteAccess,customBrandedLinks,dataResidency]);   

    return(
        <Tile>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                            <FormControlLabel control={
                            <Checkbox 
                                id="spendWorksCheckbox"
                                checked={spendWorks}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSpendWorks(event.target.checked)}
                            />} 
                            label="Spendworks" 
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <FormControlLabel control={
                            <Checkbox 
                                id="uninstallReinstallCheckbox"
                                checked={uninstallReinstall}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setUninstallReinstall(event.target.checked)}
                            />} 
                            label="Uninstall Reinstall" 
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <FormControlLabel control={
                            <Checkbox 
                                id="fpsCheckbox"
                                checked={fps}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFps(event.target.checked)}
                            />} 
                            label="Fraud Prevention Suite" 
                            />
                        </Grid>
                        
                        <Grid item xs={2}>
                            <FormControlLabel control={
                            <Checkbox 
                                id="audienceBuilderCheckbox"
                                checked={audienceBuilder}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAudienceBuilder(event.target.checked)}
                            />} 
                            label="Audience Builder" 
                            />
                        </Grid>
                        
                        <Grid item xs={2}>
                            <FormControlLabel control={
                            <Checkbox 
                                id="adRevenueCheckbox"
                                checked={adRevenue}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAdRevenue(event.target.checked)}
                            />} 
                            label="Ad Revenue" 
                            />
                        </Grid>
                        
                        <Grid item xs={2}>
                            <FormControlLabel control={
                            <Checkbox 
                                id="purchaseVerificationCheckbox"
                                checked={purchaseVerification}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPurchaseVerification(event.target.checked)}
                            />} 
                            label="Purchase Verification" 
                            />
                        </Grid>
                        
                        <Grid item xs={2}>
                            <FormControlLabel control={
                            <Checkbox 
                                id="multitouchCheckbox"
                                checked={multitouch}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setMultitouch(event.target.checked)}
                            />} 
                            label="Multitouch" 
                            />
                        </Grid>
                        
                        <Grid item xs={2}>
                            <FormControlLabel control={
                            <Checkbox 
                                id="acquiredCheckbox"
                                checked={acquired}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAcquired(event.target.checked)}
                            />} 
                            label="Acquired.io" 
                            />
                        </Grid>
                        
                        <Grid item xs={2}>
                            <FormControlLabel control={
                            <Checkbox 
                                id="subscriptionCheckbox"
                                checked={subscription}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSubscription(event.target.checked)}
                            />} 
                            label="Subscription" 
                            />
                        </Grid>
                        
                        <Grid item xs={2}>
                            <FormControlLabel control={
                            <Checkbox 
                                id="advancedPermissionsCheckbox"
                                checked={advancedPermissions}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAdvancedPermissions(event.target.checked)}
                            />} 
                            label="Advanced Permissions" 
                            />
                        </Grid>
                        
                        <Grid item xs={2}>
                            <FormControlLabel control={
                            <Checkbox 
                                id="controlCenterCheckbox"
                                checked={controlCenter}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setControlCenter(event.target.checked)}
                            />} 
                            label="Control Center" 
                            />
                        </Grid>
                        
                        <Grid item xs={2}>
                            <FormControlLabel control={
                            <Checkbox 
                                id="webAttributionCheckbox"
                                checked={webAttribution}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setWebAttribution(event.target.checked)}
                            />} 
                            label="Web Attribution" 
                            />
                        </Grid>
                        
                        <Grid item xs={2}>
                            <FormControlLabel control={
                            <Checkbox 
                                id="securityCheckbox"
                                checked={security}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSecurity(event.target.checked)}
                            />} 
                            label="Security" 
                            />
                        </Grid>
                        
                        <Grid item xs={2}>
                            <FormControlLabel control={
                            <Checkbox 
                                id="allowNewDashboardByDefaultCheckbox"
                                checked={allowNewDashboardByDefault}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setAllowNewDashboardByDefault(event.target.checked)}
                            />} 
                            label="Allow New Dashboard By Default" 
                            />
                        </Grid>
                        
                        <Grid item xs={2}>
                            <FormControlLabel control={
                            <Checkbox 
                                id="customSubdomainLinkCheckbox"
                                checked={customSubdomainLink}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCustomSubdomainLink(event.target.checked)}
                            />} 
                            label="Custom Subdomain Link" 
                            />
                        </Grid>
                        
                        <Grid item xs={2}>
                            <FormControlLabel control={
                            <Checkbox 
                                id="displayClassicDashboardsCheckbox"
                                checked={displayClassicDashboards}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDisplayClassicDashboards(event.target.checked)}
                            />} 
                            label="Display Classic Dashboards" 
                            />
                        </Grid>
                        
                        <Grid item xs={2}>
                            <FormControlLabel control={
                            <Checkbox 
                                id="smartBannersCheckbox"
                                checked={smartBanners}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSmartBanners(event.target.checked)}
                            />} 
                            label="Smart Banners" 
                            />
                        </Grid>
                        
                        <Grid item xs={2}>
                            <FormControlLabel control={
                            <Checkbox 
                                id="sdkSignatureCheckbox"
                                checked={sdkSignature}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSdkSignature(event.target.checked)}
                            />} 
                            label="Sdk Signature" 
                            />
                        </Grid>
                        
                        <Grid item xs={2}>
                            <FormControlLabel control={
                            <Checkbox 
                                id="dataStudioCheckbox"
                                checked={datastudio}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDatastudio(event.target.checked)}
                            />} 
                            label="Datastudio" 
                            />
                        </Grid>
                        
                        <Grid item xs={2}>
                            <FormControlLabel control={
                            <Checkbox 
                                id="pulseCheckbox"
                                checked={pulse}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPulse(event.target.checked)}
                            />} 
                            label="Pulse" 
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControlLabel control={
                            <Checkbox 
                                id="defaultSuiteAccessCheckbox"
                                checked={defaultSuiteAccess}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDefaultSuiteAccess(event.target.checked)}
                            />} 
                            label="Default Suite Access" 
                            />
                        </Grid>

                        <Grid item xs={2}>
                            <FormControlLabel control={
                            <Checkbox 
                                id="optionalSuiteAccessCheckbox"
                                checked={optionalSuiteAccess}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOptionalSuiteAccess(event.target.checked)}
                            />} 
                            label="Optional Suite Access" 
                            />
                        </Grid>
                        
                        <Grid item xs={2}>
                            <FormControlLabel control={
                            <Checkbox 
                                id="customBrandedLinksCheckbox"
                                checked={customBrandedLinks}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCustomBrandedLinks(event.target.checked)}
                            />} 
                            label="Custom Branded Links" 
                            />
                        </Grid>
                        
                        <Grid item xs={2}>
                            <FormControlLabel control={
                            <Checkbox 
                                id="dataResidencyCheckbox"
                                checked={dataResidency}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setDataResidency(event.target.checked)}
                            />} 
                            label="Data Residency" 
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
                
        </Tile>
    );
}