import { Input, ComboBox } from '@adjust/components';
import { Tile } from '@adjust/components/build/Tile/Tile';
import { Box, Button, Grid} from '@mui/material';
import React, {useState } from 'react';

export const MetaApi = () =>{
    const [appId, setAppId] : any = useState('');
    const [message, setMessage] : any = useState('');
    const [answer, setAnswer] : any = useState(false);

    function searchAdNetwork(appId : any, message : any){
        if(appId !== '' && message !== '' ){
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'appId': appId,'message' : message })
            };
            fetch('/api/metaapi', requestOptions)
                .then(response => response.json()).then(data => {
                    setAnswer(data.answer)
                });
        }
    }
    const messageFlow = (message: any) =>{
        setAnswer(false);
        setMessage(message)
    }

    const appIdFlow = (appId: any) =>{
        setAnswer(false);
        setAppId(appId)
    }


    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                 <h1>Meta/FB AEM/SKAN Readiness API</h1>
                            </Grid>
                            <Grid item xs={8}>
                                <Tile title="">
                                <Grid container spacing={0}>
                                <Grid item xs={4}>
                                    <Input
                                        label="App Id"
                                        id="appId" 
                                        name="appId"
                                        value={appId}
                                        placeholder=""
                                        onClear={() => {
                                            appIdFlow('');
                                        }}
                                        onChange={(e) => {
                                            appIdFlow(e.target.value);
                                        }}
                                    />
                                    <br/>
                                    <br/>
                                    <ComboBox
                                        label="Messages"
                                        options={[{ label: 'AEM Ready', value: 'AEM Ready' }]}
                                        onChange={(e) => messageFlow(e)}
                                    />
                                    </Grid>
                                    
                                    </Grid>
                                    <br/>
                                    <Button variant='contained' style={{backgroundColor:'#005ff7'}} onClick={() => searchAdNetwork(appId,message)}>Set to True</Button>
                                       
                                </Tile>
                            </Grid>
                            <br/>
                        </Grid>
                        {answer &&
                        <h3>API call succeded</h3>
                        }
                        {!answer &&
                        <h3>API call not succeded</h3>
                        }
                    </div>
                </Box>
    );
}