import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
}

const columns: GridColDef[] = [
    { field: 'date', headerName: 'Date',flex: 1, minWidth: 180},
    { field: 'os', headerName: 'OS',flex: 1, minWidth: 100},
    { field: 'processed', headerName: 'Processed',flex: 1, minWidth: 150},
    { field: 'uninstalled', headerName: 'Uninstalled',flex: 1, minWidth: 150},
    { field: 'failed', headerName: 'Failed',flex: 1, minWidth: 150},
    { field: 'skipped', headerName: 'Skipped',flex: 1, minWidth: 150},
  ];

function createData(
    id: number,
    date: string,
    os: string,
    processed: number,
    uninstalled: number,
    failed: number,
    skipped: number,
    
  ){
    return { id, date, os, processed, uninstalled, failed, skipped };
  }

export default function AppUninstallDetector(props : any) {
    var uninstallDetector = props.uninstallDetector;
    const csvData : any = [];
    if(uninstallDetector !== undefined){
        for (var i = 0; i < uninstallDetector.length; i++){
            csvData.push(
                createData(
                    i,
                    formatDate(uninstallDetector[i][0]),
                    uninstallDetector[i][1],
                    uninstallDetector[i][2],
                    uninstallDetector[i][3],
                    uninstallDetector[i][4],
                    uninstallDetector[i][5],
                )
            );
        }
    }
    return (
        <div style={{height: 600, width: '100%'}}>
                <DataGrid 
                    rows={csvData} 
                    columns={columns}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                /> 
        </div>
    );
}