export const InstallSessionCheckAndroid = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(data === undefined){
        return 0;
    }
    if(lastRunTime === ''){
        return 0;
    }
    for (var i = 0; i < data.length; i++){
        let environment = data[i]['environment'];
        let message = data[i]['message'];
        let installs = parseInt(data[i]['numOfInstalls']);
        if(environment === 'production' && message === 'install tracked' && installs > 0){
            return 1
        }
    }
    return 3;
}

export const AdvertisingIdCheckAndroid = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    // installs count in production
    let installs = 0;
    for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['numOfOccurences']);
    }
    if(installs > 0){
        return 1
    }
    return 3;
}

export const PushTokenCheckAndroid = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    // installs count in production
    let installs = 0;
    for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['numOfInstalls']);
    }
    if(installs > 0){
        return 1
    }
    return 3;
}

export const SdkSignatureCheckAndroid = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    // installs count in production
    let installs = 0;
    for (var i = 0; i < data.length; i++){
            installs = installs + parseInt(data[i]['count']);
    }
    if(installs > 0){
        return 1
    }
    return 3;
}

export const SdkEventNonRevenueCheckAndroid = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    let eventCount = data.length;
    if(eventCount > 0){
        return 1;
    }
    return 3;
}

export const SdkEventRevenueCheckAndroid = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    let counter = 0;
    for (let i = 0; i < data.length; i++){
        let revenue = data[i]['revenue'];
        let currency = data[i]['currency'];
        if(revenue !== "null" && currency !== "null"){
            counter = counter + 1;
        }
    }
    if(counter > 0){
        return 1;
    }
    return 3;
}

export const EventBufferingCheckAndroid = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    let eventCount = data.length;
    if(eventCount > 0){
        return 1;
    }
    return 3;
}


export const CallbackAndPartnerCheckAndroid = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    for (var i = 0; i < data.length; i++){
        let partnerParams = data[i]['partnerParams'];
        let callbackParams = data[i]['callbackParams'];
        if(partnerParams !== 'null' && callbackParams !== 'null'){
            return 1;
        }
    }
    return 3;
}

export const GoogleReferralApiCheckAndroid = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    if(data.length > 0){
        return 1
    }
    return 3;
}

export const AdRevenueCheckAndroid = (lastRunTime:string,data:any) => {
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    if(data.length > 0){
        return 1;
    }
    return 3;
}

export const CoppaCheckAndroid = (lastRunTime:string,data:any,newestAppVersion:any) => {
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    if(data.length > 0){
        return 1;
    }
    return 3;
}

export const ThirdPartyCheckAndroid = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    if(data.length > 0){
        return 1;
    }
    return 3;
}

export const KidsCheckAndroid = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    if(data.length > 0){
        return 1;
    }
    return 3;
}

export const DeeplinkCheckAndroid = (lastRunTime:string,data:any,newestAppVersion:any) => {
    newestAppVersion = newestAppVersion[0].version;
    // no data case
    if(lastRunTime === ''){
        return 0;
    }
    if(data === undefined){
        return 0;
    }
    if(data.length > 0){
        return 1;
    }
    return 3;
}
