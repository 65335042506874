import { Chart } from "react-google-charts";

const formatDate = (dateString : string) =>{
    if(dateString === '' || dateString === null){
        return '';
    }
    return new Date(dateString).toISOString().slice(0, 10);
}

const setChartValues =(datas : any) =>{
    const data = [
        ["Date","Attributions","Included Attributions"],
        ["",0,0],
      ];
    var rows : any = [["Date","Attributions","Included Attributions"]];
    if(datas !== undefined && datas !== '' ){
        for (let i = 0; i < datas.length; i++) {
            rows.push([formatDate(datas[i][1]), datas[i][2], datas[i][3] ]);
        }
        return rows;
    }
    return data;
}

export const options = {
    title: "Attributions L12M",
    legend: { 
      position: 'bottom', 
      alignment: 'center' 
    },
    seriesType: "bars",
    series: { 1: { type: "line" } },
    vAxis: {minValue: 0}
};

export const AttributionsChart = (props : any) =>{
    let attributions = props.attributions;

  return (
    <div style={{marginLeft:'5px',marginTop:'5px',marginRight:'5px'}}>
      <Chart
        chartType="ComboChart"
        width="100%"
        height="300px"
        data={setChartValues(attributions)}
        options={options}
      />
    </div>
  );
}
