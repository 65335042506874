import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import ApplovinInstallsTable from "../../../entities/components/Reports/ApplovinInstallsTable";
import { InfoTooltip } from "../../../shared/components/infoTooltip/InfoTooltip";

export const ApplovinIstallsPage = () => {
    const [installs,setInstalls] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true)
        fetch('/api/reportapplovininstalls').then(res => res.json()).then(data => {
            setInstalls(data["installs"]);
            setIsLoading(false);
        });
    }, []);

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                 <h1>Applovin Installs</h1>
                            </Grid>
                            {isLoading &&
                            <CircularProgress />
                            }
                            {!isLoading &&
                            <>
                                <InfoTooltip message="Source: Metabase | Refreshed: once a month" />
                                <ApplovinInstallsTable installs ={installs} />
                            </>
                            
                            }
                        </Grid>
                    </div>
                </Box>
    );
}