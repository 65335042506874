import { Chart } from "react-google-charts";

const formatDate = (dateString : string) =>{
    if(dateString === '' || dateString === null){
        return '';
    }
    return new Date(dateString).toLocaleDateString();
}

const setChartValues = (installs : any) =>{
    const data = [
        ["Date","Installs"],
        ["N/A",0],
      ];
    var rows : any = [["Date", 'Adwords','Apple','Applovin','Aura','Digital Turbine','Double Click','Facebook','Google','Instagram','Ironsource','Liftoff','Mintegral','No Partner Match','Organic','Snapchat','Tiktok','Twitter','Unity Ads','Untrusted Devices','Vungle']];
    if(installs !== undefined && installs !== '' ){
        for (let i = 0; i < installs.length; i++) {
            rows.push([
                formatDate(installs[i][2]),
                installs[i][3],
                installs[i][4],
                installs[i][5],
                installs[i][6],
                installs[i][7],
                installs[i][8],
                installs[i][9],
                installs[i][10],
                installs[i][11],
                installs[i][12],
                installs[i][13],
                installs[i][14],
                installs[i][15],
                installs[i][16],
                installs[i][17],
                installs[i][18],
                installs[i][19],
                installs[i][20],
                installs[i][21],
                installs[i][22],
            ]);
        }
        return rows;
    }
    return data;
}

export const options = {
  title: "Partner Installs",
  isStacked: true,
  height: 300,
  legend: {position: 'bottom', maxLines: 3},
  vAxis: {minValue: 0}
};

export const PartnerInstallsChart = (props : any) =>{
    let installs = props.installs;

  return (
    <div style={{marginLeft:'5px',marginTop:'5px',marginRight:'5px'}}>
      <Chart
        chartType="ColumnChart"
        width="100%"
        height="300px"
        data={setChartValues(installs)}
        options={options}
      />
    </div>
  );
}
