import { Button, Input } from '@adjust/components';
import { Tile } from '@adjust/components/build/Tile/Tile';
import { Box, Card, Grid} from '@mui/material';
import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ApptopiaAppVersionTable from '../../../entities/components/Tools/AppTopiaAppVersionTable';
import ApptopiaSdkTable from '../../../entities/components/Tools/ApptopiaSdkTable';

export default function ApptopiaPage() {
    const [appToken, setAppToken] = useState('');
    const [accountId, setAccountId] = useState('');
    const [appVersions,setAppvVersions] = useState({});
    const [sdks,setSdks] = useState({});
    const [isLoading, setLoading] = useState(false);

    const [appTokenSdk, setAppTokenSdk] = useState('');
    const [accountIdSdk, setAccountIdSdk] = useState('');

    const [accountIdDisabled, setAccountIdDisabled] = useState(false);
    const [appTokenDisabled, setAppTokenDisabled] = useState(false);
    
    const [accountIdDisabledSdk, setAccountIdDisabledSdk] = useState(false);
    const [appTokenDisabledSdk, setAppTokenDisabledSdk] = useState(false);

    const [search,setSearch]  = useState('');
    const updateVersion = () =>{
        var value = '';
        if(appToken.length > 0){
            value = 'appToken';
        }
        if(accountId.length > 0){
            value = 'accountId';
        }
        if(value !== ''){
            setLoading(true);
            setSearch('version');
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ "value": value,"appToken":appToken,"accountId":accountId })
            };
            fetch('/api/updatenewestappversiontools', requestOptions)
                .then(response => response.json()).then(data => {
                    setAppvVersions(data["newReleasedAppVersions"])
                    setLoading(false);
                });
        }
    }

    const updateSdk = () =>{
        var value = '';
        if(appTokenSdk.length > 0){
            value = 'appToken';
        }
        if(accountIdSdk.length > 0){
            value = 'accountId';
        }
        if(value !== ''){
            setLoading(true);
            setSearch('sdk');
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ "value": value,"appToken":appTokenSdk,"accountId":accountIdSdk })
            };
            fetch('/api/updatesdktools', requestOptions)
                .then(response => response.json()).then(data => {
                    setSdks(data["sdks"]);
                    setLoading(false);
                });
        }
    }

    const isDisabledAppToken = (id: any) =>{
        setAccountId(id);
        if(id.length > 0){
            setAppTokenDisabled(true);
        }
        else{
            setAppTokenDisabled(false);
        }
    }
    const isDisabledAccountId = (token : any) =>{
        setAppToken(token);
        if(token.length > 0){
            setAccountIdDisabled(true);
        }
        else{
            setAccountIdDisabled(false);
        }
    }

    const isDisabledAppTokenSdk = (id: any) =>{
        setAccountIdSdk(id);
        if(id.length > 0){
            setAppTokenDisabledSdk(true);
        }
        else{
            setAppTokenDisabledSdk(false);
        }
    }
    const isDisabledAccountIdSdk = (token : any) =>{
        setAppTokenSdk(token);
        if(token.length > 0){
            setAccountIdDisabledSdk(true);
        }
        else{
            setAccountIdDisabledSdk(false);
        }
    }
    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                 <h1>Apptopia Data Refresher:</h1>
                            </Grid>
                            <Grid item xs={6}>
                                <Tile title="App Version:">
                                <Input
                                    label="App Token"
                                    id="appToken" 
                                    name="appToken"
                                    value={appToken}
                                    css={{ width: "40%"}}
                                    disabled = {appTokenDisabled}
                                    onClear={() => {
                                        isDisabledAccountId('');
                                    }}
                                    onChange={(e) => {
                                        isDisabledAccountId(e.target.value);
                                    }}
                                />
                                <br/>
                                <Input
                                    label="Account Id"
                                    id="accountId" 
                                    name="accountId"
                                    value={accountId}
                                    css={{ width: "40%"}}
                                    disabled = {accountIdDisabled}
                                    onClear={() => {
                                        isDisabledAppToken('');
                                    }}
                                    onChange={(e) => {
                                        isDisabledAppToken(e.target.value);
                                    }}
                                />
                                <br/>
                                <br/>
                                <Button  type="submit" kind="primary" label="Search"  onClick={() => updateVersion()}></Button>{' '}
                                </Tile>
                            </Grid>

                            <Grid item xs={6}>
                                <Tile title="App SDKs:">
                                <Input
                                    label="App Token"
                                    id="appTokenSdk" 
                                    name="appTokenSdk"
                                    value={appTokenSdk}
                                    css={{ width: "40%"}}
                                    disabled = {appTokenDisabledSdk}
                                    onClear={() => {
                                        isDisabledAccountIdSdk('');
                                    }}
                                    onChange={(e) => {
                                        isDisabledAccountIdSdk(e.target.value);
                                    }}
                                />
                                <br/>
                                <Input
                                    label="Account Id"
                                    id="accountIdSdk" 
                                    name="accountIdSdk"
                                    value={accountIdSdk}
                                    css={{ width: "40%"}}
                                    disabled = {accountIdDisabledSdk}
                                    onClear={() => {
                                        isDisabledAppTokenSdk('');
                                    }}
                                    onChange={(e) => {
                                        isDisabledAppTokenSdk(e.target.value);
                                    }}
                                />
                                <br/>
                                <br/>
                                <Button  type="submit" kind="primary" label="Search"  onClick={() => updateSdk()}></Button>{' '}
                                </Tile>
                            </Grid>

                            <Grid item xs={12}>
                                <Card>
                                    {isLoading &&
                                    <CircularProgress />
                                    }
                                    {(!isLoading && search === 'version') &&
                                    <ApptopiaAppVersionTable appVersions={appVersions} />
                                    }
                                    {(!isLoading && search === 'sdk') &&
                                    <ApptopiaSdkTable sdks={sdks} />
                                    }
                                </Card>
                            </Grid>
                        </Grid>
                           
                    </div>
                </Box>
    );
}