import { Breadcrumbs } from '@adjust/components/build/Breadcrumbs/Breadcrumbs';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { SegmentedButton, Tile } from '@adjust/components';
import AccountUsersHistoryTable from '../../../entities/components/Accounts/AccountUsersHistoryTable';
import AccountUsersTable from '../../../entities/components/Accounts/AccountUserTable';
import { AccountSegmentedButton } from '../../../shared/components/accountSegmentedButton/AccountSegmentedButton';
import { useQuery } from "react-query";
import CircularProgress from '@mui/material/CircularProgress';
import { Account, AccountUsersData } from '../../../shared/components/Types/Types';
import { getData } from '../../../shared/components/Hooks/AccountHooks';
import { AccountContactsTable } from '../../../entities/components/Accounts/AccountContactsTable';
  
function MyBreadcrumbs(accountId:string,accountName:string,navigate:NavigateFunction) {
    const items = [
      { label: 'My Accounts', onClick: () => {navigate('/myaccounts')}},
      { label: accountName, onClick: () => {navigate('/account/' + accountId)}},
      { label: 'Users & History', onClick: () => {navigate('/account/' + accountId + '/users')}}
    ];
    return <Breadcrumbs items={items} />;
}
  
export const UsersPage = (_props:Account) =>{
    let navigate = useNavigate();
    let params = useParams();
    const [value, setValue] = useState('1');
    const items = [
        {value: '1', label: 'Dashboard Users' },
        {value: '2', label: 'Salesforce Contacts' },
        {value: '3', label: 'Dashboard History' }
    ];

    let accountId  = "0";
    if (params.accountId !== undefined){
        accountId = String(params.accountId);
    }

    const { status, data } = useQuery<AccountUsersData, Error>(
        ["usersPage", { accountId }],  () => getData(accountId,'accountuser'));

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    {MyBreadcrumbs(accountId,_props.accountName,navigate)}
                    <AccountSegmentedButton accountId = {accountId} tabValue='3' accountManager={_props.accountManager} competitor={_props.competitor} />
                    <br/>
                    {status === "loading" &&
                        <CircularProgress />
                    }
                    {status !== "loading" &&
                    <div>
                        <SegmentedButton
                            items={items}
                            value={value}
                            onChange={event => setValue(event.target.value)}
                        />
                        {value === '1' &&
                        <Tile title="Dashboard Users">
                            <AccountUsersTable users={data?.users} accountId={accountId}></AccountUsersTable>
                        </Tile>
                        }
                        {value === '2' &&
                        <Tile title="Salesforce Contacts">
                            <AccountContactsTable contacts = {data?.contacts} />
                        </Tile>   
                        }
                        {value === '3' &&
                        <Tile title="Account History">
                            <AccountUsersHistoryTable history={data?.history} />
                        </Tile>    
                        }
                    </div>
                    }   
                </Box>
    );
}