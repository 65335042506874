import { Button, ButtonGroup, Card } from "@mui/material";
import { useState } from "react";
import AccountUsageTable from "../../AccountUsageTable";
import { UsageChart } from "./UsageChart";

export const AccountUsage = (_props:any) => {
    const [graph,setGraph] = useState(true);
    return(
         <Card>
            <ButtonGroup
                disableElevation
                variant="contained"
                style={{marginLeft:'5px',marginTop:'5px'}}
            >
            {graph &&
                <>
                    <Button style={{backgroundColor:'#005ff7'}} onClick={() => setGraph(true)}>Graph</Button>
                    <Button variant="outlined" onClick={() => setGraph(false)}>Table</Button>
                </>
            }
            {!graph &&
                <>
                    <Button variant="outlined" onClick={() => setGraph(true)}>Graph</Button>
                    <Button style={{backgroundColor:'#005ff7'}} onClick={() => setGraph(false)}>Table</Button>
                </>
            }                        
            </ButtonGroup>
            {graph &&
                <UsageChart usageMinimized = {_props.usageMinimized} />
            }
            {!graph &&
                <AccountUsageTable usage = {_props.usage} />
            }
        </Card>    
                    
    );
}