import {Card, Divider, Grid, ListItem} from '@mui/material';
import {NumericFormat} from 'react-number-format';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

export const AccountApplovinInstallsTable = (props : any) =>{
    var installs = props.installs;
    return (
        <div >
            <Card component="main" sx={{ flexGrow: 1, p: 3 }} style={{paddingTop: '0px',paddingBottom:'2px'}}>
                <h2>Applovin Installs</h2>
                <ListItem>
                    <Grid container direction="row" alignItems="left" spacing={0}>
                        <Grid item xs={6}>Date</Grid>
                        <Grid item xs={6}>Installs</Grid>
                    </Grid>
                </ListItem>
                <Divider light />
                {installs.map((install  : any) =>
                    <ListItem>
                        <Grid container direction="row" alignItems="left" spacing={0}>
                            <Grid item xs={6}>{formatDate(install[0])}</Grid>
                            <Grid item xs={6}><NumericFormat value={install[1]} displayType={'text'} thousandSeparator={true}/></Grid>
                        </Grid>
                    </ListItem>
                    )}
            </Card>
        </div>
    );
}