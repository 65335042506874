import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import MySuiteAccountTable from "./MySuiteAccountsTable";
import { InfoTooltip } from "../../../shared/components/infoTooltip/InfoTooltip";

export const AdjustSuitePageMyAccounts = () => {
    const [accounts,setAccounts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true)
        fetch('/api/suiteaccountsmyaccounts').then(res => res.json()).then(data => {
            setAccounts(data["accounts"]);
            setIsLoading(false); 
        });
    }, []);

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                 <h1>My Adjust Suite Accounts</h1>
                            </Grid>
                            {isLoading &&
                            <CircularProgress />
                            }
                            {!isLoading &&
                            <>
                                <h3>Accounts with new Suite</h3>
                                <InfoTooltip message="Source: Adjust DB | Refreshed: instant" />
                                <MySuiteAccountTable accounts = {accounts} />
                            </>
                            }
                        </Grid>
                    </div>
                </Box>
    );
}