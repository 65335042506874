import { Button } from "@mui/material"

export const BaggerApiCancelJob = (_props : any) =>{
    const jobId = _props.jobId;

    const cancelBaggerApi = (jobId : any) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 'jobId': jobId})
        };
        fetch('/api/cancelbaggerjob', requestOptions).then(response => response.json()).then(
            data => {}
        );
    }
    return (
        <Button variant="contained" color="warning" onClick={() => cancelBaggerApi(jobId)}>Cancel Job</Button>
    )
}