import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button, Checkbox } from '@mui/material';
import { Link } from 'react-router-dom';
import {NumericFormat} from 'react-number-format';

const formatDate = (dateString : string) =>{
    if(dateString === '' || dateString === null){
        return '';
    }
    return new Date(dateString).toLocaleDateString();
}

const isSeen = (value: any) => {
  if(value === true || value === 'True'){
    return true;
  }
  return false;
}

function createData(
    id: number,
    accountId: number,
    accountManager: string,
    accountName: string,
    domain: string,
    insertDate: string,
    label: string,
    appName: string,
    appToken: string,
    failedValue: string,
    seen: string,
    callbackId: number
  ) {
    return { id,accountId,accountManager,accountName,domain,insertDate,label,appName,appToken,failedValue,seen,callbackId};
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export const FailedCallbacksTable = (_props : any) => {
    var callbacks = _props.callbacks;
    const rows : any = [];
    for (var i = 0; i < callbacks.length; i++){
        rows.push(
            createData(
              i,
              callbacks[i].accountId,
              callbacks[i].accountManager,
              callbacks[i].accountName,
              callbacks[i].domain,
              formatDate(callbacks[i].insertDate),
              callbacks[i].label,
              callbacks[i].appName,
              callbacks[i].appToken,
              callbacks[i].failedValue,
              callbacks[i].seen,
              callbacks[i].id
            )
        );
    }

    const columns: GridColDef[] = [
      { field: 'accountId', headerName: 'Account ID',flex: 1, minWidth: 100,
      renderCell: (params : any) => {
        return <Button variant="contained" component={Link} to={"/account/"+params.row.accountId} >{params.row.accountId}</Button>;
          },
      },
      { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 250},
      { field: 'accountManager', headerName: 'Account Manager',flex: 1, minWidth: 200},
      { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 100},
      { field: 'appName', headerName: 'App Name',flex: 1, minWidth: 150},
      { field: 'failedValue', headerName: 'Failed Value',flex: 1, minWidth: 100,
        renderCell: (params : any) => {
          return <NumericFormat value={params.row.failedValue} displayType={'text'} thousandSeparator={true}/>
      }},
      { field: 'domain', headerName: 'Domain',flex: 1, minWidth: 150},
      { field: 'insertDate', headerName: 'Occured At',flex: 1, minWidth: 100},
      { field: 'label', headerName: 'Label',flex: 1, minWidth: 150},
      { field: 'seen', headerName: 'Seen',flex: 1, maxWidth: 50,
        renderCell: (params : any) => {
          return <Checkbox  checked={isSeen(params.row.seen)} onChange={(isSeen:any)=> _props.onSeenChange(params.row.callbackId)}/>;
        },
      },
    ];

    return (
        <div style={{ height: 650, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}