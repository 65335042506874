import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';
import { InfoTooltip } from '../../../shared/components/infoTooltip/InfoTooltip';

const columns: GridColDef[] = [
    
    { field: 'name', headerName: 'Name',flex: 1, minWidth: 150},
    { field: 'activeSince', headerName: 'Active Since',flex: 1, minWidth: 150},
    { field: 'createdAt', headerName: 'Created At',flex: 1, minWidth: 150},
    { field: 'beta', headerName: 'Beta',flex: 1, minWidth: 150},
  
];

function createData(
    id: number,
    name: string,
    activeSince: string,
    createdAt: string,
    beta: string,
        
  ) {
    return { id, name, activeSince, createdAt, beta};
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const infoText = "Source: Adjust DB | Refreshed: instant";
export const AccountCtFeatureTable = (_props : any) =>{
    
    const rows : any = [];
    const featues = _props.features;
    if(featues !== undefined){
        for (var i = 0; i < featues.length; i++){
            rows.push(
                createData(
                    i,
                    featues[i]["name"],
                    formatDate(featues[i]["activeSince"]),
                    formatDate(featues[i]["createdAt"]),
                    featues[i]["beta"],
                    
                )
            );
        }
    }
    return (
        <div style={{ height: 650, width: '100%' }}>
            <InfoTooltip message={infoText} />
            <DataGrid
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}
