import { Breadcrumbs } from '@adjust/components/build/Breadcrumbs/Breadcrumbs';
import { useNavigate } from 'react-router-dom';
import { AppSegmentedButton } from '../../../shared/components/appSegmentedButton/AppSegmentedButton';
import { Grid } from '@mui/material';
import AppInfo from '../../../shared/components/appInfo/AppInfo';
import { Tile } from '@adjust/components';
import { useEffect, useState } from 'react';
import { InfoTooltip } from '../../../shared/components/infoTooltip/InfoTooltip';

const setNumber = (value : any) => {
    if(value === '' || value === null || value === undefined){
      return '';
    }
    value = Math.round((parseFloat(value) / 24) * 100) / 100;
    return String(value) + " days";
  }
  function checkmark(value : string){
    if(String(value) === 'true'){
        return 'True';
    }
    return '';
}

function MyBreadcrumbs(accountId:any,accountName:any,appToken:any,appName:any) {
    let navigate = useNavigate();
    const items = [
      { label: 'My Accounts', onClick: () => {navigate('/myaccounts')}},
      { label: accountName, onClick: () => {navigate('/account/' + accountId )}},
      { label: 'Apps', onClick: () => {navigate('/account/' + accountId + '/apps' )}},
      { label: appName, onClick: () => {navigate('/account/' + appToken + '/app' )}},
      { label: 'Attribution Settings', onClick: () => {navigate('/account/' + appToken + '/attributionsettings' )}},
    ];
    return <Breadcrumbs items={items} />;
}

export const AppAttributionSettings = (_props:any) => {
    const appToken = _props.appToken;
    const appInfo = _props.appInfo;
    const accountName = _props.accountName ;
    
    const [settings, setSettings] = useState([]);
    useEffect(() => {
          fetch('/api/appattributionsettings/'+appToken).then(res => res.json()).then(data => {
            setSettings(data["settings"]);
          });
    }, [appToken]);

    return (
        <div>
            {MyBreadcrumbs(appInfo[3],accountName,appInfo[2],appInfo[1])}
            <AppSegmentedButton appToken = {appToken} tabValue='12' />
            <br/><br/>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Grid container spacing={6}>
                        <Grid item xs={4}>
                            <AppInfo appInfo={appInfo} accountName={accountName} />
                        </Grid> 
                        <Grid item xs={8}>
                            <Tile title="Attribution Settings">
                                <InfoTooltip message="Source: Adjust DB | Refreshed: instant" />
                                <>
                                {settings.length > 0 &&
                                    <>
                                        <p>Impression Window: {setNumber(settings[0][3])}</p>
                                        <p>Click Window: {setNumber(settings[0][4])}</p>
                                        <p>Fingerprint Window: {setNumber(settings[0][5])}</p>
                                        <p>Reattribution Window: {setNumber(settings[0][6])}</p>
                                        <p>Reattribution Attribution Window: {setNumber(settings[0][7])}</p>
                                        <p>Impressions Tracking Enabled: {checkmark(settings[0][8])}</p>
                                        <p>Fingerprint Enabled: {checkmark(settings[0][9])}</p>
                                        <p>Reattributtion Enabled: {checkmark(settings[0][10])}</p>
                                        <p>Impression Fingerprint Window: {setNumber(settings[0][11])}</p>
                                        <p>Impression Fingerprint Enabled: {checkmark(settings[0][12])}</p>
                                        <p>Impression Reattribution Window: {setNumber(settings[0][13])}</p>
                                        <p>Impression Reattribution Attribution Window: {setNumber(settings[0][14])}</p>
                                        <p>Impression Reattribution Enabled: {checkmark(settings[0][15])}</p>
                                        <p>Click Temporary Attribution Enabled: {settings[0][16]["enabled"]}</p>

                                        <p>Click Temporary Attribution Window: {setNumber(settings[0][16]["window"])}</p>
                                        <p>Impression Temporary Attribution Enabled: {settings[0][17]["enabled"]}</p>
                                        <p>Impression Temporary Attribution Window: {setNumber(settings[0][17]["window"])}</p>
                                        <p>Attribution Privacy Model: {setNumber(settings[0][19])}</p>
                                    </>
                                }
                                </>
                            </Tile>
                        </Grid> 
                    </Grid>
                </Grid>
            </Grid>
        </div>
);
}