import { Tabs } from "@adjust/components";
import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

const RedirectLink = (event : string, accountId: number, navigate:NavigateFunction) => {
    if (event === '1'){
        navigate('/account/' + accountId);
    }
    if (event === '2'){
        navigate('/account/' + accountId + '/apps');
    }
    if (event === '3'){
        navigate('/account/' + accountId + '/users');
    }
    if (event === '4'){
        navigate('/account/' + accountId + '/features');
    }
    if (event === '5'){
        navigate('/account/' + accountId + '/anomalies');
    }
    if (event === '6'){
        navigate('/account/' + accountId + '/keystats');
    }
    if (event === '7'){
        navigate('/account/' + accountId + '/implementation');
    }
    if (event === '11'){
        navigate('/account/' + accountId + '/attributionsettings');
    }
    if (event === '12'){
        navigate('/account/' + accountId + '/coverage');
    }
    if (event === '13'){
      navigate('/account/' + accountId + '/networks');
  }
}

export const AccountSegmentedButton = (_props : any) => {
    let navigate = useNavigate();
    let accountManager = _props.accountManager;
    let anomalies = _props.anomalies;
    let accountId = _props.accountId;
    const [tabValue, setTabValue] = useState(_props.tabValue);
    useEffect(() => {
        RedirectLink(tabValue, accountId,navigate);
    }, [tabValue,accountId,navigate]);

  

    const oooIsDisabled = () =>{
        if (accountManager === '' || accountManager === null){
            return true;
        }
        return false;
    }

    const isAnomaly = () =>{
      if (anomalies === true){
          return '⚠ Anomalies';
      }
      return 'Anomalies';
  }

    const items = [
        {
          id: '1',
          label: 'Overview',
        },
        {
          id: '2',
          label: 'Apps and Settings',
        },
        {
          id: '3',
          label: 'Users & History',
        },
        {
            id: '4',
            label: 'Features'
          },
          {
            id: '5',
            label: isAnomaly()
          },
          {
            id: '6',
            label: 'Key Stats',
          },
          {
            id: '7',
            label: 'Implementation',
          },
          {
            id: '12',
            label: 'OOO Coverage',
            disabled: oooIsDisabled()
          },
          {
            id: '13',
            label: 'Networks',
          },
      ];
    return (
        <Tabs items={items} value={tabValue} onChange={setTabValue}/>
    );
  

}