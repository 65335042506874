
import { Breadcrumbs } from '@adjust/components/build/Breadcrumbs/Breadcrumbs';
import { Box, CircularProgress} from '@mui/material';
import React, { useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from "react-query";
import { Account } from '../../shared/components/Types/Types';
import { getData } from '../../shared/components/Hooks/AccountHooks';
import { AccountSalesSegmentedButton } from './AccountSalesSegmentedButton';
import { InfoTooltip } from '../../shared/components/infoTooltip/InfoTooltip';
import { SegmentedButton } from '@adjust/components';
import { AttributionForecastTable } from './ForecastAttributionTable';
import { MauForecastTable } from './ForecastMauTable';
import { DpForecastTable } from './ForecastDpTable';

function MyBreadcrumbs(accountId:string,accountName: string,navigate:NavigateFunction) {
    const items = [
      { label: 'My Accounts', onClick: () => {navigate('/myaccounts')} },
      { label: accountName, onClick: () => {navigate('/account/' + accountId)} },
      { label: 'Forecast', onClick: () => {navigate('/account/' + accountId + '/forecast')}},
    ];
    return <Breadcrumbs items={items} />;
  }

export const ForecastSalesPage = (_props:Account) => {
    let navigate = useNavigate();
    let params = useParams();let accountId  = "0";
    if (params.accountId !== undefined){
        accountId = String(params.accountId);
    }
    const [info,setInfo] = useState('1');
    const items = [
        { value: '1', label: 'Conversions',},
        { value: '2', label: 'MAUs'},
        { value: '3', label: 'DPs'},
      ];

    const { status, data } = useQuery<any, Error>(
        ["forecastSalesPage", { accountId }], () => getData(accountId,'forcastsales'));

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    {MyBreadcrumbs(accountId,_props.accountName,navigate)}
                    <AccountSalesSegmentedButton accountId = {accountId} tabValue='7' accountManager={_props.accountManager} competitor={_props.competitor} />
                    <br/>
                    {status === "loading" &&
                        <CircularProgress />
                    }
                    {status !== "loading" &&
                    <div>
                        <SegmentedButton items={items} value={info} onChange={event => setInfo(event.target.value)}/>
                        <InfoTooltip message={"Source: Own calculation | Refreshed: once a month"} />
                        {(info === '1') &&
                        <AttributionForecastTable data = {data?.attributions} />
                        }
                        {(info === '2') &&
                        <MauForecastTable data = {data?.maus} />
                        }
                         {(info === '3') &&
                        <DpForecastTable data = {data?.dps} />
                        }
                    </div>
                    }
                </Box>
    );
}