import { Breadcrumbs } from '@adjust/components/build/Breadcrumbs/Breadcrumbs';
import { useNavigate } from 'react-router-dom';
import { AppSegmentedButton } from '../../../shared/components/appSegmentedButton/AppSegmentedButton';
import { Grid } from '@mui/material';
import AppInfo from '../../../shared/components/appInfo/AppInfo';
import { Tile } from '@adjust/components';
import { useEffect, useState } from 'react';
import PartnerTable from '../../../entities/components/Apps/PartnerTable';
import { InfoTooltip } from '../../../shared/components/infoTooltip/InfoTooltip';


function MyBreadcrumbs(accountId:any,accountName:any,appToken:any,appName:any) {
    let navigate = useNavigate();
    const items = [
      { label: 'My Accounts', onClick: () => {navigate('/myaccounts')}},
      { label: accountName, onClick: () => {navigate('/account/' + accountId )}},
      { label: 'Apps', onClick: () => {navigate('/account/' + accountId + '/apps' )}},
      { label: appName, onClick: () => {navigate('/account/' + appToken + '/app' )}},
      { label: 'Partner', onClick: () => {navigate('/account/' + appToken + '/partner' )}},
    ];
    return <Breadcrumbs items={items} />;
}

export const AppPartnerPage = (_props:any) => {
    const appToken = _props.appToken;
    const appInfo = _props.appInfo;
    const accountName = _props.accountName ;

    const [partner, setPartner] = useState([[],[],[]]);
    useEffect(() => {
          fetch('/api/apppartner/'+appToken).then(res => res.json()).then(data => {
            setPartner(data["partner"]);
          });
    }, [appToken]);
    
    return (
        <div>
            {MyBreadcrumbs(appInfo[3],accountName,appInfo[2],appInfo[1])}
            <AppSegmentedButton appToken = {appToken} tabValue='11' />
            <br/><br/>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Grid container spacing={6}>
                        <Grid item xs={4}>
                            <AppInfo appInfo={appInfo} accountName={accountName} />
                        </Grid> 
                        <Grid item xs={8}>
                            <Tile title="Partner">
                                <InfoTooltip message="Source: Adjust DB | Refreshed: instant" />
                                <PartnerTable partner={partner} appToken={appInfo[2]} />
                            </Tile>
                        </Grid> 
                    </Grid>
                </Grid>
            </Grid>
        </div>
);
}