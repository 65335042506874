import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

function checkmark(value : any, name: string){
    if(value === 'true' || value === true){
        return <>{name}</>;
    }
    return '';
}

export default function AppSetupTable(_props : any) {
  const appToken = _props["data"];
  const [appId, setAppId] = React.useState(['','','','','']);
  const [bundleId, setBundleId] = React.useState(['','','','']);

  React.useEffect(() => {
        if(appToken !== undefined && appToken !== ''){
            fetch('/api/appsetup/'+appToken).then(res => res.json()).then(data => {
                setAppId(data["appId"]);
                setBundleId(data["bundleId"]);
            });
        }
  }, [appToken]);

  return (
    <TableContainer component={Paper}>
      {(appId[1] || appId[3]) &&
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
                <TableCell>Task</TableCell>
                {appId[1] &&
                <TableCell>Android</TableCell>
                }
                {appId[3] &&
                <TableCell>iOS</TableCell>
                }
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow key="appidadded">
                <TableCell component="th" scope="row">App ID added</TableCell>
                {appId[1] &&
                <TableCell component="th" scope="row">{checkmark(appId[1],appId[0])}</TableCell>
                }
                {appId[3] &&
                <TableCell component="th" scope="row">{checkmark(appId[3],appId[2])}</TableCell>
                }
            </TableRow>

            <TableRow key="bundleidadded">
                <TableCell component="th" scope="row">Bundle ID added</TableCell>
                {appId[1] &&
                <TableCell component="th" scope="row">{checkmark(bundleId[2],bundleId[0])}</TableCell>
                }
                {appId[3] &&
                <TableCell component="th" scope="row">{checkmark(bundleId[3],bundleId[1])}</TableCell>
                }

            </TableRow>
        </TableBody>
      </Table>
      }
    </TableContainer>
  );
}