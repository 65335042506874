import { Tabs } from "@adjust/components";
import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

const RedirectLink = (event : string, accountId: number, navigate:NavigateFunction) => {
    if (event === '1'){
        navigate('/account/' + accountId);
    }
    
    if (event === '4'){
        navigate('/account/' + accountId + '/features');
    }
    if (event === '5'){
        navigate('/account/' + accountId + '/anomalies');
    }
    if (event === '6'){
        navigate('/account/' + accountId + '/keystats');
    }
    if (event === '7'){
        navigate('/account/' + accountId + '/forecast');
    }
}

export const AccountSalesSegmentedButton = (_props : any) => {
    let navigate = useNavigate();
    let anomalies = _props.anomalies;
    let accountId = _props.accountId;
    const [tabValue, setTabValue] = useState(_props.tabValue);
    useEffect(() => {
        RedirectLink(tabValue, accountId,navigate);
    }, [tabValue,accountId,navigate]);

    const isAnomaly = () =>{
      if (anomalies === true){
          return '⚠ Anomalies';
      }
      return 'Anomalies';
  }

    const items = [
        {
          id: '1',
          label: 'Overview',
        },
        {
            id: '4',
            label: 'Features'
        },
        {
            id: '5',
            label: isAnomaly()
        },
        {
            id: '6',
            label: 'Key Stats',
        },
        {
            id: '7',
            label: 'Forecast',
        },
      ];
    return (
        <Tabs items={items} value={tabValue} onChange={setTabValue}/>
    );
  

}