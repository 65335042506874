import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { ActionMenu } from '@adjust/components';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';
import {NumericFormat} from 'react-number-format';

function setRedirectButton( accountId : any){
  return <form action="https://api.adjust.com/accounts/accounts/switch.json" target="_blank" accept-charset="UTF-8" method="post">
    <input name="utf8" type="hidden" value="✓"/>
    <input type="hidden" name="authenticity_token" value="Voa+WSemHcT/nTfrgVofsDQ76DiflWnRQS8h7HmlOTl/DjaU4vt2Ky5b/beHORUMo0udGFLNQx4ibYqxLlWaXg=="/>
    <input type="hidden" name="account_id" id="account_id" value={accountId} />
    <Button type="submit" name="commit" value="switch account" >
        <img id="logo-adjust" src={process.env.PUBLIC_URL + "/favicon.ico"} width="40" height="40" alt="adjust.com" />
    </Button>
  </form>
}

function createData(
    id: number,
    accountId: string,
    clientName: string,
    acv: string,
    ae: string,
    classification: string,
    contractEndDate: string,
    owner: Number,
    se: string,
    vertical: string
  ) {
    return { id,accountId, clientName, acv, ae, classification, contractEndDate, owner, se, vertical };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export default function MyAccountsTableSC(_props : any) {
  let navigate = useNavigate();
  const columns: GridColDef[] = [
  { field: 'id', headerName: 'Dashboard',flex: 1, maxWidth: 100,
    renderCell: (params : any) => {
        return <div>{setRedirectButton(params.row.accountId)}</div>
    },
  },
  { field: 'action', headerName: 'Action',flex: 1, maxWidth: 100, sortable: false,
    renderCell: (params : any) => {
        return <div>
                  <ActionMenu 
                        items={[
                          { label: 'Overview', onClick: () => {navigate("/account/"+params.row.accountId) }},
                          { label: 'Features', onClick: () => {navigate("/account/"+params.row.accountId+'/features') }},
                          { label: 'Anomalies', onClick: () => {navigate("/account/"+params.row.accountId+'/anomalies') }},
                          { label: 'Key Stats', onClick: () => {navigate("/account/"+params.row.accountId+'/keystats') }},
                        ]}
                      >
                      {/* The trigger can be anything. We use a button here */}
                      <Button variant="contained" style={{backgroundColor:'#005ff7' }}>Open</Button>
                  </ActionMenu>
              </div>;
    },
  },
  { field: 'clientName', headerName: 'Client Name',flex: 1, minWidth: 250},
  { field: 'accountId', headerName: 'Account Id',flex: 1, minWidth: 150},
  { field: 'acv', headerName: 'ACV',flex: 1, minWidth: 150,
    renderCell: (params : any) => {
        return  <NumericFormat value={Math.round(params.row.acv)} displayType={'text'} thousandSeparator={true}/>
    },
  },
  { field: 'vertical', headerName: 'Vertical',flex: 1, minWidth: 150},
  { field: 'contractEndDate', headerName: 'Contract End Date',flex: 1, minWidth: 150},
  { field: 'owner', headerName: 'Owner',flex: 1, minWidth: 150},
  { field: 'ae', headerName: 'AE',flex: 1, minWidth: 150},

  ];


    const rows : any = [];
    for (var i = 0; i < _props.data.length; i++){
        rows.push(
            createData(
                i,
                _props.data[i]["accountId"],
                _props.data[i]["accountName"],
                _props.data[i]["acv"],
                _props.data[i]["ae"],
                _props.data[i]["classification"],
                formatDate(_props.data[i]["contractEndDate"]),
                _props.data[i]["owner"],
                _props.data[i]["se"],
                _props.data[i]["vertical"]
            )
        );
    }
    return (
        <div style={{ height: 700, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}