import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { AccountCoverageModal } from './AccountCoverage/AccountCoverageModal';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

const textClean = (text: string) => {
    if(text === null || text === undefined){return '';}
    text = text.replaceAll("<p>","");
    text = text.replaceAll("</p>","");
    text = text.replaceAll("<br>","");
    return text;
}

const columns: GridColDef[] = [
    { field: 'caseId', headerName: 'Ticket Number',flex: 1, minWidth: 50, maxWidth: 150,sortable: false,
        renderCell: (params : any) => {
            return <Button variant="contained" href={"https://adjust.lightning.force.com/lightning/r/Case/"+params.row.caseId+"/view"} target="_blank">Open</Button>
        },
    },
    { field: 'subject', headerName: 'Subject',flex: 1, minWidth: 150},
    { field: 'startDate', headerName: 'Created Date',flex: 1, minWidth: 150},
    { field: 'status', headerName: 'Status',flex: 1, minWidth: 150},
    { field: 'type', headerName: 'Type/MFA',flex: 1, minWidth: 150},
    { field: 'mainFunctionalArea', headerName: 'MFA',flex: 1, minWidth: 150},
    { field: 'anyOooNotes', headerName: 'Coverage Notes',flex: 1, minWidth: 50, maxWidth: 200,sortable: false,
        renderCell: (params : any) => {
            return <AccountCoverageModal 
                issueDescription={params.row.issueDescription}
                whatWasDone={params.row.whatWasDone}
                appEventTrackerToken={params.row.appEventTrackerToken}
                slackDiscussionsJiraTicketsOpened={params.row.slackDiscussionsJiraTicketsOpened}
                suggestedNextSteps={params.row.suggestedNextSteps}
                similarTopicsFoundInSlackJira={params.row.similarTopicsFoundInSlackJira}
                logsPathAndRequestExamples={params.row.logsPathAndRequestExamples}
                linksToOtherTools={params.row.linksToOtherTools}
                dashboardLink={params.row.dashboardLink}
                anyOooNotes={params.row.anyOooNotes}
            />
        },
    },
  
];

function createData(
    id: number,
    caseId: string,
    ownerMail: string,
    subject: string,
    startDate: string,
    status : string,
    type: string,
    anyOooNotes: boolean,
    issueDescription: string,
    whatWasDone: string,
    appEventTrackerToken: string,
    slackDiscussionsJiraTicketsOpened: string,
    suggestedNextSteps: string,
    similarTopicsFoundInSlackJira: string,
    logsPathAndRequestExamples: string,
    linksToOtherTools: string,
    dashboardLink: string,
    mainFunctionalArea: string
        
  ) {
    return { id,caseId, ownerMail, subject, startDate, status, type , anyOooNotes, issueDescription, whatWasDone, appEventTrackerToken, slackDiscussionsJiraTicketsOpened, suggestedNextSteps, similarTopicsFoundInSlackJira, logsPathAndRequestExamples, linksToOtherTools, dashboardLink, mainFunctionalArea};
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export const AccountSfCasesTable = (_props : any) =>{
    const rows : any = [];
    const cases = _props.cases;
    if(cases !== undefined){
        for (var i = 0; i < cases.length; i++){
            rows.push(
                createData(
                    i,
                    textClean(cases[i][0]),
                    textClean(cases[i][1]),
                    textClean(cases[i][2]),
                    formatDate(cases[i][3]),
                    textClean(cases[i][4]),
                    textClean(cases[i][5]),
                    cases[i][6],
                    textClean(cases[i][7]),
                    textClean(cases[i][8]),
                    textClean(cases[i][9]),
                    textClean(cases[i][10]),
                    textClean(cases[i][11]),
                    textClean(cases[i][12]),
                    textClean(cases[i][13]),
                    textClean(cases[i][14]),
                    textClean(cases[i][15]),
                    cases[i][16],
                )
            );
        }
    }
    return (
        <div style={{ height: 350, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}
