import { Button, Input } from '@adjust/components';
import { Box, CircularProgress, Grid } from '@mui/material';
import { useState } from "react";

export const CvMappingPage = () => {
    const [error, setError] = useState('');
    const [isLoading,setIsLoading]  = useState(false);
    const [appId,setAppId] = useState('');
    const [data,setData] = useState('');
    
    const searchAppId = (appId: string) => {
        if(appId !== ''){
            setIsLoading(true);
            fetch('/api/cvmapping/' + appId).then(response => response.json()).then(function (data) {
                setData(data['rawData']);
                setIsLoading(false);
                setError(data['message']);
            });
        }
    }

    return(
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <h1>CV Mapping</h1>
                </Grid>
                <Grid item xs={6} style={{paddingTop:'0px'}}>
                    <Input
                        label="App Id"
                        id="appId" 
                        name="appId"
                        type="number"
                        value={appId}
                        onChange={(e) => {
                            setAppId(e.target.value);
                        }}
                    />
                    <Button style={{marginTop:'18px'}} type="submit" kind="primary" label="Search"  onClick={() => searchAppId(appId)}></Button>{' '}
                    {isLoading &&
                        <CircularProgress />
                    }
                    {error !== '' &&
                        <p style={{color:'red'}}>{error}</p>
                    }
                    {data !== '' &&
                    <pre>{JSON.stringify(data, null, 2)}</pre>
                    }
                </Grid>
            </Grid>
        </Box>
    );
}