import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import {NumericFormat} from 'react-number-format';
import { formatDate } from '../../../../shared/components/formatDate/FormatDate';

const columns: GridColDef[] = [
  { field: 'date', headerName: 'Date',flex: 1,},
  { field: 'adwords', headerName: 'Adwords',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.adwords} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'apple', headerName: 'Apple',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.apple} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'applovin', headerName: 'Applovin',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.applovin} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'aura', headerName: 'Aura',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.aura} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'digital_turbine', headerName: 'Digital Turbine',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.digital_turbine} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'double_click', headerName: 'Double Click',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.double_click} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'facebook', headerName: 'Facebook',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.facebook} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'google', headerName: 'Google',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.google} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'instagram', headerName: 'Instagram',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.instagram} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'ironsource', headerName: 'Ironsource',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.ironsource} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'liftoff', headerName: 'Liftoff',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.liftoff} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'mintegral', headerName: 'Mintegral',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.mintegral} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'no_partner_match', headerName: 'No Partner Match',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.no_partner_match} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'organic', headerName: 'Organic',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.organic} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'snapchat', headerName: 'Snapchat',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.snapchat} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'tiktok', headerName: 'Tiktok',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.tiktok} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'twitter', headerName: 'Twitter',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.twitter} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'unity_ads', headerName: 'Unity Ads',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.unity_ads} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'untrusted_devices', headerName: 'Untrusted Devices',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.untrusted_devices} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'vungle', headerName: 'Vungle',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.vungle} displayType={'text'} thousandSeparator={true}/>
  }},
];

function createData(
    id: number,
    date: string,
    adwords: number,
    apple: number,
    applovin: number,
    aura: number,
    digital_turbine: number,
    double_click: number,
    facebook: number,
    google: number,
    instagram: number,
    ironsource: number,
    liftoff: number,
    mintegral: number,
    no_partner_match: number,
    organic: number,
    snapchat: number,
    tiktok: number,
    twitter: number,
    unity_ads: number,
    untrusted_devices: number,
    vungle: number
  ) {
    return { id,date, adwords,apple,applovin,aura,digital_turbine,double_click,facebook,google,instagram,
        ironsource,liftoff,mintegral,no_partner_match,organic,snapchat,tiktok,twitter,unity_ads,untrusted_devices,vungle};
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export default function PartnerInstallsTable (_props : any) {
    const installs = _props.installs;
    const rows : any = [];
    if(installs !== undefined){
        for (var i = 0; i < installs.length; i++){
            rows.push(
                createData(
                    installs[i][0],
                    formatDate(installs[i][2]),
                    installs[i][3],
                    installs[i][4],
                    installs[i][5],
                    installs[i][6],
                    installs[i][7],
                    installs[i][8],
                    installs[i][9],
                    installs[i][10],
                    installs[i][11],
                    installs[i][12],
                    installs[i][13],
                    installs[i][14],
                    installs[i][15],
                    installs[i][16],
                    installs[i][17],
                    installs[i][18],
                    installs[i][19],
                    installs[i][20],
                    installs[i][21],
                    installs[i][22],
                )
            );
        }
    }
    return (
        <div style={{ height: 300, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}
