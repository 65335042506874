import * as React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

function createData(
  id: number,
  os: string,
  sdkVersion: string,
  installs: number,
  installsInPercent: number,
) {
  return { id, os, sdkVersion, installs, installsInPercent };
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const columns: GridColDef[] = [
  { field: 'os', headerName: 'OS',flex: 1, minWidth: 100},
  { field: 'sdkVersion', headerName: 'SDK Version',flex: 1, minWidth: 100},
  { field: 'installs', headerName: 'Installs',flex: 1, minWidth: 100},
  { field: 'installsInPercent', headerName: 'Installs in Percent',flex: 1, minWidth: 100},
]

export const SdkVersionTable = (_props : any) =>{
  const sdks = _props["data"]["sdkVersions"];
  const allInstalls = _props["data"]["allInstalls"]

  const rows : any = [];
  for (var i = 0; i < sdks.length; i++){
    rows.push(
      createData(
        i,
        sdks[i][1],
        sdks[i][2],
        sdks[i][0],
        Math.round(sdks[i][0]/allInstalls * 100)
      )
      );
    }

  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGrid 
        rows={rows} 
        columns={columns}
        components={{
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
}