import * as React from 'react';
import { DataGrid,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import { AdRevenueDefinition, AdvertisingIdDefinition, AppleAdsFrameworkDefinition, AttFrameworkDefinition, CallbackPartnerParamsDefinition, ConversionValuesDefinition, CoppaDefinition, DeeplinkDefinition, DirectPostbackDefinition, EventBufferingDefinition, GoogleReferralApiDefinition, InstallTrackingDefinition, PlaystoreKidsDefinition, PushTokensDefinition, S2sErrorReportDefinition, S2sEventTrackingDefinition, SdkEventTrackingDefinitionNonRevenue, SdkEventTrackingDefinitionRevenue, SdkSignatureDefinition, SkadnetworkIntegrationDefinition, SummaryDefinition, ThirdPartySharingDefinition, WebAttributionDefinition } from './ImplementationDataTableDefinitions';



function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export const ImplementationDataTable = (_props : any) => {
  const data = _props["data"];
  const type = _props["type"];
  let dataWithDefinition:any = [];
  if(type === 'summary'){
    dataWithDefinition = SummaryDefinition(data);
  }
  else if(type === 'installTracking'){
    dataWithDefinition = InstallTrackingDefinition(data);
  }
  else if(type === 'advertisingId'){
    dataWithDefinition = AdvertisingIdDefinition(data);
  }
  else if(type === 'pushTokens'){
    dataWithDefinition = PushTokensDefinition(data);
  }
  else if(type === 'attFramework'){
    dataWithDefinition = AttFrameworkDefinition(data);
  }
  else if(type === 'sdkSignature'){
    dataWithDefinition = SdkSignatureDefinition(data);
  }
  else if(type === 'webAttribution'){
    dataWithDefinition = WebAttributionDefinition(data);
  }
  else if(type === 'sdkEventTrackingNonRevenue'){
    dataWithDefinition = SdkEventTrackingDefinitionNonRevenue(data);
  }
  else if(type === 'sdkEventTrackingRevenue'){
    dataWithDefinition = SdkEventTrackingDefinitionRevenue(data);
  }
  else if(type === 's2sEventTracking'){
    dataWithDefinition = S2sEventTrackingDefinition(data);
  }
  else if(type === 's2sErrorReport'){
    dataWithDefinition = S2sErrorReportDefinition(data);
  }
  else if(type === 'eventBuffering'){
    dataWithDefinition = EventBufferingDefinition(data);
  }
  else if(type === 'callbackPartnerParams'){
    dataWithDefinition = CallbackPartnerParamsDefinition(data);
  }
  else if(type === 'googleReferralApi'){
    dataWithDefinition = GoogleReferralApiDefinition(data);
  }
  else if(type === 'directPostback'){
    dataWithDefinition = DirectPostbackDefinition(data);
  }
  else if(type === 'adRevenue'){
    dataWithDefinition = AdRevenueDefinition(data);
  }
  else if(type === 'coppa'){
    dataWithDefinition = CoppaDefinition(data);
  }
  else if(type === 'thirdPartySharing'){
    dataWithDefinition = ThirdPartySharingDefinition(data);
  }
  else if(type === 'playStoreKids'){
    dataWithDefinition = PlaystoreKidsDefinition(data);
  }
  else if(type === 'appleAdsFramework'){
    dataWithDefinition = AppleAdsFrameworkDefinition(data);
  }
  else if(type === 'skadnetworkIntegration'){
    dataWithDefinition = SkadnetworkIntegrationDefinition(data);
  }
  else if(type === 'conversionValues'){
    dataWithDefinition = ConversionValuesDefinition(data);
  }
  else if(type === 'deeplink'){
    dataWithDefinition = DeeplinkDefinition(data);
  }
  
  return (
    <Grid container xs={12} >
        <Grid item xs={12}>
            <div style={{ height: 600 }}>
                <DataGrid 
                    rows={dataWithDefinition.rows} 
                    columns={dataWithDefinition.columns}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                />
            </div>
        </Grid>
    </Grid>
  );
}