import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

const columns: GridColDef[] = [
  { field: 'yearWeek', headerName: 'Date',flex: 1},
  { field: 'name', headerName: 'Page Name',flex: 1},
  { field: 'visitors', headerName: 'Unique Visitors',flex: 1},
  { field: 'events', headerName: 'Events Triggered',flex: 1},
  { field: 'timeSpent', headerName: 'Time Spent',flex: 1},
];

function createData(
    id: number,
    timeSpent: string,
    yearWeek: string,
    name: string,
    accountId: string,
    events : string,
    visitors: string
  ) {
    return { id,timeSpent, yearWeek, name, accountId, events, visitors };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export default function AccountUsageTable(_props : any) {
    const usage = _props.usage;
    const rows : any = [];
    if(usage !== undefined){
        for (var i = 0; i < usage.length; i++){
            rows.push(
                createData(
                    i,
                    usage[i][0],
                    formatDate(usage[i][1]),
                    usage[i][2],
                    usage[i][3],
                    usage[i][4],
                    usage[i][5]
                )
            );
        }
    }
    return (
        <div style={{ height: 650, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}
