import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { InfoTooltip } from '../../../shared/components/infoTooltip/InfoTooltip';

const setCheckBox = (mainContact : any) =>{
    if(mainContact === true){
        return <CheckBoxOutlinedIcon color="success"></CheckBoxOutlinedIcon>
    }
    return <></>
}

const columns: GridColDef[] = [
  { field: 'fullName', headerName: 'Name',flex: 1},
  { field: 'title', headerName: 'Title',flex: 1},
  { field: 'email', headerName: 'Email',flex: 1},
  { field: 'contactType', headerName: 'Contact Type',flex: 1},
  { field: 'userPermission', headerName: 'User Permission',flex: 1},
  { field: 'mainContact', headerName: 'Main Contact',flex: 1,
    renderCell: (params : any) => {
        return <div>{setCheckBox(params.row.mainContact)}</div>
    }  
  },
];

function createData(
    id: number,
    email: string,
    title: string,
    fullName: string,
    recordType: string,
    contactType : string,
    userPermission: string,
    mainContact: string
  ) {
    return { id,email, title, fullName, recordType, contactType, userPermission, mainContact };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const infoText = "Source: Salesforce | Refreshed: once a day";
export const AccountContactsTable = (_props : any) =>{
    const contacts = _props.contacts;
    const rows : any = [];
    if(contacts !== undefined){
        for (var i = 0; i < contacts.length; i++){
            rows.push(
                createData(
                    i,
                    contacts[i][0],
                    contacts[i][1],
                    contacts[i][2],
                    contacts[i][3],
                    contacts[i][4],
                    contacts[i][5],
                    contacts[i][6],
                )
            );
        }
    }
    return (
        <div style={{ height: 650, width: '100%' }}>
            <InfoTooltip message={infoText} />
            <DataGrid
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}
