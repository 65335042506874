import * as React from 'react';
import { Badge } from '@adjust/components';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

function statusBadge(text: string){
    if(String(text) === "true" ){
        return <Badge label="Active" color="positive" />
    }
    else{
        return <Badge label="False" color="warning" />
    }
}

const columns: GridColDef[] = [
  { field: 'name', headerName: 'Name',flex: 1},
  { field: 'functions', headerName: 'Function',flex: 1},
  { field: 'installDate', headerName: 'Install Date',flex: 1},
  { field: 'present', headerName: 'Present',flex: 1,
    renderCell: (params : any) => {
        return <div>{statusBadge(params.row.present.toString())}</div>
    }  
  },
  { field: 'active', headerName: 'Active',flex: 1,
    renderCell: (params : any) => {
        return <div>{statusBadge(params.row.active.toString())}</div>
    }  
  },
  { field: 'activationDate', headerName: 'Activation Date',flex: 1},
  { field: 'deactivationDate', headerName: 'Deactivation Date',flex: 1},
  { field: 'uninstallDate', headerName: 'Uninstall Date',flex: 1},
  { field: 'appId', headerName: 'App ID',flex: 1},
];

function createData(
  id: number,
  name: string,
  functions: string,
  installDate: string,
  present: string,
  active : string,
  activationDate: string,
  deactivationDate: string,
  uninstallDate: string,
  appId: string
) {
  return { id,name, functions, installDate, present, active, activationDate, deactivationDate,uninstallDate,appId };
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
    
export default function SdkModuleTable(_props : any) {
    
  const sdks = _props["data"];
  const rows : any = []
    if(sdks !== undefined){
        for (var i = 0; i < sdks.length; i++){
            rows.push(
                createData(
                    i,
                    sdks[i][0],
                    sdks[i][1],
                    formatDate(sdks[i][2]),
                    sdks[i][3],
                    sdks[i][4],
                    formatDate(sdks[i][5]),
                    formatDate(sdks[i][6]),
                    formatDate(sdks[i][7]),
                    sdks[i][8],
                )
            );
        }
    }

  return (
    <div style={{ height: 650, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
  );
}