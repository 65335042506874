import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import {NumericFormat} from 'react-number-format';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';

const formatDate = (dateString : string) =>{
    if(dateString === '' || dateString === null){
        return '';
    }
    return new Date(dateString).toLocaleDateString();
}

function createData(
    id: number,
    accountId: number,
    month: string,
    installs: number,
    accountName: string,
  ) {
    return { id,accountId,month,installs,accountName};
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  const columns: GridColDef[] = [
    { field: 'accountId', headerName: 'Account ID',flex: 1,
    renderCell: (params : any) => {
      return <Button variant="contained" component={Link} to={"/account/"+params.row.accountId} >{params.row.accountId}</Button>;
        },
    },
    { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 150},
    { field: 'month', headerName: 'Month',flex: 1, minWidth: 150},
    { field: 'installs', headerName: 'Installs',flex: 1, maxWidth: 150,
    renderCell: (params : any) => {
        return <NumericFormat value={params.row.installs} displayType={'text'} thousandSeparator={true}/>
    }},
  ];

export default function ApplovinInstallsTable(_props : any) {
    var installs = _props.installs
    const rows : any = [];
    for (var i = 0; i < installs.length; i++){
        rows.push(
            createData(
                i,
                installs[i][0],
                formatDate(installs[i][1]),
                installs[i][2],
                installs[i][3],
            )
        );
    }
    return (
        <div style={{ height: 700, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}