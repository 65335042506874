import { Input } from '@adjust/components';
import { Tile } from '@adjust/components/build/Tile/Tile';
import { Box, Button, Card, Divider, Grid, List, ListItem} from '@mui/material';
import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { IosAppVerificationTable } from '../../../entities/components/Tools/IosAppVerificationTable';

function setIncorrectApps(datas : any){
    var rows = [];
    rows.push(<>
        <ListItem>
            <Grid container direction="row" alignItems="center" spacing={0}>
                <Grid item xs={1}>
                    App Token
                </Grid>
            </Grid>
        </ListItem>
    </>);
    if(datas !== undefined){
        for (var i = 0; i < datas.length; i++) {
            
            rows.push(<>
                <ListItem>
                    <Grid container direction="row" alignItems="center" spacing={0}>
                        <Grid item xs={1}>
                            <Divider light />
                            {datas[i]}
                        </Grid>
                    </Grid>
                </ListItem>
            </>);
        }
    }
    return <List  component="nav">
                {rows}
            </List>
}

export const IosAppVerificationPage = () => {
    const [appToken, setAppToken] = useState('');
    const [appTokenData, setAppTokenData] = useState([[],[]]);
    const [loading, setLoading] = useState(false);
    function searchAppToken(appToken : any){
        setLoading(true);
        const data = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({appToken}),
        }
        fetch('/api/iosverification',data).then(response => response.json()).then(function(result) {
            setAppTokenData(result);
            setLoading(false);
        });
    }
    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <h1>iOS App Verification</h1>
                                <h4>By Timur</h4>
                            </Grid>
                            <Grid item xs={6} style={{paddingTop:'24px'}}>
                                <Tile title="Enter App Token * (use a comma for multiple commas)">
                                <Input
                                    label=""
                                    id="apptoken" 
                                    name="apptoken"
                                    value={appToken}
                                    onClear={() => {
                                        setAppToken('');
                                    }}
                                    onChange={(e) => {
                                        setAppToken(e.target.value);
                                    }}
                                />
                                <Button  variant="contained" style={{backgroundColor:'#005ff7'}} onClick={() => searchAppToken(appToken)}>Search</Button>
                                </Tile>
                            </Grid>
                            <Grid item xs={12}>
                                <>
                                {!loading &&
                                    <Card>
                                        <h3 style={{marginLeft:'2%'}}>Verified Apps</h3>
                                        <IosAppVerificationTable data={appTokenData[0]} />
                                        {appTokenData[1].length > 0 &&
                                        <>
                                            <br/><br/>
                                            <h3 style={{marginLeft:'2%'}}>Incorrect Apps</h3>
                                            {setIncorrectApps(appTokenData[1])}
                                        </>
                                        }
                                    </Card>
                                }
                                {loading && 
                                <CircularProgress />
                                }
                                </>
                            </Grid>
                        </Grid>
                           
                    </div>
                </Box>
    );
}