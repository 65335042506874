import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Badge } from '@adjust/components';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

function statusCheck(status : any){
  if(status === true){
    return <Badge label="Disabled" color="warning" />
  }
  else{
    return <Badge label="Enabled" color="positive" />
  }
}

function boolCheck(value : any){
  if(value === true){return <CheckBoxOutlinedIcon color="success"  />;}
  return '';
}

const isIos = (value: any) => {
  if(value.includes('itunes')){
    return true;
  }
  return false;
}
const isAndroid = (value: any) => {
  if(value.includes('google')){
    return true;
  }
  return false;
}

export default function PartnerModuleSettings(_props : any) {
  const data = _props["partnerSettings"];
  const appInfo = _props["appInfo"];
  let platformString = '';
  if(appInfo[6] !== undefined){
    platformString = appInfo[6];
  }
  return (
    <>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
                <TableCell>OS</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>App ID</TableCell>
                <TableCell>Revenue Forwarding</TableCell>
                <TableCell>Parameter Forwarding</TableCell>
                <TableCell>Uninstall Forwarding</TableCell>
                <TableCell>Session Forwarding</TableCell>
                <TableCell>Attributed Only</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isAndroid(platformString) &&
          <TableRow key="android">
                <TableCell component="th" scope="row">Android</TableCell>
                <TableCell component="th" scope="row">{statusCheck(data["paused"])}</TableCell>
                <TableCell component="th" scope="row">{appInfo[7][0]}</TableCell>
                <TableCell component="th" scope="row">{boolCheck(data["androidRevenueForwarding"])}</TableCell>
                <TableCell component="th" scope="row">{boolCheck(data["androidParameterForwarding"])}</TableCell>
                <TableCell component="th" scope="row">{boolCheck(data["androidUninstallForwarding"])}</TableCell>
                <TableCell component="th" scope="row">{boolCheck(data["androidSessionForwarding"])}</TableCell>
                <TableCell component="th" scope="row">{boolCheck(data["androidAttributedOnly"])}</TableCell>
          </TableRow>
          }
          {isIos(platformString) &&
          <TableRow key="ios">
                <TableCell component="th" scope="row">iOS</TableCell>
                <TableCell component="th" scope="row">{statusCheck(data["paused"])}</TableCell>
                <TableCell component="th" scope="row">{appInfo[7][1]}</TableCell>
                <TableCell component="th" scope="row">{boolCheck(data["iosRevenueForwarding"])}</TableCell>
                <TableCell component="th" scope="row">{boolCheck(data["iosParameterForwarding"])}</TableCell>
                <TableCell component="th" scope="row">{boolCheck(data["iosUninstallForwarding"])}</TableCell>
                <TableCell component="th" scope="row">{boolCheck(data["iosSessionForwarding"])}</TableCell>
                <TableCell component="th" scope="row">{boolCheck(data["iosAttributedOnly"])}</TableCell>
          </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}