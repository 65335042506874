import {Button} from '@mui/material';
import { Link } from 'react-router-dom';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import {NumericFormat} from 'react-number-format';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';
import { UpdateModalSalesRegion } from './UpdateModalSalesRegion';

const accountLink = (accountId : any) => {
    if(accountId === ''){
        return '';
    }
    return <Button variant="contained" style={{width:'80px'}} component={Link} to={"/account/"+accountId} >{accountId}</Button>
}

function createData(
    id: number,
    accountId: string,
    accountName: string,
    acv: number,
    ae: string,
    contractEndDate: string,
    notifications: any,
    owner: string,
    se: string,
    vertical: string,
    notificationCount: number

  ) {
    return { id, accountId, accountName, acv, ae, contractEndDate, notifications, owner, se, vertical,notificationCount };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

const columns: GridColDef[] = [
    { field: 'accountId', headerName: 'Account Id',flex: 1, maxWidth: 100, sortable: false,
      renderCell: (params : any) => {
          return accountLink(params.row.accountId);
      }
    },
    { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 250},
    { field: 'acv', headerName: 'ACV',flex: 1, minWidth: 50,
        renderCell: (params : any) => {
            return  <NumericFormat value={Math.round(params.row.acv)} displayType={'text'} thousandSeparator={true}/>
        },
      },
    { field: 'vertical', headerName: 'Vertical',flex: 1, minWidth: 250},
    { field: 'notificationCount', headerName: 'Notification Count',flex: 1, minWidth: 100},
    { field: 'notifications', headerName: 'Notifications',flex: 1, minWidth: 50, maxWidth: 200,sortable: false,
        renderCell: (params : any) => {
            return <UpdateModalSalesRegion 
                notifications={params.row.notifications}
        />
        },
    },
]

export const UpdatesTableSalesRegion = (props : any) =>{
    var updates = props.data;
    const rows : any = [];
    if(updates !== undefined){
        for (var i = 0; i < updates.length; i++){
            rows.push(
                createData(
                    i,
                    updates[i]["accountId"],
                    updates[i]["accountName"],
                    updates[i]["acv"],
                    updates[i]["ae"],
                    formatDate(updates[i]["contractEndDate"]),
                    updates[i]["notifications"],
                    updates[i]["owner"],
                    updates[i]["se"],
                    updates[i]["vertical"],
                    updates[i]["notifications"].length,
                )
            );
        }
    }

    return (
        <div style={{ height: 700, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}