import { Breadcrumbs } from '@adjust/components/build/Breadcrumbs/Breadcrumbs';
import { Tile } from '@adjust/components/build/Tile/Tile';
import { Grid} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppInfo from '../../../shared/components/appInfo/AppInfo';
import { AppSegmentedButton } from '../../../shared/components/appSegmentedButton/AppSegmentedButton';
import { AppSanAnalysisTable } from './AppSanAnalysisTable';

function MyBreadcrumbs(accountId:any,accountName:any,appToken:any,appName:any) {
    let navigate = useNavigate();
    const items = [
        { label: 'My Accounts', onClick: () => {navigate('/myaccounts')}},
        { label: accountName, onClick: () => {navigate('/account/' + accountId)}},
        { label: 'Apps', onClick: () => {navigate('/account/' + accountId + '/apps')}},
        { label: appName, onClick: () => {navigate('/account/app/'+ appToken)}},
        { label: 'San Analysis', onClick: () => {navigate('/account/app/' + appToken + '/sananalysis')}},
    ];
    return <Breadcrumbs items={items} />;
}

export const AppSanAnalysisPage = (_props:any) => {
    const appToken = _props.appToken;
    const appInfo = _props.appInfo;
    const accountName = _props.accountName ;
    
      const [data, setData] = useState([['','',168,24,'',{"idfa": "","text": "","advertisingid": ""}]]);
      useEffect(() => {
          fetch('/api/sananalysis/'+appToken).then(res => res.json()).then(data => {
            setData(data["sanData"]);
          });
      }, [appToken]);
    return (
                    <div>
                        {MyBreadcrumbs(appInfo[3],accountName,appInfo[2],appInfo[1])}
                        <AppSegmentedButton appToken = {appToken} tabValue='8' />
                        <br/><br/>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Grid container spacing={6}>
                                    <Grid item xs={4}>
                                        <AppInfo appInfo={appInfo} accountName={accountName} />
                                    </Grid> 
                                    <Grid item xs={8}>
                                        <Tile title="San Analysis">
                                            <AppSanAnalysisTable data={data} />
                                        </Tile>
                                    </Grid> 
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
    );
}