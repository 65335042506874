import {Button} from '@mui/material';
import { Link } from 'react-router-dom';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

const accountLink = (accountId : any) => {
    if(accountId === ''){
        return '';
    }
    return <Button variant="contained" style={{width:'80px'}} component={Link} to={"/account/"+accountId} >{accountId}</Button>
}

function createData(
    id: number,
    type: string,
    date: string,
    accountId: string,
    accountName: string,
    info: any
  ) {
    return { id, type, date, accountId, accountName, info };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

const columns: GridColDef[] = [
    { field: 'type', headerName: 'Type',flex: 1, minWidth: 100},
    { field: 'accountId', headerName: 'Account Id',flex: 1, maxWidth: 100, sortable: false,
      renderCell: (params : any) => {
          return accountLink(params.row.accountId);
      }
    },
    { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 250},
    { field: 'date', headerName: 'Date',flex: 1, minWidth: 50},
    { field: 'info', headerName: 'Info',flex: 1, minWidth: 250},
]
export const UpdatesTableSales = (props : any) =>{
    var updates = props.updates;
    const rows : any = [];
    for (var i = 0; i < updates.length; i++){
        rows.push(
            createData(
                i,
                updates[i]["type"],
                formatDate(updates[i]["insertDate"]),
                updates[i]["accountId"],
                updates[i]["accountName"],
                updates[i]["info"],
            )
        );
    }

    return (
        <div style={{ height: 700, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}