import { Route, Routes, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { SideBar } from "../../shared/components/sideBar/SideBar";
import { AppAnomaliesPage } from "../../pages/app/appAnomalies/AppAnomalies";
import { AppCephDatapointsPage } from "../../pages/app/appCephDataPoints/AppCephDatapoints";
import { AppHistoryPage } from "../../pages/app/appHistory/AppHistory";
import { AppImplementationPage } from "../../pages/app/appImplementation/AppImplementation";
import { AppPage } from "../../pages/app/appPage/AppPage";
import { AppSettingsPage } from "../../pages/app/appSettings/AppSettings";
import { AppClickSpamHistory } from "../../pages/app/appClickSpamHistory/AppClickSpamHistory";
import { AppSanAnalysisPage } from "../../pages/app/appSanAnalysis/AppSanAnalysisPage";
import { AppEventsPage } from "../../pages/app/appEvents/AppEvents";
import { AppRawDataExportPage } from "../../pages/app/appRawDataExport/AppRawDataExport";
import { AppPartnerPage } from "../../pages/app/appPartner/AppPartner";
import { AppAttributionSettings } from "../../pages/app/attributionSettings/AttributionSettings";
import { AppSdks } from "../../pages/app/appSdks/AppSdks";


export const AppRouter = (props:any) => {
    let params = useParams();
    let appToken  = "";
    if (params.appToken !== undefined){
        appToken = String(params.appToken);
    }
    const [appInfo, setAppInfo] = useState(['','','','','','','','']);
    const [accountName, setAccountName] = useState([]);

    useEffect(() => {
        fetch('/api/appheader/'+appToken).then(res => res.json()).then(data => {
            setAppInfo(data["appData"]);
            setAccountName(data["accountName"]);
        });
    },[appToken]);

    return (
        <div style={{marginTop:'4%'}}>
            <Box sx={{ display: 'flex' }}>
                <SideBar></SideBar>
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Routes>
                        <Route path="/" element={<AppPage  appInfo={appInfo} accountName={accountName} appToken={appToken} /> } />
                        <Route path="/settings" element={<AppSettingsPage  appInfo={appInfo} accountName={accountName} appToken={appToken} /> } />
                        <Route path="/sdksentinel" element={<AppImplementationPage  appInfo={appInfo} accountName={accountName} appToken={appToken} /> } />
                        <Route path="/history" element={ <AppHistoryPage  appInfo={appInfo} accountName={accountName} appToken={appToken} /> } />
                        <Route path="/anomalies" element={<AppAnomaliesPage  appInfo={appInfo} accountName={accountName} appToken={appToken} /> } />
                        <Route path="/ceph" element={<AppCephDatapointsPage  appInfo={appInfo} accountName={accountName} appToken={appToken} /> } />
                        <Route path="/clickspamhistory" element={<AppClickSpamHistory  appInfo={appInfo} accountName={accountName} appToken={appToken} /> } />
                        <Route path="/sananalysis" element={<AppSanAnalysisPage  appInfo={appInfo} accountName={accountName} appToken={appToken} /> } />
                        <Route path="/events" element={<AppEventsPage  appInfo={appInfo} accountName={accountName} appToken={appToken} /> } />
                        <Route path="/rawexportsettings" element={<AppRawDataExportPage  appInfo={appInfo} accountName={accountName} appToken={appToken} /> } />
                        <Route path="/partner" element={<AppPartnerPage  appInfo={appInfo} accountName={accountName} appToken={appToken} /> } />
                        <Route path="/attributionsettings" element={<AppAttributionSettings  appInfo={appInfo} accountName={accountName} appToken={appToken} /> } />
                        <Route path="/appsdks" element={<AppSdks  appInfo={appInfo} accountName={accountName} appToken={appToken} /> } />
                        <Route path="/*" element={<AppPage  appInfo={appInfo} accountName={accountName} appToken={appToken} />}></Route>
                    </Routes>
                </Box>
            </Box>
        </div>
    );
}
