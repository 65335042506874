
import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { useLocation } from "react-router-dom";
import { AuthContext } from '../../app/store/AuthContext';
import { LoginButtonGoogle } from '../../entities/components/Login/LoginButtonGoogle';


  export default function LoginPage(_props:any) {
    const value = useContext(AuthContext);
    const auth = value?.auth.isLoggedIn;
    const role = value?.auth.role;
    const isManager = value?.auth.isManager;

    const location = useLocation();
    if(auth){
      if(location.pathname === '/' || location.pathname === '/login'){
        if((role === '1' || role === '4' || role === '5' || role === '7') && isManager !== false){
          window.location.href ="/myaccounts";
        }
        else{
          window.location.href = "/tools";
        }
      }
      else{
        window.location.href = location.pathname;
      }
    }

  return (
    <>
        <AppBar position="static" style={{backgroundColor:'#04182b'}}>
            <Toolbar variant="dense">
                <img id="logo-adjust" src={"https://a.storyblok.com/f/47007/x/2e8164e221/adjust-logo-white.svg"} width="200" height="50" alt="adjust.com" />
                <Typography variant="h6" color="inherit" component="div">
                  Support HQ
                </Typography>
            </Toolbar>
        </AppBar>
    
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="40vh"
        >
          <LoginButtonGoogle clientId={_props.clientId}/>
      </Box>
  </>
  );
}
