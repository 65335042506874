import { ComboBox } from '@adjust/components';
import React,{useState} from 'react'


export const SanPartnerInput = (_props) =>{
    const options = [
        { value: 'amazon_ads', label: 'Amazon Ads' },
        { value: 'amazon_san', label: 'Amazon Fire' },
        { value: 'apple_ads', label: 'Apple Ads' },
        { value: 'facebook', label: 'Facebook' },
        { value: 'googleads', label: 'Google Ads' },
        { value: 'google_marketing_platform', label: 'Google Marketing Platform' },
        { value: 'roku', label: 'Roku Endemic Media' },
        { value: 'snapchat', label: 'Snapchat' },
        { value: 'tiktok_san', label: 'TikTok SAN' },
        { value: 'twitter', label: 'Twitter' },
        { value: 'vizio', label: 'Vizio' },
        { value: 'yahoo_gemini', label: 'Yahoo Gemini' },
        { value: 'yahoo_japan_search', label: 'Yahoo!広告 検索広告 (Yahoo! JAPAN Ads Search Ads)' }
      ];
    const [value, setValue] = useState([]);
  
    const handleChange = (value) => {
      setValue(value);
      _props.onSanPartnersChange(value);
    }
  
    return (
      <ComboBox
        value={value}
        options={options}
        onChange={handleChange}
        placeholder="San Partner"
      />
    );
  }