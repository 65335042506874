import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

const columns: GridColDef[] = [
    { field: 'email', headerName: 'AM Mail',flex: 1, minWidth: 150},
    { field: 'usageCount', headerName: 'Usage Count',flex: 1, minWidth: 150},
    
  ];

function createData(
    id: number,
    usageCount: number,
    email: string,
  ){
    return { id, usageCount, email};
  }

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export const BqgUsageTable = (_props : any) => {
    var usage = _props.usage;
    const usageData : any = [];
    if(usage !== undefined){
        for (var i = 0; i < usage.length; i++){
            usageData.push(
                createData(
                    i,
                    usage[i][0],
                    usage[i][1],
                )
            );
        }
    }
    
    return (
        <div style={{ height: 550, width: '100%' }}>
            <DataGrid 
                rows={usageData} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            /> 
        </div>
    )
  }