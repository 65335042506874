import { Route, Routes } from "react-router-dom";
import {ClickSpamPage} from "../../pages/Tools/ClickSpam";
import { ToolsPage } from "../../pages/Tools/Tools";
import AdNetworkApi from "../../pages/Tools/adNetworkApi/AdNetworkApi";
import ApptopiaPage from "../../pages/Tools/apptopia/ApptopiaPage";
import { BQGPage } from "../../pages/Tools/baggerQueryGenerator/BQGPage";
import CheckDevicePage from "../../pages/Tools/checkDevice/CheckDevice";
import { ClickSpamAllowListPage } from "../../pages/Tools/clickSpam/ClickSpamAllowList";
import { ClickSpamHistoryPage } from "../../pages/Tools/clickSpam/ClickSpamHistory";
import ClickSpamNetworkPage from "../../pages/Tools/clickSpam/ClickSpamNetwork";
import { PartnerDomainBlocklistPage } from "../../pages/Tools/clickSpam/PartnerDomainBlocklist";
import { PartnerHostBlocklistPage } from "../../pages/Tools/clickSpam/PartnerHostBlocklist";
import EngagementDetailsPage from "../../pages/Tools/engagementDetails/EngagementDetails";
import FacebookSignedPage from "../../pages/Tools/facebookSigned/FbSigned";
import ForgetDevicePage from "../../pages/Tools/forgetDevice/ForgetDevice";
import GdprForgetMePage from "../../pages/Tools/gdprForgetMe/GdprForgetMe";
import InspectDevicePage from "../../pages/Tools/inspectDevice/InspectDevice";
import InspectMappingsPage from "../../pages/Tools/inspectMappings/InspectMappings";
import { IosAppVerificationPage } from "../../pages/Tools/iosAppVerification/IosAppVerification";
import LogoFinderPage from "../../pages/Tools/logoFinder/LogoFinder";
import { MetaApi } from "../../pages/Tools/metaApi/MetaApi";
import { MetaApiCalls } from "../../pages/Tools/metaApiCalls/MetaApiCalls";
import OptOutTrackingPage from "../../pages/Tools/optOutTracking/OptOutTracking";
import {PartnerCredentials} from "../../pages/Tools/partnerCredentials/PartnerCredentials";
import TrackerInfoPage from "../../pages/Tools/trackerInfo/TrackerInfo";
import { TrackerApiPage } from "../../pages/Tools/trackerApi/TrackerApiPage";
import { BaggerOverviewPage } from "../../pages/Tools/baggerOverview/BaggerOverview";
import { MetaPartnerApi } from "../../pages/Tools/metaPartnerApi/MetaPartnerApi";
import { ClickspamChecker } from "../../pages/Tools/clickSpam/ClickSpamChecker/ClickSpamChecker";
import { Box } from "@mui/material";
import { SideBar } from "../../shared/components/sideBar/SideBar";
import { CaseOverviewPage } from "../../pages/Tools/caseOverview/CaseOverview";
import { AdRevenueRefetchPage } from "../../pages/Tools/AdRevenueRefetch/AdRevenueRefetch";
import { SanAnalysisPage } from "../../pages/Tools/SanAnalysis/SanAnalysisPage";
import { CvMappingPage } from "../../pages/Tools/cvMapping/CvMappingPage";
import { AnomaliesOverviewPage } from "../../pages/Tools/AnomaliesOverview/AnomaliesOverview";

export const ToolsRouter = () => {
    return(
        <Box sx={{ display: 'flex',marginTop:'4%',marginLeft:'1%',marginRight:'2%'}}>
            <SideBar/>
            <Routes>
                <Route path="/" element={<ToolsPage/>} />
                <Route path="/logofinder/" element={<LogoFinderPage/>} />
                <Route path="/inspectdevice/" element={<InspectDevicePage/>} />
                <Route path="/iosappverification/" element={<IosAppVerificationPage/>} />
                <Route path="/credentialsserviceapi/" element={<PartnerCredentials />} />
                <Route path="/adnetworkapi" element={<AdNetworkApi/>} />
                <Route path="/metaapi" element={<MetaApi />} />
                <Route path="/metaapicalls" element={<MetaApiCalls />} />
                <Route path="/metapartnerapi" element={<MetaPartnerApi />} />
                
                <Route path="/bqg" element={<BQGPage/>} />

                <Route path="/clickspam" element={<ClickSpamPage/>} />
                <Route path="/clickspamnetwork" element={<ClickSpamNetworkPage/>} />
                <Route path="/clickspamallowlist" element={<ClickSpamAllowListPage/>} />
                <Route path="/clickspamhistory" element={<ClickSpamHistoryPage/>} />
                <Route path="/partnerdomainblocklist" element={<PartnerDomainBlocklistPage />} />
                <Route path="/partnerhostblocklist" element={<PartnerHostBlocklistPage />} />
                <Route path="/clickspamchecker" element={<ClickspamChecker />} />
                
                <Route path="/inspectmappings" element={<InspectMappingsPage/>} />
                <Route path="/trackerinfo" element={<TrackerInfoPage/>} />
                <Route path="/engagementdetails" element={<EngagementDetailsPage/>} />
                <Route path="/checkdevice" element={<CheckDevicePage/>} />
                <Route path="/fbsigned" element={<FacebookSignedPage/>} />
                <Route path="/forgetdevice" element={<ForgetDevicePage/>} />
                <Route path="/optouttracking" element={<OptOutTrackingPage/>} />
                <Route path="/gdprforgetme" element={<GdprForgetMePage/>} />
                <Route path="/apptopia" element={<ApptopiaPage/>} />
                <Route path="/caseoverview" element={<CaseOverviewPage/>} />
                <Route path="/trackerapi" element={<TrackerApiPage/>} />

                <Route path="/baggeroverview" element={<BaggerOverviewPage/>} />
                <Route path="/adrevenuerefetch" element={<AdRevenueRefetchPage/>} />
                <Route path="/sananalysis" element={<SanAnalysisPage/>} />
                <Route path="/cvmapping" element={<CvMappingPage/>} />
                <Route path="/anomaliesoverview" element={<AnomaliesOverviewPage/>} />

                <Route path="/*" element={<ToolsPage />}></Route>
            </Routes>
        </Box>
    );
}