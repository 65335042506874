export const formatVersion = (data : any) => {
    if(data === undefined){
        return 'N/A';
    }
    let version = '';
    for (let i = 0; i < data.length; i++) {
        let appVersion = '';
        if(data[i].appVersion !== undefined){
            appVersion = data[i].appVersion.replaceAll('"','').replaceAll('[','').replaceAll(']','');
            if(version === ''){
                if(appVersion !== null && appVersion !== 'null'){
                    version = appVersion.replaceAll('"','').replaceAll('[','').replaceAll(']','');
                }
            }
            else{
                let version1List = version.split('.'); //2.2405.1
                let version2List = appVersion.split('.'); //2.2405.0
                for (let j = 0; j < version1List.length; j++) {
                    if(parseInt(version2List[j]) > parseInt(version1List[j])){
                        if(appVersion !== null && appVersion !== 'null'){
                            version = appVersion;
                        }
                        break;
                    }
                    if(parseInt(version2List[j]) < parseInt(version1List[j])){
                        break;
                    }
                }
            }
        }
    }
    return version;
}

export const formatVersionShort = (data : any) => {
    if(data === undefined){
        return 'N/A';
    }
    let version = '';
    for (let i = 0; i < data.length; i++) {
        let appVersionShort = '';
        if(data[i].appVersionShort !== undefined){
            appVersionShort = data[i].appVersionShort.replaceAll('"','').replaceAll('[','').replaceAll(']','');
            if(version === ''){
                if(appVersionShort !== null && appVersionShort !== 'null'){
                    version = appVersionShort.replaceAll('"','').replaceAll('[','').replaceAll(']','');
                }
            }
            else{
                let version1List = version.split('.'); //2.2405.1
                let version2List = appVersionShort.split('.'); //2.2405.0
                for (let j = 0; j < version1List.length; j++) {
                    if(parseInt(version2List[j]) > parseInt(version1List[j])){
                        if(appVersionShort !== null && appVersionShort !== 'null'){
                            version = appVersionShort;
                        }
                        break;
                    }
                    if(parseInt(version2List[j]) < parseInt(version1List[j])){
                        break;
                    }
                }
            }
        }
    }
    return version;
}

export const formatVersionIos = (data : any) => {
    if(data === undefined){
        return 'N/A';
    }
    let version = '';
    for (let i = 0; i < data.length; i++) {
        let appVersion = '';

        if(data[i].appVersion !== undefined){
            if(data[i].appVersion !== undefined){
                appVersion = data[i].appVersion.replaceAll('"','').replaceAll('[','').replaceAll(']','');
            }
            else{
                appVersion = data[i].appVersion.replaceAll('"','').replaceAll('[','').replaceAll(']','');
            }
            if(version === ''){
                if(appVersion !== null && appVersion !== 'null'){
                    version = appVersion.replaceAll('"','').replaceAll('[','').replaceAll(']','');
                }
            }
            else{
                let version1List = version.split('.');
                let version2List = appVersion.split('.');
                for (let j = 0; j < version1List.length; j++) {
                    if(parseInt(version2List[j]) > parseInt(version1List[j])){
                        if(appVersion !== null && appVersion !== 'null'){
                            version = appVersion;
                        }
                        break;
                    }
                    if(parseInt(version2List[j]) < parseInt(version1List[j])){
                        break;
                    }
                }
            }
        }


    }
    return version;
}
