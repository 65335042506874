import { Box, Button, CircularProgress, Grid} from '@mui/material';
import { useEffect, useState } from 'react';
import { UpdatesTable } from '../../entities/components/Overview/UpdatesTable';
import { Link } from 'react-router-dom';
import { InfoTooltip } from '../../shared/components/infoTooltip/InfoTooltip';

export const OverviewPage = () => {
    const [updates, setUpdates] = useState([]);
    const [vacation, setVacation] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        setIsLoading(true);
        fetch('/api/overview').then(res => res.json()).then(data => {
            setUpdates(data["updates"]);
            setVacation(data["vacation"]);
            setIsLoading(false);
        });
    }, []);

    return (
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <div>
                    <h1 style={{marginTop:'0px'}}>My Overview</h1>
                    {vacation &&
                    <>
                        <h3>Welcome back from your vacation</h3>
                        <h4>Here is a list of the created cases during your absence</h4>
                        <Button variant="outlined" component={Link} to={"/welcomeback"}>Case List</Button>

                    </>
                    }
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Grid container spacing={6}>
                                <Grid item xs={10}>
                                    <h2>Last 30 days Updates</h2>
                                </Grid>
                                <Grid item xs={2}>
                                    <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to={"/amcaseview"}>My Cases</Button>
                                </Grid>
                            </Grid>
                            {isLoading &&
                            <CircularProgress />
                            }
                            {!isLoading &&
                            <>
                                <InfoTooltip message="Source: Data Science DB | Refreshed: instant" />
                                <UpdatesTable updates = {updates} />
                            </>
                            }
                        </Grid>
                    </Grid>
                </div>
            </Box>
    );
}