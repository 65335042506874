import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { formatDate } from "../../../shared/components/formatDate/FormatDate";

export const AccountNotesTextfield = (_props : any) => {
    const accountId = _props.accountId;
    const lastEdited = _props.lastEdited;
    const lastUpdated = _props.lastUpdated;
    const [notes,setNotes] = useState(_props.notes);

    const updateNotes = () =>{
        if(accountId !== '' ){
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'accountId': accountId, 'note':notes })
            };
            fetch('/api/updatenotes', requestOptions)
                .then(response => response.json()).then(data => {});
        }
    }

    return(
        <>
            <p>Last edit from: {lastEdited}</p>
            <p>Last edit on: {formatDate(lastUpdated)}</p>
            <TextField
                id="multilineTextfield"
                label="OOO Notes"
                multiline
                rows={6}
                value={notes}
                style={{width:'100%'}}
                onChange={(e) => {
                    setNotes(e.target.value);
                }}
            />
            <br/><br/>
            <Button variant='contained' onClick={updateNotes}>Save</Button>
        </>
    );
}