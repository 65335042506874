import { Avatar, Box, Button, Grid} from '@mui/material';
import { Tile } from '@adjust/components';
import { Link } from 'react-router-dom';

export const ManagerCasesPage = (_props:any) => {
    const users = _props.users;
    return (
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <div>
                    <Grid container spacing={2}>
                        {users.map((user : any) => (
                            <Grid item xs={3}>
                                <Tile title={user[0] + " " + user[1]}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={3}>
                                            <Avatar alt="Remy Sharp" src={user[5]} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <h5>Location: {user[2][0].toUpperCase() + user[2].slice(1)}</h5>
                                        </Grid>
                                        <Button variant="contained" component={Link} to={"/amcaseview/"+user[4]} style={{marginLeft:'2%'}}>Case View</Button>
                                    </Grid>
                                </Tile>
                            </Grid>
                        ))}
                        
                    </Grid>
                </div>
            </Box>
    );
}