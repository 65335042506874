import { TextField } from "@mui/material";

export const RequestUrlInput = (_props: any ) => {

    const handleChange = (value:any) => {
        _props.onRequestUrlChange(value.target.value);
    }

    return(
        <TextField label="Request Url" variant="outlined" onChange={handleChange} style={{width:'100%'}}/>
    );
}