import {Tile } from "@adjust/components";
import {Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import {useState } from "react";
import SearchIcon from '@mui/icons-material/Search';

export default function QueryAppSearch (props : any){
    const [appName,setAppName] = useState('');
    const [appToken,setAppToken] = useState('');
    const [accountName,setAccountName] = useState('');
    const [accountId,setAccountId] = useState('');
    const [trackerToken,setTrackerToken] = useState('');
    const [eventToken,setEventToken] = useState('');
    const [partner,setPartner] = useState('');
    const [rtc,setRtc] = useState('');
    const [csv,setCsv] = useState('');
    const queryPartner  = props.queryPartner;
    const [datas,setDatas] = useState([]);
    const [storeId,setStoreId] = useState('');
    

    function searchApps(appName : string, appToken : string, accountName : string,accountId: string, trackerToken: string, eventToken: string, partner : string, rtc : string, csv: string, storeId : string){
        props.onIsLoadingChange(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "appName": appName, "appToken" : appToken, "accountName" : accountName, "accountId" : accountId,
             "trackerToken" : trackerToken,"eventToken" : eventToken,"partner" : partner,"rtc" : rtc,"csv" : csv, "storeId": storeId}) 
        };
        fetch('/api/queryapp', requestOptions)
            .then(response => response.json()).then(data => {
                props.onAppChange(data);
                props.onIsLoadingChange(false);
                console.log(data)
                setDatas(data);
            });
    }

    const appNameKeyDown = (event : any) => {
        if (event.key === 'Enter') {
            searchApps(appName,appToken,accountName,accountId,trackerToken,eventToken,partner,rtc,csv,storeId);
        }
    }
    const appTokenKeyDown = (event : any) => {
        if (event.key === 'Enter') {
            searchApps(appName,appToken,accountName,accountId,trackerToken,eventToken,partner,rtc,csv,storeId);
        }
    }
    const accountNameKeyDown = (event : any) => {
        if (event.key === 'Enter') {
            searchApps(appName,appToken,accountName,accountId,trackerToken,eventToken,partner,rtc,csv,storeId);
        }
    }
    const accountIdKeyDown = (event : any) => {
        if (event.key === 'Enter') {
            searchApps(appName,appToken,accountName,accountId,trackerToken,eventToken,partner,rtc,csv,storeId);
        }
    }
    const trackerTokenKeyDown = (event : any) => {
        if (event.key === 'Enter') {
            searchApps(appName,appToken,accountName,accountId,trackerToken,eventToken,partner,rtc,csv,storeId);
        }
    }
    const eventTokenKeyDown = (event : any) => {
        if (event.key === 'Enter') {
            searchApps(appName,appToken,accountName,accountId,trackerToken,eventToken,partner,rtc,csv,storeId);
        }
    }

    const checkBlock = (value : string) => {
        if(value === 'appName'){
            if(eventToken === '' && appToken === '' && accountId === '' && accountName === '' && trackerToken === '' && storeId === ''){
                return false;
            }
            return true;
        }   
        if(value === 'appToken'){
            if(appName === '' && eventToken === '' && accountId === '' && accountName === '' && trackerToken === '' && storeId === ''){
                return false;
            }
            return true;
        }   
        if(value === 'accountId'){
            if(appName === '' && appToken === '' && eventToken === '' && accountName === '' && trackerToken === '' && storeId === ''){
                return false;
            }
            return true;
        }   
        if(value === 'accountName'){
            if(appName === '' && appToken === '' && accountId === '' && eventToken === '' && trackerToken === '' && storeId === ''){
                return false;
            }
            return true;
        }
        if(value === 'tracker'){
            if(appName === '' && appToken === '' && accountId === '' && accountName === '' && eventToken === '' && storeId === ''){
                return false;
            }
            return true;
        }
        if(value === 'event'){
            if(appName === '' && appToken === '' && accountId === '' && accountName === '' && trackerToken === '' && storeId === ''){
                return false;
            }
            return true;
        }
        if(value === 'storeId'){
            if(appName === '' && appToken === '' && accountId === '' && accountName === '' && trackerToken === '' && eventToken === ''){
                return false;
            }
            return true;
        }
        return false;
    }

    return(
        <Tile>
            <Grid container spacing={6}>
                <Grid item xs={6}>
                    <TextField
                        style={{width:'60%'}}
                        label="App Name"
                        id="appName" 
                        name="appName"
                        value={appName}
                        disabled={checkBlock('appName')}
                        onChange={(e) => {
                            setAppName(e.target.value);
                        }}
                        onKeyDown={appNameKeyDown}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button onClick={() => searchApps(appName,appToken,accountName,accountId,trackerToken,eventToken,partner,rtc,csv,storeId)}><SearchIcon /></Button>
                              </InputAdornment>
                            ),
                        }}
                    />
                    <br/><br/>
                    <TextField
                        style={{width:'60%'}}
                        label="App Token's"
                        id="appToken" 
                        name="appToken"
                        value={appToken}
                        disabled={checkBlock('appToken')}
                        onChange={(e) => {
                            setAppToken(e.target.value);
                        }}
                        onKeyDown={appTokenKeyDown}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button onClick={() => searchApps(appName,appToken,accountName,accountId,trackerToken,eventToken,partner,rtc,csv,storeId)}><SearchIcon /></Button>
                              </InputAdornment>
                            ),
                        }}
                    />
                    <br/><br/>
                    <TextField
                        style={{width:'60%'}}
                        label="Account Name"
                        id="accountName" 
                        name="accountName"
                        value={accountName}
                        disabled={checkBlock('accountName')}
                        onChange={(e) => {
                            setAccountName(e.target.value);
                        }}
                        onKeyDown={accountNameKeyDown}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button onClick={() => searchApps(appName,appToken,accountName,accountId,trackerToken,eventToken,partner,rtc,csv,storeId)}><SearchIcon /></Button>
                              </InputAdornment>
                            ),
                        }}
                    />
                    <br/><br/>
                    <TextField
                        style={{width:'60%'}}
                        label="Account ID's"
                        id="accountId" 
                        name="accountId"
                        value={accountId}
                        disabled={checkBlock('accountId')}
                        onChange={(e) => {
                            setAccountId(e.target.value);
                        }}
                        onKeyDown={accountIdKeyDown}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button onClick={() => searchApps(appName,appToken,accountName,accountId,trackerToken,eventToken,partner,rtc,csv,storeId)}><SearchIcon /></Button>
                              </InputAdornment>
                            ),
                        }}
                    />
                    <br/><br/>
                    <TextField
                        style={{width:'60%'}}
                        label="Tracker Token"
                        id="trackerToken" 
                        name="trackerToken"
                        value={trackerToken}
                        disabled={checkBlock('tracker')}
                        onChange={(e) => {
                            setTrackerToken(e.target.value);
                        }}
                        onKeyDown={trackerTokenKeyDown}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button onClick={() => searchApps(appName,appToken,accountName,accountId,trackerToken,eventToken,partner,rtc,csv,storeId)}><SearchIcon /></Button>
                              </InputAdornment>
                            ),
                        }}
                    />
                    <br/><br/>
                    <TextField
                        style={{width:'60%'}}
                        label="Event Token"
                        id="eventToken" 
                        name="eventToken"
                        value={eventToken}
                        disabled={checkBlock('event')}
                        onChange={(e) => {
                            setEventToken(e.target.value);
                        }}
                        onKeyDown={eventTokenKeyDown}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button onClick={() => searchApps(appName,appToken,accountName,accountId,trackerToken,eventToken,partner,rtc,csv,storeId)}><SearchIcon /></Button>
                              </InputAdornment>
                            ),
                        }}
                    />
                    <br/><br/>
                    <TextField
                        style={{width:'60%'}}
                        label="Store Id"
                        id="storeId" 
                        name="storeId"
                        value={storeId}
                        disabled={checkBlock('storeId')}
                        onChange={(e) => {
                            setStoreId(e.target.value);
                        }}
                        onKeyDown={eventTokenKeyDown}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button onClick={() => searchApps(appName,appToken,accountName,accountId,trackerToken,eventToken,partner,rtc,csv,storeId)}><SearchIcon /></Button>
                              </InputAdornment>
                            ),
                        }}
                    />
                    
                </Grid>

                <Grid item xs={6}>
                    <FormControl style={{width:'60%'}}>
                        <InputLabel id="partnerDropdown">Module Partner</InputLabel>
                        <Select
                            labelId="SelectPartnerDropdown"
                            id="SelectPartnerDropdown"
                            value={partner}
                            label="Module Partner"
                            onChange={(e) => {
                                setPartner(e.target.value);
                                searchApps(appName,appToken,accountName,accountId,trackerToken,eventToken,e.target.value,rtc,csv,storeId);
                            }}
                        >
                        {queryPartner.map((partner : any) =>
                            <MenuItem value={partner} key={partner}>{partner}</MenuItem>
                        )}
                        </Select>
                    </FormControl>
                    <br/><br/>
                    <p>RTC will not show all table stats due to a massive load</p>
                    <FormControl style={{width:'60%'}}>
                        <InputLabel id="rtcDropdown">RTC</InputLabel>
                        <Select
                            labelId="SelectRtcDropdown"
                            id="SelectRtcDropdown"
                            value={rtc}
                            label="RTC"
                            onChange={(e) => {
                                setRtc(e.target.value);
                                searchApps(appName,appToken,accountName,accountId,trackerToken,eventToken,partner,e.target.value,csv,storeId);
                            }}
                        >
                        <MenuItem value="Activated" key="activatedRTC">Activated</MenuItem>
                        <MenuItem value="Not Activated" key="notActivatedRTC">Not Activated</MenuItem>
                        </Select>
                    </FormControl>
                    <br/><br/>
                    <FormControl style={{width:'60%'}}>
                        <InputLabel id="csvDropdown">CSV Export</InputLabel>
                        <Select
                            labelId="SelectCsvDropdown"
                            id="SelectCsvDropdown"
                            value={csv}
                            label="CSV Export"
                            onChange={(e) => {
                                setCsv(e.target.value);
                                searchApps(appName,appToken,accountName,accountId,trackerToken,eventToken,partner,rtc,e.target.value,storeId);
                            }}
                        >
                        <MenuItem value="Activated" key="activatedCSV">Activated</MenuItem>
                        <MenuItem value="Not Activated" key="notActivatedCSV">Not Activated</MenuItem>
                        </Select>
                    </FormControl>

                </Grid>
                {datas.length > 499 &&
                <div style={{marginLeft:'3%'}}>
                    <p style={{color:'red'}}>Not all information are loaded, because the amount of apps is about 500</p>
                </div>
                }
            </Grid>
        </Tile>
    );
}