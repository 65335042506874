import { Button, Input } from '@adjust/components';
import { Tile } from '@adjust/components/build/Tile/Tile';
import { Box, Divider, Grid, List, ListItem} from '@mui/material';
import React, { useState } from 'react';

function setUrls(datas : any){
    var rows = [];
    rows.push(<>
        <ListItem>
            <Grid container direction="row" alignItems="center" spacing={0}>
                <Grid item xs={1}>
                    Account Id
                </Grid>
                <Grid item xs={1}>
                    App Token
                </Grid>
                <Grid item xs={5}>
                    Android Url
                </Grid>
                <Grid item xs={5}>
                    iOS Url
                </Grid>
            </Grid>
        </ListItem>
        <Divider light />
    </>);
    for (var i = 0; i < datas.length; i++) {
        rows.push(<>
                <ListItem>
                    <Grid container direction="row" alignItems="center" spacing={0}>
                        <Grid item xs={1}>
                            {datas[i][0]} 
                        </Grid>
                        <Grid item xs={1}>
                            {datas[i][1]} 
                        </Grid>
                        <Grid item xs={5}>
                            {datas[i][2]} 
                        </Grid>
                        <Grid item xs={5}>
                            {datas[i][3]} 
                        </Grid>
                    </Grid>
                </ListItem>
                <Divider light />
            </>);
    }
    return <List  component="nav">
                {rows}
            </List>
}

export default function LogoFinderPage() {
    const [appToken, setAppToken] = useState('');
    const [appTokenData, setAppTokenData] = useState([]);
    function searchAppToken(appToken : any){
        const data = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({appToken}),
        }
        fetch('/api/searchapptoken',data).then(response => response.json()).then(function(result) {
            setAppTokenData(result);
        });
    }
    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <h1>Logo Finder</h1>
                                <h4>By Timur</h4>
                            </Grid>
                            <Grid item xs={6} style={{paddingTop:'24px'}}>
                                <Tile title="Enter App Token * (use a comma for multiple values)">
                                <Input
                                    label=""
                                    id="apptoken" 
                                    name="apptoken"
                                    value={appToken}
                                    onClear={() => {
                                        setAppToken('');
                                    }}
                                    onChange={(e) => {
                                        setAppToken(e.target.value);
                                    }}
                                />
                                <Button  type="submit" kind="primary" label="Search"  onClick={() => searchAppToken(appToken)}></Button>{' '}
                                </Tile>
                            </Grid>
                            <Grid item xs={12}>
                                {setUrls(appTokenData)}
                            </Grid>
                        </Grid>
                           
                    </div>
                </Box>
    );
}