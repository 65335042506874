import { Box, Button, Card, CircularProgress, Divider, Grid, List, ListItem } from "@mui/material";
import { Breadcrumbs } from '@adjust/components/build/Breadcrumbs/Breadcrumbs';
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { AccountSegmentedButton } from "../../../shared/components/accountSegmentedButton/AccountSegmentedButton";
import { Account, AccountImplementationData } from "../../../shared/components/Types/Types";
import { useQuery } from "react-query";
import { getData } from "../../../shared/components/Hooks/AccountHooks";
import { formatDate } from '../../../shared/components/formatDate/FormatDate';
import { InfoTooltip } from "../../../shared/components/infoTooltip/InfoTooltip";

const isValidUrl = (urlString : string) => {
    let url;
    try {
        url = new URL(urlString);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}

const checkDocs = (docs : string) => {
    if (isValidUrl(docs)){
        return <Button variant="contained" href={docs} target="_blank">Show Google Doc</Button>
    }
    return docs;
}

function MyBreadcrumbs(accountId:string,accountName: string,navigate:NavigateFunction) {
    const items = [
      { label: 'My Accounts', onClick: () => {navigate('/myaccounts')}},
      { label: accountName, onClick: () => {navigate('/account/' + accountId)} },
      { label: 'Implementation', onClick: () => {navigate('/account/' + accountId + '/implementation')}},
    ];
    return <Breadcrumbs items={items} />;
  }

export const AccountImplementationPage = (_props:Account) => {
    let navigate = useNavigate();
    let params = useParams();
    let accountId  = "0";
    if (params.accountId !== undefined){
        accountId = String(params.accountId);
    }
    const { status, data } = useQuery<AccountImplementationData, Error>(
        ["implementationPage", { accountId }], () => getData(accountId,'implementation'));


    return(
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    {MyBreadcrumbs(accountId,_props.accountName,navigate)}
                    <AccountSegmentedButton accountId = {accountId} tabValue='7' accountManager={_props.accountManager} competitor={_props.competitor} />
                    <br/>
                    {status === "loading" &&
                        <CircularProgress />
                    }
                    {status !== "loading" &&
                    <div>
                        {data?.implementationData.length === 0 &&
                            <h2>There is no Implementation history for this account: {_props.accountName}</h2>
                        }
                        {data?.implementationData.map((implementation : any) =>
                            <>
                                <Card>
                                    <InfoTooltip message="Source: Salesforce | Refreshed: once a day" />
                                    <List component="nav">
                                        <ListItem>
                                            <Grid container spacing={6}>
                                                <Grid item xs={3}>
                                                    <div style={{fontWeight:'bold',marginRight:'4px'}}>Implementation Engineer: </div> 
                                                </Grid>
                                                <Grid item xs={8}><a href={"mailto:"+implementation[0]} target="_top">{implementation[1]}</a></Grid>
                                            </Grid>
                                        </ListItem>
                                        <Divider light />

                                        <ListItem>
                                            <Grid container spacing={6}>
                                                <Grid item xs={3}>
                                                    <div style={{fontWeight:'bold',marginRight:'4px'}}>Opportunity ID: </div> 
                                                </Grid>
                                                <Grid item xs={8}>{implementation[11]}</Grid>
                                            </Grid>
                                        </ListItem>
                                        <Divider light />

                                        <ListItem>
                                            <Grid container spacing={6}>
                                                <Grid item xs={3}>
                                                    <div style={{fontWeight:'bold',marginRight:'4px'}}>Start Date: </div> 
                                                </Grid>
                                                <Grid item xs={8}>{formatDate(implementation[4])}</Grid>
                                            </Grid>
                                        </ListItem>
                                        <Divider light />

                                        <ListItem>
                                            <Grid container spacing={6}>
                                                <Grid item xs={3}>
                                                    <div style={{fontWeight:'bold',marginRight:'4px'}}>End Date: </div> 
                                                </Grid>
                                                <Grid item xs={8}>{formatDate(implementation[5])}</Grid>
                                            </Grid>
                                        </ListItem>
                                        <Divider light />

                                        <ListItem>
                                            <Grid container spacing={6}>
                                                <Grid item xs={3}>
                                                    <div style={{fontWeight:'bold',marginRight:'4px'}}>Implementation Status: </div> 
                                                </Grid>
                                                <Grid item xs={8}>{implementation[2]}</Grid>
                                            </Grid>
                                        </ListItem>
                                        <Divider light />

                                        <ListItem>
                                            <Grid container spacing={6}>
                                                <Grid item xs={3}>
                                                    <div style={{fontWeight:'bold',marginRight:'4px'}}>Reason for Implementation Closed Lost: </div> 
                                                </Grid>
                                                <Grid item xs={8}>{implementation[10]}</Grid>
                                            </Grid>
                                        </ListItem>
                                        <Divider light />

                                        <ListItem>
                                            <Grid container spacing={6}>
                                                <Grid item xs={3}>
                                                    <div style={{fontWeight:'bold',marginRight:'4px'}}>Implementation Completion %: </div> 
                                                </Grid>
                                                <Grid item xs={8}>{implementation[3]}</Grid>
                                            </Grid>
                                        </ListItem>
                                        <Divider light />

                                        <ListItem>
                                            <Grid container spacing={6}>
                                                <Grid item xs={3}>
                                                    <div style={{fontWeight:'bold',marginRight:'4px'}}>Estimated Handover Date to TAM: </div> 
                                                </Grid>
                                                <Grid item xs={8}>{formatDate(implementation[9])}</Grid>
                                            </Grid>
                                        </ListItem>
                                        <Divider light />

                                        <ListItem>
                                            <Grid container spacing={6}>
                                                <Grid item xs={3}>
                                                    <div style={{fontWeight:'bold',marginRight:'4px'}}>Days in Implementation Status: </div> 
                                                </Grid>
                                                <Grid item xs={8}>{implementation[6]}</Grid>
                                            </Grid>
                                        </ListItem>
                                        <Divider light />

                                        <ListItem>
                                            <Grid container spacing={6}>
                                                <Grid item xs={3}>
                                                    <div style={{fontWeight:'bold',marginRight:'4px'}}>Days to Implementation Complete: </div> 
                                                </Grid>
                                                <Grid item xs={8}>{implementation[7]}</Grid>
                                            </Grid>
                                        </ListItem>
                                        <Divider light />

                                        <ListItem>
                                            <Grid container spacing={6}>
                                                <Grid item xs={3}>
                                                    <div style={{fontWeight:'bold',marginRight:'4px'}}>Implementation Notes: </div> 
                                                </Grid>
                                                <Grid item xs={8}>{checkDocs(implementation[8])}</Grid>
                                            </Grid>
                                        </ListItem>
                                    </List>
                                </Card>
                                <br/><br/>
                            </>
                        )}
                    </div>
                    }
                </Box>
    );
}