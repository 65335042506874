import { Breadcrumbs, SegmentedButton } from "@adjust/components";
import { Box, CircularProgress, Grid } from "@mui/material";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useState } from "react";
import { Account, AccountKeyStatsData } from "../../shared/components/Types/Types";
import { AccountApplovinInstallsChart } from "../../entities/components/Accounts/AccountApplovinInstallsChart";
import { AccountApplovinInstallsTable } from "../../entities/components/Accounts/AccountApplovinInstallsTable";
import { AttributionKeyStats } from "../../entities/components/Accounts/AccountKeyStats/AttributionKeyStats";
import { DailyDataPointsKeyStats } from "../../entities/components/Accounts/AccountKeyStats/DailyDataPointsKeyStats";
import { DataKeyStats } from "../../entities/components/Accounts/AccountKeyStats/DataKeyStats";
import { DataPointsKeyStats } from "../../entities/components/Accounts/AccountKeyStats/DataPointKeyStats";
import { MauKeyStats } from "../../entities/components/Accounts/AccountKeyStats/MauKeyStats";
import { PartnerInstalls } from "../../entities/components/Accounts/AccountKeyStats/PartnerInstalls";
import { AccountUsage } from "../../entities/components/Accounts/AccountKeyStats/Usage/AccountUsage";
import { UsageTable } from "../../entities/components/Accounts/AccountKeyStats/UsageTable";
import { getData } from "../../shared/components/Hooks/AccountHooks";
import { InfoTooltip } from "../../shared/components/infoTooltip/InfoTooltip";
import { AccountSalesSegmentedButton } from "./AccountSalesSegmentedButton";
import { SfUsageTable } from "../../entities/components/Accounts/AccountKeyStats/Usage/SfUsageTable";

const MyBreadcrumbs = (accountId:string,accountName: string, navigate:NavigateFunction) => {
    const items = [
      { label: 'My Accounts', onClick: () => {navigate('/myaccounts')}},
      { label: accountName, onClick: () => {navigate('/account/' + accountId)}},
      { label: 'Key Stats', onClick: () => {navigate('/account/' + accountId + '/keystats')}},
    ];
    return <Breadcrumbs items={items} />;
  }
  
export const AccountKeyStatsSalesPage = (_props:Account) => {
    let navigate = useNavigate();
    let params = useParams();
    let accountId  = "0";
    if (params.accountId !== undefined){
        accountId = String(params.accountId);
    }
    const [info,setInfo] = useState('1');
    const items = [
        { value: '1', label: 'Key Stats',},
        { value: '2', label: 'Applovin Installs'},
        { value: '3', label: 'Dashboard Usage'},
        { value: '4', label: 'SF Usage'},
      ];

    const { status, data } = useQuery<AccountKeyStatsData, Error>(
        ["keyStatsPage", { accountId }], () => getData(accountId,'accountkeystats'));

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    {MyBreadcrumbs(accountId,_props.accountName,navigate)}
                    <AccountSalesSegmentedButton accountId = {accountId} tabValue='6' accountManager={_props.accountManager} competitor={_props.competitor} />
                    <br/>
                    {status === "loading" &&
                        <CircularProgress />
                    }
                    {status !== "loading" &&
                    <div>
                        <SegmentedButton items={items} value={info} onChange={event => setInfo(event.target.value)}/>
                        {info === '1' &&
                            <div>
                                <InfoTooltip message="Source: Salesforce | Refreshed: monthly" />
                                {(data?.sfUsage.length > 0) &&
                                <UsageTable 
                                    sfUsage={data?.sfUsage} 
                                />
                                }
                                <br/>
                                <br/>
                                <Grid container spacing={4}>
                                    <Grid item xs={6}>
                                        <InfoTooltip message="Source: Meatabase / Data Science | Refreshed: monthly" />
                                        <AttributionKeyStats attributions={data?.attributions} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InfoTooltip message="Source: Meatabase / Data Science | Refreshed: monthly" />
                                        <MauKeyStats maus={data?.maus} />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <InfoTooltip message="Source: Meatabase / Data Science | Refreshed: monthly" />
                                        <DataPointsKeyStats dps={data?.keyStats} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InfoTooltip message="Source: Meatabase / Data Science | Refreshed: once a day" />
                                        <DailyDataPointsKeyStats dps={data?.dailyDps} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InfoTooltip message="Source: Meatabase / Data Science | Refreshed: monthly" />
                                        <DataKeyStats dps={data?.keyStats} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <InfoTooltip message="Source: Meatabase / Data Science | Refreshed: monthly" />
                                        <PartnerInstalls installs={data?.partnerInstalls} />
                                    </Grid>
                                </Grid>
                            </div>
                        }
                        {info === '2' &&
                            <div>
                                <InfoTooltip message="Source: Meatabase / Data Science | Refreshed: monthly" />
                                <AccountApplovinInstallsTable installs={data?.applovinInstalls} />
                                <br/>
                                {data?.applovinInstalls.length > 0 &&
                                <AccountApplovinInstallsChart installs={data?.applovinInstalls} />
                            }
                            </div>
                        }
                        {info === '3' &&
                            <>
                                <InfoTooltip message="Source: Meatabase / Data Science | Refreshed: bi weekly" />
                                <AccountUsage usageMinimized = {data?.usageMinimized} usage = {data?.usage}/>
                            </>
                        }
                        {info === '4' &&
                            <>
                                <InfoTooltip message="Source: Salesforce | Refreshed: monthly" />
                                <SfUsageTable data = {data?.sfUsage} />
                            </>
                        }
                    </div>
                    }
                </Box>
    );
}
