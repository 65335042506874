import { Button, ComboBox, DateRangePicker, Input } from '@adjust/components';
import { Tile } from '@adjust/components/build/Tile/Tile';
import { Box, Grid} from '@mui/material';
import React, { useEffect, useState } from 'react';
import AdNetworkTable from '../../../entities/components/Tools/AdNetworkTable';

export default function AdNetworkApi() {
    const [accountId, setAccountId] : any = useState('');
    const [partner, setPartner]:any = useState('');
    const [adNetworkData, setAdNetworkData] = useState([]);
    const [accessToken,setAccessToken] : any = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndate] = useState(new Date());
    let accId = (new URLSearchParams(window.location.search)).get("accountid");
    let accToken = (new URLSearchParams(window.location.search)).get("accesstoken");
    useEffect(() => {
        if(accId !== '' && accId !== undefined){
            setAccountId(accId);
        }
        if(accToken !== '' && accToken !== undefined){
            setAccessToken(accToken);
        }
    }, [accId,accToken]);
    
    
    function searchAdNetwork(accountId : any,accessToken : any, partner : any, startDate : any, endDate : any){
        if(accountId !== '' && accessToken !== '' && partner !== '' && startDate !== '' && endDate !== '' ){
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'accountId': accountId,'partner' : partner,'accessToken':accessToken,'startDate':startDate,'endDate':endDate })
            };
            fetch('/api/adnetworkapi', requestOptions)
                .then(response => response.json()).then(data => {
                    setAdNetworkData(data);
                });
        }
    }

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                 <h1>Ad Network Api</h1>
                            </Grid>
                            <Grid item xs={8}>
                                <Tile title="">
                                <Grid container spacing={0}>
                                <Grid item xs={4}>
                                    <Input
                                        label="Automate Account Id"
                                        id="accountId" 
                                        name="accountId"
                                        value={accountId}
                                        placeholder=""
                                        onClear={() => {
                                            setAccountId('');
                                        }}
                                        onChange={(e) => {
                                            setAccountId(e.target.value);
                                        }}
                                    />
                                    <br/>
                                    <Input
                                        label="Access Token"
                                        id="accessToken" 
                                        name="accessToken"
                                        value={accessToken}
                                        placeholder=""
                                        onClear={() => {
                                            setAccessToken('');
                                        }}
                                        onChange={(e) => {
                                            setAccessToken(e.target.value);
                                        }}
                                    />
                                    <br/>
                                    <ComboBox
                                        label="Partner"
                                        options={[
                                            { label: 'Facebook', value: 'facebook' }
                                        ]}
                                        placeholder="Choose Partner"
                                        onChange={(e) => setPartner(e)}
                                    />
                                    </Grid>
                                    <Grid item>
                                    <DateRangePicker
                                            startDate={startDate}
                                            endDate={endDate}
                                            onChange={(startDate, endDate) => {
                                                setStartDate(startDate);
                                                setEndate(endDate);
                                            }}
                                            currentMonthPosition="right"
                                    />
                                    </Grid>
                                    </Grid>
                                    <Button  type="submit" kind="primary" label="Search" onClick={() => searchAdNetwork(accountId,accessToken,partner,startDate,endDate)} ></Button>{' '}      
                                </Tile>
                            </Grid>
                            <br/>
                            <Grid item xs={12}>
                                <AdNetworkTable data={adNetworkData}/>
                            </Grid>
                        </Grid>
                    </div>
                </Box>
    );
}