import {Button} from '@mui/material';
import { Link } from 'react-router-dom';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';


const rounding = (value : any) =>{
    if(!isNaN(value)){
        return Math.round(value);
    }
    return value;
}

const columns: GridColDef[] = [
    { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 180,
    renderCell: (params : any) => {
      return  <div>
        {(params.row.appToken !== '' && params.row.appToken !== 'N/A') && 
        <Button variant="contained" style={{backgroundColor:'#005ff7'}} component={Link} to={"/account/app/"+params.row.appToken} >{params.row.appToken}</Button>
        }
        </div>}
    },
    { field: 'type', headerName: 'Anomaly Type',flex: 1, minWidth: 100},
    { field: 'createdAt', headerName: 'Created At',flex: 1, minWidth: 150},
    { field: 'platform', headerName: 'Platform',flex: 1, minWidth: 120},
    { field: 'expected', headerName: 'Expected Value',flex: 1, minWidth: 150},
    { field: 'actual', headerName: 'Actual Value',flex: 1, minWidth: 150},
  ];

function createData(
    id: number,
    appToken: string,
    type: string,
    createdAt: string,
    platform: string,
    expected: any,
    actual: any
  ){
    return { id, appToken, type, createdAt, platform, expected, actual };
  }

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export default function AccountAnomalies(_props : any) {
    const anomalies = _props.anomalies;

    const anomaliesData : any = [];
    if(anomalies !== undefined){
        for (var i = 0; i < anomalies.length; i++){
            anomaliesData.push(
                createData(
                    i,
                    anomalies[i][1],
                    anomalies[i][3],
                    formatDate(anomalies[i][0]),
                    anomalies[i][2],
                    rounding(anomalies[i][4]),
                    rounding(anomalies[i][5])
                )
            );
        }
    }

    return (
        <div style={{ height: 650, width: '100%' }}>
            <DataGrid 
                rows={anomaliesData} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
                initialState={{
                    sorting: {
                      sortModel: [{ field: 'createdAt', sort: 'desc' }],
                    },
                }}
            />
        </div>
    );
}