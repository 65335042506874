import { Box, Button, Dialog, Divider, Grid, Typography } from "@mui/material";
import { useState } from "react";

export default function HistoryModal(_props : any){
    let parameters = _props["parameters"];
    let originalState = _props["originalState"];
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return <>
            <Button variant="outlined" onClick={handleOpen} style={{width:'100%'}}>
                Show Changes
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="lg"
                fullWidth
            >
                <Box >
                <Typography id="dialog-description">
                    <Grid container xs={12} >
                        <Grid item xs={6}>
                            <h3>Before</h3>
                        </Grid>
                        <Grid item xs={6}>
                            <h3>After</h3>
                        </Grid>
                    </Grid>
                </Typography>
                <Grid container xs={12} >
                        <Grid item xs={6}>
                            <pre>{JSON.stringify(parameters, null, 2)}</pre>;
                        </Grid>
                        <Divider orientation="vertical" flexItem style={{marginRight:"-1px"}} />
                        <Grid item xs={6}>
                            <pre>{JSON.stringify(originalState, null, 2)}</pre>;
                        </Grid>
                </Grid>
                </Box>
            </Dialog>
        </>
}