import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import {NumericFormat} from 'react-number-format';
import { formatDate } from '../../../../../shared/components/formatDate/FormatDate';

const columns: GridColDef[] = [
  { field: 'processingDate', headerName: 'Date',flex: 1, maxWidth: 150},
  { field: 'attributions', headerName: 'Attributions',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.attributions} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'conversions', headerName: 'Conversions',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.conversions} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'maus', headerName: 'MAUs',flex: 1, 
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.maus} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'dps', headerName: 'Data Points',flex: 1, 
    renderCell: (params : any) => {
      return <NumericFormat value={params.row.dps} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'unitOfMeasure', headerName: 'Unit of Measure',flex: 1},

];

function createData(
    id: number,
    conversions: number,
    attributions: number,
    dps: number,
    maus: number,
    processingDate: string,
    unitOfMeasure: string
  ) {
    return { id, conversions, attributions, dps, maus, processingDate, unitOfMeasure};
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export const SfUsageTable = (_props : any) =>{
    const datas = _props.data;
    const rows : any = [];
    if(datas !== undefined){
        for (var i = 0; i < datas.length; i++){
            rows.push(
                createData(
                    i,
                    datas[i][0],
                    datas[i][1],
                    datas[i][3],
                    datas[i][4],
                    formatDate(datas[i][5]),
                    datas[i][6],
                    
                )
            );
        }
    }
    return (
        <div style={{ height: 650, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}
