import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {NumericFormat} from 'react-number-format';

const addPrice = (value: any) => {
    if(value === 'Yes'){
        return 0;
    }
    return Number(value);
}

export const UpsellDashboardTotalTable = (_props: any) =>{
  var data = _props.data;
  var acv = 0;
  var adRevenue = 0;
  var audienceBuilder = 0;
  var controlCenter = 0;
  var fps = 0;
  var multitouch = 0;
  var pulse = 0;
  var purchaseVerification = 0;
  var subscription = 0;
  var uninstallReinstall = 0;
  var webAttribution:number = 0;
  var dataResidency = 0;
  var smartBanners = 0;
  var potentialUpsell  = 0;
  for (var i = 0; i < data.length; i++){
    acv = acv + addPrice(data[i].acv);
    adRevenue = adRevenue + addPrice(data[i].features.adRevenue);
    audienceBuilder = audienceBuilder + addPrice(data[i].features.audienceBuilder);
    controlCenter = controlCenter + addPrice(data[i].features.controlCenter);
    fps = fps + addPrice(data[i].features.fps);
    multitouch = multitouch + addPrice(data[i].features.multitouch);
    pulse = pulse + addPrice(data[i].features.pulse);
    purchaseVerification = purchaseVerification + addPrice(data[i].features.purchaseVerification);
    subscription = subscription + addPrice(data[i].features.subscription);
    uninstallReinstall = uninstallReinstall + addPrice(data[i].features.uninstallReinstall);
    webAttribution = webAttribution + addPrice(data[i].features.webAttribution);
    dataResidency = dataResidency + addPrice(data[i].features.dataResidency);
    smartBanners = smartBanners + addPrice(data[i].features.smartBanners);
    potentialUpsell = potentialUpsell + addPrice(data[i].features.potentialUpsell);
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 150 }} aria-label="total-table">
        <TableHead>
          <TableRow>
            <TableCell>Potential Upsell</TableCell>
            <TableCell>Ad Revenue</TableCell>
            <TableCell>Audience Builder</TableCell>
            <TableCell>Control Center</TableCell>
            <TableCell>Data Residency</TableCell>
            <TableCell>FPS</TableCell>
            <TableCell>Multitouch</TableCell>
            <TableCell>Pulse</TableCell>
            <TableCell>Purchase Verification</TableCell>
            <TableCell>Smart Banners</TableCell>
            <TableCell>Subscription</TableCell>
            <TableCell>Uninstall Reinstall</TableCell>
            <TableCell>Web Attribution</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row"><NumericFormat value={potentialUpsell} displayType={'text'} thousandSeparator={true}/></TableCell>
              <TableCell component="th" scope="row"><NumericFormat value={adRevenue} displayType={'text'} thousandSeparator={true}/></TableCell>
              <TableCell component="th" scope="row"><NumericFormat value={audienceBuilder} displayType={'text'} thousandSeparator={true}/></TableCell>
              <TableCell component="th" scope="row"><NumericFormat value={controlCenter} displayType={'text'} thousandSeparator={true}/></TableCell>
              <TableCell component="th" scope="row"><NumericFormat value={dataResidency} displayType={'text'} thousandSeparator={true}/></TableCell>
              <TableCell component="th" scope="row"><NumericFormat value={fps} displayType={'text'} thousandSeparator={true}/></TableCell>
              <TableCell component="th" scope="row"><NumericFormat value={multitouch} displayType={'text'} thousandSeparator={true}/></TableCell>
              <TableCell component="th" scope="row"><NumericFormat value={pulse} displayType={'text'} thousandSeparator={true}/></TableCell>
              <TableCell component="th" scope="row"><NumericFormat value={purchaseVerification} displayType={'text'} thousandSeparator={true}/></TableCell>
              <TableCell component="th" scope="row"><NumericFormat value={smartBanners} displayType={'text'} thousandSeparator={true}/></TableCell>
              <TableCell component="th" scope="row"><NumericFormat value={subscription} displayType={'text'} thousandSeparator={true}/></TableCell>
              <TableCell component="th" scope="row"><NumericFormat value={uninstallReinstall} displayType={'text'} thousandSeparator={true}/></TableCell>
              <TableCell component="th" scope="row"><NumericFormat value={webAttribution} displayType={'text'} thousandSeparator={true}/></TableCell>           
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}