import { DateRangePicker } from '@adjust/components';
import { Box} from '@mui/material';
import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ClickSpamHistoryTable from '../../../entities/components/ClickSpam/ClickSpamHistoryTable';
import { InfoTooltip } from '../../../shared/components/infoTooltip/InfoTooltip';

export const ClickSpamHistoryPage = () => {
    const [tracker, setTracker] = useState('');
    const [isLoading, setLoading] = useState(false);

    const [dateRange, setDateRange] = useState({
        startDate: null,
        endDate: null
    });


    // Get the current date at midnight
    const today = new Date();

    const getClickSpamHistory = (start:Date,end:Date) => {
        if(start !== null && end !== null ){
            setLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'startDate': start, 'endDate': end })
            };
            fetch('/api/clickspamhistory', requestOptions)
                .then(response => response.json()).then(data => {
                    setTracker(data.trackers);
                    setLoading(false);
                });
        }
    }

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <h1>Click Spam History</h1>
                        <DateRangePicker
                            startDate={dateRange.startDate}
                            endDate={dateRange.endDate}
                            onChange={(startDate : any, endDate : any) => {
                                setDateRange({ startDate, endDate });
                                getClickSpamHistory(startDate,endDate)
                            }}
                            maxDate={today}
                        />
                        {isLoading &&
                        <CircularProgress />
                        }
                        {(!isLoading && tracker.length > 0) &&
                            <>
                                <InfoTooltip message="Source: Data Science DB | Refreshed: instant" />
                                <ClickSpamHistoryTable data={tracker} />
                            </>
                        }
                    </div>
                </Box>
    );
}