import { Button } from '@mui/material';
import React, { useState } from 'react';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export default function InteractiveCheckBox(_props: any){
    const [confirm, setConfirm] = useState(_props.agency);
    const email = useState(_props.email);

    function setAgency(email: any,agency : any,){
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ "email": email,"confirm":agency })
        };
        fetch('/api/setuseragency', requestOptions).then(response => response.json());
        setConfirm(agency);
      }
      if(confirm === true){
        return <Button onClick={()=>setAgency(email,false)}> <CheckBoxOutlinedIcon color="success"></CheckBoxOutlinedIcon> </Button>
      }
      else{
        return <Button onClick={()=>setAgency(email,true)}><CheckBoxOutlineBlankIcon color="success"></CheckBoxOutlineBlankIcon></Button>
      }
}