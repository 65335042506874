import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';


const columns: GridColDef[] = [
    { field: 'accountId', headerName: 'Account ID',flex: 1, minWidth: 150,sortable: false,
        renderCell: (params : any) => {
            return <Button variant="outlined" component={Link} to={"/account/"+params.row.accountId}>{params.row.accountId}</Button>;
        },
    },      
    { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 100},
    { field: 'classification', headerName: 'Account Classification',flex: 1, minWidth: 150},
    { field: 'supportRegion', headerName: 'Support Region',flex: 1, minWidth: 150},
    { field: 'mail', headerName: 'AM Mail',flex: 1, minWidth: 150},
    { field: 'acv', headerName: 'ACV in EURO',flex: 1, minWidth: 150},
    
  ];

function createData(
    id: number,
    accountId: string,
    accountName: string,
    classification: string,
    supportRegion: string,
    mail: string,
    acv: number
  ){
    return { id, accountId, accountName, classification, supportRegion , mail, acv};
  }

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export const AccountPricingAcvTable = (_props : any) => {
    var accounts = _props.accounts;
    const accountData : any = [];
    if(accounts !== undefined){
        for (var i = 0; i < accounts.length; i++){
            accountData.push(
                createData(
                    i,
                    accounts[i][0],
                    accounts[i][1],
                    accounts[i][2],
                    accounts[i][3],
                    accounts[i][4],
                    accounts[i][5].toLocaleString()
                )
            );
        }
    }
    
    return (
        <div style={{ height: 550, width: '100%' }}>
            <DataGrid 
                rows={accountData} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            /> 
        </div>
    )
  }