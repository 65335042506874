import { Tile } from "@adjust/components";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { InfoTooltip } from "../../../../shared/components/infoTooltip/InfoTooltip";


const infoText = "Source: Salesforce | Refreshed: once a day";

export const AccountOpportunities = (_props : any) => {
    let opportunities = _props.opportunities;
    if(opportunities === undefined){
        opportunities = [];
    }

    return(
        <Tile title="Opportunities">
            <InfoTooltip message={infoText} />
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Stage</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Close Date</TableCell>
                        <TableCell>ACV Amount</TableCell>
                        <TableCell>Opportunity Id</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {opportunities.map((opportunity: any) => (
                    <TableRow
                    key={opportunity[2]}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">{opportunity[0]}</TableCell>
                        <TableCell>{opportunity[1]}</TableCell>
                        <TableCell>{opportunity[2]}</TableCell>
                        <TableCell>{opportunity[3]}</TableCell>
                        <TableCell>{opportunity[4]}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </Tile>
    );
}