import { Button, Card, Grid, Stack, TextField } from "@mui/material";
import { useNavigate } from 'react-router'
import { createResult, createResultBaggerApi } from "../../../entities/components/Tools/BaggerQueryGenerator/bqgResultFunctions";
import { useState } from "react";

export const Result = (_props:any) =>{
    const navigate = useNavigate()
    let tags = _props.tags;
    let periodTime = _props.periodTime;
    let options = _props.options;
    let appTokens = _props.appTokens;
    let trackerTokens = _props.trackerTokens;
    let urlTrackser = _props.urlTrackser;
    let eventTokens = _props.eventTokens;
    let requestUrl = _props.requestUrl;
    let skadTokens = _props.skadTokens;
    let messages = _props.messages;
    let deviceIds = _props.deviceIds;
    let labels = _props.labels;
    let sanPartners = _props.sanPartners;
    
    const [result,setResult] = useState('');
    const [error,setError] = useState('');
    const [noLabelError,setNoLabelError] = useState('');
    const [answerString,setAnswerString] = useState({data:{status:''}});

    const createResultString = () => {
        const {result,error,noLabelError} = createResult(tags,periodTime,options,appTokens,trackerTokens,urlTrackser,eventTokens,requestUrl,skadTokens,messages,deviceIds,labels,sanPartners);
        setResult(result);
        setError(error);
        setNoLabelError(noLabelError);
        setAnswerString({data:{status:''}});
    }

    const createResultBaggerApiJson = () => {
        const baggerData = createResultBaggerApi(tags,periodTime,options,appTokens,trackerTokens,urlTrackser,eventTokens,requestUrl,skadTokens,messages,deviceIds,labels,sanPartners);
        const data = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'filterString': baggerData.filterString,
                'tagString': baggerData.tagString,
                'from': baggerData.from,
                'until': baggerData.until,
                'since': baggerData.since,
                'service': baggerData.service,
                'cmd': baggerData.cmd,
                'count': baggerData.count}),
        }
        fetch('/api/postbaggerapi', data).then(response => response.json()).then(function (data) {setAnswerString(data)});
    }
    return(
        <Card variant="outlined">
            <Grid container spacing={6} >
                <Grid item xs={11}>
                    <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" style={{marginTop:"1%"}}>
                        <Button variant='contained' style={{width:'15%', backgroundColor:'#005ff7'}} onClick={() => createResultString()}>Generate Query</Button>
                        <Button variant='contained' style={{width:'15%', backgroundColor:'#005ff7'}} onClick={() => navigate(0)}>Reset</Button>
                    </Stack>
                    <br/>
                    {error !== '' &&
                        <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" style={{marginTop:"1%",backgroundColor:'#f8d7da',marginLeft:'10%',marginRight:'10%'}}>
                            <div>{error}</div>
                        </Stack>
                    }
                    {noLabelError !== '' &&
                        <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" style={{backgroundColor:'#fff3cd',marginLeft:'10%',marginRight:'10%'}}>
                            <div>{noLabelError}</div>
                        </Stack>
                    }
                    {result !== '' &&
                    <>
                        <TextField
                            id="resultTextfield"
                            label=""
                            multiline
                            rows={5}
                            defaultValue={result}
                            value={result}
                            style={{width:'80%',marginLeft:'10%',marginRight:'10%'}}
                        />
                        <Stack spacing={2} direction="row" alignItems="center" justifyContent="center" style={{marginTop:"1%"}}>
                            {answerString.data.status !== 'queued' &&
                            <Button variant='contained' style={{width:'15%', backgroundColor:'#005ff7'}} onClick={() => createResultBaggerApiJson()}>Start Bagger API</Button>
                            }
                            {answerString.data.status === 'queued' &&
                            <p style={{color:'green'}}>Bagger Job started</p>
                            }
                        </Stack>
                    </>
                    }
                </Grid>
            </Grid>
            <br/>
        </Card>
    );
}