import {Tile } from "@adjust/components";
import {Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import {useState } from "react";
import SearchIcon from '@mui/icons-material/Search';

export default function QueryContactSearch (props : any){
    const [accountId,setAccountId] = useState('');
    const [accountName,setAccountName] = useState('');
    const [clientPartner,setClientPartner] = useState('');
    const [region,setRegion] = useState('');
    let queryRegion  = props.queryRegion;
    
    function searchContacts(accountId : any,accountName : any,clientPartner : any,region :any){
        props.onIsLoadingChange(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "accountId" : accountId, "accountName":accountName, "clientPartner":clientPartner, "region": region}) 
        };
        fetch('/api/querycontact', requestOptions)
            .then(response => response.json()).then(data => {
                props.onContactChange(data.contacts);
                props.onIsLoadingChange(false);
            });
    }

    const accountIdKeyDown = (event : any) => {
        if (event.key === 'Enter') {
            searchContacts(event.target.value,accountName,clientPartner,region);
        }
    }
    const accountNameKeyDown = (event : any) => {
        if (event.key === 'Enter') {
            searchContacts(accountId,event.target.value,clientPartner,region);
        }
    }
    const checkBlock = (value : any) => {
        if(value === 'id'){
            if(accountName === ''){
                return false;
            }
            return true;
        }   
        if(value === 'name'){
            if(accountId === ''){
                return false;
            }
            return true;
            
        }
        return false;
    }

    return(
        <Tile>
            <Grid container spacing={6}>
                <Grid item xs={6}>
                    <TextField
                        style={{width:'60%'}}
                        label="Account Id's"
                        id="accountId" 
                        name="accountId"
                        value={accountId}
                        disabled={checkBlock('id')}
                        onChange={(e) => {
                            setAccountId(e.target.value);
                        }}
                        onKeyDown={accountIdKeyDown}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button onClick={(e) => searchContacts(accountId,accountName,clientPartner,region)}><SearchIcon /></Button>
                              </InputAdornment>
                            ),
                        }}
                    />
                    <br/><br/>
                    <TextField
                        style={{width:'60%'}}
                        label="Account Name"
                        id="accountName" 
                        name="accountName"
                        value={accountName}
                        disabled={checkBlock('name')}
                        onChange={(e) => {
                            setAccountName(e.target.value);
                        }}
                        onKeyDown={accountNameKeyDown}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button onClick={(e) => searchContacts(accountId,accountName,clientPartner,region)}><SearchIcon /></Button>
                              </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <FormControl style={{width:'60%'}}>
                        <InputLabel id="QueryDropdownlabel">Record Type</InputLabel>
                        <Select
                            labelId="SelectClientPartnerDropdown"
                            id="SelectClientPartnerDropdown"
                            value={clientPartner}
                            label="Service"
                            onChange={(e) => {
                                setClientPartner(e.target.value);
                                searchContacts(accountId,accountName,e.target.value,region);
                            }}
                        >
                            <MenuItem value="Client" key="Client">Client</MenuItem>
                            <MenuItem value="Partner" key="Partner">Partner</MenuItem>
                        </Select>
                    </FormControl>
                    <br/>
                    <br/>
                    <FormControl style={{width:'60%'}}>
                        <InputLabel id="regionDropdown">Region</InputLabel>
                        <Select
                            labelId="SelectRegionDropdown"
                            id="SelectRegionDropdown"
                            value={region}
                            label="Region"
                            onChange={(e) => {
                                setRegion(e.target.value);
                                searchContacts(accountId,accountName,clientPartner,e.target.value);
                            }}
                        >
                        {queryRegion.map((region  : any) =>
                            <MenuItem value={region} key={region}>{region}</MenuItem>
                        )}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Tile>
    );
}