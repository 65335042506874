import { ComboBox } from '@adjust/components';
import React,{useState} from 'react'

const message_list = [
    "callback succeeded",
    "failed to unmarshal delivery",
    "failed to get callback data",
    "failed due to invalid url",
    "failed due to adjust url",
    "failed due to blacklist",
    "callback has invalid requests",
    "aborted redirecting from url",
    "callback has empty requests",

    "install attributed",
    "install failed",
    "session attributed",
    "session reattributed",
    "failed to update device data",
    "failed to marshal reattribution",
    "failed to marshal session",
    "failed to marshal update",
    "device not found",
    "device is opted out",
    "reattribution attributed",
    "reattribution update failed",

    "install tracked",
    "session tracked",
    "failed check interval",
    "failed to authorize s2s session",
    "failed to get adid",
    "failed to get app token",
    "failed to get header data",
    "failed to get install tracker",
    "failed to marshal install",
    "failed to write mappings",
    "failed validation",
    "ignored bot request",
    "ignored fraud request",
    "request too large",
    "tracking disabled for platform",
    "click tracked",
    "duplicate click ignored",
    "aborted browser preview",
    "aborted googlebot",
    "aborted invalid click",
    "aborted mobile not track",
    "aborted organic tracker",
    "click ignored due to deactivated tracker",
    "click skipped",
    "failed CheckState",
    "failed to get app data",
    "failed to get redirect url",
    "failed to marshal click for refresh",
    "s2s click provided no device identifiers",
    "tracker blacklisted",
    "impression tracked",
    "impression failed",
    "impression ignored due to blacklisted tracker",
    "impression ignored due to deactivated tracker",
    "event tracked",
    "event blacklisted",
    "failed to authorize",
    "failed to check event token",
    "failed to find device data",
    "failed to get event token",
    "failed to get revenue",
    "failed to marshal event",
    "failed to track event",
    "failed uniqueness",
    "invalid app token",
    "invalid endpoint",
    "sdk_click finished",
    "sdk_click not attributable",
    "invalid request data",
    "invalid sdk signature",
    "sdk click reattribution tracked",
    "sdk click session tracked",
    "sdk_click tracker blacklisted",
    "gdpr forget device",
    "failed to gdpr forget device",
    "missing device ids",
    "ad revenue tracked",
    "device opted out",
    "failed to get device data",
    "failed to process request",
    "failed to process s2s request",
    "tracking disabled",
    "opted out device",
    "failed to marshal optout",
    "failed to opt out device",
    "inspect device succeeded",
    "failed to marshal device data",
    "skip inactive app",
    "redirected",
    "invalid redirect",
    "show smartbanner",
    "attribution sent",
    "bad attribution request",
    "bot request ignored",
    "forgot device",
    "already opted out",
    "error to find device data",
    "failed to delete device data",
    "error tracked",
    "duplicate skadnetworkinstall",
    "failed to get skadnetworkinstall",
    "failed to validate network token regexp",
    "failed to marshal skadnetwork install",
    "failed to publish refresh sdk-ad-install",
    "skadnetwork tracked"
   ];

export const MessageInput = (_props) =>{
    const options = [];
    for (var i = 0; i < message_list.length; i++){
        options.push({value: message_list[i],label:message_list[i]});
    }
    const [value, setValue] = useState([]);
  
    const handleChange = (value) => {
      setValue(value);
      _props.onMessagesChange(value);
    }
  
    return (
      <ComboBox
        value={value}
        options={options}
        onChange={handleChange}
        placeholder="Message"
        multiple
      />
    );
  }