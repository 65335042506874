import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { InfoTooltip } from '../../../shared/components/infoTooltip/InfoTooltip';

const columns: GridColDef[] = [
    { field: 'action', headerName: 'Action',flex: 1, minWidth: 150,sortable: false,
        renderCell: (params : any) => {
            return <>
                <Button variant="outlined" component={Link} to={"/account/app/"+params.row.appToken}>View App</Button>
            </>;
        },
    },
    { field: 'appName', headerName: 'App Name',flex: 1, minWidth: 200},
    { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 150},
    { field: 'distributionFilter', headerName: 'Distribution Filter',flex: 1, minWidth: 220},
    { field: 'showInvalidSignature', headerName: 'Show Invalid Signature',flex: 1, minWidth: 220},
    { field: 'filterAnonymousTraffic', headerName: 'Filter Anonymous Traffic',flex: 1, minWidth: 220},
    { field: 'filterEngagementInjection', headerName: 'Filter Engagement Injection',flex: 1, minWidth: 220},
    { field: 'filterTooManyEngagements', headerName: 'Filter Too Many Engagements',flex: 1, minWidth: 220},
];

function createData(
    id: number,
    appToken: string,
    appName: string,
    distributionFilter: string,
    showInvalidSignature: string,
    filterAnonymousTraffic : string,
    filterEngagementInjection: string,
    filterTooManyEngagements: string
  ) {
    return { id, appToken, appName, distributionFilter, showInvalidSignature, filterAnonymousTraffic, filterEngagementInjection, filterTooManyEngagements};
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
  const infoText = "Source: Adjust DB | Refreshed: instant";
  
export const AccountFraudSettingsTable = (_props : any) =>{
    const apps = _props.apps;
    const rows : any = [];
    if(apps !== undefined){
        for (var i = 0; i < apps.length; i++){
            rows.push(
                createData(
                    i,
                    apps[i][0],
                    apps[i][1],
                    apps[i][2],
                    apps[i][3],
                    apps[i][4],
                    apps[i][5],
                    apps[i][6],
                )
            );
        }
    }
    return (
        <div style={{ height: 800, width: '100%' }}>
            <InfoTooltip message={infoText} />
            <DataGrid
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}
