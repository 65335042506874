import { Box, Button, Dialog, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, Snackbar, Typography } from "@mui/material";
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';

export const BaggerApiJRunJob = (_props : any) => {
    let appToken = _props.appToken;
    const [timeFrame,setTimeFrame] = useState('24');
    const [open, setOpen] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [error,setError] = useState('');
    const [jobToday,setJobToday] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const runBaggerApi = (timeFrame : string, forceStart: boolean) =>{
        if(appToken !== ''){
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'appToken': appToken, 'timeFrame':timeFrame, 'forceStart': forceStart })
            };
            fetch('/api/runbaggerapi', requestOptions).then(response => response.json()).then(
                data => {
                    setError(data.error)
                    setJobToday(data.requestsToday);
                    if(data.requestsToday === false){
                        handleClose();
                    }
                    _props.onMenuChange('2');
                    handleClick();
                }
            );
        }
    }

    
    const handleClick = () => {
        setOpenSnackbar(true);
      };
    
      const handleCloseSnackbar = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnackbar(false);
      };

    

    const action = (
        <>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
    );

    return (
            <>
                <Button onClick={handleOpen} variant="contained">Run SDK Sentinel</Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                >
                    <Box >
                    <Typography id="dialog-description">
                        <Grid container xs={12} >
                            <Grid item xs={12}>
                            <div style={{ height: 200, marginLeft:'2%', marginRight:'2%',marginTop: '2%', marginBottom: '2%' }}>
                                <FormControl>
                                    <FormLabel id="demo-radio-buttons-group-label">Choose Timeframe</FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        defaultValue="24"
                                        onChange={(e, value) => { setTimeFrame(value)}}
                                    >
                                        <FormControlLabel value="30" control={<Radio />} label="Last 30 minutes" />
                                        <FormControlLabel value="1" control={<Radio />} label="Last 1 hour" />
                                        <FormControlLabel value="2" control={<Radio />} label="Last 2 hours" />
                                        <FormControlLabel value="6" control={<Radio />} label="Last 6 hours" />
                                        <FormControlLabel value="12" control={<Radio />} label="Last 12 hours" />
                                    </RadioGroup>
                                </FormControl>
                                {jobToday === false && 
                                    <Button variant="contained"  style={{alignItems:'right'}} onClick={() => runBaggerApi(timeFrame, false)}>Run SDK Sentinel</Button>
                                }
                                {jobToday !== false && 
                                    <>
                                        <div style={{color:'red'}}>A job was already started today, please click again on the button if you want to rerun the checker</div>
                                        <Button variant="contained"  style={{alignItems:'right'}} onClick={() => runBaggerApi(timeFrame, true)}>Run SDK Sentinel</Button>
                                    </>
                                }
                            </div>
                            </Grid>
                        </Grid>
                    </Typography>
                    </Box>
                </Dialog>
            {error === '' && 
            <Snackbar 
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                action={action}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                message="Bagger API started"
                ContentProps={{
                    sx: {
                      background: "#4caf50"
                    }
                  }}
            />
            }
            {error !== '' && 
            <Snackbar 
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                action={action}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                message={error}
                ContentProps={{
                    sx: {
                      background: "#ef5350"
                    }
                  }}
            />
            }
            </>
    );
}