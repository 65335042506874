import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Badge } from '@adjust/components/build/Badge/Badge';
import { ActionMenu } from '@adjust/components';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

function setRedirectButton( accountId : any){
  return <form action="https://api.adjust.com/accounts/accounts/switch.json" target="_blank" accept-charset="UTF-8" method="post">
    <input name="utf8" type="hidden" value="✓"/>
    <input type="hidden" name="authenticity_token" value="Voa+WSemHcT/nTfrgVofsDQ76DiflWnRQS8h7HmlOTl/DjaU4vt2Ky5b/beHORUMo0udGFLNQx4ibYqxLlWaXg=="/>
    <input type="hidden" name="account_id" id="account_id" value={accountId} />
    <Button type="submit" name="commit" value="switch account" >
      <img id="logo-adjust" src={process.env.PUBLIC_URL + "/favicon.ico"} width="40" height="40" alt="adjust.com" />
    </Button>
  </form>
}

function createBadge(number: any){
    if(number === 3){
      return <Badge label="Paused" color="neutral" />
    }
    else if (number === 4){
      return <Badge label="Suspended" color="warning" />
    }
    else if (number === 5){
      return <Badge label="Terminated" color="negative" />
    }
    else{
      return <Badge label="Active" color="positive" />
    }
}

  function checkDashboadType(value: boolean){
    if(value === false){
      return "Classic";
    }
    return "Suite";
  }


function createData(
    id: number,
    accountId: string,
    clientName: string,
    country: string,
    classification: string,
    vertical: string,
    csm: string,
    state: Number,
    numApps: string,
    assignedOn: string,
    implementationMail: string,
    isSuite: boolean,
    am: string
  ) {
    return { id,accountId, clientName, country, classification, vertical, csm, state, numApps, assignedOn,implementationMail,isSuite,am };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export const MyAccountsTableHolidayAM = (_props : any) =>{
    let navigate = useNavigate();

    const [accountData, setAccountData] = useState([]);
    useEffect(() => {
        fetch('/api/holidaymanager').then(res => res.json()).then(data => {
            setAccountData(data["holidayAccounts"]);
        });
    }, []);

    const rows : any = [];
    for (var i = 0; i < accountData.length; i++){
        rows.push(
            createData(
                i,
                accountData[i]["accountId"],
                accountData[i]["accountName"],
                accountData[i]["country"],
                accountData[i]["classification"],
                accountData[i]["vertical"],
                accountData[i]["csmName"],
                accountData[i]["state"],
                accountData[i]["appCount"],
                accountData[i]["assignedOn"],
                accountData[i]["implementationMail"],
                accountData[i]["isSuite"],
                accountData[i]["amMail"]
            )
        );
    }

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Dashboard',flex: 1, minWidth: 100,
        renderCell: (params : any) => {
            return <div>{setRedirectButton(params.row.accountId)}</div>
        },
        },
        { field: 'action', headerName: 'Action',flex: 1, minWidth: 150, sortable: false,
          renderCell: (params : any) => {
              return <div>
                  <ActionMenu
                        items={[
                          { label: 'Overview', onClick: () => {navigate("/account/"+params.row.accountId) }},
                          { label: 'Apps', onClick: () => {navigate("/account/"+params.row.accountId+'/apps') }},
                          { label: 'Contact & History', onClick: () => {navigate("/account/"+params.row.accountId+'/users') }},
                          { label: 'Features', onClick: () => {navigate("/account/"+params.row.accountId+'/features') }},
                        ]}
                      >
                      {/* The trigger can be anything. We use a button here */}
                      <Button style={{backgroundColor:'#005ff7' }} variant="contained" >Open</Button>
                    </ActionMenu>
                    </div>;
          },
        },
        { field: 'clientName', headerName: 'Client Name',flex: 1, minWidth: 250},
        { field: 'accountId', headerName: 'Account Id',flex: 1, minWidth: 150},
        { field: 'state', headerName: 'State',flex: 1, minWidth: 150,
          renderCell: (params : any) => {
              return <div>{createBadge(params.row.state)}</div>;
          },
        },
        { field: 'isSuite', headerName: 'Dashboard',flex: 1, minWidth: 150,
          renderCell: (params : any) => {
              return <div>{checkDashboadType(params.row.isSuite)}</div>;
          },
        },
        { field: 'country', headerName: 'Country',flex: 1, minWidth: 150},
        { field: 'classification', headerName: 'Classification',flex: 1, minWidth: 150},
        { field: 'vertical', headerName: 'Vertical',flex: 1, minWidth: 150},
        { field: 'am', headerName: 'Primary AM',flex: 1, minWidth: 150},
        { field: 'csm', headerName: 'CSM',flex: 1, minWidth: 150},
        { field: 'numApps', headerName: 'Number of Apps',flex: 1, minWidth: 150},
        { field: 'assignedOn', headerName: 'Assigned On',flex: 1, minWidth: 150,
          renderCell: (params : any) => {
              return <div>{formatDate(params.row.assignedOn)}</div>;
          },
        },
      ];

    return (
        <>
            {accountData.length > 0 &&
            <>
                <h3>Holiday Coverage</h3>
            
                <div style={{ height: 600, width: '100%' }}>
                    <DataGrid 
                        rows={rows} 
                        columns={columns}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                    />
                </div>
            </>
            }
        </>
    );
}