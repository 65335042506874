import { Box, Card, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import MyAccountsTableSC from '../../entities/components/MyAccounts/MyAccountsTableSC';
import { InfoTooltip } from '../../shared/components/infoTooltip/InfoTooltip';
import { MyAccountsCard } from '../../entities/components/MyAccounts/MyAccountsCard';
 
function getBob(data:any){
    var totalAmount = 0;
    for (var i = 0; i < data.length; i++){
        var acv = Number(data[i]['acv']);
        totalAmount = totalAmount + acv;
    }
    return totalAmount;
}

export const SalesOverviewRegionPage = () => {
    const [accountData, setAccountData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [region, setRegion] = useState('');

    const regionChange = (event: SelectChangeEvent) => {
        setRegion(event.target.value as string);
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "region": event.target.value as string }) 
        };
        fetch('/api/allaccountsregion', requestOptions)
            .then(response => response.json()).then(data => {
                setAccountData(data);
                setLoading(false);
            });
      };


    return (
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <div>
                    <h1 style={{marginTop:'0px'}}>All Team Accounts</h1>
                    <InfoTooltip message="Source: Salesforce DB | Refreshed: once a day" />

                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Card>
                                <FormControl fullWidth style={{marginTop: '5px'}}>
                                    <InputLabel id="region-select-label">Region</InputLabel>
                                    <Select
                                        labelId="region-label"
                                        id="region-select"
                                        value={region}
                                        label="Region"
                                        onChange={regionChange}
                                    >
                                        <MenuItem value={"China"}>China</MenuItem>
                                        <MenuItem value={"DACH & Nordics"}>DACH & Nordics</MenuItem>
                                        <MenuItem value={"France & Belgium & Lux"}>France & Belgium & Lux</MenuItem>
                                        <MenuItem value={"Israel/So.Europe"}>Israel/So.Europe</MenuItem>
                                        <MenuItem value={"Japan"}>Japan</MenuItem>
                                        <MenuItem value={"Korea"}>Korea</MenuItem>
                                        <MenuItem value={"Latin America (ES)"}>Latin America (ES)</MenuItem>
                                        <MenuItem value={"Latin America (PT-BR)"}>Latin America (PT-BR)</MenuItem>
                                        <MenuItem value={"MENA"}>MENA</MenuItem>
                                        <MenuItem value={"North America"}>North America</MenuItem>
                                        <MenuItem value={"Russia and CEE"}>Russia and CEE</MenuItem>
                                        <MenuItem value={"Southern Europe"}>Southern Europe</MenuItem>
                                        <MenuItem value={"UKI"}>UKI</MenuItem>
                                    </Select>
                                </FormControl>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <MyAccountsCard data={getBob(accountData)} />
                        </Grid>
                    </Grid>
                    <br/>
                    {isLoading &&
                        <CircularProgress />
                    }
                    {(!isLoading && region !== '') &&
                    <>
                        <MyAccountsTableSC data={accountData}></MyAccountsTableSC>
                    </>
                    }
                </div>
            </Box>
    );
}