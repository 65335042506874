import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

function createData(
    id: number,
    firstName: string,
    lastName: string,
    startDate: string,
    endDate: string,
    location: string,
  ) {
    return { id, firstName, lastName, startDate, endDate, location};
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export default function UpcomingVacationTable(_props : any) {
    let members = _props.vacation;

    const rows : any = [];
    for (var i = 0; i < members.length; i++){
        rows.push(
            createData(
                i,
                members[i][0],
                members[i][1],
                members[i][2],
                members[i][3],
                members[i][4]
            )
        );
    }

    const columns: GridColDef[] = [
        { field: 'firstName', headerName: 'Name',flex: 1,
        renderCell: (params : any) => {
            return <div>
                    {params.row.firstName} {params.row.lastName}
                  </div>
            },
        },
        { field: 'startDate', headerName: 'Start Date',flex: 1},
        { field: 'endDate', headerName: 'End Date',flex: 1},
      ];
    return (
        <div style={{ height: 600, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}