import { formatDate } from "../../../shared/components/formatDate/FormatDate";
import { formatVersionIos } from "../../../shared/components/formatVersion/FormatVersion";
import { ImplementationModal } from "./ImplementationModal";
import { AdRevenueCheckIos, AdvertisingIdCheckIos, AppleAdsFrameworkCheckIos, AttFrameworkCheckIos, CallbackAndPartnerCheckIos, ConversionValuesCheckIos, CoppaCheckIos, DeeplinkCheckIos, DirectPostbackCheckIos, EventBufferingCheckIos, InstallSessionCheckIos, KidsCheckIos, PushTokenCheckIos, SdkEventNonRevenueCheckIos, SdkEventRevenueCheckIos, SdkSignatureCheckIos, SkadNetworkIntegrationCheckIos, ThirdPartyCheckIos } from "./StatusCheckIos";
import { StatusCellIos } from "./StatusCell";
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

const columns: GridColDef[] = [
    { field: 'sdkComponent', headerName: 'SDK Component',flex: 1},
    { field: 'lastTimeChecked', headerName: 'Last Time Checked',flex: 1, maxWidth: 150,},
    { field: 'version', headerName: 'Version',flex: 1, maxWidth: 100,},
    { field: 'status', headerName: 'Status',flex: 1, 
        renderCell: (params : any) => {
            return <StatusCellIos status={params.row.status} />
        },
    },      
    { field: 'data', headerName: 'Check Data',flex: 1, maxWidth: 150,
        renderCell: (params : any) => {
            return <ImplementationModal data={params.row.data} type={params.row.type} />
        },
    }
];

function createData(
    id: string,
    sdkComponent: string,
    lastTimeChecked: string,
    status: number,
    data: any,
    type: string,
    version: string
  ) {
    return { id, sdkComponent, lastTimeChecked, status, data, type, version};
}

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
}


export const IntegrationOverviewIos = (_props: any) =>{
    const data = _props.data;
    const newestAppVersionIos = _props.newestAppVersionIos;
    const AdRevenueCheck = AdRevenueCheckIos(data.adRevenue,newestAppVersionIos);
    const AdvertisingIdCheck = AdvertisingIdCheckIos(data.lastRunTime,data.advertisingIdIos,newestAppVersionIos);
    const CallbackAndPartnerCheck = CallbackAndPartnerCheckIos(data.lastRunTime,data.callbackPartnerParams,newestAppVersionIos);
    const CoppaCheck = CoppaCheckIos(data.lastRunTime,data.coppa,newestAppVersionIos);
    const DeeplinkCheck = DeeplinkCheckIos(data.lastRunTime,data.deepLink,newestAppVersionIos);
    const DirectPostbackCheck = DirectPostbackCheckIos(data.lastRunTime,data.directPostback);
    const EventBufferingCheck = EventBufferingCheckIos(data.lastRunTime,data.eventBuffering,newestAppVersionIos);
    const InstallSessionCheck = InstallSessionCheckIos(data.lastRunTime,data.installTracking,newestAppVersionIos);
    const KidsCheck = KidsCheckIos(data.lastRunTime,data.playStoreKids,newestAppVersionIos);
    const PushTokenCheck = PushTokenCheckIos(data.lastRunTime,data.pushTokens,newestAppVersionIos);
    const SdkEventNonRevenueCheck = SdkEventNonRevenueCheckIos(data.lastRunTime,data.sdkEventTrackingNonRevenue,newestAppVersionIos);
    const SdkEventRevenueCheck = SdkEventRevenueCheckIos(data.lastRunTime,data.sdkEventTrackingRevenue,newestAppVersionIos);
    const SdkSignatureCheck = SdkSignatureCheckIos(data.lastRunTime,data.sdkSignature,newestAppVersionIos);
    const ThirdPartyCheck = ThirdPartyCheckIos(data.lastRunTime,data.thirdPartySharing,newestAppVersionIos);
    const AttFrameworkCheck = AttFrameworkCheckIos(data.lastRunTime,data.attFramework,newestAppVersionIos);
    const AppleAdsFrameworkCheck = AppleAdsFrameworkCheckIos(data.lastRunTime,data.attFramework,newestAppVersionIos);
    const SkadNetworkIntegrationCheck = SkadNetworkIntegrationCheckIos(data.lastRunTime,data.attFramework,newestAppVersionIos);
    const ConversionValuesCheck = ConversionValuesCheckIos(data.lastRunTime,data.attFramework,newestAppVersionIos);


    const rows : any = [];
    rows.push(
        createData(
            "install-session-tracking",
            "Install/Session tracking",
            formatDate(data.lastRunTime),
            InstallSessionCheck,
            data.installTracking,
            "installTracking",
            formatVersionIos(data.installTracking)
        )
    )

    rows.push(
        createData(
            "advertising-id-tracking",
            "Advertising ID collection/tracking",
            formatDate(data.lastRunTime),
            AdvertisingIdCheck,
            data.advertisingIdIos,
            "advertisingId",
            formatVersionIos(data.advertisingIdIos)
        )
    );

    rows.push(
        createData(
            "push-tokens",
            "Push tokens",
            formatDate(data.lastRunTime),
            PushTokenCheck,
            data.pushTokens,
            "pushTokens",
            formatVersionIos(data.pushTokens)
        )
    );

    rows.push(
        createData(
            "att-framework",
            "ATT Framework",
            formatDate(data.lastRunTime),
            AttFrameworkCheck,
            data.attFramework,
            "attFramework",
            formatVersionIos(data.attFramework)
        )
    );

    rows.push(
        createData(
            "sdk-signature",
            "SDK Signature ",
            formatDate(data.lastRunTime),
            SdkSignatureCheck,
            data.sdkSignature,
            "sdkSignature",
            formatVersionIos(data.sdkSignature)
        )
    );

    rows.push(
        createData(
            "sdk-event-tracking-non-revenue",
            "SDK Event tracking (non revenue)",
            formatDate(data.lastRunTime),
            SdkEventNonRevenueCheck,
            data.sdkEventTrackingNonRevenue,
            "sdkEventTrackingNonRevenue",
            formatVersionIos(data.sdkEventTrackingNonRevenue)
        )
    );

    rows.push(
        createData(
            "sdk-event-tracking-revenue",
            "SDK Event tracking (revenue)",
            formatDate(data.lastRunTime),
            SdkEventRevenueCheck,
            data.sdkEventTrackingRevenue,
            "sdkEventTrackingRevenue",
            formatVersionIos(data.sdkEventTrackingRevenue)
        )
    );

    rows.push(
        createData(
            "event-buffering",
            "Event Buffering",
            formatDate(data.lastRunTime),
            EventBufferingCheck,
            data.eventBuffering,
            "eventBuffering",
            formatVersionIos(data.eventBuffering)
        )
    );

    rows.push(
        createData(
            "callback-partner-params",
            "Callback and partner Params",
            formatDate(data.lastRunTime),
            CallbackAndPartnerCheck,
            data.callbackPartnerParams,
            "callbackPartnerParams",
            formatVersionIos(data.callbackPartnerParams)
        )
    );

    rows.push(
        createData(
            "apple-ads-framework",
            "Apple Ads Framework",
            formatDate(data.lastRunTime),
            AppleAdsFrameworkCheck,
            data.appleAdsFramework,
            "appleAdsFramework",
            formatVersionIos(data.appleAdsFramework)
        )
    );

    rows.push(
        createData(
            "skadnetwork-integration",
            "Skadnetwork Integration",
            formatDate(data.lastRunTime),
            SkadNetworkIntegrationCheck,
            data.skadnetworkIntegration,
            "skadnetworkIntegration",
            formatVersionIos(data.skadnetworkIntegration)
        )
    );

    rows.push(
        createData(
            "direct-postback",
            "Direct Postback",
            formatDate(data.lastRunTime),
            DirectPostbackCheck,
            data.directPostback,
            "directPostback",
            formatVersionIos(data.directPostback)
        )
    );
    rows.push(
        createData(
            "conversion-values",
            "Conversion Values",
            formatDate(data.lastRunTime),
            ConversionValuesCheck,
            data.conversionValues,
            "conversionValues",
            formatVersionIos(data.conversionValues)
        )
    );
    rows.push(
        createData(
            "ad-revenue",
            "Ad Revenue",
            formatDate(data.lastRunTime),
            AdRevenueCheck,
            data.adRevenue,
            "adRevenue",
            formatVersionIos(data.adRevenue)
        )
    );

    rows.push(
        createData(
            "coppa",
            "COPPA Compliance",
            formatDate(data.lastRunTime),
            CoppaCheck,
            data.coppa,
            "coppa",
            formatVersionIos(data.coppa)
        )
    );

    rows.push(
        createData(
            "third-party-sharing",
            "3rd Party data sharing",
            formatDate(data.lastRunTime),
            ThirdPartyCheck,
            data.thirdPartySharing,
            "thirdPartySharing",
            formatVersionIos(data.thirdPartySharing)
        )
    );

    rows.push(
        createData(
            "play-store-kids",
            "Play Store Kids",
            formatDate(data.lastRunTime),
            KidsCheck,
            data.playStoreKids,
            "playStoreKids",
            formatVersionIos(data.playStoreKids)
        )
    );

    rows.push(
        createData(
            "deeplink",
            "Deeplink",
            formatDate(data.lastRunTime),
            DeeplinkCheck,
            data.deepLink,
            "deeplink",
            formatVersionIos(data.deepLink)
        )
    );
    return(
        <div style={{ height: 1200 }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}