import { Button, Input } from '@adjust/components';
import { Tile } from '@adjust/components/build/Tile/Tile';
import { Box, Card, Grid, List, ListItemText} from '@mui/material';
import React, { useState } from 'react';


export default function InspectMappingsPage() {
    const [uuid, setUuid] = useState('');
    const [appToken, setAppToken] = useState('');
    const [inspectMappings,setInspectMappings] = useState([]);

    function searchInspectMappings(appToken : any,uuid : any){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "uuid": uuid,"appToken" : appToken })
        };
        fetch('/api/inspectmappings', requestOptions)
            .then(response => response.json()).then(data => {
                setInspectMappings(data.ids);
            });
    }
    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                 <h1>Inspect Mappings</h1>
                            </Grid>
                            <Grid item xs={12}>
                                <Tile title="Inspect Mappings">
                                <Input
                                    label="App Token"
                                    id="appToken" 
                                    name="appToken"
                                    value={appToken}
                                    css={{ width: "40%"}}
                                    onClear={() => {
                                        setAppToken('');
                                    }}
                                    onChange={(e) => {
                                        setAppToken(e.target.value);
                                    }}
                                />
                                <Input
                                    label="UUID"
                                    id="uuid" 
                                    name="uuid"
                                    value={uuid}
                                    css={{ width: "40%"}}
                                    onClear={() => {
                                        setUuid('');
                                    }}
                                    onChange={(e) => {
                                        setUuid(e.target.value);
                                    }}
                                />
                                <br/>
                                <Button  type="submit" kind="primary" label="Search"  onClick={() => searchInspectMappings(appToken,uuid)}></Button>{' '}
                                </Tile>
                            </Grid>
                            {inspectMappings.length > 0 &&
                            <Grid item xs={12}>
                                <Card>
                                    <List style={{marginLeft:'2%'}}>
                                    {inspectMappings.map((settings:any) => ( 
                                        <ListItemText primary={settings[0] + " : " + settings[1]}></ListItemText>
                                    ))}
                                    </List>
                                </Card>
                            </Grid>
                            }
                        </Grid>
                           
                    </div>
                </Box>
    );
}