import { Breadcrumbs } from '@adjust/components/build/Breadcrumbs/Breadcrumbs';
import { Tile } from '@adjust/components/build/Tile/Tile';
import { Box, Button, Grid} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { AppVersionHistoryTable } from '../../../entities/components/Apps/AppVersionHistoryTable';
import { SdkVersionTable } from '../../../entities/components/Apps/SdkVersionTable';
import AppInfo from '../../../shared/components/appInfo/AppInfo';
import { AppSegmentedButton } from '../../../shared/components/appSegmentedButton/AppSegmentedButton';
import { InfoTooltip } from '../../../shared/components/infoTooltip/InfoTooltip';

function MyBreadcrumbs(accountId:any,accountName:any,appToken:any,appName:any) {
    let navigate = useNavigate();
    const items = [
      { label: 'My Accounts', onClick: () => {navigate('/myaccounts')}},
      { label: accountName, onClick: () => {navigate('/account/' + accountId )}},
      { label: 'Apps', onClick: () => {navigate('/account/' + accountId + '/apps' )}},
      { label: appName, onClick: () => {navigate('/account/' + appToken + '/app' )}},
    ];
    return <Breadcrumbs items={items} />;
}

export const AppPage = (_props:any) => {
    const appToken = _props.appToken;
    const appInfo = _props.appInfo;
    const accountName = _props.accountName ;
    
    const [appVersionHistory, setAppVersionHistory] = useState({"android":[],"ios":[]});
    const [sdkVersion, setSdkVersion] = useState({"allInstalls": 0, "sdkVersions": []});
    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        fetch('/api/appoverview/'+appToken).then(res => res.json()).then(data => {
            setSdkVersion(data["sdkVersions"]);
            setAppVersionHistory(data["appVersionHistory"]);
        });
    }, [appToken]);
    
    const updateVersion = () =>{
        if(appToken !== '' ){
            setIsLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'appToken': appToken })
            };
            fetch('/api/updatenewestappversion', requestOptions)
                .then(response => response.json()).then(data => {
                    setAppVersionHistory(data)
                    setIsLoading(false);
                });
        }
    }

    return (
                    <div>
                        {MyBreadcrumbs(appInfo[3],accountName,appInfo[2],appInfo[1])}
                        <AppSegmentedButton appToken = {appToken} tabValue='1' />
                        <br/><br/>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Grid container spacing={6}>
                                    <Grid item xs={4}>
                                        <AppInfo appInfo={appInfo} accountName={accountName} />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Tile title="App Version History" style={{minHeight: '200px'}}>
                                            {isLoading && 
                                            <CircularProgress />
                                            }
                                            {!isLoading && 
                                            <Box display="flex" justifyContent="flex-end" style={{marginBottom:'10px'}}>
                                                <Button variant="outlined" style={{alignItems:'right'}} onClick={updateVersion} >Update</Button>
                                            </Box>
                                            }
                                            <InfoTooltip message="Source: Apptopia | Refreshed: manually" />
                                            <Grid container spacing={6}>
                                                {appVersionHistory.android.length > 0 &&
                                                <Grid item xs={6}>
                                                    <Tile title="Android">
                                                        <InfoTooltip message="Source: Apptopia | Refreshed: manually" />
                                                        <AppVersionHistoryTable data = {appVersionHistory.android} />
                                                    </Tile>
                                                </Grid>
                                                }
                                                {appVersionHistory.ios.length > 0 &&
                                                <Grid item xs={6}>
                                                    <Tile title="iOS">
                                                        <InfoTooltip message="Source: Apptopia | Refreshed: manually" />
                                                        <AppVersionHistoryTable data = {appVersionHistory.ios} />
                                                    </Tile>
                                                </Grid>
                                                }
                                            </Grid>
                                        </Tile>
                                    </Grid>
                                </Grid>
                                
                                <Tile title="Most Active SDK Versions">
                                    <InfoTooltip message="Source: Metabase | Refreshed: once a day" />
                                    <SdkVersionTable data = {sdkVersion} />
                                </Tile>
                            </Grid>
                            
                        </Grid>
                    </div>
    );
}