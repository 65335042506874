import { Box, Grid} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { MyAccountsUsageTable } from './MyAccountsUsageTable';

export const MyAccountsUsagePage = () => {
    const [usage,setUsage] = useState({accountName:'', accountId:0, usage:0});
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true)
        fetch('/api/accountsusage').then(res => res.json()).then(data => {
            setUsage(data);
            setIsLoading(false); 
        });
    }, []);
    
    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <h1>My Accounts Usage</h1>
                                {isLoading &&
                                <CircularProgress />
                                }
                                {!isLoading &&
                                <>
                                    <MyAccountsUsageTable accounts={usage} />
                                </>
                                }
                            </Grid>
                        </Grid>
                    </div>
                </Box>
    );
}