import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';
import { Checkbox } from '@mui/material';

function createData(
    id: number,
    accountId: number,
    accountName: string,
    appName: string,
    appToken: string,
    createdAt: string,
    type: string,
    typeId: string,
    wasSeen: boolean,
    idAnom: string
  ) {
    return { id, accountId, accountName, appName, appToken, createdAt, type, typeId, wasSeen, idAnom };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export const AnomaliesOverviewTable = (_props : any) =>{
    const clickedAnomalies: string[] = [];
    let anomalies = _props.anomalies;
    const anomaliesData : any = [];
    if(anomalies !== undefined){
        for (var i = 0; i < anomalies.length; i++){
            anomaliesData.push(
                createData(
                    i,
                    anomalies[i]["accountId"],
                    anomalies[i]["accountName"],
                    anomalies[i]["appName"],
                    anomalies[i]["appToken"],
                    formatDate(anomalies[i]["createdAt"]),
                    anomalies[i]["type"],
                    anomalies[i]["typeId"],
                    anomalies[i]["wasSeen"],
                    anomalies[i]["idAnom"],
                )
            );
        }
    }

    const columns: GridColDef[] = [
        { field: 'wasSeen', headerName: 'Seen ?',flex: 1, minWidth: 180,
            renderCell: (params : any) => {
            return  <Checkbox
                checked={checkedCheck(params.row.wasSeen,params.row.idAnom)}
                onChange={() => checkBoxChange(params.row.idAnom,params.row.typeId)}
                inputProps={{ 'aria-label': 'controlled' }}
               />

            }
        },
        { field: 'type', headerName: 'Type',flex: 1, minWidth: 150},
        { field: 'accountId', headerName: 'Account Id',flex: 1, minWidth: 150},
        { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 150},
        { field: 'appName', headerName: 'App Name',flex: 1, minWidth: 150},
        { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 150},
        { field: 'createdAt', headerName: 'Created At',flex: 1, minWidth: 150}
      ];
      
    const checkBoxChange = (idAnom: string,type: string) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "type": type, "idAnom": idAnom})
          };
          fetch('/api/anomaliesread', requestOptions).then((response) => response.json()
          .then((user) => {
            if(user.error === ''){
                clickedAnomalies.push(idAnom);
            }
          }));
    }
    const checkedCheck = (lastSeen: boolean, idAnom: string) => {
        if(lastSeen){
            return true;
        }
        if(clickedAnomalies.includes(idAnom)){
            return true;
        }
        return false;
    }
    return (
        <div style={{ height: 650, width: '100%' }}>
            <DataGrid 
                rows={anomaliesData} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}