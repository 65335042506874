import { useState } from "react";
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid";
import { Box, Button, Dialog, DialogTitle, Typography } from "@mui/material";

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

function createData(
    id: number,
    click: string,
    view: string,
    actionType: string,
    value: string,
    inline: string
) {
    return { id, click, view, actionType, value, inline };
}

export const MetaPartnerApiModal = (_props : any) =>{
    let campaignId = _props.campaignId;
    let data = _props.data;
    const rows : any = [];
    
    for (var i = 0; i < data.length; i++){
        if(campaignId === data[i]["campaign_id"]){
          let a = data[i]["actions"];
          for (var j = 0; j < a.length; j++){
            let click = '';
            let view = '';
            let type = ''
            let value = '';
            let inline = '';

            if ("7d_click" in a[j]) {click = a[j]["7d_click"]}
            if ("1d_view" in a[j]) {view = a[j]["1d_view"]}
            if ("action_type" in a[j]) {type = a[j]["action_type"]}
            if ("value" in a[j]) {value = a[j]["value"]}
            if ("inline" in a[j]) {inline = a[j]["inline"]}

            rows.push(
                createData(
                    j,
                    click,
                    view,
                    type,
                    value,
                    inline
                )
            );
         }
        }
      }

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const columns: GridColDef[] = [

        { field: 'click', headerName: '7d Click',flex: 1},
        { field: 'view', headerName: '1d View',flex: 1},
        { field: 'actionType', headerName: 'Action Type',flex: 1},
        { field: 'value', headerName: 'Value',flex: 1},
      ];
      
    return <>
            <Button onClick={handleOpen} variant="outlined">Inspect</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xl"
                fullWidth
            >
                <Box >
                    <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                        Actions for: {campaignId}
                    </DialogTitle>
                    <Typography id="dialog-description">
                        <DataGrid 
                            rows={rows} 
                            columns={columns}
                            disableRowSelectionOnClick
                            components={{
                                Toolbar: CustomToolbar,
                            }}
                        />
                    </Typography>
                </Box>
            </Dialog>
        </>
}