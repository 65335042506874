import { Chart } from "react-google-charts";

const formatDate = (dateString : string) =>{
    if(dateString === '' || dateString === null){
        return '';
    }
    return new Date(dateString).toLocaleDateString();
}

const setChartValues =(datas : any) =>{
    const data = [
        ["Date","Data Points"],
        ["N/A",0],
      ];
    var rows : any = [["Date","Data Points"]];
    if(datas !== undefined && datas !== '' ){
        for (let i = 0; i < datas.length; i++) {
            rows.push([formatDate(datas[i][1]), datas[i][7] ]);
        }
        return rows;
    }
    return data;
}

export const options = {
  title: "Data Points L12M",
  legend: { 
    position: 'bottom', 
    alignment: 'center' 
  },
  seriesType: "bars",
  series: { 1: { type: "line" } },
  vAxis: {minValue: 0}
};

export const DataPointsChart = (props : any) =>{
    let dps = props.dps;

  return (
    <div style={{marginLeft:'5px',marginTop:'5px',marginRight:'5px'}}>
      <Chart
        chartType="ComboChart"
        width="100%"
        height="300px"
        data={setChartValues(dps)}
        options={options}
      />
    </div>
  );
}
