import { Box, Card, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import { UpdatesTableSalesRegion } from '../../entities/components/Overview/UpdateTableSalesRegion';

export const OverviewPageSalesRegion = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    
    const [region, setRegion] = useState('');

    const regionChange = (event: SelectChangeEvent) => {
        setRegion(event.target.value as string);
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "region": event.target.value as string }) 
        };
        fetch('/api/allaccountsregionoverview', requestOptions)
            .then(response => response.json()).then(data => {
                setData(data);
                setLoading(false);
            });
      };

    return (
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <div>
                    <h1 style={{marginTop:'0px'}}>Overview Region </h1>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Card>
                                <FormControl fullWidth style={{marginTop: '5px'}}>
                                    <InputLabel id="region-select-label">Region</InputLabel>
                                    <Select
                                        labelId="region-label"
                                        id="region-select"
                                        value={region}
                                        label="Region"
                                        onChange={regionChange}
                                    >
                                        <MenuItem value={"China"}>China</MenuItem>
                                        <MenuItem value={"DACH & Nordics"}>DACH & Nordics</MenuItem>
                                        <MenuItem value={"France & Belgium & Lux"}>France & Belgium & Lux</MenuItem>
                                        <MenuItem value={"Israel/So.Europe"}>Israel/So.Europe</MenuItem>
                                        <MenuItem value={"Japan"}>Japan</MenuItem>
                                        <MenuItem value={"Korea"}>Korea</MenuItem>
                                        <MenuItem value={"Latin America (ES)"}>Latin America (ES)</MenuItem>
                                        <MenuItem value={"Latin America (PT-BR)"}>Latin America (PT-BR)</MenuItem>
                                        <MenuItem value={"MENA"}>MENA</MenuItem>
                                        <MenuItem value={"North America"}>North America</MenuItem>
                                        <MenuItem value={"Russia and CEE"}>Russia and CEE</MenuItem>
                                        <MenuItem value={"Southern Europe"}>Southern Europe</MenuItem>
                                        <MenuItem value={"UKI"}>UKI</MenuItem>
                                    </Select>
                                </FormControl>
                            </Card>
                        </Grid>
                    </Grid>
                    <br/>
                    {loading &&
                        <CircularProgress />
                    }
                    {!loading &&
                    <>
                        <UpdatesTableSalesRegion data = {data} />
                    </>
                    }
                </div>
            </Box>
    );
}