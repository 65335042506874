import { Icon, Tooltip } from '@adjust/components';
import { Grid } from '@mui/material';

export const InfoTooltip = (_props : any) => {
    return(
        <Grid container justifyContent="flex-end">
            <Tooltip content={_props.message} position="bottom">
                <Icon name="Info" />
            </Tooltip>
        </Grid>
        
    );

}