
import { Breadcrumbs } from '@adjust/components/build/Breadcrumbs/Breadcrumbs';
import { Tile } from '@adjust/components/build/Tile/Tile';
import { Box, CircularProgress} from '@mui/material';
import React, { useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from "react-query";
import { Tabs } from '@adjust/components';
import { AccountSalesSegmentedButton } from './AccountSalesSegmentedButton';
import { Account, AccountFeaturesData } from '../../shared/components/Types/Types';
import { getData } from '../../shared/components/Hooks/AccountHooks';
import { FeatureTable } from '../../entities/components/Accounts/FeatureTable';
import { AccountProvisionTable } from '../../entities/components/Accounts/AccountProvisionTable';

function MyBreadcrumbs(accountId:string,accountName: string, navigate:NavigateFunction) {
    const items = [
      { label: 'My Accounts', onClick: () => {navigate('/myaccounts')}},
      { label: accountName, onClick: () => {navigate('/account/' + accountId)} },
      { label: 'Features', onClick: () => {navigate('/account/' + accountId + '/features')}},
    ];
    return <Breadcrumbs items={items} />;
  }
  
export const FeatureSalesPage = (_props:Account) =>{
    let navigate = useNavigate();
    let params = useParams();
    let accountId  = "0";
    if (params.accountId !== undefined){
        accountId = String(params.accountId);
    }
    const [tabs, setTabs] = useState('1');
    const items = [
    {
        id: '1',
        label: 'Metabase Features'
    },
    {   
        id: '3',
        label: 'SF Provision Features'
    }
    ];
    
    const { status, data } = useQuery<AccountFeaturesData, Error>(
        ["featuresPage", { accountId }], () => getData(accountId,'feature'));
    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    {MyBreadcrumbs(accountId,_props.accountName,navigate)}
                    <AccountSalesSegmentedButton accountId = {accountId} tabValue='4' accountManager={_props.accountManager} competitor={_props.competitor} />
                    <br/>
                    {status === "loading" &&
                            <CircularProgress />
                    }
                    {status !== "loading" &&
                    <div>
                        <Tabs items={items} onChange={setTabs} value={tabs} />
                        <Tile>
                            {(tabs === '1') &&
                            <FeatureTable features={data?.features} />
                            }
                            {(tabs === '3') &&
                            <AccountProvisionTable features={data?.provisions} />
                            }
                        </Tile>
                    </div>
                    }
                </Box>
    );
}