import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { CtvProbabilisticMatchingTable } from "./CtvProbabilisticMatchingTable";
import { InfoTooltip } from "../../../shared/components/infoTooltip/InfoTooltip";


const formatDate = (dateString : string) =>{
    if(dateString === '' || dateString === null){
        return '';
    }
    return new Date(dateString).toLocaleDateString();
}

export const ProbabilisticMatchingPage = () => {
    const [ctvProbabilisticMatchingEnabled,setCtvProbabilisticMatchingEnabled] = useState({description:'',apps:[],updated_at: ''});
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true)
        fetch('/api/ctvprobabilisticmatchingenabled').then(res => res.json()).then(data => {
            setCtvProbabilisticMatchingEnabled(data);
            setIsLoading(false); 
        });
    }, []);

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                 <h1>Ctv Probabilistic Matching Enabled List</h1>
                            {isLoading &&
                            <CircularProgress />
                            }
                            {!isLoading &&
                            <>
                                <p>Description: {ctvProbabilisticMatchingEnabled.description}</p>
                                <p>Updated At: {formatDate(ctvProbabilisticMatchingEnabled.updated_at)}</p>
                                <InfoTooltip message="Source: Adjust DB | Refreshed: instant" />
                                <CtvProbabilisticMatchingTable apps={ctvProbabilisticMatchingEnabled.apps} />
                            </>
                            }
                            </Grid>
                        </Grid>
                    </div>
                </Box>
    );
}