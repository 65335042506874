import { Box, CircularProgress, Grid } from "@mui/material";
import { Breadcrumbs } from '@adjust/components/build/Breadcrumbs/Breadcrumbs';
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { AccountSegmentedButton } from "../../../shared/components/accountSegmentedButton/AccountSegmentedButton";
import { Tile } from "@adjust/components";
import { AccountSfCasesTable } from "../../../entities/components/Accounts/AccountSfCasesTable";
import { AccountNotesTextfield } from "../../../entities/components/Accounts/AccountNotesTextField";
import { Account, AccountOooCoverageData } from "../../../shared/components/Types/Types";
import { useQuery } from "react-query";
import { getData } from "../../../shared/components/Hooks/AccountHooks";
import { InfoTooltip } from "../../../shared/components/infoTooltip/InfoTooltip";

function MyBreadcrumbs(accountId:string,accountName: string,navigate:NavigateFunction) {
    const items = [
      { label: 'My Accounts', onClick: () => {navigate('/myaccounts')}},
      { label: accountName, onClick: () => {navigate('/account/' + accountId)} },
      { label: 'OOO Coverage', onClick: () => {navigate('/account/' + accountId + '/coverage')} },
    ];
    return <Breadcrumbs items={items} />;
  }

export const AccountOOOCoveragePage = (_props:Account) => {
    let navigate = useNavigate();
    let params = useParams();
    let accountId  = "0";
    if (params.accountId !== undefined){
        accountId = String(params.accountId);
    }
    
    const { status, data } = useQuery<AccountOooCoverageData, Error>(
        ["oooCoveragePage", { accountId }], () => getData(accountId,'accountcoverage'));
    

    return(
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    {MyBreadcrumbs(accountId,_props.accountName,navigate)}
                    <AccountSegmentedButton accountId = {accountId} tabValue='12' accountManager={_props.accountManager} competitor={_props.competitor} />
                    <br/>
                    {status === "loading" &&
                        <CircularProgress />
                    }
                    {status !== "loading" &&
                    <div>
                        <InfoTooltip message="Source: Salesforce | Refreshed: once a day" />
                            <Tile>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid item xs={5}>
                                            <div style={{fontWeight:'bold'}}>Main account manager: </div>
                                        </Grid>
                                        <Grid item xs={7}> {data?.manager[0]}</Grid>

                                        <Grid item xs={5}>
                                            <div style={{fontWeight:'bold',marginRight:'4px'}}>Holiday account manager: </div>
                                        </Grid>
                                        <Grid item xs={7}> {data?.manager[1]}</Grid>
                                    </Grid>
                                </Grid>
                            </Tile>
                            <br/>
                            <Tile title="Cases">
                                <AccountSfCasesTable cases={data?.cases} />
                            </Tile>
                            <br/>
                            <Tile>
                                <AccountNotesTextfield 
                                    accountId={accountId} 
                                    notes={data?.note} 
                                    lastEdited={data?.lastEdited}
                                    lastUpdated={data?.lastUpdated}
                                />
                                
                            </Tile>

                        
                    </div>
                    }
                </Box>
    );
}