import { ComboBox } from '@adjust/components';
import React,{useEffect, useState} from 'react'

export const TrackerTokenInput = (_props) => {
  const [value, setValue]= useState([]);
  const [inputValue, setInputValue] = useState('');
  const handleChange = (value) => setValue(value);
  const handleInput = (inputValue) => setInputValue(inputValue);

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setValue([
          ...value,
          {
            value: `${inputValue}-${value.length}`,
            label: inputValue
          }
        ]);
        setInputValue('');
        event.preventDefault();
        break;
      default:
        return;
    }
    
  };

  const handleBlur = (event) => {
    if (!inputValue) return;
    if(event.type === 'blur'){
      setValue([
        ...value,
        {
          value: `${inputValue}-${value.length}`,
          label: inputValue
        }
      ]);
      setInputValue('');
      event.preventDefault();
    }
  }
  
  useEffect(() => {
    let trackerTokenList = [];
    for (var i = 0; i < value.length; i++){
      if(!trackerTokenList.includes(value[i].label)){
        trackerTokenList.push(value[i].label)
      }
    }
    _props.onTrackerTokensChange(trackerTokenList);
  },[value])
    
   return (
    <div>
        <ComboBox
            kind="multi-creatable"
            value={value}
            inputValue={inputValue}
            onChange={handleChange}
            onInputChange={handleInput}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            placeholder="Enter Tracker Token and press `Enter`"
        />
    </div>
   );
}
