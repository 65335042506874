import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';



function createData(
    id: number,
    eventToken: string,
    eventName: string,
    appToken: string,
    appName: string,
    accountId: number,
    accountName: string,
    accountManager: string,
  ) {
    return { id,eventToken,eventName,appToken,appName,accountId,accountName,accountManager};
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export const EventsS2SOnlyTable = (_props : any) => {
    var apps = _props.apps;
    const rows : any = [];
    for (var i = 0; i < apps.length; i++){
        rows.push(
            createData(
              i,
              apps[i].eventToken,
              apps[i].eventName,
              apps[i].appToken,
              apps[i].appName,
              apps[i].accountId,
              apps[i].accountName,
              apps[i].accountManager,
            )
        );
    }

    const columns: GridColDef[] = [
        { field: 'eventToken', headerName: 'Event Token',flex: 1, minWidth: 150},
        { field: 'eventName', headerName: 'Event Name',flex: 1, minWidth: 150},
        { field: 'accountId', headerName: 'Account ID',flex: 1, minWidth: 100,
        renderCell: (params : any) => {
            return <Button variant="contained" component={Link} to={"/account/"+params.row.accountId} >{params.row.accountId}</Button>;
            },
        },
        { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 250},
        { field: 'accountManager', headerName: 'Account Manager',flex: 1, minWidth: 150},
        { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 150},
        { field: 'appName', headerName: 'App Name',flex: 1, minWidth: 150},
    ];

    return (
        <div style={{ height: 650, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}