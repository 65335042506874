import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import {NumericFormat} from 'react-number-format';
import { formatDate } from '../../../../shared/components/formatDate/FormatDate';

const columns: GridColDef[] = [
  { field: 'date', headerName: 'Date',flex: 1,},
  { field: 'sumDataPoints', headerName: 'Data Points',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.sumDataPoints} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'sumImpressions', headerName: 'Impressions',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.sumImpressions} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'sumClicks', headerName: 'Clicks',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.sumClicks} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'sumInstalls', headerName: 'Installs',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.sumInstalls} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'sumReattributions', headerName: 'Reattributions',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.sumReattributions} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'sumEvents', headerName: 'Events',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.sumEvents} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'sumUninstalls', headerName: 'Uninstalls',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.sumUninstalls} displayType={'text'} thousandSeparator={true}/>
  }},
  { field: 'paidInstalls', headerName: 'Paid Installs',flex: 1,
  renderCell: (params : any) => {
    return <NumericFormat value={params.row.paidInstalls} displayType={'text'} thousandSeparator={true}/>
  }},
];

function createData(
    id: number,
    date: string,
    sumImpressions: number,
    sumClicks: number,
    sumInstalls: number,
    sumReattributions: number,
    sumEvents: number,
    sumDataPoints: number,
    sumUninstalls: number,
    paidInstalls: number,
  ) {
    return { id,date, sumImpressions, sumClicks, sumInstalls, sumReattributions, sumEvents, sumDataPoints, sumUninstalls, paidInstalls};
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export const DataPointsCompleteTable = (_props : any) => {
    const attributions = _props.keystat;
    const rows : any = [];
    if(attributions !== undefined){
        for (var i = 0; i < attributions.length; i++){
            rows.push(
                createData(
                    attributions[i][0],
                    formatDate(attributions[i][1]),
                    attributions[i][2],
                    attributions[i][3],
                    attributions[i][4],
                    attributions[i][5],
                    attributions[i][6],
                    attributions[i][7],
                    attributions[i][8],
                    attributions[i][9],

                )
            );
        }
    }
    return (
        <div style={{ height: 300, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}
