import React, { useEffect, useContext, useState } from 'react';
import { Routes,Route, BrowserRouter} from "react-router-dom";
import { AuthContext } from "../app/store/AuthContext";
import ReactGA from "react-ga4";
import { AccountRouter } from './AccountRouter/AccountRouter';
import { ToolsRouter } from './ToolsRouter/ToolsRouter';
import { AppRouter } from './AppRouter/AppRouter';
import { ReportsRouter } from './ReportsRouter/ReportsRouter';
import { SitesWithAuthRouter } from './SitesWithAuthRouter/SitesWithAuthRouter';
import LoginPage from '../pages/login/Login';
import NotFound from '../pages/notFound/NotFound';
import { Logout } from '../pages/logout/Logout';

export const Router = () => {
    const value = useContext(AuthContext);
    const auth = value?.auth.isLoggedIn;
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        if(!auth){
        setLoading(true);
        fetch('/api/authentification').then(res => res.json()).then(data => {
            value?.setAuthentification({
            isLoggedIn: data.login,
            apiToken: data.apiToken,
            isManager: data.isManager,
            role: data.role
            });
            value?.setLogin({
            name: data.profile.name,
            url: data.profile.url,
            wfh: data.profile.status
            });
            setLoading(false);
        });
        }
    },[auth]);
    const TRACKING_ID = "G-DHE20GH4T9"; 
    ReactGA.initialize(TRACKING_ID);
    const clientId = process.env.REACT_APP_GOOGLE_ID;
  
    ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: "Page View on" + window.location.pathname});
    return (
        <BrowserRouter>    
          <Routes>
            <Route path="/" element={<LoginPage clientId={clientId}/>}></Route>
            <Route path="/login" element={<LoginPage clientId={clientId}/>}></Route>
            <Route path="/logout" element={<Logout clientId={clientId}/>}></Route>
            {auth &&
            <>
              <Route path="/account/app/:appToken/*" element={<AppRouter />}></Route>
              <Route path="/account/:accountId/*" element={<AccountRouter />}></Route>
              <Route path="/reports/*" element={<ReportsRouter />}></Route>
              <Route path="/tools/*" element={<ToolsRouter />}></Route>
              <Route path="/*" element={<SitesWithAuthRouter />}></Route>
            </>
          }  
            {(!isLoading && !auth) &&
            <Route path="*" element={<NotFound clientId={clientId}/>}></Route>
            }
            {isLoading &&
            <Route path="*" element={<div/>}></Route>
            }    
            
          </Routes>
        </BrowserRouter>
      );
    }
