import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

const columns: GridColDef[] = [
  { field: 'appId', headerName: '',flex: 1, maxWidth:1},
  { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 150,
    renderCell: (params : any) => {
      return <Button variant="contained" component={Link} to={"/account/app/"+params.row.appToken} >{params.row.appToken}</Button>
    },
  },
  { field: 'name', headerName: 'App Name',flex: 1},
  { field: 'accountId', headerName: 'Account ID',flex: 1,
    renderCell: (params : any) => {
      return <Button variant="contained" component={Link} to={"/account/"+params.row.accountId} >{params.row.accountId}</Button>
    },
  },
  { field: 'accountName', headerName: 'Account Name',flex: 1},
  { field: 'deletedAt', headerName: 'Deletet At',flex: 1,},
  { field: 'iosAppId', headerName: 'iOS App Id',flex: 1},
  { field: 'iosBundleId', headerName: 'iOS Bundle Id',flex: 1},
  { field: 'skAppId', headerName: 'SK App Id',flex: 1},
  { field: 'bundleId', headerName: 'Bundle Id',flex: 1},
  { field: 'country', headerName: 'Country',flex: 1},
  { field: 'sellerName', headerName: 'Seller Name',flex: 1},
  { field: 'trackerName', headerName: 'Tracker Name',flex: 1},
  { field: 'id', headerName: 'Control Tower',flex: 1,
    renderCell: (params : any) => {
      return <Button variant="contained" href={"https://control.adjust.com/mobile_apps/"+params.row.appId+"/edit"} target="_blank">Open</Button> 
    },
  },
];

function createData(
    id: number,
    accountId: number,
    accountName: string,
    appId: number,
    appToken: string,
    deletedAt: string,
    iosAppId: string,
    iosBundleId: string,
    name: string,
    skAppId: string,
    bundleId: string,
    country: string,
    sellerName: string,
    trackerName: string
  ) {
    return { id,accountId,accountName,appId,appToken,deletedAt,iosAppId,iosBundleId,name,skAppId,bundleId,country,sellerName,trackerName };
  }
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export const IosAppVerificationTable = (_props : any) =>{
    const data = _props.data;
    const rows : any = [];
    if(data !== undefined){
        for (var i = 0; i < data.length; i++){
            rows.push(
                createData(
                    i,
                    data[i][0].accountId,
                    data[i][0].accountName,
                    data[i][0].appId,
                    data[i][0].appToken,
                    formatDate(data[i][0].deletedAt),
                    data[i][0].iosAppId,
                    data[i][0].iosBundleId,
                    data[i][0].name,
                    data[i][0].skAppId,
                    data[i][1].bundleId,
                    data[i][1].country,
                    data[i][1].sellerName,
                    data[i][1].trackerName,
                )
            );
        }
    }
    return (
        <div style={{ height: 600, width: '98%' }}>
            <DataGrid 
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}