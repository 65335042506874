import { Box, Card, Grid, List, ListItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { formatDate } from '../../shared/components/formatDate/FormatDate';

export const UpdatesPage = () =>{
    
    const [updates, setUpdates] = useState([]);
    useEffect(() => {
        fetch('/api/updates').then(res => res.json()).then(data => {
            setUpdates(data["updates"]);
        });
    }, []);
    
    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {updates.map((update:any) => (
                <ListItem>
                    <Card style={{width: '100%'}}>
                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                            <Grid item xs={2} >
                                <p style={{marginLeft: '5%'}} >{formatDate(update[2])}</p>
                            </Grid>
                            <Grid item xs={10}>
                                <Typography>{update[1]}</Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </ListItem>
                ))}
            </List>
        </Box>
    );
}
