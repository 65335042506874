import { Button, Input } from '@adjust/components';
import { Box, Grid} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import ClickSpamAllowListTrackerTable from '../../../entities/components/ClickSpam/ClickSpamAllowListTrackerTable';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from '../../../app/store/AuthContext';

export const ClickSpamAllowListPage = () =>{
    const value = useContext(AuthContext);
    const role = value?.auth.role;
    const [tracker, setTracker] : any = useState('');
    const [appToken, setAppToken] : any = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [trackers, setTrackers] : any = useState([]);

    useEffect(() => {
        fetch('/api/clickspamallowlist').then(res => res.json()).then(data => {
            setTrackers(data['trackerToken']);
        });
    }, [message]);

    
    function allowTrackerToken(tracker : any){
        if(tracker !== '' ){
            setIsLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'trackerToken': tracker })
            };
            fetch('/api/setallowedclickspamtracker', requestOptions)
                .then(response => response.json()).then(data => {
                    setIsLoading(false);
                    setMessage(data["message"]);
                });
        }
    }

    function unAllowTrackerToken(tracker : any){
        if(tracker !== '' ){
            setIsLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'trackerToken': tracker })
            };
            fetch('/api/setunallowedclickspamtracker', requestOptions)
                .then(response => response.json()).then(data => {
                    setIsLoading(false);
                    setMessage(data["message"]);
                });
        }
    }

    function allowAppToken(appToken : any){
        if(appToken !== '' ){
            setIsLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'appToken': appToken })
            };
            fetch('/api/setallowedclickspamapp', requestOptions)
                .then(response => response.json()).then(data => {
                    setIsLoading(false);
                    setMessage(data["message"]);
                });
        }
    }

    function unAllowAppToken(appToken : any){
        if(appToken !== '' ){
            setIsLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'appToken': appToken })
            };
            fetch('/api/setunallowedclickspamapp', requestOptions)
                .then(response => response.json()).then(data => {
                    setIsLoading(false);
                    setMessage(data["message"]);
                });
        }
    }

    const isAllowed = () => {
        if(role === '2' || role === '8'){
            return true;
        }
        return false;
    }

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <h1>Click Spam Allow / Unallow list</h1>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Input
                                    label="Tracker Token"
                                    id="tracker" 
                                    name="tracker"
                                    value={tracker}
                                    placeholder=""
                                    css={{ width: '50%' }}
                                    onClear={() => {
                                        setTracker('');
                                    }}
                                    onChange={(e) => {
                                        setTracker(e.target.value);
                                    }}
                                />
                                <br/>
                                {isAllowed() &&
                                <>
                                    <Button label={'Allow Tracker'} kind="feedback-info-primary" onClick={() => allowTrackerToken(tracker)}></Button>
                                    <Button label={'Unallow Tracker'} kind="feedback-negative-primary" onClick={() => unAllowTrackerToken(tracker)}></Button>
                                </>
                                }
                                {!isAllowed() &&
                                <>
                                    <Button label={'Allow Tracker'} kind="feedback-info-primary" disabled></Button>
                                    <Button label={'Unallow Tracker'} kind="feedback-negative-primary" disabled></Button>
                                </>
                                }
                                <br/><br/>
                                <Input
                                    label="App Token"
                                    id="app" 
                                    name="app"
                                    value={appToken}
                                    placeholder=""
                                    css={{ width: '50%' }}
                                    onClear={() => {
                                        setAppToken('');
                                    }}
                                    onChange={(e) => {
                                        setAppToken(e.target.value);
                                    }}
                                />
                                <br/>
                                {isAllowed() &&
                                <>
                                    <Button label={'Allow App Token'} kind="feedback-info-primary" onClick={() => allowAppToken(appToken)}></Button>
                                    <Button label={'Unallow App Token'} kind="feedback-negative-primary" onClick={() => unAllowAppToken(appToken)}></Button>
                                </>
                                }
                                {!isAllowed() &&
                                <>
                                    <Button label={'Allow App Token'} kind="feedback-info-primary"  disabled></Button>
                                    <Button label={'Unallow App Token'} kind="feedback-negative-primary" disabled></Button>
                                </>
                                }

                                {isLoading && 
                                <CircularProgress />
                                }
                                {!isLoading && 
                                <p>{message}</p>
                                }
                            </Grid>
                            
                            <Grid item xs={8}>
                                <h3>Allow List</h3>
                                <ClickSpamAllowListTrackerTable trackers={trackers} />
                            </Grid>
                        </Grid>
                    </div>
                </Box>
    );
}