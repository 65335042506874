import { Breadcrumbs } from '@adjust/components/build/Breadcrumbs/Breadcrumbs';
import { Grid, Button, ButtonGroup} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LatestReleasedAppVersion } from '../../../entities/components/Integration/LatestReleasedAppVersion';
import AppInfo from '../../../shared/components/appInfo/AppInfo';
import { AppSegmentedButton } from '../../../shared/components/appSegmentedButton/AppSegmentedButton';
import { IntegrationOverviewIos } from '../../../entities/components/Integration/IntegrationOverviewIos';
import { BaggerApi } from '../../../entities/components/Integration/BaggerApi';
import { IntegrationOverviewAndroid } from '../../../entities/components/Integration/IntegrationOverviewAndroid';
import { PdfCreatorButtonAndroid } from '../../../entities/components/Integration/PdfCreatorButtonAndroid';
import { PdfCreatorButtonIos } from '../../../entities/components/Integration/PdfCreatorButtonIos';
import { IntegrationOverviewOther } from '../../../entities/components/Integration/IntegrationOverviewOther';
import { BaggerApiHistoryTable } from '../../../entities/components/Integration/BaggerApiHistoryTable';
import { SummaryTable } from '../../../entities/components/Integration/SummaryTable';
import { PdfCreatorButtonOthers } from '../../../entities/components/Integration/PdfCreatorButtonOthers';
import { Tabs } from '@adjust/components';

function MyBreadcrumbs(accountId:any,accountName:any,appToken:any,appName:any) {
    let navigate = useNavigate();
    const items = [
        { label: 'My Accounts', onClick: () => {navigate('/myaccounts')}},
        { label: accountName, onClick: () => {navigate('/account/' + accountId)}},
        { label: 'Apps', onClick: () => {navigate('/account/' + accountId + '/apps')}},
        { label: appName, onClick: () => {navigate('/account/app/'+ appToken)}},
        { label: 'Sdk Sentinel', onClick: () => {navigate('/account/app/' + appToken + '/sdksentinel')}},
    ];
    return <Breadcrumbs items={items} />;
}

export const AppImplementationPage = (_props:any) => {
    const appToken = _props.appToken;
    const appInfo = _props.appInfo;
    const accountName = _props.accountName ;

      const [os,setOs] = useState('android');
      const [isAndroid,setIsAndroid] = useState('');
      const [isIos,setIsIos] = useState('');
      const [androidData,setAndroidData] = useState([]);
      const [iosData,setIosData] = useState([]);
      const [othersData,setOthersData] = useState([]);
      const [lastRunTime,setLastRunTime] = useState('');
      const [menu,setMenu] = useState('1');
      const menuList = [
        { id: '1', value: '1', label: 'Checker' },
        { id: '2', value: '2', label: 'Job History' },
      ];
      const [newestAppVersionApptopiaAndroid,setNewestAppVersionApptopiaAndroid] = useState([{'date':'','description':'','version':''}]);
      const [newestAppVersionApptopiaIos,setNewestAppVersionApptopiaIos] = useState([{'date':'','description':'','version':''}]);

      const [newestAppVersionAnalyticsAndroid,setNewestAppVersionAnalyticsAndroid] = useState([{'date':'','description':'','version':''}]);
      const [newestAppVersionAnalyticsIos,setNewestAppVersionAnalyticsIos] = useState([{'date':'','description':'','version':''}]);

      const [history,setHistory] = useState([]);
      const [jobRunning,setJobRunning] = useState({'running':false,'jobId':0});
      useEffect(() => {
          fetch('/api/appimplementation/'+appToken).then(res => res.json()).then(data => {
            setHistory(data["history"]);
            setIsAndroid(data["isAndroid"]);
            setIsIos(data["isIos"]);
            setAndroidData(data["android"]);
            setIosData(data["ios"]);
            setJobRunning(data["jobRunning"]);
            setOthersData(data["others"]);
            if(data["isAndroid"] !== ''){
                setOs('android');
                setLastRunTime(data["android"]["lastRunTime"]);
                setNewestAppVersionApptopiaAndroid(data["android"]["newestAppVersionMetabase"]);
                setNewestAppVersionAnalyticsAndroid(data["android"]["versionAnalytics"]);
                
            }
            if(data["isIos"] !== ''){
                if(data["isAndroid"] === ''){
                    setOs('ios');
                    setLastRunTime(data["ios"]["lastRunTime"]);
                }
                setNewestAppVersionApptopiaIos(data["ios"]["newestAppVersionMetabase"]);
                setNewestAppVersionAnalyticsIos(data["ios"]["versionAnalytics"]);
            }
          });
      }, [appToken]);

    function menuChange(value : string) {setMenu(value);}
    return (
                    <div>
                        {MyBreadcrumbs(appInfo[3],accountName,appInfo[2],appInfo[1])}
                        <AppSegmentedButton appToken = {appToken} tabValue='3' />
                        <br/>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <AppInfo appInfo={appInfo} accountName={accountName} />
                                    </Grid> 
                                    <Grid item xs={8}>
                                        <Tabs
                                            items={menuList}
                                            value={menu}
                                            onChange={setMenu}
                                        />
                                        {menu === '1' &&
                                        <>
                                            <ButtonGroup
                                                disableElevation
                                                variant="contained"
                                                style={{marginLeft:'5px',marginTop:'15px'}}
                                            >
                                            {os === 'android' &&
                                            <>
                                                {isAndroid !== '' &&
                                                <Button style={{width:'300px'}} onClick={() => setOs('android')}>Android SDK</Button>
                                                }
                                                {isIos !== '' &&
                                                <Button style={{width:'300px'}} variant="outlined" onClick={() => setOs('ios')}>iOS SDK</Button>
                                                }
                                                <Button style={{width:'300px'}} variant="outlined" onClick={() => setOs('other')}>Other</Button>
                                            </>
                                            }
                                            {os === 'ios' &&
                                            <>
                                                {isAndroid !== '' &&
                                                <Button style={{width:'300px'}} variant="outlined" onClick={() => setOs('android')}>Android SDK</Button>
                                                }
                                                {isIos !== '' &&
                                                <Button style={{width:'300px'}} onClick={() => setOs('ios')}>iOS SDK</Button>
                                                }
                                                <Button style={{width:'300px'}} variant="outlined" onClick={() => setOs('other')}>Other</Button>
                                            </>
                                            }
                                            {os === 'other' &&
                                            <>
                                                {isAndroid !== '' &&
                                                <Button style={{width:'300px'}} variant="outlined" onClick={() => setOs('android')}>Android SDK</Button>
                                                }
                                                {isIos !== '' &&
                                                <Button style={{width:'300px'}} variant="outlined" onClick={() => setOs('ios')}>iOS SDK</Button>
                                                }
                                                <Button style={{width:'300px'}} onClick={() => setOs('other')}>Other</Button>
                                            </>
                                            }
                                            </ButtonGroup>
                                            
                                            <br/><br/>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <BaggerApi appToken={appToken} lastRunTime={lastRunTime} jobRunning={jobRunning} onMenuChange={menuChange}/>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <LatestReleasedAppVersion os={os} appToken={appToken} newestAppVersionAndroid={newestAppVersionApptopiaAndroid} newestAppVersionIos={newestAppVersionApptopiaIos} newestAppVersionAndroidAnalytics={newestAppVersionAnalyticsAndroid} newestAppVersionIosAnalytics={newestAppVersionAnalyticsIos}/>
                                                </Grid>
                                            </Grid>
                                            <br/>
                                            <SummaryTable data={othersData} lastRunTime={lastRunTime}/>
                                            <br/>
                                            {os === 'android' &&
                                                <>
                                                    <IntegrationOverviewAndroid appToken={appToken} data={androidData} newestAppVersionAndroid={newestAppVersionApptopiaAndroid} />
                                                    <PdfCreatorButtonAndroid clientName={accountName} appName={appInfo[1]} logo={appInfo[7]} appToken={appInfo[2]} lastRunTime={lastRunTime} os={'android'} data={androidData} newestAppVersionAndroid={newestAppVersionApptopiaAndroid}/>
                                                </>
                                            }
                                            {os === 'ios' &&
                                                <>
                                                    <IntegrationOverviewIos appToken={appToken} data={iosData} newestAppVersionIos={newestAppVersionApptopiaIos} />
                                                    <PdfCreatorButtonIos clientName={accountName} appName={appInfo[1]} logo={appInfo[7]} appToken={appInfo[2]} lastRunTime={lastRunTime} os={'ios'} data={iosData} newestAppVersionIos={newestAppVersionApptopiaIos}/>
                                                </>
                                            }
                                            {os === 'other' &&
                                                <>
                                                    <IntegrationOverviewOther data={othersData} lastRunTime={lastRunTime}/>
                                                    <PdfCreatorButtonOthers clientName={accountName} appName={appInfo[1]} logo={appInfo[7]} appToken={appInfo[2]} lastRunTime={lastRunTime} os={'others'} data={othersData} />
                                                </>
                                            }
                                        </>
                                        }
                                        {menu === '2' &&
                                            <BaggerApiHistoryTable history={history} />
                                        }
                                    </Grid> 
                                </Grid>
                                
                               
                                
                            </Grid>
                        </Grid>
                    </div>
    );
}