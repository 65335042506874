import React, { useContext } from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Button } from '@adjust/components';
import { Alert, Snackbar } from '@mui/material';
import { AuthContext } from '../../../app/store/AuthContext';
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

function createData(
    id: number,
    createdAt: string,
    accountId: string,
    appToken: string,
    networkId: string,
    accountName: string,
    accountManager: string,
    salesManager: Number,
    partnerId: string,
    appName: string,
    addedToAllowList: string,
    trackerName: string,
    partnerName: string
  ) {
    return { id, createdAt, accountId, appToken, networkId, accountName, accountManager, salesManager, partnerId, appName, addedToAllowList, trackerName, partnerName };
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export const ClickSpamNetworkTable = (_props : any) =>{
    const [openSnackBarAllow, setOpenSnackBarAllow] = React.useState(false);
    const [openSnackBarUnallow, setOpenSnackBarUnallow] = React.useState(false);
    const value = useContext(AuthContext);
    const role = value?.auth.role;
    const rows : any = [];
    for (var i = 0; i < _props.data.length; i++){
        rows.push(
            createData(
                i,
                formatDate(_props.data[i]["createdAt"]),
                _props.data[i]["accountId"],
                _props.data[i]["appToken"],
                _props.data[i]["networkId"],
                _props.data[i]["accountName"],
                _props.data[i]["accountManager"],
                _props.data[i]["salesManager"],
                _props.data[i]["partnerId"],
                _props.data[i]["appName"],
                _props.data[i]["addedToAllowList"],
                _props.data[i]["trackerName"],
                _props.data[i]["partnerName"]
            )
        );
    }

    function allowTracker(trackerToken : any) {
        const tracker = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ trackerToken }),
        }
        fetch('/api/setallowedclickspamtracker', tracker).then(response => response.json()).then(function (data) {
            setOpenSnackBarAllow(true);
        });
    }

    function unAllowTracker(trackerToken : any) {
        const tracker = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ trackerToken }),
        }
        fetch('/api/setunallowedclickspamtracker', tracker).then(response => response.json()).then(function (data) {
            setOpenSnackBarUnallow(true);
        });
        
    }

    const columns_allowed: GridColDef[] = [
        { field: 'createdAt', headerName: 'Created At',flex: 1, minWidth: 150},
        { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 150},
        { field: 'accountId', headerName: 'Account Id',flex: 1, minWidth: 150},
        { field: 'appName', headerName: 'App Name',flex: 1, minWidth: 150},
        { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 150},
        { field: 'trackerName', headerName: 'Tracker Name',flex: 1, minWidth: 150},
        { field: 'networkId', headerName: 'Network Id',flex: 1, minWidth: 150},
        { field: 'partnerName', headerName: 'Partner Name',flex: 1, minWidth: 150},
        { field: 'accountManager', headerName: 'Account Manager',flex: 1, minWidth: 150},
        { field: 'salesManager', headerName: 'Sales Manager',flex: 1, minWidth: 150},
        { field: 'abc', headerName: 'Allow Tracker Token',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
          return <Button label="Allow" onClick={() => allowTracker(params.row.networkId)} ></Button>; 
          },
        },
        { field: 'abcd', headerName: 'Unallow Tracker Token',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
          return <Button label="Unallow" onClick={() => unAllowTracker(params.row.networkId)} ></Button>; 
          },
        },
        { field: 'addedToAllowList', headerName: 'addedToAllowList',flex: 1, minWidth: 150},
        
      
      ];

      const columns_not_allowed: GridColDef[] = [
        { field: 'createdAt', headerName: 'Created At',flex: 1, minWidth: 150},
        { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 150},
        { field: 'accountId', headerName: 'Account Id',flex: 1, minWidth: 150},
        { field: 'appName', headerName: 'App Name',flex: 1, minWidth: 150},
        { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 150},
        { field: 'trackerName', headerName: 'Tracker Name',flex: 1, minWidth: 150},
        { field: 'networkId', headerName: 'Network Id',flex: 1, minWidth: 150},
        { field: 'partnerName', headerName: 'Partner Name',flex: 1, minWidth: 150},
        { field: 'accountManager', headerName: 'Account Manager',flex: 1, minWidth: 150},
        { field: 'salesManager', headerName: 'Sales Manager',flex: 1, minWidth: 150},
        { field: 'abc', headerName: 'Allow Tracker Token',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
          return <Button label="Allow" onClick={() => allowTracker(params.row.networkId)} disabled></Button>; 
          },
        },
        { field: 'abcd', headerName: 'Unallow Tracker Token',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
          return <Button label="Unallow" onClick={() => unAllowTracker(params.row.networkId)}disabled ></Button>; 
          },
        },
        { field: 'addedToAllowList', headerName: 'addedToAllowList',flex: 1, minWidth: 150},
        
      
      ];

      const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        setOpenSnackBarAllow(false);
        setOpenSnackBarUnallow(false);
      };

    const isAllowed = () => {
        if(role === '2' || role === '8'){
            return columns_allowed;
        }
        return columns_not_allowed;
    }
    return (
        <div style={{ height: 700, width: '100%' }}>
            <DataGrid 
                rows={rows} 
                columns={isAllowed()}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
            <Snackbar
                open={openSnackBarAllow}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Note archived"
            >
                <Alert severity="success">The Tracker was successfully Allowed!</Alert>
            </Snackbar>
            <Snackbar
                open={openSnackBarUnallow}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Note archived"
            >
                <Alert severity="success">The Tracker was successfully Unallowed!</Alert>
            </Snackbar>
        </div>
    );
}