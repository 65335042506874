import { Box } from "@mui/material";
import { PeriodFilter } from "./Period";
import { useEffect, useState } from "react";
import { OptionsFilter } from "./Options";
import { MainFilters } from "./MainFilters";
import { Result } from "./Result";

export const BQGPage = () => {
    const [periodTime,setPeriodTime] = useState( {'startDate':null,'endDate':null,'startTime':null,'endTime':null,'unit':null,'unitValue':null} );
    const [options,setOptions] = useState( {"service": '', "baggerPath": '', "outputFilePath":'',"outputFile":'',"uniqueFile":false,"count":'no',"command":'no'} );
    const [tags, setTags] = useState({});

    const [appTokens, setAppTokens] = useState([]);
    const [trackerTokens, setTrackerTokens] = useState([]);
    const [urlTrackser, setUrlTrackers] = useState([]);
    const [eventTokens, setEventTokens] = useState([]);
    const [requestUrl, setRequestUrl] = useState('');
    const [skadTokens, setSkadTokens] = useState([]);
    const [messages, setMessages] = useState([]);
    const [deviceIds, setDeviceIds] = useState([]);
    const [labels, setLabels] = useState([]);
    const [sanPartners, setSanPartners] = useState([]);

    const handlePeriodChange = (value:any) => {setPeriodTime(value);}
    const handleOptionChange = (value:any) => {setOptions(value);}
    const handleTagsChange = (value:any) => {setTags(value);}

    const handleAppTokensChange = (value:any) => {setAppTokens(value);}
    const handleTrackerTokensChange = (value:any) => {setTrackerTokens(value);}
    const handleUrlTrackersChange = (value:any) => {setUrlTrackers(value);}
    const handleEventTokensChange = (value:any) => {setEventTokens(value);}
    const handleRequestUrlChange = (value:any) => {setRequestUrl(value);}
    const handleSkadTokensChange = (value:any) => {setSkadTokens(value);}
    const handleMessagesChange = (value:any) => {setMessages(value);}
    const handleDeviceIdsChange = (value:any) => {setDeviceIds(value);}
    const handleLabelsChange = (value:any) => {setLabels(value);}
    const handleSanPartnersChange = (value:any) => {setSanPartners(value);}
    

    const [skadTokenList,setSkadTokenList] = useState([]);
    const [partners, setPartners] = useState([]);
    const [baggerName, setBaggerName] = useState('');

    useEffect(() => {
        fetch('/api/bqg').then(res => res.json()).then(data => {
            setSkadTokenList(data.skadTokens);
            setPartners(data.partners);
            setBaggerName(data.baggerName);
        });
    }, []);

    return(
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <h1>Bagger Query Generator</h1>
                        <PeriodFilter 
                            onPeriodChange={handlePeriodChange}
                        />
                        <br/>
                        <OptionsFilter 
                            onOptionChange={handleOptionChange} 
                            onTagsChange={handleTagsChange} 
                            baggerName={baggerName}
                        />
                        <br/>
                        <MainFilters 
                            service ={options.service}
                            tags = {tags}
                            partners={partners}
                            skadTokenList={skadTokenList}
                            onAppTokensChange={handleAppTokensChange} 
                            onTrackerTokensChange={handleTrackerTokensChange}
                            onUrlTrackersChange={handleUrlTrackersChange}
                            onEventTokensChange={handleEventTokensChange}
                            onRequestUrlChange={handleRequestUrlChange}
                            onSkadTokensChange={handleSkadTokensChange}

                            onMessagesChange={handleMessagesChange}
                            onDeviceIdsChange={handleDeviceIdsChange}
                            onLabelsChange={handleLabelsChange}
                            onSanPartnersChange={handleSanPartnersChange}
                            
                        />
                        <br/>
                        <Result 
                            periodTime={periodTime}
                            options={options}
                            tags={tags}
                            appTokens={appTokens}
                            trackerTokens={trackerTokens}
                            urlTrackser={urlTrackser}
                            eventTokens={eventTokens}
                            requestUrl={requestUrl}
                            skadTokens={skadTokens}
                            messages={messages}
                            deviceIds={deviceIds}
                            labels={labels}
                            sanPartners={sanPartners}
                        />
                    </div>
                </Box>
    );
}