import { Box} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ClickSpamTable from '../../entities/components/ClickSpam/ClickSpamTable';
import CircularProgress from '@mui/material/CircularProgress';
import { InfoTooltip } from '../../shared/components/infoTooltip/InfoTooltip';

export const ClickSpamPage = () =>{
    const [clickSpam, setClickSpam] = useState([]);
    useEffect(() => {
        fetch('/api/clickspam').then(res => res.json()).then(data => {
            setClickSpam(data.clickSpam);
        });
    }, []);
    
    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <h1>Click Spam</h1>
                        {clickSpam.length > 0 &&
                        <>
                            <InfoTooltip message="Source: Data Science DB | Refreshed: instant" />
                            <ClickSpamTable data={clickSpam} />
                        </>
                        }
                        {clickSpam.length === 0 &&
                        <CircularProgress />
                        }
                    </div>
                </Box>
    );
}