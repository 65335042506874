import { useEffect, useState } from "react";
import { DatePickerButton } from "@adjust/components";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button, Card, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import dayjs from 'dayjs';

type DateRange = {
    startDate: Date | null,
    endDate: Date | null
}
export const PeriodFilter = (_props:any) =>{
    const [dateRange, setDateRange] = useState<DateRange >({
        startDate: null,
        endDate: null
    });
    const { startDate, endDate } = dateRange;
    const [startTime,setStartTime] = useState(dayjs());
    const [endTime,setEndTime] = useState(dayjs());
    const [unit,setUnit] = useState('');
    const [unitValue,setUnitValue] = useState('');
    useEffect(() => {
        _props.onPeriodChange({'startDate':startDate,'endDate':endDate,'startTime':startTime,'endTime':endTime,'unit':unit,'unitValue':unitValue});
    },[startDate,endDate,startTime,endTime,unit,unitValue]);

    const setPeriod = () =>{
        setStartTime(dayjs());
        setEndTime(dayjs());
        setDateRange({'startDate': null, 'endDate':null})
        setUnit('');
        setUnitValue('');
    }
    let sincePeriod = false;
    let normalPeriod = false;

    if(unit !== '' || unitValue !== ''){sincePeriod = false;}
    else{if(startDate !== null || endDate !== null){sincePeriod = true;}}

    if(unit !== '' || unitValue !== ''){normalPeriod = true;}
    else{if(startTime !== null || endTime != null){normalPeriod = false;}}

    return(
        <Card variant="outlined">
            <Grid container spacing={6} style={{marginBottom:'1%'}} >
                <Grid item xs={5} style={{marginLeft:'2%',marginRight:'-2%'}}>
                    <h2 style={{color: '#005ff7',marginTop:'0%'}}>Period</h2>
                    <Grid container spacing={6} >
                        <Grid item xs={1}>
                            <div>Date:</div>
                        </Grid>
                        <Grid item xs={10}>
                            <DatePickerButton
                                startDate={startDate}
                                endDate={endDate}
                                onApply={setDateRange}
                            />
                        </Grid>
                    </Grid>
                    <br/> <br/>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TimePicker
                                    label="Start Time:"
                                    value={startTime}
                                    onChange={(newStartTime:any) => setStartTime(newStartTime)}
                                    disabled={normalPeriod}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TimePicker
                                    label="End Time:"
                                    value={endTime}
                                    onChange={(newEndTime:any) => setEndTime(newEndTime)}
                                    disabled={normalPeriod}
                                />
                            </Grid>
                        </Grid>
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={1}  justifyContent="center" alignItems="center" display="flex" >
                    <Divider orientation="vertical"/>
                </Grid>
                <Grid item xs={6}>
                    <br/><br/>
                    <div>Since:</div>
                    <TextField style={{width:'30%'}} type="number" label={'e.g. 24'} margin="normal" value={unitValue} disabled={sincePeriod} onChange={(newUnitValue:any) => setUnitValue(newUnitValue.target.value)}/>
                    <br/>
                    <FormControl style={{width:'30%'}}>
                    <InputLabel id="unit-name-select-label">Select an unit</InputLabel>
                        <Select
                            labelId="unit-select-label"
                            id="unit-select"
                            value={unit}
                            label={"Unit"}
                            onChange={(newUnit:any) => setUnit(newUnit.target.value)}
                            disabled={sincePeriod}
                        >
                            <MenuItem value={'seconds'}>seconds</MenuItem>
                            <MenuItem value={'minutes'}>minutes</MenuItem>
                            <MenuItem value={'hours'}>hours</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={11}  justifyContent="center" alignItems="center" display="flex" style={{marginTop:'-2%'}}>
                    <Button variant='contained' style={{width:'10%', backgroundColor:'#005ff7'}} onClick={() => setPeriod()}>Reset Period</Button>
                </Grid>
            </Grid>
            
        </Card>

    );
}