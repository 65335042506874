import { Router } from "./routes/Router";
import { AuthProvider } from './app/store/AuthContext';

function App() {
  return (
    <AuthProvider>
      <Router />
    </AuthProvider>
  );
}

export default App;
