import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import { Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from '@mui/material';

function getOs(os : any){
  if(os === 'itunes' && os === 'google'){
      return <div><AndroidIcon/><AppleIcon/></div>
  }
  else if(os === 'itunes'){
      return <div><AppleIcon/></div>
  }
  else if(os === 'google'){
      return <div><AndroidIcon/></div>
  }
  else{
      return <div></div>
  }
}

const columns: GridColDef[] = [
    { field: 'appName', headerName: 'App Name',flex: 1, minWidth: 250,
    },
    { field: 'appToken', headerName: 'App Token',flex: 1, minWidth: 200,
      renderCell: (params : any) => {
        return <Button variant="contained" component={Link} to={"/account/app/"+params.row.appToken}>{params.row.appToken} </Button>
      },
    },
    { field: 'accountName', headerName: 'Account Name',flex: 1, minWidth: 280},
    { field: 'accountId', headerName: 'Account Id',flex: 1, minWidth: 150,
      renderCell: (params : any) => {
        return <Button variant="contained" component={Link} to={"/account/"+params.row.accountId}>{params.row.accountId} </Button>
      },
    },
    { field: 'ios', headerName: 'Platform',flex: 1, minWidth: 80,
      renderCell: (params : any) => {
          return <div>{getOs(params.row.os)}</div>;
      },
    },
    { field: 'accountManager', headerName: 'Account Manager',flex: 1, minWidth: 150},
    { field: 'csmMail', headerName: 'CSM',flex: 1, minWidth: 150},
    { field: 'attribution', headerName: 'Attribution',flex: 1, minWidth: 100 },
  ];

function createData(
    id: Number,
    appName: string,
    appToken: string,
    accountId: Number,
    os: string,
    accountName: string,
    accountManager: Number,
    csmMail: string,
    attribution: string,
  ) {
    return {id,appName,appToken, accountId, os, accountName, accountManager, csmMail, attribution };
  }

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export default function MyAppsTable(_props : any) {
    const rows : any = [];
    for (var i = 0; i < _props.data.length; i++){
        rows.push(
            createData(
                i,
                _props.data[i]["appName"],
                _props.data[i]["appToken"],
                _props.data[i]["accountId"],
                _props.data[i]["os"],
                _props.data[i]["accountName"],
                _props.data[i]["accountManager"],
                _props.data[i]["csmMail"],
                _props.data[i]["attribution"]
            )
        );
    }
    return (
      <>
        {_props.isLoading &&
          <CircularProgress />
        }
        {!_props.isLoading &&
        <div style={{ height: 600, width: '100%' }}>
            <DataGrid
                rows={rows} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
        }
      </>
    );
}