import { Table, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { formatDate } from '../../../shared/components/formatDate/FormatDate';

export const AppVersionHistoryTable = (_props : any) => {
    const data = _props["data"];
    return (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 400 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                    <TableCell>Version</TableCell>
                    <TableCell>Release Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {data.map((data : any) => (
                <TableRow key={data.version}>
                    <TableCell>{data.version}</TableCell> 
                    <TableCell>{formatDate(data.date)}</TableCell> 
                </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      );
}