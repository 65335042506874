

import React from 'react';

  export default function Domo() {
  return (
    <div>
        <p>
        <iframe src="https://adjust.domo.com/embed/card/private/o2xML" width="600" height="600" title="Domo"></iframe>
        </p>
        <p>Hello e Bon Jour ?!</p>
    </div>
  );
}
