import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import {useState } from "react";

const services = [
    'Accounts',
    'Apps',
    'Contacts',
    'Emails',
    'Features',
  ];

export default function QueryDropdown (props : any){
    const [tool,setTool] = useState(props.tool);

    const handleChange = (event: SelectChangeEvent) => {
        setTool(event.target.value as string);
        props.onToolChange(event.target.value as string);
      };

    return (
        <FormControl style={{width:'20%'}}>
          <InputLabel id="QueryDropdownlabel">Service</InputLabel>
          <Select
            labelId="SelectQueryDropdown"
            id="SelectQueryDropdown"
            value={tool}
            label="Service"
            onChange={handleChange}
          >
          {services.map((service) =>
              <MenuItem value={service} key={service}>{service}</MenuItem>
          )}
          </Select>
      </FormControl>
    );                                   
}