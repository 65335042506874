import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { WelcomeBackTable } from '../../entities/components/welcomeBackTable/WelcomeBackTable';

export const WelcomeBackPage = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        fetch('/api/welcomeback').then(res => res.json()).then(data => {
            setData(data["data"]);
        });
    }, []);
    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <div>
                <h1>Welcome back</h1>
                <WelcomeBackTable data={data} />
            </div>
        </Box>
    );
}
