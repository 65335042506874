import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { FailedCallbacksTable } from "./FailedCallbacksTable";
import { InfoTooltip } from "../../../shared/components/infoTooltip/InfoTooltip";

export const FailedCallbacksPage = () => {
    const [failedCallbacks,setFailedCallbacks]:any = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true)
        fetch('/api/failedcallbacks').then(res => res.json()).then(data => {
            setFailedCallbacks(data["failedCallbacks"]);
            setIsLoading(false); 
        });
    }, []);


    const handleSeenChange = (value : any) =>{
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "callbackId": value })
        };
        fetch('/api/setcallbackasseen', requestOptions).then(res => res.json()).then(data => {
            setFailedCallbacks(data["failedCallbacks"]);
        });
    }

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <h1>Failed Callbacks</h1>
                    {isLoading &&
                    <CircularProgress />
                    }
                    {!isLoading &&
                    <>
                        <InfoTooltip message="Source: Grafana | Refreshed: instant" />
                        <FailedCallbacksTable callbacks={failedCallbacks} onSeenChange={handleSeenChange} />
                    </>
                    }
                </Box>
    );
}