import { Button, Input } from '@adjust/components';
import { Tile } from '@adjust/components/build/Tile/Tile';
import { Box, Card, Grid} from '@mui/material';
import React, { useState } from 'react';

export default function TrackerInfoPage() {
    const [trackerToken, setTrackerToken] = useState('');
    const [trackerInfo,setTrackerInfo] = useState({});

    function searchTrackerInfo(trackerToken : any){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"trackerToken" : trackerToken })
        };
        fetch('/api/trackerinfo', requestOptions)
            .then(response => response.json()).then(data => {
                setTrackerInfo(data);
            });
    }

    return (
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <div>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                 <h1>Check info about trackers:</h1>
                            </Grid>
                            <Grid item xs={12}>
                                <Tile title="Check info about trackers:">
                                <Input
                                    label="Tracker Token"
                                    id="trackerToken" 
                                    name="trackerToken"
                                    value={trackerToken}
                                    css={{ width: "40%"}}
                                    onClear={() => {
                                        setTrackerToken('');
                                    }}
                                    onChange={(e) => {
                                        setTrackerToken(e.target.value);
                                    }}
                                />
                                <br/>
                                <Button  type="submit" kind="primary" label="Search"  onClick={() => searchTrackerInfo(trackerToken)}></Button>{' '}
                                </Tile>
                            </Grid>

                            <Grid item xs={12}>
                                <Card>
                                    <pre>{JSON.stringify(trackerInfo, null, 2)}</pre>
                                </Card>
                            </Grid>
                        </Grid>
                           
                    </div>
                </Box>
    );
}