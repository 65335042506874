import { Tabs } from "@adjust/components";
import { useState, useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

const RedirectLink = (tabValue : string, appToken:string, navigate:NavigateFunction) => {
    if (tabValue === '1'){
        navigate('/account/app/' + appToken);
    }
    if (tabValue === '2'){
        navigate('/account/app/' + appToken + '/settings');
    }
    if (tabValue === '3'){
        navigate('/account/app/' + appToken + '/sdksentinel');
    }
    if (tabValue === '4'){
        navigate('/account/app/' + appToken + '/history');
    }
    if (tabValue === '5'){
        navigate('/account/app/' + appToken + '/anomalies');
    }
    if (tabValue === '6'){
        navigate('/account/app/' + appToken + '/ceph' );
    }
    if (tabValue === '7'){
        navigate('/account/app/' + appToken + '/clickspamhistory' );
    }
    if (tabValue === '8'){
      navigate('/account/app/' + appToken + '/sananalysis' );
  }
  if (tabValue === '9'){
      navigate('/account/app/' + appToken + '/events' );
  }
  if (tabValue === '10'){
      navigate('/account/app/' + appToken + '/rawexportsettings' );
  }
  if (tabValue === '11'){
      navigate('/account/app/' + appToken + '/partner' );
  }
  if (tabValue === '12'){
    navigate('/account/app/' + appToken + '/attributionsettings' );
  }
  if (tabValue === '13'){
    navigate('/account/app/' + appToken + '/appsdks' );
  }
}

export const AppSegmentedButton = (props : any) => {
    let appToken = props.appToken;
    const [tabValue, setTabValue] = useState(props.tabValue);
    let navigate = useNavigate();
    useEffect(() => {
        RedirectLink(tabValue, appToken,navigate) 
    }, [tabValue]);
    
    const items = [
        {
          id: '1',
          label: 'Overview',
        },
        {
          id: '2',
          label: 'Platform Setup',
        },
        {
          id: '3',
          label: 'SDK Sentinel',
          disabled: true,
        },
        {
          id: '4',
          label: 'History'
        },
        {
          id: '5',
          label: 'Anomalies'
        },
        {
          id: '6',
          label: 'Ceph Collector',
        },
        {
          id: '7',
          label: 'Click Spam History',
        },
        {
          id: '8',
          label: 'San Analysis',
        },
        {
          id: '9',
          label: 'Events',
        },
        {
          id: '10',
          label: 'Raw data export',
        },
        {
          id: '11',
          label: 'Partner',
        },
        {
          id: '12',
          label: 'Attribution Settings',
        },
        {
          id: '13',
          label: 'App Sdks',
        },
      ];
    return (
        <Tabs items={items} value={tabValue} onChange={setTabValue}/>
    );
  

}