import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import MyAccountsTableSC from '../../entities/components/MyAccounts/MyAccountsTableSC';
import { InfoTooltip } from '../../shared/components/infoTooltip/InfoTooltip';
import { MyAccountsCard } from '../../entities/components/MyAccounts/MyAccountsCard';
 
function getBob(data:any){
    var totalAmount = 0;
    for (var i = 0; i < data.length; i++){
        var acv = Number(data[i]['acv']);
        totalAmount = totalAmount + acv;
    }
    return totalAmount;
}

export const SalesOverviewTeamPage = () => {
    const [accountData, setAccountData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        fetch('/api/allaccountssalesteam').then(res => res.json()).then(data => {
            setAccountData(data["accounts"]);
            setLoading(false);
        });
    }, []);

    return (
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <div>
                    <h1 style={{marginTop:'0px'}}>All Team Accounts</h1>
                    <InfoTooltip message="Source: Salesforce DB | Refreshed: once a day" />
                    {isLoading &&
                        <CircularProgress />
                    }
                    {!isLoading &&
                    <>
                        <MyAccountsCard data={getBob(accountData)} />
                        <br/>
                        <MyAccountsTableSC data={accountData}></MyAccountsTableSC>
                    </>
                    }
                </div>
            </Box>
    );
}