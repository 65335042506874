import React from 'react';
import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';

const columns: GridColDef[] = [
  { field: 'networkName', headerName: 'Network Name',flex: 1},
  { field: 'appCount', headerName: 'App Count',flex: 1},
  { field: 'isPaused', headerName: 'Is Paused',flex: 1},
  
];

function createData(
    id: number,
    networkName: string,
    appCount: number,
    isPaused: boolean
  ) {
    return { id,networkName, appCount, isPaused};
  }

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }
export const AccountNetworksTable = (_props : any) => {
    const networks = _props.networks.networks;
    const rows : any = [];
    if(networks !== undefined){
        for (var i = 0; i < networks.length; i++){
            rows.push(
                createData(
                    i,
                    networks[i].networkName,
                    networks[i].appCount,
                    networks[i].isPaused,
                    
                )
            );
        }
    }
    return (
        <div style={{ height: 650, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    );
}
