import { DataGrid, GridColDef,GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { formatDate } from '../../shared/components/formatDate/FormatDate';
import {NumericFormat} from 'react-number-format';

const columns: GridColDef[] = [
    { field: 'month', headerName: 'Month',flex: 1, minWidth: 100},
    { field: 'packagemrr', headerName: 'Package MRR',flex: 1, minWidth: 150,
        renderCell: (params : any) => {
            return <NumericFormat value={params.row.packagemrr} displayType={'text'} thousandSeparator={true}/>
          }
    },
    { field: 'includedAttributions', headerName: 'Included Attributions',flex: 1, minWidth: 200,
        renderCell: (params : any) => {
            return <NumericFormat value={params.row.includedAttributions} displayType={'text'} thousandSeparator={true}/>
          }
    },
    { field: 'actualAttributions', headerName: 'Actual Attributions',flex: 1, minWidth: 200,
        renderCell: (params : any) => {
            return <NumericFormat value={params.row.actualAttributions} displayType={'text'} thousandSeparator={true}/>
          }
    },
    { field: 'avgAttributionsL3Month', headerName: 'Avg Attributions L3 Month',flex: 1, minWidth: 200,
        renderCell: (params : any) => {
            return <NumericFormat value={params.row.avgAttributionsL3Month} displayType={'text'} thousandSeparator={true}/>
          }
    },
    { field: 'weigthedAvgAttributionsL12Month', headerName: 'Weigthed Avg Attributions L12 Month',flex: 1, minWidth: 200,
        renderCell: (params : any) => {
            return <NumericFormat value={params.row.weigthedAvgAttributionsL12Month} displayType={'text'} thousandSeparator={true}/>
          }
    },
    { field: 'suggestedPackagePrice', headerName: 'Suggested Package Price',flex: 1, minWidth: 200,
        renderCell: (params : any) => {
            return <NumericFormat value={params.row.suggestedPackagePrice} displayType={'text'} thousandSeparator={true}/>
          }
    },
    { field: 'suggestedPriceImpact', headerName: 'Suggested Price Impact',flex: 1, minWidth: 200,
        renderCell: (params : any) => {
            return <NumericFormat value={params.row.suggestedPriceImpact} displayType={'text'} thousandSeparator={true}/>
          }
    },
    { field: 'suggestChangeType', headerName: 'Suggest Change Type',flex: 1, minWidth: 200},
    { field: 'forecastAttributions12Month', headerName: 'Forecast Attributions 12 Month',flex: 1, minWidth: 200,
        renderCell: (params : any) => {
            return <NumericFormat value={params.row.forecastAttributions12Month} displayType={'text'} thousandSeparator={true}/>
          }
    },
    { field: 'forecastPackagePrice', headerName: 'Forecast Package Price',flex: 1, minWidth: 200,
        renderCell: (params : any) => {
            return <NumericFormat value={params.row.forecastPackagePrice} displayType={'text'} thousandSeparator={true}/>
          }
    },
    { field: 'forecastPriceImpact', headerName: 'Forecast Price Impact',flex: 1, minWidth: 200,
        renderCell: (params : any) => {
            return <NumericFormat value={params.row.forecastPriceImpact} displayType={'text'} thousandSeparator={true}/>
          }
    },
    { field: 'forecastChangeType', headerName: 'Forecast Change Type',flex: 1, minWidth: 200},
    { field: 'attributionsForecastWeightedAv', headerName: 'Attributions Forecast Weighted Av',flex: 1, minWidth: 200},
    { field: 'attributionForecastOutlook', headerName: 'Attribution Forecast Outlook',flex: 1, minWidth: 200},
    { field: 'weightedAvAttributionUsage', headerName: 'Weighted Av Attribution Usage',flex: 1, minWidth: 200},
  ];

function createData(
    id: number,
    month: string,
    packagemrr: string,
    includedAttributions: number,
    actualAttributions: number,
    avgAttributionsL3Month: number,
    weigthedAvgAttributionsL12Month: number,
    suggestedPackage: string,
    suggestedPackagePrice: number,
    suggestedPriceImpact: number,
    suggestChangeType: string,
    forecastAttributions12Month: string,
    forecastPackagePrice: number,
    forecastPriceImpact: number,
    forecastChangeType: string,
    attributionsForecastWeightedAv: number,
    attributionForecastOutlook: string,
    weightedAvAttributionUsage: number
  ){
    return { id, month, packagemrr, includedAttributions, actualAttributions, avgAttributionsL3Month, weigthedAvgAttributionsL12Month, suggestedPackage, suggestedPackagePrice, suggestedPriceImpact,
        suggestChangeType, forecastAttributions12Month, forecastPackagePrice, forecastPriceImpact, forecastChangeType, attributionsForecastWeightedAv, attributionForecastOutlook, weightedAvAttributionUsage
      };
  }

function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

export const AttributionForecastTable = (_props : any) =>{
    const data = _props.data;

    const attributionData : any = [];
    if(data !== undefined){
        for (var i = 0; i < data.length; i++){
            attributionData.push(
                createData(
                    i,
                    formatDate(data[i][1]),
                    data[i][3],
                    data[i][4],
                    data[i][5],
                    data[i][6],
                    data[i][7],
                    data[i][8],
                    data[i][9],
                    data[i][10],
                    data[i][11],
                    data[i][12],
                    data[i][14],
                    data[i][15],
                    data[i][16],
                    
                    data[i][18],
                    data[i][19],
                    data[i][20],
                )
            );
        }
    }

    return (
        <div style={{ height: 650, width: '100%' }}>
            <DataGrid 
                rows={attributionData} 
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
                initialState={{
                    sorting: {
                      sortModel: [{ field: 'createdAt', sort: 'desc' }],
                    },
                }}
            />
        </div>
    );
}