import { Breadcrumbs } from '@adjust/components/build/Breadcrumbs/Breadcrumbs';
import { Grid} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppInfo from '../../../shared/components/appInfo/AppInfo';
import { AppSegmentedButton } from '../../../shared/components/appSegmentedButton/AppSegmentedButton';
import { ClickSpamTable } from '../../../entities/components/Apps/ClickSpamTable';
import { AllowedTrackerTable } from '../../../entities/components/Apps/AllowedTrackerTable';
import { InfoTooltip } from '../../../shared/components/infoTooltip/InfoTooltip';

function MyBreadcrumbs(accountId:any,accountName:any,appToken:any,appName:any) {
    let navigate = useNavigate();
    const items = [
        { label: 'My Accounts', onClick: () => {navigate('/myaccounts')}},
        { label: accountName, onClick: () => {navigate('/account/' + accountId)}},
        { label: 'Apps', onClick: () => {navigate('/account/' + accountId + '/apps')}},
        { label: appName, onClick: () => {navigate('/account/app/'+ appToken)}},
        { label: 'Click Spam History', onClick: () => {navigate('/account/app/' + appToken + '/clickspamhistory')}},
    ];
    return <Breadcrumbs items={items} />;
}

export const AppClickSpamHistory = (_props:any) => {
    const appToken = _props.appToken;
    const appInfo = _props.appInfo;
    const accountName = _props.accountName ;

    const [clickSpam, setClickSpam] = useState([]);
    const [allowedTracker, setAllowedTracker] = useState([]);
      useEffect(() => {
          fetch('/api/appclickspamhistory/'+appToken).then(res => res.json()).then(data => {
            setClickSpam(data["clickSpam"]);
            setAllowedTracker(data["allowedTracker"]);
          });
      }, [appToken]);


    return (
        <div>
            {MyBreadcrumbs(appInfo[3],accountName,appInfo[2],appInfo[1])}
            <AppSegmentedButton appToken = {appToken} tabValue='7' />
            <br/><br/>
            <Grid container spacing={6}>
                <Grid item xs={12}>
                    <Grid container spacing={6}>
                        <Grid item xs={4}>
                            <AppInfo appInfo={appInfo} accountName={accountName} />
                        </Grid> 
                        <Grid item xs={8}>
                            <InfoTooltip message="Source: Data Science DB | Refreshed: instant" />
                            <ClickSpamTable clickSpam = {clickSpam} />
                            <p>Allowed Trackers</p>
                            <AllowedTrackerTable tracker ={allowedTracker} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
        
    );
}