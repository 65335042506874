import { Button,ButtonGroup, Card } from "@mui/material";
import { useState } from "react";
import { AttributionsChart } from "./AttributionsChart";
import KeyStatsTable from "./KeyStatsTable";

export const AttributionKeyStats = (props : any) =>{
    const [graph,setGraph] = useState(true);
    let attributions = props.attributions;

  return (
    <Card>
        <ButtonGroup
            disableElevation
            variant="contained"
            style={{marginLeft:'5px',marginTop:'5px'}}
        >
            {graph &&
            <>
                <Button style={{backgroundColor:'#005ff7'}} onClick={() => setGraph(true)}>Graph</Button>
                <Button variant="outlined" onClick={() => setGraph(false)}>Table</Button>
            </>
            }
            {!graph &&
            <>
                <Button variant="outlined" onClick={() => setGraph(true)}>Graph</Button>
                <Button style={{backgroundColor:'#005ff7'}} onClick={() => setGraph(false)}>Table</Button>
            </>
            }
            
        </ButtonGroup>
        {graph &&
            <AttributionsChart attributions={attributions} />
        }
        {!graph &&
            <KeyStatsTable keystat={attributions} />
        }
    </Card>
  );
}
